/* eslint-disable no-console */
/* import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'; */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import GlobalStyles from './GlobalStyles';
import App from './App';
import AppLoading from './components/common/AppLoading';
import Logger from './utils/Logger';
import { store, persistor } from './store/redux/reduxStore';
import Util from './utils/Util';
import { country, state } from './utils/timezones';
import CacheProvider from './context/Cache';

// Client Error to server
window.addEventListener('error', (message, url, lineNo, columnNo, error, currentPage) => {
  console.log(
    '%cEnviroment:',
    'background: black; color: white; padding: 5px;',
    message,
    url,
    lineNo,
    columnNo,
    error,
    currentPage,
    window.location.href
  );
  Logger.logWindowError(message, url, lineNo, columnNo, error, currentPage, window.location.href);
});

console.log('%cEnviroment:', `background: ${process.env.NODE_ENV === 'development' ? '#FFAC1C' : '#FF0000'}; color: black; padding: 5px;`, process.env.NODE_ENV);
console.log('%cCountry:', 'background: #50C878; color: #000; padding: 5px;', `${country}, ${state}`);
console.log('%cBrowser:', 'background: #50C878; color: #000; padding: 5px;', Util.getBrowser());

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <PersistGate persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <Suspense fallback={<AppLoading />}>
              <CacheProvider>
                <App />
              </CacheProvider>
            </Suspense>
          </I18nextProvider>
        </PersistGate>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.querySelector('#root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

reportWebVitals(Logger.logWebVitals);

if (navigator.serviceWorker) {
  navigator.serviceWorker
    .register('./serviceWorker.js')
    .then((registration) => {
      console.log('ServiceWorker registration successful with scope:', registration.scope);
    })
    .catch((error) => {
      console.log('ServiceWorker registration failed:', error);
    });
}
