import { ACTION_TYPES } from '../ReduxActionTypes';
import Util from '../../../utils/Util';

const initialState = {
  appLanguage: {
    language: Util.getLanguageNameByCode(Util.getNavigatorLanguageCode()),
    code: Util.getNavigatorLanguageCode()
  },
  setting: {},
  recipientCountries: [],
  error: undefined,
  loading: false,
  coockieConcentIsVisible: true,
  faqItems: [],
  maintenanceMessage: undefined
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_SETTING:
      return { ...state, setting: action.payload };
    case ACTION_TYPES.SET_SENDER_COUNTRIES:
      return { ...state, senderCountries: action.payload };
    case ACTION_TYPES.SET_RECIPIENT_COUNTRIES:
      return { ...state, recipientCountries: action.payload };
    case ACTION_TYPES.SET_APP_ERROR:
      return { ...state, error: action.payload };
    case ACTION_TYPES.SET_APP_LOADING:
      return { ...state, loading: action.payload };
    case ACTION_TYPES.SET_APP_LANGUAGE:
      return { ...state, appLanguage: action.payload };
    case ACTION_TYPES.SET_COOCKIE_CONCENT_VISIBLE:
      return { ...state, coockieConcentIsVisible: action.payload };
    case ACTION_TYPES.SET_FAQ_ITEMS:
      return { ...state, faqItems: action.payload };
    case ACTION_TYPES.SET_MAINTENANCE_MESSAGE:
      return { ...state, maintenanceMessage: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
