import makeStyles from '@mui/styles/makeStyles';

const addRecipientDialogStyle = makeStyles((theme) => ({
  gridContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  dialogPaper: {
    '&.MuiDialog-paperWidthSm': {
      maxWidth: '720px',
      [theme.breakpoints.down('md')]: {
        maxWidth: 'min-content'
      }
    }
  },
  title: {
    opacity: 0.9,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.78,
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  saveUserButton: {
    width: '100%',
    height: 62,
    marginTop: 20,
    marginBottom: 10,
    padding: '22px 120px',
    objectFit: 'contain',
    opacity: 0.9,
    borderRadius: theme.borderRadiusButton,
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover,&:focus': {
      background: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  saveUserButtonText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    paddingRight: 4,
    textTransform: 'capitalize'
  },
  closeUserRegistrationButtonText: {
    opacity: 0.9,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#919ba8',
    textTransform: 'none'
  },
  transferHabitContainer: {
    marginTop: 50,
    marginBottom: 50,
    textAlign: 'left'
  },
  countryMultiSelectContainer: {
    marginBottom: 50,
    textAlign: 'left'
  },
  transferHabitTitle: {
    textAlign: 'left',
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0px',
    color: '#37aacb',
    padding: theme.spacing(1),
    paddingBottom: 0
  },
  transferHabitSubTitle: {
    textAlign: 'left',
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#919ba8',
    padding: theme.spacing(1)
  },
  pepTitle: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0px',
    color: '#37aacb',
    padding: theme.spacing(3)
  },
  userIsPEPTitle: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0px',
    color: '#37aacb',
    padding: theme.spacing(1)
  },
  dialogPepTitle: {
    fontFamily: 'Rubik',
    fontSize: 24,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.78,
    letterSpacing: '-0.42px',
    color: '#f1f6f8',
    padding: theme.spacing(2)
  },
  dialogPepSubtitle: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#f1f6f8',
    padding: theme.spacing(3),
    marginTop: '-50px'
  },
  emailConcentContaier: {},
  emailConcent: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  transferHabitRadioButtonGroupContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: '8px'
  },
  transferHabitRadioButtonContainer: {
    display: 'grid',
    rowGap: '10px',
    boxShadow: '4px 0px 18px -3px rgb(0 0 0 / 20%)',
    /* background: 'rgb(33, 162, 198, 0.1)', */
    padding: '20px',
    /*   margin: '2px', */
    borderRadius: theme.borderRadius,
    cursor: 'pointer',
    '& div > input': {
      float: 'right',
      cursor: 'pointer'
    },
    '& div > label': {
      /* float: 'left', */
      display: 'block',
      cursor: 'pointer',
      height: '100%',
      margin: 'auto'
    },
    '&:hover': {
      /* boxShadow: '0 18px 48px 0 rgba(145, 155, 168, 15%)' */
      outline: '1px solid rgba(33, 161, 197, 1)',
      /* border: '1px solid transparent' */
      transition: 'all 0.1s ease-in-out'
    },
    '&:focused': {
      backgroundColor: '#37aacb'
    }
  },
  radioButtonGroupContainer: {
    background: 'rgb(33, 162, 198, 0.1)'
  },
  emailHelpPopover: {
    position: 'relative',
    bottom: '-45px',
    left: '-20px',
    zIndex: 10,
    height: 0,
    textAlign: 'end'
  },
  countryMultiSelectLabel: {
    color: '#2f4662',
    margin: '5px',
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: '500',
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0px',
    cursor: 'pointer',
    padding: '5px'
  },
  countryMultiSelect: {
    marginTop: 10,
    '& .MuiSelect-icon': {
      position: 'relative'
    },
    borderRadius: theme.borderRadiusInput,
    backgroundColor: '#eef3f9',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    padding: '20px 15px 10px',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    } /* ,
         '&.Mui-focused': {
      border: 'solid 1px #21a1c5'
    } */
  },
  multiCountrySelectMenuItem: {
    color: '#2f4662',
    cursor: 'pointer',
    margin: '5px',
    zIndex: 10,
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0px',
    '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'aliceblue'
    }
  }
}));

export default addRecipientDialogStyle;
