import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert , Box } from '@mui/material';

import API from '../../utils/api';
import { logout } from '../../context/auth';
import { errorHandler } from '../../utils/errorMapper';
import SessionTimeProgress from './SessionTimeProgress';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: '#2f4662',
    fontSize: theme.typography.pxToRem(22),
    fontStyle: 'normal',
    fontFamily: 'Rubik',
    fontWeight: 500,
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-1.42px'
  },
  dialogContent: {
    padding: theme.spacing(3)
  },
  dialogContentText: {
    padding: theme.spacing(1),
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#4a6582'
  }
}));

function SessionDialog({ getTimeLeft, toggleDialog, open }) {
  const classes = useStyles();
  const [countDown, setCountDown] = useState();
  const [error, setError] = useState();
  const { t } = useTranslation(['dashboard-common']);

  const sessionTimeOut = () => {
    const authentication = localStorage.getItem('authentication');
    if (authentication) {
      const decodedJwt = jwt.decode(authentication, { complete: true });
      const timeLeft = new Date(decodedJwt.payload.exp * 1000) - new Date();
      setCountDown(timeLeft);
      toggleDialog(false);
    }
  };

  function millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000).toFixed(0);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    const time = `${minutes === 0 ? Math.abs(minutes) : minutes}:${
      (seconds < 10 && seconds > -10) || seconds === 60 ? '0' : ''
    }${seconds <= 0 ? Math.abs(seconds) : seconds}`;
    getTimeLeft(time);

    return time;
  }

  const handleClose = () => {
    toggleDialog(!open);
  };

  const refreshSession = () =>
    API.get('/refresh')
      .then(() => {
        const refreshedAuth = Cookies.get('authentication');
        localStorage.setItem('authentication', refreshedAuth);
        handleClose();
      })
      .catch((_error) => {
        localStorage.clear();
        setError(_error);
      });

  useEffect(() => {
    const interval = setInterval(() => {
      sessionTimeOut();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const sessionEnded = millisToMinutesAndSeconds(countDown) <= '0:01';

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="session-dialog-title"
        aria-describedby="session-dialog-description"
        transitionDuration={500}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="session-dialog-title" className={classes.dialogTitle}>
          {sessionEnded ? t('youHaveBeenLoggedOut') : t('youAreAboutToBeLoggedOut')}
        </DialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          {!!error && <Alert severity={error?.type || 'error'}>{errorHandler(error)}</Alert>}
          <Box
            className={classes.dialogContentText}
            id="session-dialog-description"
            style={{ display: 'flex', gap: 15 }}
          >
            {sessionEnded ? null : <SessionTimeProgress countDown={millisToMinutesAndSeconds(countDown)} />}
            {sessionEnded ? t('youHaveBeenLoggedOutDueToInactivity') : t('youWillSoonBeLoggedOutAutomatically')}
          </Box>
        </DialogContent>
        <DialogActions>
          {sessionEnded ? (
            <Button onClick={logout} color="primary">
              {t('logInAgain')}
            </Button>
          ) : (
            <>
              <Button onClick={refreshSession} color="primary">
                {t('stayLoggedIn')}
              </Button>
              <Button onClick={logout} color="primary" autoFocus>
                {t('logout')}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

SessionDialog.defaultProps = {
  getTimeLeft: undefined,
  toggleDialog: undefined,
  open: false
};

SessionDialog.propTypes = {
  getTimeLeft: PropTypes.func,
  toggleDialog: PropTypes.func,
  open: PropTypes.bool
};
export default SessionDialog;
