import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
/**
 * Here we've decided to use a library for creating the QR images. At the time of
 * writing this library is safe to use. But you should always do your research
 * and not use libraries you do not trust.
 */
import QRLib from 'qrcode';

const qrCodeOptions = {
  width: 200,
  margin: 3,
  errorCorrectionLevel: 'L'
};

function ScanQR({ qrCode }) {
  const [qrImage, setQrImage] = useState();

  useEffect(() => {
    if (qrCode) {
      QRLib.toDataURL(qrCode, qrCodeOptions)
        .then((url) => {
          setQrImage(url);
        })
        .catch(() => {
          setQrImage(undefined);
        });
    }
  }, [qrCode]);

  if (!qrImage) {
    return null;
  }

  return (
    <div className='qr-scan-code'>
      <img style={{
        margin: 16,
        padding: 17,
        outline: '5px solid #21a1c5',
        outlineOffset: '-5px',
        WebkitMask: 'conic-gradient(at 32px 32px, #0000 75%, #000 0) 0 0/calc(100% - 32px) calc(100% - 32px), linear-gradient(#000 0 0) content-box'
      }}
        src={qrImage} alt="qr-code"
      />
    </div>
  );
}

ScanQR.defaultProps = {
  qrCode: ''
}

ScanQR.propTypes = {
  qrCode: PropTypes.string
}

export default ScanQR;