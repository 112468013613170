/* eslint-disable no-console */
import { useState } from 'react'

export function useCopyToClipboard() {
  const [copiedText, setCopiedText] = useState(null);
  const [error, setError] = useState(null);

  const copy = async (text) => {
    if (!navigator?.clipboard) {
      setError('Clipboard not supported')
      return false
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)
      return true
    } catch (_error) {
      setError('Copy failed', _error)
      setCopiedText(null)
      return false
    }
  }

  return [error, copiedText, copy]
}