import React from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import InputFeedback from './InputFeedback';

const styles = makeStyles((theme) => ({
  card: {
    maxWidth: 420
  },
  formFieldContainer: {
    display: 'Flex',
    justifyContent: 'center'
  },
  textfield: {
    borderRadius: theme.borderRadiusInput,
    backgroundColor: '#eef3f9',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    padding: '20px 15px 10px !important',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
    '&.Mui-focused': {
      border: 'solid 1px #21a1c5'
    }
  },
  noBorder: {
    border: 'none'
  },
  inputLabel: {
    width: 'max-content',
    height: '32px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#4a6582',
    zIndex: 10,
    padding: '20px'
  }
}));

function TextfieldWrapper({ name, fullWidth, shrink, readOnly, disableUnderline, customVariant, ...otherProps }) {
  const [field, meta] = useField(name);
  const classes = styles();

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth,
    variant: customVariant || 'outlined',
    value: otherProps.value,
    InputProps: {
      ...otherProps.InputProps,
      className: classes.textfield,
      classes: { notchedOutline: classes.noBorder },
      readOnly
    },
    InputLabelProps: {
      ...otherProps.InputLabelProps,
      className: classes.inputLabel,
      shrink
    }
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = <InputFeedback error={meta.error} />;
  }

  /* eslint-disable react/jsx-props-no-spreading */
  return <TextField disabled={readOnly} style={{ width: '100%' }} {...configTextfield} />;
}

export default TextfieldWrapper;

TextfieldWrapper.defaultProps = {
  fullWidth: false,
  otherprops: '',
  shrink: undefined,
  readOnly: false,
  disableUnderline: true,
  customVariant: 'outlined'
};

TextfieldWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  otherprops: PropTypes.string,
  shrink: PropTypes.bool,
  readOnly: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  customVariant: PropTypes.string
};
