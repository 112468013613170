import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types'; 
import makeStyles from '@mui/styles/makeStyles';
import Loading from './Loading';

const styles = makeStyles(() => ({
  iframe: {
    overflow: 'hidden',
    border: 0,
    alignSelf: 'center',
    position: 'relative',
    top: 0,
    left: 0,
    display: 'block',
    height: '100vh',
    backgroundColor: 'transparent',
    width: 'calc(100% + 17px)',
    '&:root': {
      backgroundColor: 'transparent'
    }
  }
}));

function Iframe({ source, width, height, title }) {
  const classes = styles();
  const iframeRef = useRef(null)

  useEffect(() => {}, [source]);

  if (!source) {
    return <Loading size={80} />;
  }
  return (
    <iframe
    ref={iframeRef}
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      className={classes.iframe}
      seamless
      scrolling="no"
      frameBorder="0"
      title={title}
      src={source}
      style={{ width, height }}
    />
  );
}

Iframe.defaultProps = {
  source: '', 
  width: undefined, 
  height: undefined, 
  title: ''
};

Iframe.propTypes = {
  source: PropTypes.string, 
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
  title: PropTypes.string
}

export default Iframe;
