import React from 'react';
import Lottie from 'react-lottie';
import makeStyles from '@mui/styles/makeStyles';
import animationData from '../../assets/lotties/logo-loading.json';

const styles = makeStyles(() => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loaderTriangle: {
    width: '100%',
    height: '100%'
  }
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function AppLoading() {
  const classes = styles();
  return (
    <div id="app-loading-container" className={classes.container}>
      <div className={classes.loaderTriangle}>
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
    </div>
  );
}
export default AppLoading;
