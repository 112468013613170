import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from '@mui/material';
import PlantBackground from '../../../assets/svg/PlantBackground.svg';
import ConfirmEmail from '../../../assets/img/ConfirmEmail.svg';
import Button from '../../common/Button';
import CheckList from '../../common/CheckList';
import UpdateEmail from './UpdateEmail';
import { errorHandler } from '../../../utils/errorMapper';
import PERMALINKS from '../../../constants/permalinks';
import DialogTitle from '../../common/DialogTitle';

const useStyles = makeStyles((theme) => ({
  welcomeContainer: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  cardContent: {
    padding: theme.spacing(2)
  },
  subTitle: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#4a6582',
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  subTitleThankYou: {
    fontFamily: 'Rubik',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#4a6582',
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  steps: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#4a6582',
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  continueButton: {
    width: 220,
    height: 62,
    marginTop: 20,
    marginBottom: 10,
    padding: '25 121',
    objectFit: 'contain',
    opacity: 1,
    borderRadius: theme.borderRadiusButton,
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover,&:focus': {
      background: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  continueButtonText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    paddingRight: 4,
    textTransform: 'none'
  },
  cardActionRoot: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  cardActionContainer: {
    background: `url(${PlantBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '200px',
    marginBottom: '-215px',
    opacity: 0.3
  },
  cardMediaRoot: {
    gridColumn: 1,
    gridRow: 1,
    objectFit: 'contain'
  },
  expandedTextTitleStyle: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#4a6582',
    textAlign: 'start',
    padding: theme.spacing(2)
  },
  expandedTextStyle: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.78,
    letterSpacing: 'normal',
    color: '#4a6582',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  helpExpandContainer: {
    background: '#f3f5fd',
    borderRadius: 5
  },
  mailLinkText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: 'normal',
    color: '#21a2c6',
    textDecoration: 'none'
  },
  itemLinkText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.78,
    letterSpacing: 'normal',
    color: '#21a2c6',
    textDecoration: 'none'
  },
  expandButton: { display: 'flex', position: 'relative', float: 'right', margin: '-52px 16px' }
}));

function EmailConfirmationDialog({
  handleClose,
  closeWithOutVerification,
  email,
  emailVerificationDialogOpen,
  showEmailNotVerifiedMessage,
  error
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation('dashboard-common');

  const handleExpand = () => setExpanded(!expanded);

  const failedVerificationSteps = [
    { text: t('cantFindTheVerificationEmail'), element: null },
    { text: t('didYouUseTheCorrectEmail'), element: <UpdateEmail /> },
    {
      text: (
        <>
          {t('stillNothing')}{' '}
          <Link
            color="inherit"
            className={classes.itemLinkText}
            href={PERMALINKS.CONTACT_US}
            target="_blank"
            rel="noreferrer"
          >
            {t('submitArequest')}
          </Link>{' '}
          {t('andWellTakeALook')}
        </>
      ),
      element: null
    }
  ];

  return (
    <Dialog
      onClose={handleClose}
      open={emailVerificationDialogOpen}
      scroll="body"
      classes={{ paper: classes.dialogPaper }}
      transitionDuration={500}
      maxWidth='md'
    >
      <div className={classes.welcomeContainer}>
        <DialogTitle
          title={t('verifyEmail')}
          subTitle={t('pleaseVerifyYourEmail')}
          icon={
            <CardMedia
              classes={{ root: classes.cardMediaRoot }}
              component="img"
              width="500"
              height="100"
              image={ConfirmEmail}
              alt="Celebration"
            />
          }
          onClose={closeWithOutVerification}
        />
        <Grid item xs={12} md={12} lg={12} className={classes.placeholderImgContainer}>
          {!showEmailNotVerifiedMessage ? null : (
            <Fade in={showEmailNotVerifiedMessage}>
              <Alert severity="warning" style={{ textAlign: 'left' }}>
                <AlertTitle>
                  <strong>{t('emailAddressIsNotVerified')}</strong>
                </AlertTitle>
                {t('accountCannotBeUsedUntilEmailVerified')} {t('weSentAnEmail')} <strong>{email}</strong>.{' '}
                {t('clickTheLinkInTheVerificationEmail')}
              </Alert>
            </Fade>
          )}
          <Card>
            <CardContent className={classes.cardContent}>
              {error ? (
                <Fade in={!!error}>
                  <Alert severity={error?.type || 'error'}>{errorHandler(error)}</Alert>
                </Fade>
              ) : null}
              <Typography gutterBottom variant="h4" component="p" className={classes.subTitle}>
                {t('weSentAnEmail')} <strong>{email}</strong>. {t('clickTheLinkInTheVerificationEmail')}
              </Typography>
              <div>
                <Typography gutterBottom variant="h5" component="p" className={classes.subTitleThankYou}>
                  {t('thankYou')}
                </Typography>
              </div>
                <CheckList
                  id="email-confirmation-dialog"
                  handleClick={handleExpand}
                  expanded={expanded}
                  title={t('cantVerifyYourEmail')}
                  items={failedVerificationSteps}
                  severity="info"
                />
                <div className={classes.cardActionContainer} />
                <div className={classes.expandButton}  
                  role="button"
                  onClick={handleExpand}
                  onKeyDown={handleExpand}
                  tabIndex={0}
                  style={{ cursor: 'pointer', width: '100%', justifyContent: 'right' }
                }>
                  {expanded ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 svg-icon-style">
                      <path d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 svg-icon-style">
                      <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                  )}
                </div>
            </CardContent>
            <CardActions classes={{ root: classes.cardActionRoot }}>
              <div style={{ zIndex: 10 }}>
                <Button
                  id="confirm-email-button"
                  className={classes.continueButton}
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  <span className={classes.continueButtonText}>{t('continue')}</span>
                </Button>
                <Typography gutterBottom variant="h4" component="p" className={classes.subTitle}>
                  <span className={classes.continueButtonText}>
                    {t('needHelp')}{' '}
                    <Link
                      color="inherit"
                      className={classes.mailLinkText}
                      href={PERMALINKS.CONTACT_US}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('contactUs')}
                    </Link>
                  </span>
                </Typography>
              </div>
            </CardActions>
          </Card>
        </Grid>
      </div>
    </Dialog>
  );
}

EmailConfirmationDialog.defaultProps = {
  error: undefined,
  email: '',
  closeWithOutVerification: undefined
};

EmailConfirmationDialog.propTypes = {
  email: PropTypes.string,
  showEmailNotVerifiedMessage: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  closeWithOutVerification: PropTypes.func,
  emailVerificationDialogOpen: PropTypes.bool.isRequired,
  error: PropTypes.objectOf(PropTypes.string)
};

export default EmailConfirmationDialog;
