import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setGlobalCookieConcentVisible } from '../../store/redux/actions/rootAction';
import useDevice from '../../hooks/useDevice';
import Title from './Title';

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: 'auto',
    padding: 0,
    margin: 0,
    width: '100%'
  },
  accordionSummary: {
    display: 'flex'
  },
  root: {
    width: 'max-content',
    maxWidth: '50%',
    backgroundColor: 'rgb(55, 71, 79, .99)',
    filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2))',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      maxWidth: '100%'
    }
  },
  alert: {
    backgroundColor: 'rgb(55, 71, 79, 0.0)',
    fontFamily: 'Inter',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#fff',
    width: '100%',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'left',
    '& .MuiAlert-icon': {
      fontSize: 40,
      color: '#2196f3'
    },
    '& .MuiAlert-action': {
      display: 'block'
    }
  },
  coockieText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#ffffff'
  },
  messageContainer: {
    display: 'flex'
  },
  iconButton: {
    '&:hover': {
      borderRadius: '5%'
    }
  },
  closeButton: {
    padding: '10px',
    color: '#fff',
    border: '1px solid #fff'
  }
}));
function CookieConsent(props) {
  const classes = useStyles();
  const { isMobileOrTablet } = useDevice();
  const { children } = props;
  const snackbarRef = useRef();
  const { t } = useTranslation(['landing-page-common']);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    open: useSelector((reduxState) => reduxState.app.coockieConcentIsVisible),
    Transition: Fade
  });
  const { loading } = useSelector((reduxState) => reduxState.app)
  const [expanded, setExpanded] = useState(!isMobileOrTablet || false);

  useEffect(() => {}, [isMobileOrTablet, expanded, state, loading])


  const handleClose = () => {
    dispatch(setGlobalCookieConcentVisible(false));
    setState({
      ...state,
      open: false
    });
  };

  return (
    <div id="cookie-consent-container" className={classes.container}>
      {children}
      <Snackbar
        style={{ width: '100%' }}
        aria-label='cookie-consent'
        ref={snackbarRef}
        id="cookie-consent-snackbar"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={state.open}
        TransitionComponent={state.Transition}
        key={state.Transition.name}
        ContentProps={{
          classes: {
            root: classes.root
          }
        }}
        message={
          <div className={classes.messageContainer}>
            <Accordion elevation={0} style={{ backgroundColor: 'rgb(55, 71, 79, .99)' }} expanded={!isMobileOrTablet || expanded} onClick={() => setExpanded(!expanded)}>
              <div style={{ display: 'grid', gridTemplateColumns: '0fr 3fr 0fr 0fr' }}>
                <span style={{ marginRight: 8 }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 svg-icon-style">
                    <path d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                  </svg></span>
                <Title 
                  customStyleTitle={{ color: 'white', opacity: 1 }} 
                  customStyleSubtitle={{ color: 'white', opacity: 1 }} 
                  subtitle={isMobileOrTablet && !expanded ? t('expandForMoreInfo') : null}
                >
                  {t('coockieConsentTitle')}
                </Title>
                {isMobileOrTablet ? <IconButton
                  id="cookie-consent-expand-button"
                  aria-label="expand"
                  color="inherit"
                  size="medium"
                  title={t('coockieConsentExapand')}
                  onClick={() => setExpanded(!expanded)}
                ><ExpandMoreIcon fontSize="inherit" style={{ color: '#fff' }} />
                </IconButton> : null}
                <IconButton
                  style={{ color: '#fff' }}
                  id="cookie-consent-message-close-button"
                  aria-label="close"
                  size="medium"
                  title={t('coockieConsentClose')}
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </div>
              <AccordionDetails>
                <Typography>
                  <span className={classes.coockieText}>
                    {t('coockieConsentText')}
                    <Link href="/cookie-policy">{t('coockieConsentPrivacyLinkText')}</Link>
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        }
      />
    </div>
  );
}

CookieConsent.propTypes = {
  children: PropTypes.node.isRequired
};

export default CookieConsent;