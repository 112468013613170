import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

const checkListStyles = makeStyles((theme) => ({
  listItemContainer: {
    display: 'flex'
  },
  serviceInfoRoot: {
    width: 'fit-content',
    minWidth: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  listItemIconRoot: {
    width: '35px',
    height: '20px',
    color: '#5cb660',
    margin: 3
  },
  item: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.78,
    letterSpacing: 'normal',
    color: '#4a6582',
    textAlign: 'left'
  },
  alertRoot: {
    backgroundColor: '#ddf4f6',
    '&.MuiAlert-standardSuccess .MuiAlert-icon': {
      color: '#21a1c5',
      display: 'none'
    }
  },
  alertTitle: {
    fontFamily: 'Rubik',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0,
    color: '#2f4662',
    textAlign: 'start',
    cursor: 'pointer'
  }
}));

function CheckList(props) {
  const { children, items, title, expanded, handleClick, severity, check, id } = props;
  const classes = checkListStyles();

  return (
    <div className={classes.serviceInfoRoot} id={`${id}-checklist`} aria-hidden="true">
      <Alert className={classes.alertRoot} severity={severity}>
        {title ?
        <AlertTitle onClick={handleClick} className={classes.alertTitle}>
          {title}
        </AlertTitle> : null}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {items.map((item, index) => (
            <div key={item.text} className={classes.listItemContainer}>
              {check ? (
                <div style={{ display: 'flex' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={classNames(classes.listItemIconRoot, 'h-6 w-6')}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <Typography variant="h6" gutterBottom className={classes.item}>
                    {item.text}
                  </Typography>
                  {item.element ? item.element : null}
                </div>
              ) : (
                <div key={item.text}>
                  <Typography variant="h6" gutterBottom className={classes.item}>
                    <b>{index + 1}</b>. <span>{item.text}</span>
                  </Typography>
                  <div>{item.element ? item.element : null}</div>
                </div>
              )}
            </div>
          ))}
        </Collapse>
      </Alert>
      {children}
    </div>
  );
}

CheckList.defaultProps = {
  children: undefined,
  items: [],
  title: '',
  expanded: true,
  handleClick: undefined,
  severity: 'success',
  check: false,
  id: 'deafult'
};

CheckList.propTypes = {
  children: PropTypes.node,
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  expanded: PropTypes.bool,
  handleClick: PropTypes.func,
  severity: PropTypes.string,
  check: PropTypes.bool,
  id: PropTypes.string
};
export default CheckList;
