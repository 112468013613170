/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
/* import API from './api'; */

export default class Logger {
  /**
   * Logs an error server side
   */
  static logWindowError(message, url, lineNo, columnNo, error, currentPage) {
    const payload = {
      message,
      url,
      lineNo,
      columnNo,
      error,
      currentPage
    };

    const contentAreaDiv = document.querySelector('.event-log-contents');
    if (contentAreaDiv) {
      payload.currentPage = contentAreaDiv.getAttribute('class');
    }
    if (payload) {
      /** TODO uncomment */
      if (error && process.env.NODE_ENV === 'development') {
        console.log(error);
        // API.post('/errors', error);
      }
      // API.post('/errors', error);
    }
  }

  static logError(error) {
    if (error && process.env.NODE_ENV === 'development') {
      console.log(error);
      // API.post('/errors', error);
    }
  }

  static logWebVitals(webVitals) {
    /** TODO uncomment */
    if (process.env.NODE_ENV === 'development') {
      console.log(webVitals);
    }
    // API.post('/web-vitals', webVitals);
  }
}
