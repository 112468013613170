import React, { useState } from 'react';

import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { Alert , Typography, Switch, Button, Fade, Grid } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { mapFormikErrorFields } from '../sign-up/mapFormikErrorFields';
import { FORM_TRANSLATIONS } from '../formTranslations';
import Textfield from '../../common/TextfieldWrapper';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  gridItem: {
    padding: 16
  },
  infoTitle: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662',
    padding: '34px 0px 0px 0px'
  },
  inputLabel: {
    width: 'fit-content',
    height: '32px',
    margin: '10px 83px 0 30px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  innerLabel: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    marginLeft: theme.spacing(0),
    color: theme.palette.globalAppTheme.globalAppTextColor
  },
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.globalAppTheme.globalAppTextColor
    }
  },
  inputBackground: {
    borderRadius: theme.borderRadius,
    backgroundColor: '#f8fafc'
  },
  buttonContainer: {
    textAlign: 'center',
    justifyContent: 'center',
    display: 'grid'
  },
  saveDetailsButton: {
    width: '220px',
    height: 62,
    marginTop: 40,
    marginBottom: 10,
    padding: '25 121',
    objectFit: 'contain',
    opacity: 0.9,
    borderRadius: theme.borderRadiusButton,
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover,&:focus': {
      background: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  saveDetailsButtonText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    paddingRight: 4,
    textTransform: 'initial',
    color: '#fff'
  },
  cancelButtonText: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#919ba8',
    textTransform: 'none'
  },
  emailConcentTitle: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  emailConcentAnswer: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662'
  },
  emailConcentContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr'
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  switchRoot: {
    width: '90px',
    height: '50px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  track: {
    width: '50px',
    height: '30px',
    borderRadius: '20px'
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.globalAppTheme.globalAppButtonColor,
      transform: 'translateX(40px)'
    },
    '& + $track': {
      backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  checked: {
    color: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover': {
      backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  thumb: {
    width: '32px',
    height: '32px',
    transform: 'translate(0px, -1px)'
  }
}));

const validationSchema = Yup.object().shape({
  email: Yup.string().email(FORM_TRANSLATIONS.formEnterValidEmail).required(FORM_TRANSLATIONS.formEmailIsRequired),
  street: Yup.string()
    .min(2, FORM_TRANSLATIONS.formStreetMustBeXOrMore)
    .required(FORM_TRANSLATIONS.formStreetNameIsRequired),
  zipCode: Yup.string()
    .min(2, FORM_TRANSLATIONS.formZipCodeMustBeXOrMore)
    .required(FORM_TRANSLATIONS.formZipCodeIsRequired),
  city: Yup.string().min(2, FORM_TRANSLATIONS.formCityMustBeXOrMore).required(FORM_TRANSLATIONS.formCityIsRequired),
  phoneNumber: Yup.string()
    .min(9, FORM_TRANSLATIONS.formEnterValidMobileNumber)
    .max(20, FORM_TRANSLATIONS.formEnterValidMobileNumber)
    .nullable()
    /** TODO ADD VALIDATION */
    /* .matches(/^\+*\({0,1}\d{1,4}\){0,1}[\d\s./-]*$/g, { message: FORM_TRANSLATIONS.formEnterValidMobileNumber }) */
    .required(FORM_TRANSLATIONS.formMobileNumberIsRequired),
  country: Yup.string().required(FORM_TRANSLATIONS.formCountryIsRequired),
  emailConsent: Yup.boolean().nullable()
});

function UpdateForm({ initialValues, updateUser, editMode, handleCancelEdit }) {
  const classes = useStyles();
  const { t } = useTranslation(['dashboard-common']);
  const [isEditAborted, setIsEditAborted] = useState(false);

  React.useEffect(() => {
    if(editMode) {
      setIsEditAborted(false);
    }
  }, [editMode, isEditAborted, initialValues])
  return (
    <Formik initialValues={initialValues} onSubmit={(values) => updateUser(values)} validationSchema={validationSchema}>
      {({
        values,
        errors,
        /* touched, */
        handleChange,
        /*  handleBlur, */
        /* handleSubmit, */
        isSubmitting
      }) => (

        <Form>
          {isSubmitting && !_isEmpty(errors) && (
            <Alert severity="warning" style={{ textAlign: 'justify', width: '100%' }} key={errors}>
              {FORM_TRANSLATIONS.formMustBeFilledIn}
              {mapFormikErrorFields(errors, t, FORM_TRANSLATIONS)}
            </Alert>
          )}
          <Grid container item direction="row" className={classes.gridContainer} spacing={2}>
            <Grid item xs={12} md={12} lg={12} className={classes.gridItem}>
              <div className={classes.emailConcentContainer}>
                <Typography className={classes.emailConcentTitle}>{t('mayWeSendYouInformation')}</Typography>
                <div className={classes.switchContainer}>
                  <Typography className={classes.emailConcentAnswer}>{t('no')}</Typography>
                  <Switch
                    color="primary"
                    classes={{
                      root: classes.switchRoot,
                      switchBase: classes.switchBase,
                      checked: classes.checked,
                      track: classes.track,
                      thumb: classes.thumb
                    }}
                    name="emailConsent"
                    size="medium"
                    checked={isEditAborted ? initialValues.emailConsent : values.emailConsent}
                    disabled={!editMode}
                    onChange={handleChange}
                    inputProps={{
                      'aria-label': 'controlled'
                    }}
                  />
                  <Typography className={classes.emailConcentAnswer}>{t('yes')}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          {/** Personal information */}
          <Grid container item direction="row" className={classes.gridContainer} spacing={2}>
            <Typography className={classes.infoTitle} noWrap>
              {t('personalInformation')}
            </Typography>
            <Grid container item direction="row" className={classes.gridContainer} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                <Textfield
                  fullWidth
                  onChange={handleChange}
                  value={isEditAborted ? initialValues.phoneNumber : values.phoneNumber}
                  label={t('formPhoneNumber')}
                  name="phoneNumber"
                  readOnly={!editMode}
                  shrink
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                <Textfield
                  fullWidth
                  onChange={handleChange}
                  value={isEditAborted ? initialValues.email : values.email}
                  label={t('formEmail')}
                  name="email"
                  readOnly={!editMode}
                  shrink
                />
              </Grid>
            </Grid>
          </Grid>
          <Fade in={editMode}>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.saveDetailsButton}
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => updateUser(values)}
                disabled={!_isEmpty(errors)}
              >
                <span className={classes.saveDetailsButtonText}>{t('save')}</span>
              </Button>
              <Button className={classes.cancelButtonText} onClick={() => { 
                  setIsEditAborted(true)
                  handleCancelEdit()
                }}>
                {t('cancel')}
              </Button>
            </div>
          </Fade>
        </Form>
        )}
    </Formik>
  );
}

UpdateForm.defaultProps = {
  initialValues: undefined,
  updateUser: undefined,
  editMode: undefined,
  handleCancelEdit: undefined
};

UpdateForm.propTypes = {
  initialValues: PropTypes.shape({
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    street: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    emailConsent: PropTypes.bool
  }),
  updateUser: PropTypes.func,
  editMode: PropTypes.bool,
  handleCancelEdit: PropTypes.func
};

export default UpdateForm;
