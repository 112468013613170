import makeStyles from '@mui/styles/makeStyles';

const contactFormStyle = makeStyles((theme) => ({
  contactFormContainer: {
    justifyContent: 'center'
  },
  mainText: {
    fontFamily: 'Inter',
    fontSize: '26px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#4a6582',
    marginBottom: '10px',
    textAlign: 'center'
  },
  subText: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#4a6582',
    marginBottom: '10px',
    textAlign: 'center'
  },
  gridContainer: {
    padding: '95px',
    paddingTop: '0px'
  },
  gridItem: {
    padding: 8
  },
  placeholderImgContainer: {
    display: 'inline-block',
    maxWidth: '100%',
    marginBottom: '1rem',
    border: '4px solid #fefefe',
    borderRadius: 0,
    boxShadow: '0 0 0 1px rgb(10 10 10 / 20%)',
    lineHeight: 0
  },
  contactButtonContainer: {
    alignItems: 'flex-end',
    position: 'relative',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      top: '45px',
      margin: 0
    }
  },
  extraLargeButtonLabel: {
    fontSize: theme.typography.pxToRem(18),
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  extraLargeButton: {
    width: '105px',
    height: '56px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.62px',
    backgroundColor: theme.palette.globalAppTheme.globalAppColor,
    margin: '16px',
    color: '#FFF',
    '&:hover': {
      background: '#106982'
    },
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  },
  callButton: {
    height: '56.9px',
    textDecoration: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.62px',
    backgroundColor: 'transparent',
    color: '#21a2c6',
    margin: '16px',
    paddingTop: 14
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  form: {
    width: '100%',
    maxWidth: '1160px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2)
    }
  },
  buttonsContainer: {
    display: 'flex',
    paddingTop: '32px'
  },
  removeAttacmentIcon: {
    color: '#2f4662',
    width: '1em',
    height: '1.4em',
    cursor: 'pointer',
    position: 'absolute',
    marginLeft: '-30px'
  },
  helpPopover: {
    position: 'relative',
    bottom: '-20px',
    left: '-190px',
    zIndex: 10,
    height: 0,
    textAlign: 'end'
  }
}));

export default contactFormStyle;
