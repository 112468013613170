import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import camelCase from 'lodash/camelCase';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Grid, Typography, CardMedia, Container, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoginToSendModal from '../../sign-in-modal/LoginModal';
import CustomBreadcrumbs from '../../breadcrumbs/CustomBreadcrumbs';
import ErrorBoundary from '../../common/ErrorBoundary';

const useStyles = makeStyles((theme) => ({
  sectionTeamplateSectionContainer: {
    display: 'block',
    justifyContent: 'center',
    background: '#fff',
    margin: 'auto',
    paddingTop: 100,
    [theme.breakpoints.down('md')]: {
      paddingTop: 100,
      height: '100%',
      padding: '20px',
      display: 'block'
    }
  },
  sectionTeamplateHeadContainer: {
    background: 'rgb(33, 161, 197, 0.1)',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      padding: '20px'
    },
    '@media print': {
      display: 'none'
    }
  },
  mainText: {
    height: '28px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#4a6582',
    textAlign: 'left',
    marginBottom: '10px'
  },
  mainTextSub: {
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(36),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-1.42px',
    color: '#2f4662',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto'
    }
  },
  printTitle: {
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(36),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-1.42px',
    color: '#2f4662',
    textAlign: 'center',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto'
    },
    '@media print': {
      display: 'block'
    }
  },
  cardMedia: {
    width: '215px',
    height: 'auto',
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      margin: theme.spacing(5)
    }
  },
  mediaContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
      display: 'flex',
      justifyContent: 'center'
    }
  },
  gridContainer: {
    padding: '95px',
    paddingTop: '0px'
  },
  textGrid: {
    padding: '60px',
    [theme.breakpoints.down('md')]: {
      padding: '0px'
    }
  },
  breadcrumbs: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px'
    },
    '@media print': {
      display: 'none'
    }
  }
}));

function SectionTemplate(props) {
  const classes = useStyles();
  const {
    children,
    sectionImg,
    sectionImgTitle,
    sectionTitle,
    sectionSubTitle,
    pathName,
    sectionId,
    translation,
    home
  } = props;
  const { error } = useSelector((state) => state.app);
  const { t } = useTranslation([translation]);

  return (
      <Box id={sectionId} className={classNames(classes.contactFormContainer)} component="section" sx={{ p: 2 }}>
      <ErrorBoundary>
      <Container className={classNames(classes.sectionTeamplateSectionContainer, 'lg-mg-bottom')}>
        <Grid item xs={12} md={12} lg={12} className={classes.breadcrumbs}>
          <CustomBreadcrumbs home={home} pathname={pathName} />
        </Grid>
        <Grid
          container
          direction="row"
          spacing={0}
          id={`${sectionId}-template-head`}
          className={classNames(classes.sectionTeamplateHeadContainer, 'lg-mg-bottom-custom')}
        >
          <Grid item sm={12} md={8} lg={9} className={classNames(classes.textGrid, 'lg-mg-bottom-custom')}>
            <Typography variant="h5" component="h5" align="left" className={classNames(classes.mainTextSub)}>
              {t(camelCase(sectionTitle))}
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              align="left"
              className={classNames(classes.mainText, 'lg-mg-bottom-custom')}
            >
              {t(sectionSubTitle)}
            </Typography>
          </Grid>
          <Grid item sm={12} md={4} lg={3} className={classes.mediaContainer}>
            <CardMedia
              className={classes.cardMedia}
              component="img"
              image={sectionImg}
              title={sectionImgTitle}
              height="auto"
              width="215"
            />
          </Grid>
        </Grid>
        <Typography variant="h5" component="h5" align="left" className={classNames(classes.printTitle)}>
          {`Vivowire - ${t(camelCase(sectionTitle))}`}
        </Typography>
        {children}
        <div style={{ display: 'none' }}>
          <LoginToSendModal appError={error} sendMoneyButtonText="" />
        </div>
      </Container>
      </ErrorBoundary>
    </Box>
  );
}

SectionTemplate.defaultProps = {
  children: null,
  home: ''
};

SectionTemplate.propTypes = {
  children: PropTypes.node,
  sectionImg: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  sectionTitle: PropTypes.string.isRequired,
  sectionSubTitle: PropTypes.string.isRequired,
  sectionImgTitle: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  translation: PropTypes.string.isRequired,
  home: PropTypes.string
};

export default SectionTemplate;
