/**
 * Constants for the transaction status
 *
 * pending -> rejected
 * pending -> approved -> paid
 * pending -> approved -> processing -> paid
 * pending -> approved -> processing -> error
 */

const TRANSACTION_STATUS = {
  pending: 'Pending',
  approved: 'Approved',
  rejected: 'Rejected',
  processing: 'Processing',
  error: 'Error',
  paid: 'Paid',
  scheduledForPayment: 'Scheduled for Payment',
  initiated: 'Initiated',
  canceledPayment: 'Canceled Payment',
  failedPayment: 'Failed Payment',
  completedPayment: 'Completed Payment',
  createdTransfer: 'Created Transfer',
  confirmedTransfer: 'Confirmed Transfer',
  rejectedTransfer: 'Rejected Transfer',
  cancelledTransfer: 'Cancelled Transfer',
  submittedTransfer: 'Submitted Transfer',
  availableTransfer: 'Available Transfer',
  completedTransfer: 'Completed Transfer',
  reversedTransfer: 'Reversed Transfer',
  declinedTransfer: 'Declined Transfer',
  pendingTransfer: 'Pending Transfer',
  canceledTransfer: 'Canceled Transfer',
  failedTransfer: 'Failed Transfer', 
  completed: 'Completed'
};

export default TRANSACTION_STATUS;
