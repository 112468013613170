export const PAYMENT_TYPE = {
  bankTransfer: 'bankTransfer',
  creditDebit: 'creditDebit'
};

export const PAYMENT_SERVICE = {
  brite: 'brite',
  unlimit: 'unlimit',
  default: 'default'
};

export const PAYMENT_METHODS = [
  { id: 1, label: PAYMENT_TYPE.bankTransfer, value: PAYMENT_SERVICE.brite, disabled: false, selected: true },
  { id: 2, label: PAYMENT_TYPE.creditDebit, value: PAYMENT_SERVICE.unlimit, disabled: !(process.env.NODE_ENV === 'development'), selected: false }
];

export const CREDIT_DEBIT_CARD_LIST = ['Visa', 'Mastercard'];
