/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import kebabCase from 'lodash/kebabCase';
import InputFeedback from './InputFeedback';

const style = makeStyles((theme) => ({
  checkboxInput: {
    width: '1.8em',
    height: '1.8em',
    borderRadius: '50%',
    border: '0.1em solid currentColor'
  },
  checkboxInputLabel: {
    width: '100%',
    maxWidth: '570px',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#2f4662',
    cursor: 'pointer',
    height: '100%',
    display: 'block'
  },
  checkboxCheckedInputLabel: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    cursor: 'pointer',
    height: '100%',
    display: 'block'
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '35px 1fr',
    gap: '10px',
    padding: theme.spacing(1)
  }
}));

function CheckboxPlain({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
  label,
  className,
  noShadow,
  ...props
}) {
  const classes = style();
  return (
    <>
      <div className={classes.container} id={`checkbox${kebabCase(name)}-${kebabCase(id)}`}>
        <input
          name={name}
          id={id}
          type="checkbox"
          value={value} // could be something else for output?
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          className={classNames('radio-button', classes.checkboxInput)}
          {...props}
        />
        <label htmlFor={id} className={value ? classes.checkboxCheckedInputLabel : classes.checkboxInputLabel}>
          {label}
        </label>
      </div>
      {touched[name] && <InputFeedback error={errors[name]} />}
    </>
  );
}

CheckboxPlain.defaultProps = {
  field: undefined,
  form: undefined,
  id: '',
  label: '',
  className: undefined,
  error: null,
  errors: null,
  noShadow: false
};

CheckboxPlain.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  }),
  form: PropTypes.shape({
    errors: PropTypes.shape({
      city: PropTypes.shape({
        type: PropTypes.func
      }),
      country: PropTypes.shape({
        type: PropTypes.func
      }),
      email: PropTypes.shape({
        type: PropTypes.func
      }),
      phoneNumber: PropTypes.shape({
        type: PropTypes.func
      }),
      street: PropTypes.shape({
        type: PropTypes.func
      }),
      userIsPep: PropTypes.shape({
        type: PropTypes.func
      }),
      zipCode: PropTypes.shape({
        type: PropTypes.func
      })
    }),
    touched: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool])
  }),
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(PropTypes.object)]),
  error: PropTypes.shape(PropTypes.string),
  errors: PropTypes.shape(PropTypes.string),
  noShadow: PropTypes.bool
};

InputFeedback.defaultProps = {
  error: null
};

InputFeedback.propTypes = {
  error: PropTypes.shape(PropTypes.string)
};

export default CheckboxPlain;
