/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import useImageSvg from '../../hooks/useImageSvg';
import Loading from './Loading';

function SvgImage({ fileName, pathInAssets, alt, className, noAvatar, title, ...rest }) {
  const { loading, error, image } = useImageSvg(fileName, pathInAssets);
  if (error) return <span>{alt}</span>;
  if (loading) return <Loading size={20} style={{ padding: 0 }}/>

  return (
      noAvatar ? <img loading="lazy" className={className} src={image} alt={alt} title={title} {...rest} /> :  <Avatar className={`Image-${className}`} src={image} alt={alt} title={title} {...rest} />
  );
}

SvgImage.defaultProps = {
  fileName: '',
  alt: '',
  className: '',
  pathInAssets: '',
  title: '',
  noAvatar: false
};
SvgImage.propTypes = {
  fileName: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  pathInAssets: PropTypes.string,
  title: PropTypes.string,
  noAvatar: PropTypes.bool
};

export default SvgImage;
