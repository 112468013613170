import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import API from '../utils/api';  
import { setGlobalUserRecipients } from '../store/redux/actions/dashboardAction'; 
import { useCache } from '../context/Cache';

function delay(ms) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function keyify(key) {
    return key.map((item) => JSON.stringify(item)).join("-");
}

const useUserRecipients = () => {
  const dispatch = useDispatch();
  const [userRecipients, setUserRecipients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getCache, setCache } = useCache(); 
  const cacheKey = keyify(["app", "get", "recipients"]);
  

  const fetchUserRecipients = async () => {
    try {
      setIsLoading(true);

      if (getCache(cacheKey) !== undefined) {
        const val = getCache(cacheKey);
        delay(400).then(() => {
        setUserRecipients(val);
        dispatch(setGlobalUserRecipients(val));
          setIsLoading(false);
          setError(undefined);
        });
      } else {
        const response = await API.get('/recipients');
        const userRecipientsData = response.data;
        dispatch(setGlobalUserRecipients(userRecipientsData));
        setUserRecipients(userRecipientsData);
        setCache(cacheKey, userRecipientsData, 200);
        setIsLoading(false);
      }

    } catch (_error) {
      setError(_error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserRecipients();  // Fetch user recipients when the component mounts
  }, []);  // Empty dependency array to mimic componentDidMount

  return { userRecipients, isLoading, error, fetchUserRecipients };
};

export default useUserRecipients;
