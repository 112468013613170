export const formIntitalValues = {
/*   firstName: undefined,
  lastName: undefined,
  street: undefined,
  city: undefined,
  zipCode: undefined,
  country: 'Sweden', */
  phoneNumber: undefined,
  email: undefined,
  emailConsent: false,
  transferHabit: undefined,
  receiverCountries: [],
  frequencyOfTransaction: undefined,
  recipientRelationship: [],
  sendToMultipleRecipients: undefined,
  originsOfFunds: [],
/*   isPep: undefined,
  checkboxGroupPepRolls: [],
  checkboxGroupPepRollsPepCountry: undefined,
  checkboxGroupPepRollsPepOrganisation: undefined,
  checkboxGroupPepRollsPepTitle: undefined,
  checkboxGroupPepRollsPepDateOfBirth: undefined,
  checkboxGroupPepRollsPepFullName: undefined,
  isFamilymemberOfPep: undefined,
  checkboxGroupPepFamilyRelation: [],
  checkboxGroupPepFamilyRelationPepCountry: '',
  checkboxGroupPepFamilyRelationPepFullName: undefined,
  checkboxGroupPepFamilyRelationPepOrganisation: undefined,
  checkboxGroupPepFamilyRelationPepTitle: undefined,
  checkboxGroupPepFamilyRelationPepDateOfBirth: undefined,
  isAssociateOfPep: undefined,
  checkboxGroupPepAssociateRelation: [],
  checkboxGroupPepAssociateRelationPepCountry: '',
  checkboxGroupPepAssociateRelationPepFullName: undefined,
  checkboxGroupPepAssociateRelationPepOrganisation: undefined,
  checkboxGroupPepAssociateRelationPepTitle: undefined,
  checkboxGroupPepAssociateRelationPepDateOfBirth: undefined,
  checkboxGroupPepOriginsOfFunds: [], */
  userIsPep: false/* ,
  cityId: '' */
};
