/* eslint-disable array-callback-return */
import React from 'react';
import * as Yup from 'yup';
import { Translation } from 'react-i18next';
import { FORM_TRANSLATIONS } from '../../../dashboard/formTranslations';
/** TODO Translate! */

/* const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png'
]; */

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/[A-Za-zÇÖÜçöüĞğİıŞş]+/g, {
      message: FORM_TRANSLATIONS.formEnterValidName,
      excludeEmptyString: false
    })
    .min(3, FORM_TRANSLATIONS.formNameMustBeXOrMore)
    .required(FORM_TRANSLATIONS.formFullNameIsRequired),
  email: Yup.string().email(FORM_TRANSLATIONS.formEnterValidEmail).required(FORM_TRANSLATIONS.formEmailIsRequired),
  phoneNumber: Yup.string().matches(/^(\+(\d{1,3})\s?)?((\(\d{3,5}\)|\d{3,5})(\s)?)\d{3,8}$/g, {
    message: FORM_TRANSLATIONS.formEnterValidMobileNumber,
    excludeEmptyString: false
  }),
  supportType: Yup.string().ensure().required(FORM_TRANSLATIONS.formSupportTypeIsRequired),
  description: Yup.string()
    .matches(/[A-Za-zÇÖÜçöüĞğİıŞş]+/g, {
      message: FORM_TRANSLATIONS.formTypeAValidMessage,
      excludeEmptyString: false
    })
    .min(20, ({ min, value }) => (
      <>
        <span>{`${min - value.length}`}</span>
        <Translation ns={['dashboard-common']}>{(t) => t('charactersToGo')}</Translation>
      </>
    ))
    .max(500, ({ max, value }) => (
      <Translation ns={['dashboard-common']}>
        {(t) => t('messageCannotExceedMaxChars', { max, length: value.length })}
      </Translation>
    ))
    .required(FORM_TRANSLATIONS.formMessageIsRequired),
  /** TODO validation unstable, fix it */
  attachment: Yup.mixed().nullable().notRequired()
});

export default ContactSchema;
