import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMediaQuery } from '@mui/material';
import Header from './header/Header';
import Footer from './footer/Footer';
import Hero from '../sections/hero/Hero';
import LeftHeaderLinks from './header/HeaderLinks';
import RightHeaderLinks from './header/RightHeaderLinks';
import brandColor from '../../../assets/img/vivowire-logo-outlined-text-color.png';
import brand from '../../../assets/img/vivowire-logo-outlined-text-white.png';
import brandNoText from '../../../assets/img/vivowire-logo-outlined-no-text-white.png';
import brandNoTextColor from '../../../assets/img/vivowire-logo-outlined-no-text-color.png';

import styles from '../landingPageStyles';
import PERMALINKS from '../../../constants/permalinks';

function Layout(props) {
  const classes = styles();
  const { route, children } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const headerLinkColor = isScrolled ? '#fff' : '#2f4662';
  const matches = useMediaQuery('(max-width: 1280px) and (min-width: 960px)');

  const handleHeaderScroll = (bool) => {
    setIsScrolled(bool);
  };

  return (
    <>
      {route === PERMALINKS.HOME || route === PERMALINKS.GET_STARTED ? (
        <>
          <Header
            color="transparent"
            brand={matches ? brandNoText : brand}
            brandWhenScroll={matches ? brandNoText : brand}
            rightLinks={<RightHeaderLinks />}
            leftLinks={<LeftHeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 50,
              color: 'info'
            }}
          />
          <Hero />
        </>
      ) : (
        <Header
          color="white"
          brand={matches ? brandNoTextColor : brandColor}
          brandWhenScroll={matches ? brandNoText : brand}
          rightLinks={<RightHeaderLinks color={headerLinkColor} />}
          leftLinks={<LeftHeaderLinks color={headerLinkColor} />}
          fixed
          changeColorOnScroll={{
            callback: handleHeaderScroll,
            height: 50,
            /* "primary","info","success","warning","danger","transparent","white","rose","dark"]. */
            color: 'info'
          }}
        />
      )}
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {children}
          <Footer />
        </div>
      </div>
    </>
  );
}

Layout.defaultProps = {
  route: '',
  children: undefined
};

Layout.propTypes = {
  route: PropTypes.string,
  children: PropTypes.node
};

export default Layout;
