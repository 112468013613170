import React from 'react';
import { useTranslation } from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

/**
color: rgba(255, 255, 255, 1);
background-color: rgb(0, 172, 193, 0.9);
border: 1px solid;
*/

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '1em',
    paddingLeft: '18px',
    marginTop: '14px',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'inherit',
    '&.Mui-selected': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundColor: 'rgb(25, 180, 199, 1)',
      border: '1px solid transparent;'
    }
  },
  toggleButtonGroupRoot: {
    display: 'block'
  }
}));

export default function HelpType(props) {
  const classes = useStyles();
  const { t } = useTranslation(['help-center-page']);
  const { handleTypeSelect, selectedType } = props;

  return (
    <ToggleButtonGroup
      classes={{
        root: classes.toggleButtonGroupRoot
      }}
      value={selectedType}
      exclusive
      onChange={handleTypeSelect}
      aria-label="type alignment"
      id="help-center-faq-types"
    >
      <ToggleButton className={classes.root} value="all" aria-label="left aligned">
        {t('typeAll')}
      </ToggleButton>
      <ToggleButton className={classes.root} value="general" aria-label="centered">
        {t('typeGeneral')}
      </ToggleButton>
      <ToggleButton className={classes.root} value="account" aria-label="right aligned">
        {t('typeAccount')}
      </ToggleButton>
      <ToggleButton className={classes.root} value="transaction" aria-label="justified">
        {t('typeTransaction')}
      </ToggleButton>
      <ToggleButton className={classes.root} value="payment" aria-label="justified">
        {t('typePayment')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

HelpType.propTypes = {
  handleTypeSelect: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired
};
