import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, AlertTitle , Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import PERMALINKS from '../../../constants/permalinks';

const useStyles = makeStyles((theme) => ({
  inProgressMessageContainer: {
    width: '100%',
    maxWidth: 1000,
    outline: '1px solid rgba(33, 161, 197, 1)',
    borderRadius: theme.borderRadius
  },
  alertTitleContainer: {
    display: 'grid',
    gridTemplateColumns: '60px 1fr 0fr'
  },
  progressChip: {
    color: '#fff'
  },
  contentContainer: {
    padding: '16px 60px 0 60px'
  },
  content: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  thankYou: {
    fontFamily: 'Rubik',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-1.42px',
    color: '#2f4662',
    fontSize: theme.typography.pxToRem(24),
    paddingTop: theme.spacing(2)
  },
  alertRoot: {
    display: 'block',
    padding: theme.spacing(3),
    backgroundColor: '#fff'
    /*     '&.MuiAlert-standardSuccess .MuiAlert-icon': {
      color: '#21a1c5'
    } */
  },
  clock: {
    color: '#21a1c5',
    width: '40px',
    margin: -1
  },
  alertTitle: {
    fontFamily: 'Rubik',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-1.42px',
    color: '#2f4662',
    fontSize: theme.typography.pxToRem(24)
  },
  profileLink: {
    textDecoration: 'none',
    color: '#21a2c6',
    cursor: 'pointer'
  }
}));

function ProgressMessage({ isVisible }) {
  const classes = useStyles();
  const { t } = useTranslation('dashboard-common');

  return (
    <div id="in-progress-message" className={classes.inProgressMessageContainer}>
      <Alert icon={false} className={classes.alertRoot}>
        <div className={classes.alertTitleContainer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={classNames(classes.clock, 'h-6 w-6')}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <AlertTitle className={classes.alertTitle}>
            {isVisible ? t('registrationIsBeingProcessed') : t('registrationIsCompleted')}
          </AlertTitle>
          {isVisible ? 
            <Chip className={classes.progressChip} label={t('inProgress')} color="primary" /> :
           (
            <Chip
              className={classes.progressChip}
              label={t('complete')}
              color="success"
              style={{ backgroundColor: '#4caf50' }}
            />
          )}
        </div>
        <div className={classes.contentContainer}>
          <Typography variant="h6" gutterBottom className={classes.content}>
            {isVisible ? (
              <>
                {t('yourRequestIsBeingProcessed')} {t('requestTime3to5hours')} {t('checkYourEmailUnderProfile')}
                <Link color="inherit" className={classes.profileLink} href={PERMALINKS.DASHBOARD_PROFILE}>
                  {t('profile')}
                </Link>
                .
              </>
            ) : 
              t('registrationIsCompleted')
            }
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.content}>
            {isVisible ? t('notBeAbleToMakeATransaction') : t('beAbleToMakeATransaction')}
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.thankYou}>
            {t('thankYouForYourPatience')}
          </Typography>
        </div>
      </Alert>
    </div>
  );
}

ProgressMessage.defaultProps = {
  isVisible: false
};

ProgressMessage.propTypes = {
  isVisible: PropTypes.bool
};

export default ProgressMessage;
