import React, { useState, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Routes, Route } from 'react-router-dom';
import {
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Hidden,
  Grid,
  Fade,
  Container, 
  Alert, 
  AlertTitle } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import { useSelector, useDispatch } from 'react-redux';
import LanguageIcon from '@mui/icons-material/Language';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ExitToApp } from '@mui/icons-material';
import { withRouter } from '../../hooks/withRouter';
import LanguageSelectMenu from '../common/LanguageSelectMenu';
import ProgressMessage from './sign-up/ProgressMessage';
import theme from '../../theme';
import API from '../../utils/api';
import LoadingBackdrop from '../common/LoadingBackdrop';
import Profile from './profile/Profile';
import StatsContainer from './stats/StatsContainer';
import RecentTransactions from './recent-transactions/RecentTransactions';
import AccountStatus from './accounts-status/AccountStatus';
import { setGlobalUser } from '../../store/redux/actions/userAction';
import {
  setGlobalIsRegistred,
  setGlobalIsVerifiedPep,
  setGlobalIsPep,
  setGlobalIsEmailConfirmed,
  setGlobalUserRecipients,
  setGlobalSelectedRecipient
} from '../../store/redux/actions/dashboardAction';
import MTFBoxNarrow from '../mtf/MtfBoxNarrow';
import SignUpDialog from './sign-up/SignUpDialog';
import useStyles from './dashboardStyles';
import DashboardDrawer from './DashboardDrawer';
import Recipients from './recipient/Recipients';
/* import EmailConfirmationDialog from './sign-up/EmailConfirmationDialog'; */
import SessionDialog from './SessionDialog';
import { logout } from '../../context/auth';
import ErrorBoundary from '../common/ErrorBoundary';
import NotFoundPage from '../landing-page/pages/404/NotFoundPage';
import GlobalErrorAlert from '../common/GlobalErrorAlert';
import ContactUsDialog from './ContactUsDialog';
import useTransactions from '../../hooks/useTransactions';
import useTransactionsStats from '../../hooks/useTransactionStats';
import useUserRecipients from '../../hooks/useUserRecipients';
import { errorHandler } from '../../utils/errorMapper';
/* import DashboardPageTemplate from './DashboardPageTemplate'; */

function Dashboard() {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const appBarRef = useRef();
  const { isLoading: transactionsLoading, error: transactionError, fetchTransactions } = useTransactions();
  const { isLoading: transactionStatsLoading, error: transactionStatsError, fetchTransactionsStats } = useTransactionsStats();
  const { isLoading: getRecipientsLoading, error: getRecipientsError, fetchUserRecipients } = useUserRecipients();
  const { i18n, t } = useTranslation(['dashboard-common']);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { error: appError } = useSelector((state) => state.app);
  const { convertedAmountError } = useSelector((state) => state.moneyTransfer);
  const { user } = useSelector((state) => state.userInfo);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(useSelector((state) => state.app.appLanguage));
  const {
    currentUserIsVerifiedPep,
    currentUserEmailIsConfirmed,
    currentUserIsRegistred,
    userTransactionAmountMonthLimitReached
  } = useSelector((state) => state.dashboard);
  const [isPep, setIsPep] = useState(user?.userFlag?.isPepList);
  const [isVerifiedPep, setIsVerifiedPep] = useState(currentUserIsVerifiedPep);
  const [currentUser, setCurrentUser] = useState(user);
  const [isOnWatchList, setIsOnWatchList] = useState(user?.userFlag?.isSanctionList);
  const [emailVerificationDialogOpen, setEmailVerificationDialogOpen] = useState(false);
  const [contactUsDialogOpen, setContactUsDialogOpen] = useState(false);
/*   const [showEmailNotVerifiedMessage, setShowEmailNotVerifiedMessage] = useState(false); */
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openSessionDialog, setOpenSessionDialog] = useState(false);
  const matchesIPadLandscape = useMediaQuery('(max-width: 1200px) and (min-width: 600px)');

  const getUser = () => {
    setLoading(true);
    API.get('/user')
      .then((response) => {
        // eslint-disable-next-line no-underscore-dangle
        const _user = _get(response, 'data', {});
        const pep = _get(response.data, 'pep', {});
        const userIsPEP = pep?.isPep || pep?.isAssociateOfPep || pep?.isFamilymemberOfPep || user?.userFlag?.isPepList;
        setCurrentUser(_user);
        setIsPep(userIsPEP);
        setIsVerifiedPep(_user?.userFlag?.isVerifiedPep);
        setIsOnWatchList(_user?.userFlag?.isSanctionList);
        dispatch(setGlobalUser(_user));
        dispatch(setGlobalIsRegistred(_user?.userFlag?.isRegistered));
        dispatch(setGlobalIsEmailConfirmed(_user?.userFlag?.isEmailConfirmed));
        dispatch(setGlobalIsVerifiedPep(_user?.userFlag?.isVerifiedPep));
        dispatch(setGlobalUserRecipients(user?.recipient));
        dispatch(setGlobalIsPep(userIsPEP));
        dispatch(setGlobalSelectedRecipient(undefined));
        setLoading(false);
      })
      .catch((_error) => {
        if (_error) {
          logout();
        }
        setError(_error);
        setLoading(false);
      });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEmailVerificationDialogOpen = () => {
    if (!currentUserEmailIsConfirmed) setEmailVerificationDialogOpen(true);
  };

  const handleEmailVerificationDialogClose = () => {
    setEmailVerificationDialogOpen(false);
  };

/*   const handleEmailVerification = () => {
    API.get('/userflag')
      .then((res) => {
        const { data } = res;
        const { isEmailConfirmed } = data;
        // setIsVerifiedEmail(isEmailConfirmed);
        dispatch(setGlobalIsEmailConfirmed(isEmailConfirmed));
        if (!isEmailConfirmed) setShowEmailNotVerifiedMessage(true);
      })
      .catch((flagError) => {
        setError(flagError);
      });
  }; */

  const handleLanguageClose = () => {
    setAnchorEl(null);
  };

  const updateFlags = () => (e) => {
    setIsVerifiedPep(e.detail?.userIsVerfiedPep);
    setIsPep(e.detail?.isPepUser);
  };

  const pageTitle = () => {
    switch (location.pathname) {
      case '/dashboard':
        return t('overview');
      case '/dashboard/profile':
        return t('profile');
      default:
        return t('overview');
    }
  };

  const sessionTimeLeft = (timeLeft) => {
    if (timeLeft <= '0:59') {
      setOpenSessionDialog(true);
    }
    if (timeLeft === '0:01') {
      logout();
    }
  };

  const handleReloadClick = () => {
    fetchTransactions();
    fetchTransactionsStats();
/*     getUser(); */
    fetchUserRecipients();
  };

  const toggleContactUsDialog = () => {
    setContactUsDialogOpen(!contactUsDialogOpen);
  };

  useEffect(() => {
    appBarRef?.current?.scrollIntoView();
    const currentLanguage = i18n.language;
    if (currentLanguage !== selectedLanguage.code) {
      i18n.changeLanguage(selectedLanguage.code);
    }
    window.addEventListener('welcome-dialog-close', updateFlags());
    fetchTransactions();
    fetchTransactionsStats();
    if (currentUserIsRegistred) getUser();
    fetchUserRecipients();
    return () => window.removeEventListener('welcome-dialog-close', updateFlags());
  }, [
    matchesIPadLandscape,
    selectedLanguage,
    currentUserEmailIsConfirmed,
    emailVerificationDialogOpen,
    currentUserIsRegistred,
    userTransactionAmountMonthLimitReached
  ]);

  const container = window !== undefined ? () => window.document.body : undefined;
  const isPepOrOnWatchList = (isPep && !isVerifiedPep) || isOnWatchList;

  if (currentUser === null) logout();

  if (currentUser) {
    return (
      <div className={classes.dashboardRoot}>
        <ContactUsDialog handleClose={() => setContactUsDialogOpen(false)} open={contactUsDialogOpen} />
        <Hidden lgDown implementation="css">
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            {/** Collapsed Drawer */}
            <Hidden lgUp implementation="js">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                <DashboardDrawer drawerToggle={handleDrawerToggle} isRegistered={currentUserIsRegistred} />
              </Drawer>
            </Hidden>
            {/** Default Drawer */}
            <Hidden lgDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                <DashboardDrawer drawerToggle={handleDrawerToggle} isRegistered={currentUserIsRegistred} />
              </Drawer>
            </Hidden>
          </nav>
        </Hidden>
        <Routes>
          <Route key={1} path="profile" exact element={<Profile user={user} />} />
          {/* <Route key={1} path="transactions" exact element={<DashboardPageTemplate title="Transactions"><div>Transactions</div></DashboardPageTemplate>} /> */}
          <Route path="*" element={<NotFoundPage />} />
          <Route
            key={2}
            path="/"
            exact
            element={
              <ErrorBoundary>
                <main className={classes.content}>
                  <Container maxWidth={false} className={classes.container}>
                    <AppBar ref={appBarRef} elevation={0} position="fixed" className={classes.appBar} style={{ width: matchesIPadLandscape && 'inherit' }}>
                      <Toolbar>
                        <Hidden lgUp>
                          <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                            size="large">
                            <MenuIcon />
                          </IconButton>
                        </Hidden>
                        <Grid container direction="row">
                          <Grid item sm={6} md={6} lg={6}>
                            <Typography className={classes.appBarTitle} noWrap>
                              {pageTitle()}
                            </Typography>
                          </Grid>
                          <Grid item sm={6} md={6} lg={6} className={classes.appBarButtonsContainer} style={{ padding: matchesIPadLandscape && 8 }}>
                            <Grid container direction="row">
                              <Grid item sm={3} md={3} lg={3}>
                                <Button
                                  title={t('reload')}
                                  onClick={handleReloadClick}
                                  className={classes.appBarButtons}
                                  style={{ textIndent: matchesIPadLandscape && '-9999px' }}
                                  disabled={!currentUserIsRegistred}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    className="w-6 h-6 svg-icon-style"
                                  >
                                    <path
                                      stroke="rgb(47, 70, 98)"
                                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                    />
                                  </svg>{' '}
                                  <Typography className={classes.topMenuText}>{t('reload')}</Typography>
                                </Button>
                              </Grid>
                              <Grid item sm={3} md={3} lg={3}>
                                <Button
                                  title={t('contactUs')}
                                  onClick={toggleContactUsDialog}
                                  className={classes.appBarButtons}
                                  style={{ textIndent: matchesIPadLandscape && '-9999px' }}
                                  disabled={!currentUserIsRegistred}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 svg-icon-style">
                                    <path
                                      stroke="rgb(47, 70, 98)"
                                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                    />
                                  </svg>{' '}
                                  <Typography className={classes.topMenuText}>{t('needHelp')}</Typography>
                                </Button>
                              </Grid>
                              <Grid item sm={3} md={3} lg={3}>
                                <Button
                                  title={t('languageSelect')}
                                  aria-controls="translator-dropdown"
                                  aria-haspopup="true"
                                  onClick={handleLanguageClick}
                                  className={classes.appBarButtons}
                                  style={{ color: '#2f4662', textIndent: matchesIPadLandscape && '-9999px' }}
                                  disabled={!currentUserIsRegistred}
                                >
                                  <LanguageIcon className={classes.languageIcon} /><Typography className={classes.topMenuText}> {selectedLanguage.language}</Typography>
                                  <KeyboardArrowDownIcon />
                                </Button>
                              </Grid>
                              <Grid item sm={3} md={3} lg={3}>
                                <Button
                                  title={t('logOut')}
                                  onClick={logout}
                                  className={classes.appBarButtons}
                                  style={{ color: '#2f4662', textIndent: matchesIPadLandscape && '-9999px' }}
                                  disabled={!currentUserIsRegistred}
                                >
                                  <ExitToApp />{' '}
                                  <Typography className={classes.topMenuText}>{t('logOut')}</Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <LanguageSelectMenu
                          anchorEl={anchorEl}
                          handleClose={handleLanguageClose}
                          setAnchorElement={setAnchorEl}
                          setLanguage={setSelectedLanguage}
                        />
                      </Toolbar>
                    </AppBar>
                    <div className={classes.toolbar} style={{ minHeight: 100 }} />
                    {!!error ||
                      (!!appError && (
                        <Grid container direction="row" style={{ paddingBottom: 8 }}>
                          <Fade in={!!error || !!appError}>
                            <Grid item xs={12} style={{ paddingTop: 8 }}>
                              <GlobalErrorAlert error={error || appError} style={{ boderRadius: 10 }} />
                            </Grid>
                          </Fade>
                        </Grid>
                      ))}
                    <Grid container direction="column" style={{ paddingTop: 8, paddingBottom: 8 }}>
                      {currentUserIsRegistred && !currentUserEmailIsConfirmed && !emailVerificationDialogOpen && (
                        <Grid xs={12} item style={{ paddingBottom: 8, width: '100%', maxWidth: 1000 }}>
                          <Fade
                            timeout={3000}
                            in={currentUserIsRegistred && !currentUserEmailIsConfirmed && !emailVerificationDialogOpen}
                          >
                            <Alert severity="warning">
                              <AlertTitle>
                                <strong>{t('emailAddressIsNotVerified')}</strong>
                              </AlertTitle>
                              {t('accountCannotBeUsedUntilEmailVerified')} {t('weSentAnEmail')}{' '}
                              <strong>{user.email}</strong>. {t('clickTheLinkInTheVerificationEmail')}
                              <Typography
                                style={{ cursor: 'pointer' }}
                                color="inherit"
                                onClick={handleEmailVerificationDialogOpen}
                              >
                                <Button
                                  className={classes.buttonForMoreInformation}
                                  onKeyDown={handleEmailVerificationDialogOpen}
                                  tabIndex={0}
                                  onClick={handleEmailVerificationDialogOpen}
                                >
                                  {t('clickForMoreInformation')}
                                </Button>
                              </Typography>
                            </Alert>
                          </Fade>
                        </Grid>
                      )}
                      {isPepOrOnWatchList && currentUserIsRegistred && (
                        <Grid
                          item
                          justifyContent="flex-start"
                          sm={12}
                          md={12}
                          lg={6}
                          style={{ paddingTop: 8, paddingBottom: 24 }}
                        >
                          <ProgressMessage isVisible={!isVerifiedPep || isOnWatchList} />
                        </Grid>
                      )}
                      {/* Info Section Start */}
                      <Grid container className={classes.infoSection}>
                        {/* Transaction Stats Section Start */}
                        <AccountStatus loading={transactionStatsLoading} error={transactionStatsError} />
                        {/* Transaction Stats Section End */}
                        <Grid item sm={12} md={12} lg={6}>
                          <Typography className={classes.sendMoneyTitle}>{t('sendMoney')}</Typography>
                          {userTransactionAmountMonthLimitReached && (
                            <Grid xs={12} item style={{ paddingBottom: 8 }}>
                              <Fade timeout={3000} in={userTransactionAmountMonthLimitReached}>
                                <Alert severity="info">
                                  <AlertTitle>
                                    <strong>{t('youHaveReachedTheMaximumTransactionLimitForThisMonth')}</strong>
                                  </AlertTitle>
                                  {t('seeAccountStatusForInformation')}
                                </Alert>
                              </Fade>
                            </Grid>
                          )}
                          {convertedAmountError && (
                            <Grid xs={12} item style={{ paddingBottom: 8 }}>
                              <Fade
                                timeout={3000}
                                in={!!convertedAmountError}
                              >
                                <Alert severity={convertedAmountError?.type || "error"}>
                                  {errorHandler(convertedAmountError)}
                                </Alert>
                              </Fade>
                            </Grid>
                          )}
                          <div
                            className={`mtf-box ${!currentUserIsRegistred ||
                              isPepOrOnWatchList ||
                              (isPep && !isVerifiedPep) ||
                              (currentUserIsRegistred && !currentUserEmailIsConfirmed) ?
                              'disabled-element' :
                              ''
                              }`}
                          >
                            <MTFBoxNarrow shadow={1} />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container direction="row">
                        <Recipients loading={getRecipientsLoading} error={getRecipientsError} />
                        {/* Stats Section Start */}
                        <StatsContainer loading={transactionStatsLoading} error={transactionStatsError} />
                        {/* Stats Section End */}
                        <Hidden lgDown implementation="js">
                          <Grid item xs={1} style={{ justifyContent: 'center', display: 'flex' }}>
                            <Divider className={classes.verticalDivider} />
                          </Grid>
                        </Hidden>
                        {/* Recent Transactions Section Start */}
                        <RecentTransactions
                          error={transactionError}
                          loading={transactionsLoading}
                          reload={handleReloadClick}
                        />
                        {/* Recent Transactions Section End */}
                      </Grid>
                      {/* Info Section End */}
                    </Grid>
                    <SessionDialog
                      getTimeLeft={sessionTimeLeft}
                      open={openSessionDialog}
                      toggleDialog={setOpenSessionDialog}
                    />
                    <SignUpDialog
                      closeWithOutVerification={handleEmailVerificationDialogClose}
                      emailVerificationDialogOpen={emailVerificationDialogOpen}
                      setEmailVerificationDialogOpen={setEmailVerificationDialogOpen}
                    />
                  </Container>
                </main>
              </ErrorBoundary>
            }
          />
        </Routes>
      </div>
    );
  }
  return <LoadingBackdrop isLoading={loading} />;
}

export default withRouter(Dashboard);
