/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  container: {
    marginTop: 12,
    marginBottom: 12,
    textAlign: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogSubTitle: {
    fontSize: '26px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-1.42px',
    color: '#2f4662'
  },
  dialogTitle: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.78,
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  topIconRoot: {
    color: '#818a97',
    textAlign: 'center',
    width: '2em',
    height: 'auto',
    fontSize: '2.5rem',
    paddingTop: '25px',
    [theme.breakpoints.down('md')]: {
      width: '2em',
      height: 'auto',
      fontSize: '1.2rem',
      paddingTop: '0px'
    }
  }
});
const DialogTitle = withStyles(styles)((props) => {
  const { title, subTitle, icon, classes, onClose, id, other } = props;
  return (
    <MuiDialogTitle /* disableTypography */ id={id} className={classes.root} {...other}>
      <div className={classes.container}>
        <div>
          <Icon classes={{ root: classes.topIconRoot }}>{icon}</Icon>
          <Typography variant="h6" gutterBottom className={classes.dialogTitle}>
            {title}
          </Typography>
          <Typography className={classes.dialogSubTitle}>{subTitle}</Typography>
        </div>
      </div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default DialogTitle;
