import { Divider, Step, StepLabel, Stepper, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import FormNavigation from '../../common/FormNavigation';
import addRecipientDialogStyle from './AddRecipientDialogStyle';

function MultiStepForm({
  children,
  onClose,
  initialValues,
  snapshotState,
  onSubmit,
  lastStepName,
  disableStepper,
  stepNumber,
  setStepNumber,
  setError,
  error,
  disabled,
  onContinueClick = () => false,
  onBackClick
}) {
  const useStyles = addRecipientDialogStyle;
  const classes = useStyles();
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('md')); 
  const formRef = useRef();
  const steps = React.Children.toArray(children);

  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber + 1);
  };

  const previous = (values) => {
    if (stepNumber === 0) {
      onClose();
      setError(null)
    } else {
      setSnapshot(values);
      setStepNumber(stepNumber - 1);
      setError(null)
      if (typeof onBackClick === 'function') onBackClick(stepNumber)
    }
  };

  const handleSubmit = async (values, actions) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values);
    }

    if (isLastStep) {
      return onSubmit(values, actions);
    }
    actions.setTouched({});
    return next(values);
  };
  
  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
      >
        {(formik) => (
          <Form>
            {snapshotState(formik)}
            {steps[stepNumber]?.props?.heading ?? ''}
            {!disableStepper && (
              <Stepper sx={{ pl: 8 }} activeStep={stepNumber} alternativeLabel={!matchesSm} orientation={matchesSm? "vertical" : "horizontal"}>
                {steps.map((currentStep) => {
                  const label = currentStep.props.stepName;

                  return (
                    <Step key={label}>
                      <StepLabel>
                        <Typography className={classes.title}>{label}</Typography>
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )}
            {step}
            <Divider className={classNames(classes.dottedLine, classes.spaceBottom, classes.spaceTop)} />
            <FormNavigation
              formik={formik}
              lastStepButtonLabel={lastStepName}
              isLastStep={isLastStep}
              hasPrevious={stepNumber > 0}
              onBackClick={() => previous(formik.values)}
              error={error}
              disabled={disabled}
              onContinueClick={onContinueClick}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default MultiStepForm;

MultiStepForm.defaultProps = {
  children: undefined,
  initialValues: undefined,
  snapshotState: undefined,
  lastStepName: '',
  disableStepper: false,
  stepNumber: 0,
  setError: undefined,
  error: undefined,
  disabled: false,
  onContinueClick: undefined,
  onBackClick: undefined
};

MultiStepForm.propTypes = {
  children: PropTypes.oneOfType([PropTypes.shape(PropTypes.node), PropTypes.array]),
  disableStepper: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  lastStepName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    country: PropTypes.string,
    payers: PropTypes.array,
    reasonForSending: PropTypes.string,
    recipientPayoutAccount: PropTypes.string
  }),
  snapshotState: PropTypes.oneOfType([PropTypes.shape(PropTypes.object), PropTypes.func]),
  onSubmit: PropTypes.func.isRequired,
  stepNumber: PropTypes.number,
  setStepNumber: PropTypes.func.isRequired,
  setError: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  onContinueClick: PropTypes.func,
  onBackClick: PropTypes.func
};

/* eslint-disable no-unused-vars */
export const FormStep = ({ stepName = '', children }) => children;
