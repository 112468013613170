import React, { createContext, useContext, useMemo } from "react";
import PropTypes from 'prop-types';

const CacheContext = createContext(null);

export const useCache = () => useContext(CacheContext);

export default function Cache({ children }) {
  const map = new Map();

  function getCache(key) {
    const cacheValue = map.get(key);
    if (!cacheValue) return undefined;
    if (new Date().getTime() > cacheValue.expires.getTime()) {
      map.delete(key);
      return undefined;
    }
    return cacheValue.data;
  }

  function setCache(key, value, ttl = 10) {
    const t = new Date();
    t.setSeconds(t.getSeconds() + ttl);
    map.set(key, {
      expires: t,
      data: value
    });
  }

  function clearCache() {
    map.clear();
  }

  function deleteCache(key) {
    map.delete(key);
  }

  const contextValue = useMemo(() => ({
    getCache,
    setCache,
    clearCache,
    deleteCache,
    cache: map
  }));

  return (
    <CacheContext.Provider value={contextValue}>
      {children}
    </CacheContext.Provider>
  );
}

Cache.propTypes = {
    children: PropTypes.node.isRequired
}