import { PAYOUT_ACCOUNT_TYPES } from "../../constants/payoutAccountTypes";

export const payoutAccountType = (_type, _payoutAccount, opt = '') => {
  if (PAYOUT_ACCOUNT_TYPES.MOBILE_WALLET.includes(_type) || PAYOUT_ACCOUNT_TYPES.CASH_PICKUP.includes(_type)) {
    if(opt && opt === 'phoneNumber' || opt === '') {
        return { 
          number: _payoutAccount.replace(/[+-]/g,''), 
          type: 'phone', 
          accountFormat: { phoneNumber: _payoutAccount.replace(/[+-]/g,'')}
        };
    }
    return {
      number: _payoutAccount.replace(/[+-]/g,''), 
      type: 'phone', 
      accountFormat: { msisdn: _payoutAccount.replace(/[+-]/g,'')}
    };
  }
  if (PAYOUT_ACCOUNT_TYPES.BANK_ACCOUNT.includes(_type)) {
      const formatPayoutAccount = _payoutAccount.replace(/[+._-]/g, '');
      // Reason for bank account number snake case is that it is thunes parameter
      if(opt && opt === 'bank_account_number') {
        return {
          number: formatPayoutAccount, 
          type: 'bank',
          accountFormat: { bank_account_number: formatPayoutAccount }
        }
    } 
    return { number: _payoutAccount, type: 'bank', accountFormat: { bankAccountNumber: formatPayoutAccount } }
  
  }
  return '';
};
