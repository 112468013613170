import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import API from '../utils/api'; 
import { setTransaction } from '../store/redux/actions/userAction';
import { setGlobalUserTransactions} from '../store/redux/actions/dashboardAction';

const useTransactions = () => {
  const dispatch = useDispatch();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTransactions = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await API.get('/transactions');
      const transactionData = response.data;

      // Update Redux store
      dispatch(setTransaction(transactionData));
      dispatch(setGlobalUserTransactions(transactionData));

      setTransactions(transactionData);
      setIsLoading(false);
    } catch (_error) {
      setError(_error);
      setIsLoading(false);
    }
  }, [transactions]);

  useEffect(() => {
    fetchTransactions();  // Fetch transactions when the component mounts
  }, []);  // Empty dependency array to mimic componentDidMount

  return { transactions, isLoading, error, fetchTransactions };
};

export default useTransactions;
