const helpCenterStyles = (theme) => ({
  container: {
    margin: 'auto',
    marginBottom: '100px',
    [theme.breakpoints.down('md')]: {
      height: '100%'
    }
  },
  gridContainer: {
    display: 'block',
    textAlign: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: 'white',
    border: '1px solid #00acc1',
    borderRadius: theme.borderRadius,
    padding: theme.spacing(3),
    position: 'relative',
    zIndex: '3',
    textDecoration: 'none',
    overflow: 'hidden',
    width: '100%',
    maxWidth: '1222px',
    outline: 'none',
    boxShadow: '0 18px 48px 0 rgb(145 155 168 / 20%)',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
    '&:hover': {
      boxShadow: '0 18px 48px 0 rgb(145 155 168 / 50%)'
    }
  },
  helpIconRoot: {
    color: '#818a97',
    textAlign: 'center',
    width: '2em',
    height: '3em',
    fontSize: '2rem',
    paddingTop: '16px',
    [theme.breakpoints.down('md')]: {
      width: '2em',
      height: '2em',
      fontSize: '1.2rem',
      paddingTop: '0px'
    }
  },
  helpTitle: {
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.83px',
    color: theme.palette.globalAppTheme.globalAppColor,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '4px',
      padding: 0
    }
  },
  subTitle: {
    float: 'left',
    paddingLeft: '18px',
    marginTop: '14px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.62px',
    color: '#2f4662'
  },
  helpSubTitle: {
    height: '24px',
    fontFamily: 'inter',
    fontSize: '18x',
    fontWeight: '540000',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.83px',
    color: '#636472',
    padding: '25px',
    paddingLeft: '4px'
  },
  titleIconContainer: {
    width: '100%',
    whiteSpace: 'break-spaces',
    display: 'grid',
    gridTemplateColumns: '90px 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  expandeblePanelContainer: {},
  expandableHelpCenterArticles: {
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  expandedTextTitleStyle: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: '400',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.62px',
    color: '#818a97',
    textAlign: 'left',
    gridColumn: 2
  },
  expandedTextStyle: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582',
    marginLeft: '16px'
  },
  needHelpText: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#4a6582',
    textAlign: 'left',
    marginBottom: '10px',
    float: 'right',
    paddingRight: '30px',
    [theme.breakpoints.down('md')]: {
      float: 'left',
      paddingLeft: '32px'
    },
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      float: 'none'
    }
  },
  contactButtonContainer: {
    alignItems: 'flex-end',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      top: '45px',
      margin: 0
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '0'
    }
  },
  extraLargeButton: {
    width: '229px',
    height: '56.9px',
    fontFamily: 'Inter',
    textTransform: 'capitalize',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.62px',
    backgroundColor: theme.palette.globalAppTheme.globalAppColor,
    float: 'left',
    color: '#FFF',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:hover': {
      color: 'inherit',
      background: '#106982'
    },
    [theme.breakpoints.down('md')]: {
      float: 'none',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  },
  contactSupportLink: {
    textDecoration: 'none',
    color: '#fff'
  },
  extraLargeButtonLabel: {
    fontSize: theme.typography.body1.fontSize,
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.h6.fontSize
    }
  },
  noResultsImgContainer: {
    width: '100%'
  },
  noResultsImg: {
    height: '120px',
    width: '100%'
  },
  noResultsText: {
    height: '28px',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#4a6582',
    textAlign: 'center',
    marginBottom: '10px',
    paddingRight: '30px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '32px'
    },
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      float: 'none'
    }
  }
});

export default helpCenterStyles;
