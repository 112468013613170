import { ACTION_TYPES } from '../ReduxActionTypes';

export function setGlobalIsGetStartedLogin(bool) {
  return {
    type: ACTION_TYPES.SET_IS_GET_STARTED_LOGIN,
    payload: bool
  };
}

export function setGlobalLoginModalVisible(bool) {
  return {
    type: ACTION_TYPES.SET_LOGIN_MODAL_VISIBLE,
    payload: bool
  };
}
