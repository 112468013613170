/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, createRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import withStyles from '@mui/styles/withStyles';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { PropTypes } from 'prop-types';
import { Box, MenuItem } from '@mui/material';
import { uniqueId } from "lodash";
import { errorHandler } from '../../../utils/errorMapper';
import Loading from '../../common/Loading';
import Button from '../../common/Button';
import signUpDialogStyle from './signUpDialogStyle';
import HelpPopOver from '../../common/HelpPopOver';
import API from '../../../utils/api';
import Textfield from '../../common/TextfieldWrapper';
import SignUpFormSchema from './signUpFormValidationSchema';
import ContinueOrCancelDialog from '../common/ContinueOrCancelDialog';
import RadioButtonGroup from '../../common/RadioButtonGroup';
import RadioButton from '../../common/RadioButton';
import WelcomeDialog from './WelcomeDialog';
import CommonDialogTitle from '../../common/DialogTitle';
import { logout } from '../../../context/auth';
import Checkbox from '../../common/Checkbox';
import { FORM_TRANSLATIONS } from '../formTranslations';
import { mapFormikErrorFields } from './mapFormikErrorFields';
import Util from '../../../utils/Util';
import { transferHabitItems, frequencyOfTransactionItems, userRecipientRelationshipItems, originsOfFundsItems } from './contants';
import { formIntitalValues } from './signUpFormInitialValues';
/* import PepFormContainer from './pep/PepFormContainer'; */
import EmailConfirmationDialog from './EmailConfirmationDialog';
import { setGlobalUser } from '../../../store/redux/actions/userAction';

import {
  setGlobalIsEmailConfirmed,
  setGlobalIsPep,
  setGlobalIsRegistred,
  setGlobalIsVerifiedPep
} from '../../../store/redux/actions/dashboardAction';
import MultiCountrySelect from '../../common/MultiCountrySelect';
import CheckboxGroup from '../../common/CheckboxGroup';
import CheckboxPlain from '../../common/CheckboxPlain';
import { mapPEP } from './mapPep';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const HabitRadioButton = withStyles(styles)((props) => {
  const { classes, ...other } = props;
  return <RadioButton className={classes} {...other} />;
});

const useStyles = signUpDialogStyle;

export default function SignUpDialog({
  isRegistered,
  emailVerificationDialogOpen,
  setEmailVerificationDialogOpen,
  closeWithOutVerification
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('dashboard-common');
  const { recipientCountries/* , recipientCountryOptionValue */ } = useSelector((state) => state.moneyTransfer);
  const { user } = useSelector((state) => state.userInfo);
  const { loading: appLoading } = useSelector((state) => state.app);
  const { currentUserIsVerifiedPep } = useSelector((state) => state.dashboard);
  const { firstName, lastName, dateOfBirth } = user || {};
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [userIsRegistred, setUserIsRegistred] = useState();
  const [userIsVerfiedPep, setUserIsVerfiedPep] = useState(currentUserIsVerifiedPep);
  const [isPepUser, setIsPepUser] = useState(false);
  /* const [showPepForm, setShowPepForm] = useState(false); */
  const [welcomeDialogOpen, setWelcomeDialogOpen] = useState(!userIsRegistred);
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailVerificationError, setEmailVerificationError] = useState();
  const [showEmailNotVerifiedMessage, setShowEmailNotVerifiedMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(undefined);
  const [apiError, setApiError] = useState(undefined);
  const [formikValues, setFormikValues] = useState({
    ...formIntitalValues
    /*     ...recipientCountryOptionValue,
        receiverCountries: recipientCountries.filter((country) => recipientCountryOptionValue.id === country.id) */
    /* ,
    firstName,
    lastName,
    city,
    street,
    zipCode */
  });

  const getUser = () =>
    API.get('/user')
      .then((response) => {
        // eslint-disable-next-line no-underscore-dangle
        const _user = _get(response, 'data', {});
        const flags = _get(_user, 'userFlag', {});
        setUserIsRegistred(flags.isRegistered);
        dispatch(setGlobalIsRegistred(flags?.isRegistered));
        dispatch(setGlobalIsEmailConfirmed(flags?.isEmailConfirmed));
        dispatch(setGlobalIsVerifiedPep(flags?.isVerifiedPep));
        dispatch(setGlobalIsPep(flags?.isPepList));
      })
      .catch((_error) => {
        setApiError(_error);
      });

  const updateUserFlags = async (userIsPEP) => {
    await API.put('/userflag', {
      isRegistered: true,
      isVerifiedPep: userIsPEP ? false : undefined
    })
      .then((res) => {
        const { data } = res;
        const { isRegistered: _isRegistered, isVerifiedPep, isEmailConfirmed } = data;
        setUserIsRegistred(_isRegistered);
        setUserIsVerfiedPep(isVerifiedPep);
        setEmailVerified(isEmailConfirmed);
        dispatch(setGlobalIsRegistred(_isRegistered));
        dispatch(setGlobalIsVerifiedPep(isVerifiedPep));
        dispatch(setGlobalIsPep(userIsPEP));
        if (_isRegistered && !isEmailConfirmed) setEmailVerificationDialogOpen(true);
        setLoading(false);
      })
      .catch((flagError) => {
        setApiError(flagError);
        setLoading(false);
      });
  };

  const sendConfirmationEmaill = async () => {
    try {
      await API.get('/confirm');
    } catch (_error) {
      setEmailVerificationError(_error);
    }
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const updateUser = _pick(values, ['phoneNumber', 'email', 'transferHabit', 'emailConsent', 'receiverCountries', 'frequencyOfTransaction', 
    'recipientRelationship', 'sendToMultipleRecipients', 'originsOfFunds']);
    updateUser.sendToMultipleRecipients = Util.stringToBoolean(updateUser.sendToMultipleRecipients)
    const pepForm = mapPEP(values, lastName, dateOfBirth);
    const userIsPEP =
      Util.stringToBoolean(values.isPep) ||
      Util.stringToBoolean(values.isAssociateOfPep) ||
      Util.stringToBoolean(values.isFamilymemberOfPep);

    setApiError(null);

    API.put('/user', updateUser)
      .then((res) => {
        if (res && res.status === 200) {
          const updatedUser = _get(res, 'data', {});
          dispatch(setGlobalUser(updatedUser));
          API.post('/pep', pepForm);
          setIsPepUser(userIsPEP);
          updateUserFlags(userIsPEP);
          sendConfirmationEmaill();
        }
      })
      .catch((error) => {
        setFormikValues(values);
        setApiError(error);
        setLoading(false);
        setFormikValues({ ...formikValues });
      });
  };
  const handleClose = (e, reason) => {
    if (reason && reason === 'backdropClick') return;
    if (userIsRegistred) {
      setOpenCancelDialog(false);
      setWelcomeDialogOpen(false);
      const event = new CustomEvent('welcome-dialog-close', {
        detail: { userIsRegistred, userIsVerfiedPep, isPepUser }
      });

      setTimeout(() => {
        API.get('/user');
        window.dispatchEvent(event);
      }, 100);
    } else {
      setOpenCancelDialog(true);
    }
  };

  const handleContinueRegistration = () => {
    setOpenCancelDialog(false);
  };

  const handleCancelRegistration = () => {
    logout();
  };

  const noPepTypeSelected = (values) => {
    let userIsPEPType;
    const userIsPep = Util.stringToBoolean(values.userIsPep);
    if (
      values.isPep !== undefined &&
      values.isAssociateOfPep !== undefined &&
      values.isFamilymemberOfPep !== undefined
    ) {
      userIsPEPType =
        Util.stringToBoolean(values.isPep) ||
        Util.stringToBoolean(values.isAssociateOfPep) ||
        Util.stringToBoolean(values.isFamilymemberOfPep);
    }
   /*  setShowPepForm(userIsPep); */

    if (userIsPEPType !== undefined && !userIsPEPType && userIsPep) {
      /* setShowPepForm(!userIsPep); */
      setFormikValues({
        ...formikValues,
        userIsPep: 'no',
        isPep: undefined,
        isAssociateOfPep: undefined,
        isFamilymemberOfPep: undefined,
        checkboxGroupPepOriginsOfFunds: undefined
      });
    }
  };

  const handleEmailVerification = () => {
    API.get('/user')
      .then((res) => {
        const { data } = res;
        const { isEmailConfirmed } = data.userFlag;
        setEmailVerified(isEmailConfirmed);
        if (!isEmailConfirmed) setShowEmailNotVerifiedMessage(true);
      })
      .catch((flagError) => {
        setApiError(flagError);
        setLoading(false);
      });
  };

  const handleFormikChange = useCallback(
    (values) => {
      setFormikValues(values);
      noPepTypeSelected(values);
    },
    [formikValues]
  );

  useEffect(() => {
    if (!isRegistered) getUser();
  }, [welcomeDialogOpen]);

  const setFormikSubmitError = () => {
    setSubmitError(true);
  };
  const submitErrorDisplay = (formikError) => (
    <Alert severity="warning" style={{ textAlign: 'justify', width: '100%' }} key={formikError}>
      <AlertTitle>{FORM_TRANSLATIONS.formMustBeFilledIn}</AlertTitle>
      {mapFormikErrorFields(formikError, t, FORM_TRANSLATIONS)}
    </Alert>
  );

  if (userIsRegistred && !emailVerified) {
    return (
      <EmailConfirmationDialog
        showEmailNotVerifiedMessage={showEmailNotVerifiedMessage}
        emailVerificationDialogOpen={emailVerificationDialogOpen}
        handleClose={handleEmailVerification}
        closeWithOutVerification={closeWithOutVerification}
        email={user.email}
        error={apiError || emailVerificationError}
        open
      />
    );
  }
  if (userIsRegistred && emailVerified) {
    setEmailVerificationDialogOpen(false);
    return <WelcomeDialog handleClose={handleClose} open={welcomeDialogOpen} userName={firstName} />;
  }

  if (userIsRegistred === undefined) {
    return null;
  }
  return (
    <Dialog
      transitionDuration={500}
      onClose={handleClose}
      open={!appLoading && !userIsRegistred}
      scroll="body"
      classes={{ paper: classes.dialogPaper }}
    >
      <ContinueOrCancelDialog
        open={openCancelDialog}
        handleContinue={handleContinueRegistration}
        handleCancel={handleCancelRegistration}
        title={t('cancel')}
        subTitle={t('cancelSignUp')}
        content={t('cancleSignUpMessage')}
        continueButtonText={t('continueSignUp')}
        cancelButtonText={t('cancelSignUp')}
        note={t('pleaseNote')}
      />
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.gridContainer}
      >
        {loading ? (
          <Grid container justifyContent="center" className={classes.gridContainer}>
            <Grid item sm={12} md={12} lg={12}>
              <Loading message={null} title={null} subtitle={null} />
            </Grid>
          </Grid>
        ) : (
          <>
            <CommonDialogTitle
              title={t('signUp')}
              subTitle={t('registrationFormMessage')}
              icon={
                <svg className="h-6 w-6 svg-icon-style" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                </svg>
              }
              onClose={handleClose}
            />
            <DialogContent dividers>
              <Grid container justifyContent="space-between" className={classes.gridContainer}>
                <Grid item sm={12} md={12} lg={12}>
                  {!!apiError && <Alert severity={apiError?.type || 'error'}>{errorHandler(apiError)}</Alert>}
                </Grid>
                <Formik
                  initialValues={formikValues}
                  enableReinitialize
                  onSubmit={handleFormSubmit}
                  validationSchema={SignUpFormSchema}
                >
                  {(formik) => (
                    <div>
                      {handleFormikChange(formik.values)}
                      {formik.isSubmitting && formik.errors && setTimeout(() => setFormikSubmitError(), 0)}
                      {submitError && !_isEmpty(formik.errors) ? submitErrorDisplay(formik.errors) : null}
                      <Form>
                        <Grid container item direction="row" className={classes.gridContainer} spacing={2}>
                          <Grid item sm={12} md={12} lg={12} className={classes.gridItem}>
                            <Typography variant="h6" gutterBottom className={classes.userIsPEPTitle}>
                              {t('contactInformation')}
                            </Typography>
                          </Grid>
                          <Grid item sm={12} md={6} lg={6} className={classes.gridItem}>
                            <div className={classes.emailHelpPopover}>
                              <HelpPopOver
                                content={
                                  <>
                                    <p>{t('whyWeNeedYourEmailAddressContent')}</p>
                                    <div style={{ textAlign: 'center' }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style">
                                        <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                      </svg>
                                    </div>
                                    <p>{t('whyWeNeedYourEmailAddressAditionalContent')}</p>
                                  </>
                                }
                                title={t('whyWeNeedYourEmailAddress')}
                                fullWidth
                              />
                            </div>
                            <Textfield name="email" label={FORM_TRANSLATIONS.formEmail} fullWidth />
                          </Grid>
                          <Grid item sm={12} md={6} lg={6} className={classes.gridItem}>
                           <Textfield name="phoneNumber" label={FORM_TRANSLATIONS.formPhoneNumber} fullWidth />
                          </Grid>
                        </Grid>
                        {/** Transactions amount radiobuttons */}
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          className={classes.transferHabitContainer}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <RadioButtonGroup
                              id="radioGroup"
                              label={
                                <>
                                  <Typography variant="h6" gutterBottom className={classes.transferHabitTitle}>
                                    {FORM_TRANSLATIONS.formTransferHabitTitle}
                                  </Typography>
                                  <Typography variant="h6" gutterBottom className={classes.transferHabitSubTitle}>
                                    {t('optionThatBestDescribes')}
                                  </Typography>
                                  <HelpPopOver
                                    paragraph={t('whyWeAskKYCQuestions')}
                                    content={
                                      <>
                                        <p>{t('yourSafetyAndSecurityAreOurTopPriorities')}</p>
                                        <div
                                          style={{
                                            fontSize: '24px',
                                            color: '#21a1c5',
                                            textAlign: 'center',
                                            justifyContent: 'center'
                                          }}
                                        >
                                          §
                                        </div>
                                        <p>{t('adheringToTheHighestLegalAndRegulatoryStandards')}</p>
                                      </>
                                    }
                                    title={t('kycQuestions')}
                                  />
                                </>
                              }
                              value={formik.values.transferHabit}
                              error={formik.errors.transferHabit}
                              touched={formik.touched.transferHabit}
                              onChange={formik.setFieldValue}
                              onBlur={formik.setFieldTouched}
                            >
                              {transferHabitItems.map((item, index) => {
                                const newRef = createRef();
                                return (
                                  <div
                                    key={`${formik.values.transferHabit}${item}${index + 1}`}
                                    aria-hidden="true"
                                    ref={newRef}
                                    className={classes.transferHabitRadioButtonContainer}
                                    style={{
                                      background: item.value === formik.values.transferHabit ? 'aliceblue' : 'none',
                                      border: item.value === formik.values.transferHabit ? '1px solid #37aacb' : 'none'
                                    }}
                                  >
                                    <Field
                                      key={item.value}
                                      label={t(item.value)}
                                      id={item.value}
                                      name="transferHabit"
                                      component={HabitRadioButton}
                                    />
                                  </div>
                                );
                              })}
                            </RadioButtonGroup>
                          </Grid>
                        </Grid>
                        {/** END */}
                        {/** Multi country select - revceiver country of intent */}
                        <Grid item sm={12} md={12} lg={12} className={classes.countryMultiSelectContainer}>
                          <>
                            <Typography variant="h6" gutterBottom className={classes.transferHabitTitle}>
                              {FORM_TRANSLATIONS.formCountriesToSendTo}
                            </Typography>
                            <Typography variant="h6" gutterBottom className={classes.transferHabitSubTitle}>
                              {t('youCanSelectOneOrMultipleRecipientCountries')}
                            </Typography>
                          </>
                          <MultiCountrySelect 
                            multiple 
                            id="receiverCountries" 
                            name="receiverCountries" 
                            label={t('receiverCountries')} 
                            fullWidth 
                            value={formik.values.receiverCountries}>
                            {recipientCountries.map((country) => (
                              <MenuItem className={classes.multiCountrySelectMenuItem} key={uniqueId()} value={country.countryName}>
                                {country.countryName}
                              </MenuItem>
                            ))}
                          </MultiCountrySelect>
                        </Grid>
                        {/** END */}
                        {/** Frequency of transactions radiobuttons */}
                        <Grid item sm={12} md={12} lg={12} className={classes.countryMultiSelectContainer}>
                          <RadioButtonGroup
                            id="radioGroup"
                            label={
                              <>
                                <Typography variant="h6" gutterBottom className={classes.transferHabitTitle}>
                                  {FORM_TRANSLATIONS.formFrequencyOfTransactionTitle}
                                </Typography>
                                <Typography variant="h6" gutterBottom className={classes.transferHabitSubTitle} />
                              </>
                            }
                            value={formik.values.frequencyOfTransaction}
                            error={formik.errors.frequencyOfTransaction}
                            touched={formik.touched.frequencyOfTransaction}
                            onChange={formik.setFieldValue}
                            onBlur={formik.setFieldTouched}
                          >
                            {frequencyOfTransactionItems.map((item, index) => {
                              const newRef = createRef();
                              return (
                                <div
                                  key={`${formik.values.frequencyOfTransaction}${item}${index + 1}`}
                                  aria-hidden="true"
                                  ref={newRef}
                                  className={classes.transferHabitRadioButtonContainer}
                                  style={{
                                    background: item.value === formik.values.frequencyOfTransaction ? 'aliceblue' : 'none',
                                    border: item.value === formik.values.frequencyOfTransaction ? '1px solid #37aacb' : 'none'
                                  }}
                                >
                                  <Field
                                    key={item.value}
                                    label={t(item.value)}
                                    id={item.value}
                                    name="frequencyOfTransaction"
                                    component={HabitRadioButton}
                                  />
                                </div>
                              );
                            })}
                          </RadioButtonGroup>
                        </Grid>
                        {/** END */}
                        {/** Recipient relationship */}
                        <Grid item sm={12} md={12} lg={12} className={classes.countryMultiSelectContainer}>
                          <CheckboxGroup
                            id='recipientRelationship'
                            className={classes.fieldItemContainer}
                            label={
                              <>
                                <Typography variant="h6" gutterBottom className={classes.transferHabitTitle}>
                                  {FORM_TRANSLATIONS.formUserRecipientRelationshipTitle}
                                </Typography>
                                <Typography variant="h6" gutterBottom className={classes.transferHabitSubTitle} />
                              </>
                            }
                            value={formik.values.recipientRelationship}
                            error={formik.errors.recipientRelationship}
                            touched={formik.touched.recipientRelationship}
                            onChange={formik.setFieldValue}
                            onBlur={formik.setFieldTouched}
                          >
                            {userRecipientRelationshipItems.map((item) =>
                              <Field key={item.value} label={t(item.value)} id={item.value} name='recipientRelationship' component={Checkbox} />
                            )}
                          </CheckboxGroup>
                        </Grid>
                        {/** END */}
                        {/** Origins of funds */}
                        <Grid item sm={12} md={12} lg={12} className={classes.countryMultiSelectContainer}>
                          <CheckboxGroup
                            id='originsOfFunds'
                            className={classes.fieldItemContainer}
                            label={
                              <>
                                <Typography variant="h6" gutterBottom className={classes.transferHabitTitle}>
                                  {FORM_TRANSLATIONS.formOriginOfFundsTitle}
                                </Typography>
                                <Typography variant="h6" gutterBottom className={classes.transferHabitSubTitle} />
                              </>
                            }
                            value={formik.values.originsOfFunds}
                            error={formik.errors.originsOfFunds}
                            touched={formik.touched.originsOfFunds}
                            onChange={formik.setFieldValue}
                            onBlur={formik.setFieldTouched}
                          >
                            {originsOfFundsItems.map((item) =>
                              <Field key={item.value} label={t(item.value)} id={item.value} name='originsOfFunds' component={Checkbox} />
                            )}
                          </CheckboxGroup>
                        </Grid>
                        {/** Origins of funds END */}
                        {/** Send money to multiple recipients */}
                        <Grid item sm={12} md={12} lg={12}>
                          <RadioButtonGroup
                            className={classes.userIsPEP}
                            id="radioGroup"
                            label={
                              <>
                                <Typography variant="h6" gutterBottom className={classes.transferHabitTitle}>
                                  {t('planToSednMoneyToMultipleRecipient')}
                                </Typography>
                                <Typography variant="h6" gutterBottom className={classes.transferHabitSubTitle} />
                              </>
                            }
                            value={formik.values.sendToMultipleRecipients}
                            error={formik.errors.sendToMultipleRecipients}
                            touched={formik.touched.sendToMultipleRecipients}
                          >
                            <Box style={{ display: 'flex', marginBottom: 40 }}>
                              <Field label={t('yes')} id="yes" name="sendToMultipleRecipients" component={RadioButton} />
                              <Field label={t('no')} id="no" name="sendToMultipleRecipients" component={RadioButton} />
                            </Box>
                          </RadioButtonGroup>
                        </Grid>
                        {/** END */}
                        {/** PEP Form */}
                        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                          <RadioButtonGroup
                            className={classes.userIsPEP}
                            id="radioGroup"
                            label={
                              <>
                                <Typography variant="h6" gutterBottom className={classes.transferHabitTitle}>
                                  {t('planToSednMoneyToMultipleRecipient')}
                                </Typography>
                                <Typography variant="h6" gutterBottom className={classes.transferHabitSubTitle} />
                              </>
                            }
                            value={formik.values.sendMoneyToMultipleRecipients}
                            error={formik.errors.sendMoneyToMultipleRecipients}
                            touched={formik.touched.sendMoneyToMultipleRecipients}
                          >
                            <Field label={t('yes')} id="yes" name="sendMoneyToMultipleRecipients" component={RadioButton} />
                            <Field label={t('no')} id="no" name="sendMoneyToMultipleRecipients" component={RadioButton} />
                          </RadioButtonGroup>
                        </Grid>
                        {/** END */}
                        {/** PEP Form */}
                        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                          <RadioButtonGroup
                            className={classes.userIsPEP}
                            id="radioGroup"
                            label={
                              <Typography variant="h6" gutterBottom className={classes.userIsPEPTitle}>
                                {t('areYouPepOrRCA')}
                                <HelpPopOver
                                  content={
                                    <>
                                      <p>{t('whatIsPEP')}</p>
                                      <SectionSign />
                                      <p>{t('kycPep')}</p>
                                    </>
                                  }
                                  title={t('pep')}
                                />
                              </Typography>
                            }
                            value={formik.values.userIsPep}
                            error={formik.errors.userIsPep}
                            touched={formik.touched.userIsPep}
                          >
                            <Field label={t('yes')} id="yes" name="userIsPep" component={RadioButton} />
                            <Field label={t('no')} id="no" name="userIsPep" component={RadioButton} />
                          </RadioButtonGroup>
                          <Collapse in={showPepForm} timeout={{ enter: 1000, exit: 1000 }}>
                            <Grid item sm={12} lg={12}>
                              <PepFormContainer formik={formik} user={user} />
                            </Grid>
                          </Collapse>
                        </Grid> */}
                        {/** PEP SECTION END */}
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          className={classes.gridContainer}
                        />
                        <Grid item sm={12} md={12} lg={12}>
                          {!!apiError && <Alert severity={apiError?.type || 'error'}>{errorHandler(apiError)}</Alert>}
                          {submitError && !_isEmpty(formik.errors) ? submitErrorDisplay(formik.errors) : null}
                        </Grid>
                        <div className={classes.gridContainer}>
                          <Grid item sm={12} lg={12} className={classes.emailConcentContaier}>
                            <Field
                              key={null}
                              label={
                                <Typography paragraph gutterBottom className={classes.emailConcent}>
                                  {t('emailSubscriptionCheckbox')}
                                </Typography>
                              }
                              id="emailConsent"
                              name="emailConsent"
                              component={CheckboxPlain}
                            />
                          </Grid>
                          <Grid item sm={12} md={12} lg={12}>
                            <Button
                              className={classes.saveUserButton}
                              type="submit"
                              disabled={formik.isSubmitting}
                              variant="contained"
                              color="primary"
                            >
                              <span className={classes.saveUserButtonText}>{t('registrationFormSignUp')}</span>
                            </Button>
                          </Grid>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid item sm={12} md={12} lg={12}>
                <Button onClick={handleClose} type="button" color="transparent">
                  <span className={classes.closeUserRegistrationButtonText}>{t('cancelSignUp')}</span>
                </Button>
              </Grid>
            </DialogActions>
          </>
        )}
      </Grid>
    </Dialog>
  );
}

SignUpDialog.defaultProps = {
  isRegistered: undefined,
  emailVerificationDialogOpen: undefined,
  setEmailVerificationDialogOpen: undefined,
  closeWithOutVerification: undefined
}

SignUpDialog.propTypes = {
  isRegistered: PropTypes.bool,
  emailVerificationDialogOpen: PropTypes.bool,
  setEmailVerificationDialogOpen: PropTypes.func,
  closeWithOutVerification: PropTypes.func
}
