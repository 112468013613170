import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
    background: '#fff'
  },
  spinner: {
    color: theme.palette.globalAppTheme.globalAppColor
  }
}));

export default function LoadingWithBackdrop(props) {
  const classes = useStyles();
  const { isLoading, message } = props;

  return (
    <div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" className={classes.spinner} size={80} thickness={3} />
        <Box>
          <Typography>{message || ''}</Typography>
        </Box>
      </Backdrop>
    </div>
  );
}

LoadingWithBackdrop.defaultProps = {
  message: '',
  isLoading: true
};

LoadingWithBackdrop.propTypes = {
  message: PropTypes.string,
  isLoading: PropTypes.bool
};
