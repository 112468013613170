import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Fade , Alert, AlertTitle } from '@mui/material';

import { useTranslation } from 'react-i18next';
import mtfBoxStyles from './mtfBoxStylesNarrow';
import ErrorBoundary from '../common/ErrorBoundary';
import SendMoneyDialog from '../dashboard/send-money-dialog/SendMoneyDialog';
import { setGlobalIsInvalidSenderAmount } from '../../store/redux/actions/moneyTransferBoxAction';
import SenderAmount from './SenderAmount';
import RecipientAmount from './RecipientAmount';
import PayerTransactionAmountLimitMessage from './PayerTransactionAmountLimitMessage';
import Util from '../../utils/Util';

const useStyles = mtfBoxStyles;

function MtfBoxNarrow(props) {
  const { shadow } = props;
  const classes = useStyles();
  const { t } = useTranslation(['dashboard-common']);
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.app);
  const { userTotalTransactionAmountCurrentMonth, userTransactionAmountMonthLimitReached, sendMoneyDialogIsOpen } = useSelector(
    (state) => state.dashboard
  );

  const { amount: senderAmount, isInvalidSenderAmount } = useSelector((state) => state.moneyTransfer);
  const { minimumAmount, amountDayLimit, amountMonthLimit } = useSelector((state) => state.app.setting);
  const [monthlyTransactionAmountReached, setMonthlyTransactionAmountReached] = useState();
  const matchesIPadLandscape = useMediaQuery('(max-width: 1024px) and (min-width: 600px)');
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('md'));

  React.useEffect(() => {
    if (
      !senderAmount ||
      senderAmount === '' ||
      senderAmount === null ||
      senderAmount < minimumAmount ||
      senderAmount > amountDayLimit ||
      !!error
    ) {
      dispatch(setGlobalIsInvalidSenderAmount(true));
    } else {
      dispatch(setGlobalIsInvalidSenderAmount(false));
    }

    const userTransactionLimit = amountMonthLimit - userTotalTransactionAmountCurrentMonth - parseInt(senderAmount, 10);

    if (userTransactionLimit === 0 || Util.isNegative(userTransactionLimit)) {
      setMonthlyTransactionAmountReached(true);
    } else {
      setMonthlyTransactionAmountReached(false);
    }
  }, [senderAmount, userTransactionAmountMonthLimitReached, monthlyTransactionAmountReached, sendMoneyDialogIsOpen]);

  return (
    <>
      <Grid container>
        {userTransactionAmountMonthLimitReached || monthlyTransactionAmountReached && !isInvalidSenderAmount && (
          <Grid xs={12} item style={{ paddingBottom: 8, width: '100%', maxWidth: matchesSm ? 300 : 'max-content' }}>
            <Fade timeout={3000} in={monthlyTransactionAmountReached}>
              <Alert severity="info">
                <AlertTitle>
                  <strong>{t('youHaveReachedTheMaximumTransactionLimitForThisMonth')}</strong>
                </AlertTitle>
                {t('seeAccountStatusForInformation')}
              </Alert>
            </Fade>
          </Grid>
        )}
        <PayerTransactionAmountLimitMessage />
      </Grid>
      <Paper elevation={shadow ? 10 : 0} className={classes.mtfBox} id="mtfbox-container" style={{ maxWidth: matchesIPadLandscape && 500 }}>
        <Grid container className={classes.gridContainer}>
          <ErrorBoundary>
            <Grid item xs={12} sm={12} md={4} lg={4} className={classes.gridItemInput}>
              <SenderAmount sendMoneyDialogOpen={sendMoneyDialogIsOpen} />
            </Grid>
            <Divider className={classes.divider} orientation="vertical" />
            <Grid item xs={12} sm={12} md={4} lg={4} className={classes.gridItemRecipientInput}>
              <RecipientAmount />
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} className={classes.gridItemButton}>
              <SendMoneyDialog />
            </Grid>
          </ErrorBoundary>
        </Grid>
      </Paper>
    </>
  );
}

MtfBoxNarrow.defaultProps = {
  shadow: 0
};

MtfBoxNarrow.propTypes = {
  shadow: PropTypes.number
};

export default MtfBoxNarrow;
