/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import axios from 'axios';
import config from '../config/config';
import AppError from './appError';

const instance = axios.create({
  transformResponse: [
    (response) => {
      let data;
      try {
        if (!response || response === '') {
          return {
            message: 'No content',
            status: 204
          };
        }
        data = JSON.parse(response);
      } catch (error) {
        if (typeof error === "string") {
          throw new AppError({
            type: "error",
            message: error
          });
        }
        throw new AppError(error);
      }
      return data;
    } 
  ],
  baseURL: `${config.api.apiGateway}/api/v1`,
  withCredentials: true,
  withXSRFToken: true,
  responseType: 'json'
});

instance.interceptors.response.use(
  (response) => {
    if (response?.status === 204) {
      return {
        message: 'No content',
        status: 204
      };
    }
    return response;
  },
  (error) => {
    const message = typeof error.response.data?.error === 'string' ? error.response.data?.error : error.response.data?.message;
    if (error.response.status >= 400 && error.response.status <= 499) {
      throw new AppError({
        type: "warning",
        status: error.response.status,
        message,
        error: error.response.data
      });
    }
    if (error.response.status >= 500) {
      throw new AppError({
        type: "error",
        status: error.response.status,
        message,
        error: error.response.data
      });
    }
    return Promise.resolve(error);
  }
);

/* axios.interceptors.response.use(undefined, (error) => {
  // eslint-disable-next-line no-param-reassign
  error.originalMessage = error.message;
  Object.defineProperty(error, 'message', {
    get() {
      if (!error.response) {
        return error.originalMessage;
      }
      return JSON.stringify(error.response.data);
    }
  });
  return Promise.reject(error);
}); */

export default instance;
