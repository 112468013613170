import Util from '../../../utils/Util';
/** PEP Form is not in use but will maybe be in the future. Leaving code related to PEP form till then. */
export const mapPEP = (pepValues, firstName, lastName, dateOfBirth) => {
    if (
      Util.stringToBoolean(pepValues.userIsPep) &&
      (Util.stringToBoolean(pepValues.isPep) ||
        Util.stringToBoolean(pepValues.isAssociateOfPep) ||
        Util.stringToBoolean(pepValues.isFamilymemberOfPep))
    ) {
      return {
        isPep: Util.stringToBoolean(pepValues.isPep),
        fullName: Util.stringToBoolean(pepValues.isPep) ? `${firstName} ${lastName}` : '',
        dateOfBirth: Util.stringToBoolean(pepValues.isPep) ? dateOfBirth : null,
        pepRole: pepValues.checkboxGroupPepRolls,
        pepTitle: pepValues.checkboxGroupPepRollsPepTitle,
        pepOrganisation: pepValues.checkboxGroupPepRollsPepOrganisation,
        pepCountry: Util.getISO3Code(pepValues.checkboxGroupPepRollsPepCountry),
        isFamilymemberOfPep: Util.stringToBoolean(pepValues.isFamilymemberOfPep),
        familyRelationPepFullName: pepValues.checkboxGroupPepFamilyRelationPepFullName,
        familyRelationPepDateOfBirth: pepValues.checkboxGroupPepFamilyRelationPepDateOfBirth,
        pepFamilyRelation: pepValues.checkboxGroupPepFamilyRelation,
        familyRelationPepTitle: pepValues.checkboxGroupPepFamilyRelationPepTitle,
        familyRelationPepOrganisation: pepValues.checkboxGroupPepFamilyRelationPepOrganisation,
        familyRelationPepCountry: Util.getISO3Code(pepValues.checkboxGroupPepFamilyRelationPepCountry),
        isAssociateOfPep: Util.stringToBoolean(pepValues.isAssociateOfPep),
        associateRelationPepFullName: pepValues.checkboxGroupPepAssociateRelationPepFullName,
        associateRelationPepDateOfBirth: pepValues.checkboxGroupPepAssociateRelationPepDateOfBirth,
        pepAssociateRelation: pepValues.checkboxGroupPepAssociateRelation,
        associateRelationPepTitle: pepValues.checkboxGroupPepAssociateRelationPepTitle,
        associateRelationPepOrganisation: pepValues.checkboxGroupPepAssociateRelationPepOrganisation,
        associateRelationPepCountry: Util.getISO3Code(pepValues.checkboxGroupPepAssociateRelationPepCountry),
        pepOriginsOfFunds: pepValues.checkboxGroupPepOriginsOfFunds
      };
    }

    return {
      isPep: Util.stringToBoolean(pepValues.isPep),
      isFamilymemberOfPep: Util.stringToBoolean(pepValues.isFamilymemberOfPep),
      isAssociateOfPep: Util.stringToBoolean(pepValues.isAssociateOfPep),
      pepOriginsOfFunds: []
    };
  };