import makeStyles from '@mui/styles/makeStyles';

const landingPageStyle = makeStyles((theme) => ({
  root: {},
  container: {
    ...theme.container,
    zIndex: '12',
    color: '#FFFFFF',
    textAlign: 'center'
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3'
  },

  lead: {
    color: '#fff',
    fontSize: '1.0rem',
    lineHeight: '1.8'
  }
}));

export default landingPageStyle;
