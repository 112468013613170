import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import uniqueId from 'lodash/uniqueId';
// import { aboutVivowireText } from './aboutVivowireText';

const styles = makeStyles((theme) => ({
  title: {
    textAlign: 'left',
    color: '#4a6582',
    fontSize: theme.typography.pxToRem(22),
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: 1.43,
    fontStretch: 'normal',
    letterSpacing: 'normal'
  },
  subTitle: {
    textAlign: 'left',
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582',
    marginBottom: '20px'
  },
  subTextNested: {
    textAlign: 'left',
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582',
    marginBottom: '20px'
  },
  link: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    marginBottom: '20px',
    textDecoration: 'none',
    color: '#21a2c6',
    cursor: 'pointer'
  },
  linkText: {
    textAlign: 'left',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: 'normal',
    color: '#21a2c6',
    textDecoration: 'none'
  }
}));

function ArticleTemplate({ content }) {
  const classes = styles();
  const { t } = useTranslation(['help-center-page']);
  const readMoreLinkRef = useRef(null);
  return (
    <Box mt={5} textAlign="left" className='help-articles'>
      <Typography className={classes.subTitle} gutterBottom>
        {t(content.subTitle)}
      </Typography>
      {content &&
        content?.text &&
        content?.text.map((txt) => (
          <Typography key={uniqueId()} className={classes.subTextNested} gutterBottom>
            {t(txt)}
          </Typography>
        ))}
      {content.readMore ? (
        <Link ref={readMoreLinkRef} color="inherit" className={classes.linkText} href={content.readMoreUrl} target="_blank" rel="noopener noreferrer">
          {t(content.readMore)}
        </Link>
      ) : null}
    </Box>
  );
}

ArticleTemplate.defaultProps = {
  content: []
};

ArticleTemplate.propTypes = {
  content: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default ArticleTemplate;
