import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import TRANSACTION_STATUS from '../../../constants/transactionStatus';

const useStyles = makeStyles(() => ({
  recentTransactionListSecondary: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#4a6582',
    textTransform: 'none'
  }
}));

function RecentTransactionsStatus({ status }) {
  const classes = useStyles();
  const { t } = useTranslation(['dashboard-common']);
  const handleTransactionStatus = (transactionStatus) => {
    switch (transactionStatus) {
      case TRANSACTION_STATUS.availableTransfer: 
      case TRANSACTION_STATUS.completedTransfer:
      case TRANSACTION_STATUS.completed:  
        return '#4caf50';
      case TRANSACTION_STATUS.completedPayment:
        return '#ff9800';
      case TRANSACTION_STATUS.canceledPayment:
      case TRANSACTION_STATUS.canceledTransfer:
        return '#a5a5a4';
      case TRANSACTION_STATUS.declinedTransfer:
      case TRANSACTION_STATUS.failedPayment:
      case TRANSACTION_STATUS.failedTransfer:
        return '#f44336';
      default:
        return '#ff9800';
    }
  };

  return (
    <Typography className={classes.recentTransactionListSecondary} noWrap>
      <span>{t('status')}:{' '}</span>
      <span style={{ letterSpacing: '1.0px', color: handleTransactionStatus(status.replace(/_/g, ' ')) }} size="small">
        {t(camelCase(TRANSACTION_STATUS[camelCase(status.replace(/_/g, ' '))]))}
      </span>
    </Typography>
  );
}

RecentTransactionsStatus.defaultProps = {
  status: ''
};

RecentTransactionsStatus.propTypes = {
  status: PropTypes.string
};

export default RecentTransactionsStatus;
