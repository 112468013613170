import makeStyles from '@mui/styles/makeStyles';
import LoginDialogBackground from '../../assets/svg/LoginBackground.svg';

const loginToSendModalStyle = makeStyles((theme) => ({
  dialogPaper: {
    '&.MuiDialog-paperWidthSm': {
      maxWidth: 600,
      [theme.breakpoints.down('md')]: {
        maxWidth: 'fit-content'
      }
    }
  },
  dialogContent: {
    backgroundImage: `url(${LoginDialogBackground})`,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  },
  errorAlertRoot: {
    padding: '90px 20px',
    fontFamily: 'Inter',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    width: '100%',
    fontSize: theme.typography.pxToRem(16),
    backgroundColor: 'rgb(255, 244, 229)',
    '& .MuiAlert-icon': {
      fontSize: 50,
      marginRight: 22
    }
  },
  infoAlertRoot: {
    border: '1px solid #fff',
    display: 'inline-flex'
  },
  serviceInfoRoot: {
    paddingTop: theme.spacing(2)
  },
  expandedLinkText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: 'normal',
    color: '#21a2c6',
    textDecoration: 'none'
  },
  loginButtonContainer: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0
    },
    [theme.breakpoints.up('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      margin: 0
    }
  },
  loginButton: {
    width: 'auto',
    minWidth: 205,
    height: '78px',
    textTransform: 'none',
    margin: 10,
    borderRadius: theme.borderRadiusButton,
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    backgroundImage: 'linear-gradient(to right, #19cac3, #1569af)',
    '&:hover,&:focus': {
      color: 'inherit',
      background: '#21a2c6'
    },
    [theme.breakpoints.down('md')]: {
      margin: 0
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    }
  },
  loginButtonGridItem: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginTop: '-108px'
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
      padding: '30px'
    }
  },
  loginButtonArrowForward: {
    marginLeft: theme.spacing(2),
    width: '16px',
    height: '16px',
    objectFit: 'contain',
    color: '#ffffff'
  },
  loginButtonText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.7px',
    color: '#ffffff'
  },
  loginModalCancelButtonGrid: {
    textAlign: 'center'
  },
  finishLoginButton: {
    height: '20px',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.7px',
    color: '#ffffff',
    textTransform: 'none',
    padding: '30px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.borderRadiusButton,
    width: '100%',
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover,&:focus': {
      color: '#fff',
      background: '#106982'
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  mainGridContainer: {},
  checkboxGrid: {
    float: 'left',
    marginTop: '1em'
  },
  dialogSubTitle: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  dialogTitle: {
    fontFamily: 'Rubik',
    fontSize: '34px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-1.42px',
    color: '#2f4662'
  },
  termAndConditions: {
    width: '100%'
  },
  termsAndConditionsConcent: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  pnrInputArormentStart: {
    padding: '10px'
  },
  bankidAutostartLink: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    textTransform: 'initial',
    border: '1px solid #21a1c5'
  }
}));

export default loginToSendModalStyle;