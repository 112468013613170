import withStyles from '@mui/styles/withStyles';

import InterWoff2 from './assets/fonts/inter/inter-v2-latin-regular.woff2';
import RubikWoff2 from './assets/fonts/rubik/rubik-v10-latin-regular.woff2';

const fontBasePath = './assets/fonts/';

/* inter-regular - latin */
const inter = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `local('Inter'),
       url(${fontBasePath}/inter/inter-v2-latin-regular.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(${InterWoff2}) format('woff2'), /* Super Modern Browsers */
       url(${fontBasePath}/inter/inter-v2-latin-regular.woff) format('woff'), /* Modern Browsers */
       url(${fontBasePath}/inter/inter-v2-latin-regular.ttf) format('truetype'), /* Safari, Android, iOS */
       url(${fontBasePath}/inter/inter-v2-latin-regular.svg#Inter) format('svg'); /* Legacy iOS */`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};

/* rubik sans-serif */
const rubik = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `local('Rubik'),
       url(${fontBasePath}/rubik/rubik-v10-latin-regular.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(${RubikWoff2}) format('woff2'), /* Super Modern Browsers */
       url(${fontBasePath}/rubik/rubik-v10-latin-regular.woff) format('woff'), /* Modern Browsers */
       url(${fontBasePath}/rubik/rubik-v10-latin-regular.ttf) format('truetype'), /* Safari, Android, iOS */
       url(${fontBasePath}/rubik/rubik-v10-latin-regular.svg#Rubik) format('svg'); /* Legacy iOS */`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};

const styles = (theme) => ({
  '@global': {
    html: {
      touchAction: 'manipulation'
    },
    body: {
      margin: 'auto 0',
      paddingRight: 0,
      overflow: 'auto',
      width: '100%', 
      height: '100%'
    },
    '@media print': {
      footer: {
        display: 'none',
        position: 'fixed',
        bottom: 0
      },
      header: {
        display: 'none',
        position: 'fixed',
        top: 0
      }
    },
    '@font-face': [inter, rubik],
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    // Romeves arrow up and down on number input
    'input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '*:focus': {
      outline: 0
    },
    '.text-white': {
      color: theme.palette.common.white
    },
    '.app-theme-text-color': {
      color: theme.palette.globalAppTheme.globalAppTextColor
    },
    '.container': {
      width: '100%',
      /*       paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4), */
      marginRight: 'auto',
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 540
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 720
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1170
      }
    },
    '.row': {
      display: 'flex',
      flexWrap: 'wrap',
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2)
    },
    '.lg-mg-top': {
      marginTop: `${theme.spacing(20)} !important`,
      [theme.breakpoints.down('lg')]: {
        marginTop: `${theme.spacing(18)} !important`
      },
      [theme.breakpoints.down('md')]: {
        marginTop: `${theme.spacing(16)} !important`
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: `${theme.spacing(14)} !important`
      }
    },
    '.lg-mg-bottom': {
      marginBottom: `${theme.spacing(20)} !important`,
      [theme.breakpoints.down('lg')]: {
        marginBottom: `${theme.spacing(18)} !important`
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: `${theme.spacing(16)} !important`
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: `${theme.spacing(14)} !important`
      }
    },
    '.lg-mg-bottom-custom': {
      marginBottom: `${theme.spacing(3)} !important`,
      [theme.breakpoints.down('lg')]: {
        marginBottom: `${theme.spacing(4)} !important`
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: `${theme.spacing(4)} !important`
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: `${theme.spacing(3)} !important`
      }
    },
    '.lg-p-top': {
      paddingTop: `${theme.spacing(20)} !important`,
      [theme.breakpoints.down('lg')]: {
        paddingTop: `${theme.spacing(18)} !important`
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: `${theme.spacing(16)} !important`
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: `${theme.spacing(14)} !important`
      }
    },
    '.lg-p-bottom': {
      paddingBottom: `${theme.spacing(20)} !important`,
      [theme.breakpoints.down('lg')]: {
        paddingBottom: `${theme.spacing(18)} !important`
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: `${theme.spacing(16)} !important`
      },
      [theme.breakpoints.down('sm')]: {
        paddingBottom: `${theme.spacing(14)} !important`
      }
    },
    '.lg-p-top-custom': {
      paddingTop: `${theme.spacing(5)} !important`,
      [theme.breakpoints.down('lg')]: {
        paddingTop: `${theme.spacing(9)} !important`
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: `${theme.spacing(8)} !important`
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: `${theme.spacing(7)} !important`
      }
    },
    '.lg-p-custom': {
      paddingTop: `${theme.spacing(5)} !important`,
      paddingBottom: `${theme.spacing(5)} !important`,
      [theme.breakpoints.down('lg')]: {
        paddingTop: `${theme.spacing(9)} !important`,
        paddingBottom: `${theme.spacing(9)} !important`
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: `${theme.spacing(8)} !important`,
        paddingBottom: `${theme.spacing(8)} !important`
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: `${theme.spacing(7)} !important`,
        paddingBottom: `${theme.spacing(7)} !important`
      }
    },
    '.font-family-rubik': {
      fontFamily: 'Rubik'
    },
    '.font-family-inter': {
      fontFamily: 'Inter'
    },
    '.svg-icon-style': {
      width: '30px',
      height: '30px',
      stroke: '#21a1c5',
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: 2
    },
    '.svg-icon-style-white': {
      width: '30px',
      height: '30px',
      stroke: '#fff',
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: 2
    },
    '.disabled-element': {
      pointerEvents: 'none',
      opacity: 0.15
    },
    '.MuiAlert-root': {
      marginRight: '8px 0'
    },
    '.MuiAlert-root .MuiAlert-message': {
      textAlign: 'left',
      fontFamily: 'Inter',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#4a6582',
      fontSize: theme.typography.pxToRem(16)
    },
    '.MuiAlert-root .MuiAlert-icon': {
      marginRight: 8
    },
    '.MuiButtonBase-root': {
      fontFamily: 'Inter',
      textTransform: 'none',
      fontSize: 'large'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  }
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
