import { ACTION_TYPES } from '../ReduxActionTypes';
import { moneyTransferBoxInitialValues } from '../../constants';

const moneyTransferBoxReducer = (
  state = {
    senderCountries: [],
    recipientCountries: [],
    deliveryMethods: [],
    convertedAmount: {},
    convertedAmountError: undefined,
    senderCountryOptionValue: {},
    recipientCountryOptionValue: {},
    selectedSenderCountryName: moneyTransferBoxInitialValues.SELECTED_SENDER_COUNTRY_NAME,
    selectedRecipientCountryName: moneyTransferBoxInitialValues.SELECTED_RECIPIENT_COUNTRY_NAME,
    selectedDeliveryMethod: moneyTransferBoxInitialValues.SELECTED_DELIVERY_METHOD,
    amount: moneyTransferBoxInitialValues.INITITAL_AMOUNT,
    amountToReceive: undefined,
    maxTransactionAmountForSelectedDeliveryMethod: undefined,
    isInvalidSenderAmount: false,
    isInvalidPayerTransactionAmount: false,
    isOpenMtfPriceDetails: false,
    conversionMode: moneyTransferBoxInitialValues.SOURCE_AMOUNT,
    isConverting: false
  },
  action
) => {
  switch (action.type) {
    case ACTION_TYPES.SET_CONVERTED_AMOUNT:
      return { ...state, convertedAmount: action.payload };
    case ACTION_TYPES.SET_CONVERTED_AMOUNT_ERROR:
      return { ...state, convertedAmountError: action.payload };
    case ACTION_TYPES.SET_AMOUNT:
      return { ...state, amount: action.payload };
    case ACTION_TYPES.SET_AMOUNT_TO_RECEIVE:
      return { ...state, amountToReceive: action.payload };
    case ACTION_TYPES.SET_RECIPIENT_COUNTRIES:
      return { ...state, recipientCountries: action.payload };
    case ACTION_TYPES.SET_SENDER_COUNTRIES:
      return { ...state, senderCountries: action.payload };
    case ACTION_TYPES.SET_SENDER_COUNTRY_OPTION_VALUE:
      return { ...state, senderCountryOptionValue: action.payload };
    case ACTION_TYPES.SET_RECIPIENT_COUNTRY_OPTION_VALUE:
      return { ...state, recipientCountryOptionValue: action.payload };
    case ACTION_TYPES.SET_INITIAL_SENDER_COUNTRY_NAME:
      return { ...state, selectedSenderCountryName: action.payload };
    case ACTION_TYPES.SET_SELECTED_RECIPIENT_COUNTRY_NAME:
      return { ...state, selectedRecipientCountryName: action.payload };    
    case ACTION_TYPES.SET_IS_INVALID_SENDER_AMOUNT:
        return { ...state, isInvalidSenderAmount: action.payload };
    case ACTION_TYPES.SET_IS_INVALID_PAYER_TRANSACTION_AMOUNT:
        return { ...state, isInvalidPayerTransactionAmount: action.payload };
    case ACTION_TYPES.SET_IS_OPEN_MTF_PRICE_DETAILS:
        return { ...state, isOpenMtfPriceDetails: action.payload };
    case ACTION_TYPES.SET_SELECTED_DELIVERY_METHOD:
        return { ...state, selectedDeliveryMethod: action.payload };  
    case ACTION_TYPES.SET_MAX_TRANSACTION_AMOUNT_SELECTED_DELIVERY_METHOD:
        return { ...state, maxTransactionAmountForSelectedDeliveryMethod: action.payload };         
    case ACTION_TYPES.SET_DELIVERY_METHODS:
      return { ...state, deliveryMethods: action.payload };      
    case ACTION_TYPES.SET_CONVERSION_MODE:
      return { ...state, conversionMode: action.payload };  
    case ACTION_TYPES.SET_IS_CONVERTING:
      return { ...state, isConverting: action.payload };  
    default:
      return state;
  }
};

export default moneyTransferBoxReducer;
