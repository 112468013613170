import Logger from './Logger';

const date = new Date();

const DEFAULT_ERROR = 'Error';
const ERROR = 'error';
const WARNING = 'warning';

const defaultErrorMessage = 'An unexpected error has occurred. Please try again later. If this issue persists, contact tech support';
const defaultErrorStatus = 1000;

const errorType = (error) => {
  if (error?.status && error?.status >= 400 && error?.status <= 499) {
    return WARNING
  }
  if (error?.status && error?.status >= 500) {
    return ERROR
  }
  return ERROR
}

function AppError(error) {

    this.name = error?.name || DEFAULT_ERROR;
    this.type = errorType(error);
    this.message = error?.message || defaultErrorMessage;
    this.code = error?.status || defaultErrorStatus;
    this.time = date.toLocaleString();
    this.isAppError = true; 
    this.isDismissable = error?.isDismissable || false;
  
  Error.captureStackTrace(this, error || AppError);
  Logger.logError(this);
}

export default AppError;
