/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import Menu from '@mui/icons-material/Menu';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import ErrorBoundary from '../../../common/ErrorBoundary';
import styles from './headerStyle';
import PERMALINKS from '../../../../constants/permalinks';

export default function Header(props) {
  const classes = styles();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const appBarReference = useRef(null);
  const [selectedLanguage] = useState(useSelector((state) => state.app.appLanguage));
  const matches = useMediaQuery('(max-width: 1280px) and (min-width: 960px)');

  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      setScrolled(windowsScrollTop > changeColorOnScroll.height);
      if (changeColorOnScroll.callback) changeColorOnScroll.callback(windowsScrollTop > changeColorOnScroll.height);
      document.body.querySelectorAll('header')[0].classList.remove(classes[color]);
      document.body.querySelectorAll('header')[0].classList.add(classes[changeColorOnScroll.color]);
    } else {
      setScrolled(false);
      if (changeColorOnScroll.callback) changeColorOnScroll.callback(false);
      document.body.querySelectorAll('header')[0].classList.add(classes[color]);
      document.body.querySelectorAll('header')[0].classList.remove(classes[changeColorOnScroll.color]);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setTimeout(() => {
      setMobileOpen(false);
    }, 1000);
  };

  const { color, rightLinks, leftLinks, brand, fixed, absolute, brandWhenScroll } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });

  const handleBrandClick = () => {
    const landingPageHeroElement = document.querySelector('#hero-section');
    if (window.location.pathname === PERMALINKS.HOME) landingPageHeroElement.scrollIntoView({ behavior: 'smooth' });
    navigate(PERMALINKS.HOME);
  };

  const brandComponent = (
    <div className={classes.brandContainer}>
      <img
        src={brand}
        id="vivowire-logo-white"
        className={classes.brand}
        alt="Vivowire Logo"
        onClick={handleBrandClick}
        onKeyDown={handleBrandClick}
        width={matches ? '50' : '150'}
        height="auto"
      />
    </div>
  );
  const brandComponentScroll = (
    <div className={classes.brandAltContainer}>
      <img
        src={brandWhenScroll}
        id="vivowire-logo-blue"
        className={classes.brandAlt}
        alt="Vivowire Logo"
        onClick={handleBrandClick}
        onKeyDown={handleBrandClick}
        width={matches ? '50' : '150'}
        height="auto"
      />
    </div>
  );

  useEffect(() => {
    window.addEventListener('header-login-clicked', handleDrawerClose);
    window.addEventListener('language-select', handleDrawerClose);
    window.addEventListener('header-link-clicked', handleDrawerClose);
    const { changeColorOnScroll } = props;
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return () => {
      window.removeEventListener('header-login-clicked', handleDrawerClose);
      window.addEventListener('language-select', handleDrawerClose);
      window.addEventListener('header-link-clicked', handleDrawerClose);
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  }, [selectedLanguage]);
  
  return (
    <ErrorBoundary>
      <AppBar id="header" className={appBarClasses} ref={appBarReference}>
        <Toolbar className={classes.container}>
          {leftLinks !== undefined && !scrolled ? brandComponent : brandComponentScroll}
            <div className={classes.leftLinksContainer} id="header-left-links-container">
              {leftLinks !== undefined ? (
                <Hidden mdDown implementation="css">
                  {leftLinks}
                </Hidden>
              ) : 
                brandComponent
              }
            </div>
          <Hidden mdDown implementation="css">
            {rightLinks}
          </Hidden>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              size="large">
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={handleDrawerToggle}
          >
            <div className={classes.appResponsive}>
              {leftLinks}
              {rightLinks}
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    </ErrorBoundary>
  );
}

Header.defaultProp = {
  color: 'white',
  brandWhenScroll: PropTypes.string,
  changeColorOnScroll: undefined,
  callback: undefined,
  rightLinks: undefined,
  leftLinks: undefined,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool
};

Header.propTypes = {
  callback: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark'])
    .isRequired,
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  brandWhenScroll: PropTypes.string,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark'])
      .isRequired
  })
};
