import { ACTION_TYPES } from '../ReduxActionTypes';

export const setGlobalConvertedAmount = (convertedAmount) => ({
  type: ACTION_TYPES.SET_CONVERTED_AMOUNT,
  payload: convertedAmount
});

export const setGlobalConvertedAmountError = (convertedAmountError) => ({
  type: ACTION_TYPES.SET_CONVERTED_AMOUNT_ERROR,
  payload: convertedAmountError
});

export const setGlobalAmount = (amount) => ({
  type: ACTION_TYPES.SET_AMOUNT,
  payload: amount
});

export const setGlobalAmountToReceive = (amountToReceive) => ({
  type: ACTION_TYPES.SET_AMOUNT_TO_RECEIVE,
  payload: amountToReceive
});

export const setGlobalSelectedRecipientCountryName = (selectedRecipientCountryName) => ({
  type: ACTION_TYPES.SET_SELECTED_RECIPIENT_COUNTRY_NAME,
  payload: selectedRecipientCountryName
});

export const setGlobalSenderCountryOptionValue = (senderCountryOptionValue) => ({
  type: ACTION_TYPES.SET_SENDER_COUNTRY_OPTION_VALUE,
  payload: senderCountryOptionValue
});

export const setGlobalRecipientCountryOptionValue = (recipientCountryOptionValue) => ({
  type: ACTION_TYPES.SET_RECIPIENT_COUNTRY_OPTION_VALUE,
  payload: recipientCountryOptionValue
});

export const setGlobalIsInvalidSenderAmount = (isInvalidSenderAmount) => ({
  type: ACTION_TYPES.SET_IS_INVALID_SENDER_AMOUNT,
  payload: isInvalidSenderAmount
});

export const setGlobalIsInvalidPayerTransactionAmount = (isInvalidPayerTransactionAmount) => ({
  type: ACTION_TYPES.SET_IS_INVALID_PAYER_TRANSACTION_AMOUNT,
  payload: isInvalidPayerTransactionAmount
});

export const setGlobalIsOpenMtfPriceDetails = (isOpenMtfPriceDetails) => ({
  type: ACTION_TYPES.SET_IS_OPEN_MTF_PRICE_DETAILS,
  payload: isOpenMtfPriceDetails
});

export const setGlobalSelectedDeliveryMethod = (selectedDeliveryMethod) => ({
  type: ACTION_TYPES.SET_SELECTED_DELIVERY_METHOD,
  payload: selectedDeliveryMethod
});

export const setGlobalDeliveryMethods = (deliveryMethods) => ({
  type: ACTION_TYPES.SET_DELIVERY_METHODS,
  payload: deliveryMethods
});

export const setGlobalMaxTransactionAmountForSelectedDeliveryMethod = (maxTransactionAmountForDeliveryMethod) => ({
  type: ACTION_TYPES.SET_MAX_TRANSACTION_AMOUNT_SELECTED_DELIVERY_METHOD,
  payload: maxTransactionAmountForDeliveryMethod
});

export const setGlobalConversionMode = (mode) => ({
  type: ACTION_TYPES.SET_CONVERSION_MODE,
  payload: mode
});

export const setGlobalIsConverting = (isConverting) => ({
  type: ACTION_TYPES.SET_IS_CONVERTING,
  payload: isConverting
})