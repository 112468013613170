import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '@mui/icons-material/ErrorRounded';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, AlertTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import support from '../../constants/support';

const useStyles = makeStyles((theme) => ({
  componentErrorRoot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  alert: {
    backgroundColor: 'rgba(244, 67, 54, 0.5)',
    fontFamily: 'Inter',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#fff',
    width: '100%',
    fontSize: theme.typography.pxToRem(16),
    '& .MuiAlert-icon': {
      color: '#fff',
      fontSize: 50,
      marginRight: 55
    }
  },
  reload: {
    cursor: 'pointer',
    display: 'inline-block'
  },
  default: {
    fontFamily: 'Inter',
    color: '#686868',
    padding: '2px'
  },
  geContainer: {
    textAlign: 'center',
    padding: '2em',
    fontSize: '1.4em',
    fontFamily: 'Roboto',
    color: '#686868'
  },
  inputError: {
    padding: 0
  }
}));

export function ComponentError() {
  const classes = useStyles();
  const { t } = useTranslation(['landing-page-common']);
  const reload = () => window.location.reload();

  return (
    <div className={classes.componentErrorRoot}>
      <Alert severity="error" className={classes.alert}>
        <AlertTitle>
          <Typography variant="h6" component="h6">
            {t('errorBoundryTitle')}
          </Typography>
        </AlertTitle>
        {t('errorBoundryMessage', { email: support.VIVOWIRE_SUPPORT_EMAIL })}
        <div role="button" className={classes.reload} onClick={reload} onKeyDown={reload} tabIndex={0}>
          {t('errorBoundryReload')}
        </div>
      </Alert>
    </div>
  );
}

export function InputErrorDisplay(props) {
  const classes = useStyles();
  const { message } = props;
  return (
    <div className={classes.inputError} title={message}>
      <ErrorIcon color="error" fontSize="large" />
    </div>
  );
}

InputErrorDisplay.propTypes = {
  message: PropTypes.string
};

InputErrorDisplay.defaultProps = {
  message: ''
};
