import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { styles } from './fieldStyles';

function FormikFieldSelect(props) {
  const { id, items, name, label, required = false } = props;

  const classes = styles();
  return (
    <div className={classes.formFieldContainer}>
      <Field
        variant="filled"
        InputProps={{
          disableUnderline: true,
          className: classes.textfield,
          style: { textAlign: 'left' }
        }}
        defaultValue=""
        InputLabelProps={{ className: classes.inputLabel }}
        id={id}
        select
        required={required}
        autoComplete="off"
        as={TextField}
        label={label}
        name={name}
        fullWidth
        helperText={
          <span className={classes.helperText}>
            <ErrorMessage name={name} />
          </span>
        }
      >
        {items.map((option) => (
          <MenuItem className={classes.optionItem} key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Field>
    </div>
  );
}

FormikFieldSelect.defaultProps = {
  name: '',
  label: '',
  required: false,
  items: {},
  id: ''
};

FormikFieldSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      value: PropTypes.string
    })
  ),
  id: PropTypes.string
};

export default FormikFieldSelect;
