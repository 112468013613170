import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
/* import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined'; */
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
/* import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'; */
/* import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'; */
/* import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'; */
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { List, ListItem, ListItemIcon, ListItemText, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import brand from '../../assets/img/vivowire-logo-outlined-no-text-color.png';
import useStyles from './dashboardStyles';
import { logout } from '../../context/auth';

function DashboardDrawer({ isRegistered, drawerToggle }) {
  const classes = useStyles();
  const { t } = useTranslation(['dashboard-common']);
  return (
    <Grid className={classes.drawerContainer} container direction="column">
      <Grid container item direction="row" justifyContent="center">
        <img className={classes.brand} src={brand} alt="logo" height='39' width='50' />
      </Grid>
      <div className={classes.toolbar} />
      {/*  <Divider /> */}
      <Grid item container direction="row" justifyContent="center" xs={7} className={classes.drawerTop}>
        <List>
          <ListItem
            onClick={drawerToggle}      
            disabled={!isRegistered}
            button
            key="Overview"
            selected={window.location.pathname === '/dashboard'}
            component={Link}
            to="/dashboard"
          >
            <ListItemIcon>
              <DashboardOutlinedIcon />
            </ListItemIcon>
            <ListItemText className={classes.drawerMenuText} primary={t('overview')} />
          </ListItem>
          <ListItem button key="Profile" component={Link} to="/dashboard/profile" onClick={drawerToggle} disabled={!isRegistered}>
            <ListItemIcon>
              <PersonOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText className={classes.drawerMenuText} primary={t('profile')} />
          </ListItem>
{/*           <ListItem button key="Transactions" component={Link} to="/dashboard/transactions" onClick={drawerToggle} disabled={!isRegistered}>
            <ListItemIcon>
              <ReceiptOutlinedIcon />
            </ListItemIcon>
            <ListItemText className={classes.drawerMenuText} primary="Transactions" />
          </ListItem> */}
          {/** DONT REMOVE COMMENTED CODE FROM THIS FILE */}
          {/*  <ListItem button key="Send Money">
            <ListItemIcon>
              <LocalAtmOutlinedIcon />
            </ListItemIcon>
            <ListItemText className={classes.drawerMenuText} primary="Send Money" />
          </ListItem>

          <ListItem button key="Profile" component={Link} to="/dashboard/profile">
            <ListItemIcon>
              <PersonOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText className={classes.drawerMenuText} primary="Profile" />
          </ListItem>
          <ListItem button key="Recipients">
            <ListItemIcon>
              <GroupOutlinedIcon />
            </ListItemIcon>
            <ListItemText className={classes.drawerMenuText} primary="Recipients" />
          </ListItem>

          <ListItem button key="Help">
            <ListItemIcon>
              <HelpOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText className={classes.drawerMenuText} primary="Help" />
          </ListItem> */}
        </List>
      </Grid>
      <Grid container item justifyContent="center" style={{ marginTop: 60 }}>
        {/** TODO IMPLEMENT */}
        <List>
          {[
            /* { label: 'Settings', icon: <SettingsOutlinedIcon />, handleClick: () => null }, */
            { label: t('logOut'), icon: <ExitToAppOutlinedIcon />, handleClick: () => logout() }
          ].map((menu) => (
            <ListItem button key={menu.label} onClick={menu.handleClick}>
              <ListItemIcon>{menu.icon}</ListItemIcon>
              <ListItemText primary={menu.label} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}

DashboardDrawer.defaultProps = {
  isRegistered: false,
  drawerToggle: undefined
}

DashboardDrawer.propTypes = {
  isRegistered: PropTypes.bool,
  drawerToggle: PropTypes.func
}

export default DashboardDrawer;
