import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { Typography, Divider, Grid , Alert } from '@mui/material';

import useStyles from '../dashboardStyles';
import HelpPopOver from '../../common/HelpPopOver';
import NumberFormatter from '../../../utils/NumberFormatter';
import Loading from '../../common/Loading';
import { errorHandler } from '../../../utils/errorMapper';
import Util from '../../../utils/Util';
import { setGlobalUserTotalTransactionAmountCurrentMonth, setGlobalUserTransactionAmountMonthLimitReached } from '../../../store/redux/actions/dashboardAction';

function AccountStatus({ loading, error }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const transactionStats = useSelector((state) => state.userInfo.transactionStats);
  const { userTransactionAmountMonthLimitReached, userTotalTransactionAmountCurrentMonth } = useSelector((state) => state.dashboard);
  const { amountMonthLimit } = useSelector((state) => state.app?.setting);
  const { amount } = useSelector((state) => state.moneyTransfer);
  const { t } = useTranslation(['dashboard-common']);

  const currency = 'kr';
  const { nextResetDate, totalTransactionsCurrentMonth } = transactionStats || {};
  const resetDate = nextResetDate && nextResetDate.split('T')[0];
  const remaining = amountMonthLimit - totalTransactionsCurrentMonth;
  const remainingAmount = transactionStats ? `${NumberFormatter.svAmountFormatNoFloat(remaining || 0)} ${currency}` : 0;
  const userTransactionLimit = amountMonthLimit - userTotalTransactionAmountCurrentMonth - parseInt(amount, 10);

  useEffect(() => {
    dispatch(setGlobalUserTransactionAmountMonthLimitReached(remaining < 0  || Util.isNegative(remaining)));
    dispatch(setGlobalUserTotalTransactionAmountCurrentMonth(totalTransactionsCurrentMonth));
  }, [remainingAmount]);

  return (
    <Grid item sm={12} md={12} lg={4} className="lg-p-custom">
      <Typography className={classes.transactionStatsTitle}>{t('accountStatus')}</Typography>
      <Grid item sm={12} md={12} lg={10}>
        {isEmpty(error) ? null : <Alert severity={error?.type || 'error'}>{errorHandler(error)}</Alert>}
      </Grid>
      {loading ? 
        <Loading /> :
       (
        <Grid container className={classes.transactionInfoSection}>
          <Grid item xs={3}>
          <Typography className={classes.transactionInfoSectionTitle}>
            {t('monthlyLimit')}
            <HelpPopOver
              content={<p>{t('monthlyLimitContent', { remaining: remainingAmount, reset: resetDate })}</p>}
              title={t('monthlyLimit')}
            />
            </Typography>
            <Typography className={classes.transactionInfoSectionAmount}>
              {`${NumberFormatter.svAmountFormatNoFloat(amountMonthLimit || 0)} ${currency}`}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Divider sx={{ width: '1px', height: 1 }} variant="middle" />
          </Grid>
          <Grid item xs={3}>
          <Typography className={classes.transactionInfoSectionTitle}>
            {t('remainingAmountForSendingCurrentMonth')}
            {userTransactionAmountMonthLimitReached || Util.isNegative(userTransactionLimit) ? (
              <HelpPopOver
                type="info"
                content={
                  <>
                    <p>{t('whyThereIsALimit')}</p>
                    <p>{t('theMonthlyTransactionLimitIsAMechanism')}</p>
                    <p>{t('resetDate', { reset: resetDate })}</p>
                  </>
                }
                title={t('youHaveReachedTheMaximumTransactionLimitForThisMonth')}
              />
            ) : null}
            </Typography>
            <Typography className={classes.transactionInfoSectionAmount}>{remainingAmount}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Divider sx={{ width: '1px', height: 1 }} variant="middle" />
          </Grid>
          <Grid item xs={3}>
          <Typography className={classes.transactionInfoSectionTitle}>{t('nextResetDate')}</Typography>
            <Typography className={classes.transactionInfoSectionAmount}>{resetDate}</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

AccountStatus.defaultProps = {
  loading: false,
  error: {}
};

AccountStatus.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default AccountStatus;