/* eslint-disable no-undef */
import React, { useRef, useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, Divider, Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _camelCase from 'lodash/camelCase';
import _kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import LoadingSmall from '../common/LoadingSmall';
import { InputErrorDisplay } from '../common/ErrorDisplay';
import Title from '../common/Title';
import { deliveryMethodsIcons } from './deliveryMethodsIcons';
import {
  setGlobalConvertedAmount,
  setGlobalIsOpenMtfPriceDetails,
  setGlobalMaxTransactionAmountForSelectedDeliveryMethod,
  setGlobalSelectedDeliveryMethod
} from '../../store/redux/actions/moneyTransferBoxAction';
import convertAmount from './convertAmount';
import { PAYOUT_ACCOUNT_TYPES } from '../../constants/payoutAccountTypes';
import { moneyTransferBoxInitialValues } from '../../store/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'start',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      background: '#F8FAFC',
      padding: theme.spacing(2)
    }
  },
  inputAltStyle: {
    borderRadius: theme.borderRadiusInput,
    backgroundColor: '#eef3f9',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    padding: theme.spacing(2)
  },
  title: {
    margin: '10px'
  },
  menuItemText: {
    textTransform: 'capitalize',
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#4a6582',
    opacity: 0.8,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  menuItem: {
    width: 'auto',
    height: 'auto',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  avatar: {
    margin: '5px',
    width: '30px',
    height: '30px',
    background: 'transparent'
  },
  label: {
    marginBottom: '-8px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#4a6582',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  mainButton: {
    marginLeft: '-11px',

    [theme.breakpoints.down('sm')]: {
      padding: 13,
      marginLeft: '-15px'
    }
  },
  svg: {
    height: '25px',
    width: '25px',
    stroke: '#21a1c5',
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: 2
  },
  deliveryMethod: {
    width: 'max-content',
    maxWidth: 250,
    padding: '5px 6px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    textTransform: 'none',
    textAlign: 'left',
    color: theme.palette.globalAppTheme.globalAppTextColor,
    [theme.breakpoints.down('sm')]: {
      fontSize: `${theme.typography.pxToRem(16)} !important`
    }
  }
}));

function DeliveryMethodSelect({ isInputStyle, filter }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const buttonReference = useRef(null);
  const [anchorElement, setAnchorElement] = useState(null);
  const {
    recipientCountryOptionValue,
    deliveryMethods,
    selectedDeliveryMethod,
    amount,
    amountToReceive,
    conversionMode,
    convertedAmount
  } = useSelector((state) => state.moneyTransfer);
  const [deliveryMethod, setDeliveryMethod] = useState(selectedDeliveryMethod?.type);
  const { t } = useTranslation('landing-page-common');
  const { error: appError, loading: appLoading } = useSelector((state) => state.app);
  const { getConvertedRate } = convertAmount();

  const handleMenuButtonClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuItemClick = (e, _deliveryMethod) => {
    const listItemClickEvent = new CustomEvent('delivery-method-change', {
      detail: _deliveryMethod,
      bubbles: true
    });
    e.target.dispatchEvent(listItemClickEvent);
    setDeliveryMethod(_deliveryMethod?.type);
    const amountToSend = conversionMode === moneyTransferBoxInitialValues.SOURCE_AMOUNT ? amount : amountToReceive;
    const activePayer = deliveryMethods.find((p) => p.isActive)

    if(_deliveryMethod?.isActive) {
      getConvertedRate(amountToSend, _deliveryMethod?.id, conversionMode);
      dispatch(setGlobalSelectedDeliveryMethod(_deliveryMethod));
      dispatch(
        setGlobalMaxTransactionAmountForSelectedDeliveryMethod({
          maxAmount: _deliveryMethod?.maxTransaction,
          minAmount: _deliveryMethod?.minTransaction,
          currency: _deliveryMethod?.currency,
          name: _deliveryMethod?.name
        })
      );
      dispatch(setGlobalIsOpenMtfPriceDetails(true));
    } else if(activePayer && activePayer.type === _deliveryMethod?.type) {
      dispatch(setGlobalSelectedDeliveryMethod(activePayer));
      dispatch(
        setGlobalMaxTransactionAmountForSelectedDeliveryMethod({
          maxAmount: activePayer?.maxTransaction,
          minAmount: activePayer?.minTransaction,
          currency: activePayer?.currency,
          name: activePayer?.name
        })
      );
      dispatch(setGlobalIsOpenMtfPriceDetails(true));
    } else {
      dispatch(setGlobalSelectedDeliveryMethod({ type: 'deliveryMethodNotAvailableForThisPayer' }));
      dispatch(setGlobalMaxTransactionAmountForSelectedDeliveryMethod(null));
      dispatch(
        setGlobalConvertedAmount(
          Object.assign(convertedAmount, { ...convertedAmount, rate: '', totalToPay: '', convertedAmount: '' })
        )
      );
      dispatch(setGlobalIsOpenMtfPriceDetails(false));
    }
    setAnchorElement(null);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const deliveryMethodDescription = (_method) => {
    if (PAYOUT_ACCOUNT_TYPES.MOBILE_WALLET.includes(_method)) {
      return t('moneyTransferToDigialWallet');
    }
    if (PAYOUT_ACCOUNT_TYPES.CASH_PICKUP.includes(_method)) {
      return t('sendMoneyForCashPickup');
    }
    if (PAYOUT_ACCOUNT_TYPES.BANK_ACCOUNT.includes(_method)) {
      return t('transferMoneyDirectlyToBankAccount');
    }
    return null;
  };

  const displayDefaultMenuItem = () => {
    if (appError) {
      return <InputErrorDisplay message={t('defaultErrorMessage')} />;
    }
    if (appLoading) {
      return <LoadingSmall />;
    }
    return (
      <>
       {selectedDeliveryMethod?.type === 'deliveryMethodNotAvailableForThisPayer' ?
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-6 h-6 svg-icon-style">
            <path d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
          </svg> :
        <Avatar alt={selectedDeliveryMethod?.type} className={classes.avatar}>
          <span className={classes.svg}>{deliveryMethodsIcons(selectedDeliveryMethod?.type)}</span>
        </Avatar>}
        <span style={{ fontSize: selectedDeliveryMethod?.type === 'deliveryMethodNotAvailableForThisPayer' ? '1rem' : 24 }} className={classes.deliveryMethod}>{t(_camelCase(selectedDeliveryMethod?.type))}</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style">
            <path d="M19 9l-7 7-7-7" />
          </svg>
      </>
    );
  };

  useEffect(() => {}, [deliveryMethod, deliveryMethods, recipientCountryOptionValue, selectedDeliveryMethod, amountToReceive]);

  const deliveryMethodsUnique = [...new Map(deliveryMethods?.filter((m) => m.isActive).map((item) => [item.type, item])).values()];

  return (
    <div className={classNames(classes.root, isInputStyle ? classes.inputAltStyle : null)}>
      <Typography className={classes.label}>{t('deliveryMethod')}</Typography>
      <Button
        id={`selected-${_kebabCase(deliveryMethod)}`}
        className={classes.mainButton}
        ref={buttonReference}
        aria-label="delivery-method-button"
        aria-controls={`${_kebabCase(deliveryMethod)}-menu-item`}
        aria-haspopup="true"
        onClick={handleMenuButtonClick}
        disabled={appError || !selectedDeliveryMethod?.name}
      >
        {displayDefaultMenuItem()}
      </Button>
      <Menu
        className="delivery-method-menu"
        id={`${_kebabCase(deliveryMethod)}-menu-item`}
        aria-label="delivery-method-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
        disableScrollLock
      >
        {filter ?
          deliveryMethodsUnique.map((method) => (
            <div key={`$${method.id}-${method}`}>
              <MenuItem
                id={`${deliveryMethod}-menu-item-${method}`}
                onClick={(e) => handleMenuItemClick(e, method)}
                value={method.type}
                key={`${method.id}-${method}`}
                className={classNames(classes.menuItem, 'menu-item-delivery-method')}
              >
                <span className={classes.svg}>{deliveryMethodsIcons(method.type)}</span>
                <div className={classes.title}>
                  {/*   <Typography className={classes.menuItemText} gutterBottom>{t(_camelCase(method.type))}</Typography> */}
                  <Title
                    customStyleTitle={{ color: '#21a1c5', fontWeight: 600 }}
                    customStyleSubtitle={{ width: '100%', maxWidth: '350px' }}
                    subtitle={deliveryMethodDescription(method.type)}
                  >
                    {t(_camelCase(method.type))}
                  </Title>
                </div>
              </MenuItem>
              {deliveryMethodsUnique.length > 1 ? <Divider variant="middle" style={{ height: 1 }} /> : null}
            </div>
          )) :
          deliveryMethods &&
          deliveryMethods.map((method) => (
            <div key={`$${method.name}-${method.type}`}>
              <MenuItem
                id={`${deliveryMethod}-menu-item-${method.type}`}
                onClick={() => handleMenuItemClick(method)}
                value={method.type}
                key={`$${method.name}-${method.type}`}
                className={classNames(classes.menuItem, 'menu-item-delivery-method')}
              >
                <span className={classes.svg}>{deliveryMethodsIcons(method.type)}</span>
                <div className={classes.title}>
                  <Title subtitle={`${t('partner')}: ${method.name} \n ${t('currency')}: ${method.currency}`}>
                    {t(_camelCase(method.type))}
                  </Title>
                </div>
              </MenuItem>
              {deliveryMethods.length > 1 ? <Divider variant="middle" style={{ height: 1 }} /> : null}
            </div>
          ))}
      </Menu>
    </div>
  );
}

DeliveryMethodSelect.defaultProps = {
  isInputStyle: false,
  filter: false
};

DeliveryMethodSelect.propTypes = {
  isInputStyle: PropTypes.bool,
  filter: PropTypes.bool
};

export default DeliveryMethodSelect;
