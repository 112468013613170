import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import camelCase from 'lodash/camelCase';

export default function DashboardBreadcrumbs(props) {
  const navigate = useNavigate();
  const { pathname } = props;
  const { t } = useTranslation(['dashboard-common']);

  const handleClick = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="/" onClick={handleClick} style={{ display: 'grid', gridTemplateColumns: '35px 1fr' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 svg-icon-style"
          viewBox="0 0 24 24"
          style={{ height: 22, width: 22 }}
        >
          <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
        {t('overview')}
      </Link>
      <Typography color="textPrimary">{t(`${camelCase(pathname)}`)}</Typography>
    </Breadcrumbs>
  );
}

DashboardBreadcrumbs.propTypes = {
  pathname: PropTypes.string.isRequired
};
