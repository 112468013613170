import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _camelCase from 'lodash/camelCase';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import LoadingSmall from '../common/LoadingSmall';
import {
  setGlobalSenderCountryOptionValue,
  setGlobalRecipientCountryOptionValue,
  setGlobalIsOpenMtfPriceDetails,
  setGlobalSelectedRecipientCountryName} from '../../store/redux/actions/moneyTransferBoxAction';
import Title from '../common/Title';
import { InputErrorDisplay } from '../common/ErrorDisplay';
import SvgImage from '../common/DynamicSvgImageRender';
import { COUNTRY_TYPES } from './constants';
import { getPayersForRecipientCountry } from '../mtf/getPayers';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '6px'
  },
  title: {
    margin: '10px'
  },
  currency: {
    padding: '5px 6px',
    height: 'auto',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    marginLeft: theme.spacing(0),
    flex: 1,
    color: theme.palette.globalAppTheme.globalAppTextColor,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18)
    }
  },
  arrowDown: {
    opacity: '0.5'
  },
  menuItem: {
    width: 'auto',
    height: 'auto',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18)
    }
  },
  '@global': {
    '@keyframes flash': {
      '50%': {
        background: 'rgb(170, 170, 170)'
      },
      '100%': {
        background: 'transparent'
      }
    }
  },
  buttonRefFocus: {
    animation: 'flash 2s'
  },
  mainButton: {
    [theme.breakpoints.down('sm')]: {
      padding: 13,
      marginLeft: '-24px'
    }
  }
}));

function CountryCurrencySelectMenu({ type }) {
  const classes = useStyles();
  const { t } = useTranslation(['landing-page-common']);
  const { t: tc } = useTranslation(['common']);
  const buttonReference = useRef(null);
  const dispatch = useDispatch();
  const { senderCountryOptionValue, recipientCountryOptionValue } = useSelector(
    (state) => state.moneyTransfer
  );

  const { senderCountries, recipientCountries } = useSelector((state) => state.app);
  const countries = type === COUNTRY_TYPES.SENDER ? senderCountries : recipientCountries;

  const { loading: appLoading, error: appError } = useSelector((state) => state.app);
  const [anchorElement, setAnchorElement] = useState(null);
  const [error, setError] = useState(undefined);
  const [countryType, setCountryType] = useState(type);
  const { mapIsoCodeAndGetPayers } = getPayersForRecipientCountry();

  const getSelectedCountryByType = () => {
    switch (countryType) {
      case COUNTRY_TYPES.SENDER:
        return senderCountryOptionValue;
      case COUNTRY_TYPES.RECIPIENT:
        return recipientCountryOptionValue;
      default:
        return undefined;
    }
  };

  const [selectedCountry, setSelectedCountry] = useState(
    getSelectedCountryByType() && getSelectedCountryByType()[0]?.countryName
  );

  const handleMenuButtonClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const setSelectedSenderCountry = (selectedCountryName) => {
    const currentSelected = senderCountries?.find((co) => co.countryName === selectedCountryName);
    dispatch(setGlobalSenderCountryOptionValue(currentSelected));
    return currentSelected;
  };

  const setSelectedRecipientCountry = (selectedCountryName) => {
    let currentSelected = [];
    if (recipientCountries.length > 0) {
      currentSelected = recipientCountries.find((co) => co.countryName === selectedCountryName);
      dispatch(setGlobalRecipientCountryOptionValue(currentSelected));
      dispatch(setGlobalSelectedRecipientCountryName(currentSelected?.isoCode3));
    }
    return currentSelected;
  };

  const handleMenuItemClick = (event, countryName) => {
    const userSelectedCountry = countryName;
    setSelectedCountry(userSelectedCountry);

    if (countryType === COUNTRY_TYPES.SENDER) {
      setSelectedSenderCountry(userSelectedCountry);
    }
    if (countryType === COUNTRY_TYPES.RECIPIENT) {
      setSelectedRecipientCountry(userSelectedCountry);
      const getCountryIsoCode = recipientCountries.find((country) => country.countryName === countryName);
      /** Special handling for Somaliland */
      mapIsoCodeAndGetPayers(getCountryIsoCode.isoCode3, countryName);
    }

    setAnchorElement(null);
    dispatch(setGlobalIsOpenMtfPriceDetails(true));
  };

  const handleMenuClose = (event) => {
    setAnchorElement(null);
    if (event.target.innerHTML === '') {
      setSelectedCountry(getSelectedCountryByType() && getSelectedCountryByType()[0]?.countryName);
    } else {
      setSelectedCountry(event.target.innerHTML);
    }
  };

  const handleDestinationItemClicked = (event) => {
    setCountryType(type);
    setError(null);
    if (buttonReference?.current !== null) {
      buttonReference.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setTimeout(() => {
        if (buttonReference.current?.id === 'selected-recipient-currency') {
          let recipientCountry;
          if (event.detail.modal) {
            buttonReference.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            setSelectedCountry(event.detail.value);
            recipientCountry = setSelectedRecipientCountry(event.detail.value);
          } else {
            setSelectedCountry(event.detail);
            recipientCountry = setSelectedRecipientCountry(event.detail);
          }

          buttonReference.current?.classList.add(classes.buttonRefFocus);
          mapIsoCodeAndGetPayers(recipientCountry?.isoCode3, recipientCountry?.countryName);
        }
      }, 1);
    }
  };

  const displayDefaultMenuItem = () => {
    if (appError || error) {
      return <InputErrorDisplay message={t('defaultErrorMessage')} />;
    }
    if (appLoading) {
      return <LoadingSmall size={30} />;
    }
    return (
      <>
        <SvgImage
          width="30"
          height="30"
          fileName={getSelectedCountryByType()?.isoCode2?.toLowerCase()}
          pathInAssets="flags/4x3"
          alt={getSelectedCountryByType()?.isoCode2?.toLowerCase()}
          className={getSelectedCountryByType()?.countryName}
        />
        <span className={classes.currency}>{getSelectedCountryByType()?.currency}</span>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style">
          <path d="M19 9l-7 7-7-7" />
        </svg>
      </>
    );
  };

  React.useEffect(() => {
    window.addEventListener('destination-item-clicked', handleDestinationItemClicked);
    // cleanup this component
    return () => {
      window.removeEventListener('destination-item-clicked', handleDestinationItemClicked);
    };
  }, [
    selectedCountry,
    anchorElement,
    countryType,
    senderCountries,
    recipientCountries,
    senderCountryOptionValue,
    recipientCountryOptionValue
  ]);

  return (
    <div className={classes.root}>
      <Button
        id={`selected-${countryType}-currency`}
        className={classes.mainButton}
        ref={buttonReference}
        aria-controls={`${countryType}-menu`}
        aria-haspopup="true"
        onClick={handleMenuButtonClick}
        disabled={appError || error}
      >
        {displayDefaultMenuItem()}
      </Button>
      <Menu
        id={`${countryType}-menu`}
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
        disableScrollLock
      >
        {countries &&
          countries.map((country) => (
            <div key={country.countryName}>
              <MenuItem
                id={`${countryType}-menu-item-${country.countryName}`}
                onClick={(e) => handleMenuItemClick(e, country.countryName)}
                value={country.countryName}
                key={country.countryName}
                className={classNames(classes.menuItem, 'menu-item-with-country-name')}
              >
                <SvgImage
                  width="30"
                  height="30"
                  fileName={country?.isoCode2.toLowerCase()}
                  pathInAssets="flags/4x3"
                  alt={country?.isoCode2.toLowerCase()}
                  className={country.countryName}
                />
                <div className={classes.title}>
                  <Title subtitle={country.currency}>{tc(_camelCase(country.countryName))}</Title>
                </div>
              </MenuItem>
              <Divider variant="middle" style={{ height: 1 }} />
            </div>
          ))}
      </Menu>
    </div>
  );
}

CountryCurrencySelectMenu.defaultProps = {};

CountryCurrencySelectMenu.propTypes = {
  type: PropTypes.string.isRequired
};

export default CountryCurrencySelectMenu;
