import React, { useState } from 'react';

import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import { Alert , Button, Fade, Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { mapFormikErrorFields } from '../sign-up/mapFormikErrorFields';
import { FORM_TRANSLATIONS } from '../formTranslations';
import Textfield from '../../common/TextfieldWrapper';
import { recipientDetailsValidation } from './addRecipientValidation';
import { setGlobalSelectedRecipient, setGlobalUserRecipients } from '../../../store/redux/actions/dashboardAction';
import API from '../../../utils/api';
import { errorHandler } from '../../../utils/errorMapper';
import RecipientPayoutAccountList from '../recipient-payout-account/RecipientPayoutAccountList';
import Loading from '../../common/Loading';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  gridItem: {
    padding: 16
  },
  infoTitle: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662',
    padding: '34px 0px 16px 0px'
  },
  inputLabel: {
    width: 'fit-content',
    height: '32px',
    margin: '10px 83px 0 30px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  innerLabel: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    marginLeft: theme.spacing(0),
    color: theme.palette.globalAppTheme.globalAppTextColor
  },
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.globalAppTheme.globalAppTextColor
    }
  },
  inputBackground: {
    borderRadius: theme.borderRadius,
    backgroundColor: '#f8fafc'
  },
  buttonContainer: {
    textAlign: 'center',
    justifyContent: 'center',
    display: 'grid'
  },
  saveDetailsButton: {
    width: '220px',
    height: 62,
    marginTop: 40,
    marginBottom: 10,
    padding: '25 121',
    objectFit: 'contain',
    opacity: 0.9,
    borderRadius: theme.borderRadiusButton,
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover,&:focus': {
      background: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  saveDetailsButtonText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    paddingRight: 4,
    textTransform: 'initial',
    color: '#fff'
  },
  cancelButtonText: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#919ba8',
    textTransform: 'none'
  },
  emailConcentTitle: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662',
    padding: '48px 0px 30px 0px'
  },
  emailConcentAnswer: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662'
  },
  emailConcentContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr'
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  switchRoot: {
    width: '90px',
    height: '50px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  track: {
    width: '50px',
    height: '30px',
    borderRadius: '20px'
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.globalAppTheme.globalAppButtonColor,
      transform: 'translateX(40px)'
    },
    '& + $track': {
      backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  checked: {
    color: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover': {
      backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  thumb: {
    width: '32px',
    height: '32px',
    transform: 'translate(0px, -1px)'
  },
  recipientPayerListName: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.62,
    color: '#2f4662'
  },
  recipientPayerListSecondary: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#4a6582'
  }
}));

function RecipientUpdateForm({ recipient, handleMenuItemClose, editMode, handleCancelEdit }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['dashboard-common']);
  const { userRecipients } = useSelector((state) => state.dashboard);
  const [, /* recipientPayers, */ setRecipientPayers] = useState([]);
  const [, /* payers, */ setPayers] = useState([]);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = useState(false);

  const getRecipientPayers = () => {
    API.get(`/recipient/${recipient?.id}`)
      .then((res) => {
        const { data } = res;
        setRecipientPayers(data.recipientPayer);
      })
      .catch((_error) => setError(_error));
  };

  const getAllPayers = () => {
    API.get('/payers')
      .then((res) => {
        const { data } = res;
        setPayers(data);
      })
      .catch((_error) => setError(_error));
  };

  const getUserRecipients = async () => {
    setLoading(true);
    API.get('/recipients')
      .then((response) => {
        setLoading(false);
        dispatch(setGlobalUserRecipients(response.data));
      })
      .catch((_error) => {
        setLoading(false);
        setError(_error);
        dispatch(setGlobalUserRecipients([]));
      });
  };

  const updateSelectedRecipient = (values) => {
    const { id } = values;
    const updateRecipient = _pick(values, ['firstName', 'lastName', 'street', 'city']);
    if (id) {
      API.put(`/recipient/${id}`, updateRecipient)
        .then((response) => {
          const recipientToUpdate = userRecipients.find((_recipient) => _recipient.id === id);
          const updatedRecipient = { ...recipientToUpdate, ...response.data };
          dispatch(setGlobalSelectedRecipient(updatedRecipient));
          getUserRecipients();
          handleMenuItemClose();
        })
        .catch((_error) => {
          setError(_error);
        });
    }
  };

  React.useEffect(() => {
    getRecipientPayers();
    getAllPayers();
    setError(null);
  }, []);

  return (
    <>
      {!!error && <Alert severity="error">{errorHandler(error)}</Alert>}
      <Formik
        initialValues={recipient}
        onSubmit={updateSelectedRecipient}
        validationSchema={recipientDetailsValidation}
      >
        {({
          values,
          errors,
          /* touched, */
          handleChange,
          /*  handleBlur, */
          handleSubmit
          /* isSubmitting */
        }) => 
          loading ? <Loading /> :
          <Form onSubmit={handleSubmit}>
            {!_isEmpty(errors) && (
              <Alert severity="warning" style={{ textAlign: 'justify', width: '100%' }} key={errors}>
                {FORM_TRANSLATIONS.formMustBeFilledIn}
                {mapFormikErrorFields(errors, t, FORM_TRANSLATIONS)}
              </Alert>
            )}
            {/** Personal information */}
            <Grid container direction="row" className={classes.gridContainer} spacing={2} sx={{ pl: 2 }}>
              <Typography className={classes.infoTitle} noWrap>
                {t('basicInformation')}
              </Typography>
              <Grid container item direction="row" className={classes.gridContainer} spacing={2}>
                <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                  <Textfield
                    fullWidth
                    onChange={handleChange}
                    value={values.firstName}
                    label={FORM_TRANSLATIONS.formFirstName}
                    name="firstName"
                    readOnly={!editMode}
                    shrink
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                  <Textfield
                    fullWidth
                    onChange={handleChange}
                    value={values.lastName}
                    label={FORM_TRANSLATIONS.formLastName}
                    name="lastName"
                    readOnly={!editMode}
                    shrink
                  />
                </Grid>
              </Grid>
              <Grid container item direction="row" className={classes.gridContainer} spacing={2}>
                <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                  <Textfield
                    fullWidth
                    onChange={handleChange}
                    value={values.street}
                    label={FORM_TRANSLATIONS.formStreet}
                    name="street"
                    readOnly={!editMode}
                    shrink
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                  <Textfield
                    fullWidth
                    onChange={handleChange}
                    value={values.city}
                    label={FORM_TRANSLATIONS.formCity}
                    name="city"
                    readOnly={!editMode}
                    shrink
                  />
                </Grid>
              </Grid>
            </Grid>
            {/** Account information */}
            <RecipientPayoutAccountList recipient={recipient} editMode={editMode} handleChange={handleChange} />
            <Grid item xs={12} md={12} lg={12} className={classes.gridItem}>
            <Fade in={editMode}>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.saveDetailsButton}
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={updateSelectedRecipient}
                  disabled={!_isEmpty(errors)}
                >
                  <span className={classes.saveDetailsButtonText}>{t('save')}</span>
                </Button>
                <Button className={classes.cancelButtonText} onClick={handleCancelEdit}>
                  {t('cancel')}
                </Button>
              </div>
            </Fade>
            </Grid>
          </Form>
        }
      </Formik>
    </>
  );
}

RecipientUpdateForm.defaultProps = {
  recipient: undefined,
  handleMenuItemClose: undefined,
  editMode: undefined,
  handleCancelEdit: undefined
};

RecipientUpdateForm.propTypes = {
  recipient: PropTypes.shape({
    id: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    street: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    emailConsent: PropTypes.bool
  }),
  handleMenuItemClose: PropTypes.func,
  editMode: PropTypes.bool,
  handleCancelEdit: PropTypes.func
};

export default RecipientUpdateForm;
