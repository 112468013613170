// The text i this file are place holders to generate translation properties and are mapped i the *-policy.json file in the locales folder
// eslint-disable-next-line no-unused-vars

const policy = [
  {
    id: 1,
    title: '',
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 2,
    title: 'Who is Vivowire',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 3,
    title: 'What we collect',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      }
    ]
  },
  {
    id: 4,
    title: 'What we do with the information we gather ',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      }
    ]
  },
  {
    id: 5,
    title: 'Legal grounds for processing your personal data',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 6,
    title: 'How long we keep your personal data',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 7,
    title: 'How we share information with others',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      }
    ]
  },
  {
    id: 8,
    title: 'Transferring data outside of European Economic Area',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 9,
    title: 'Users responsibility',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 10,
    title: 'Security',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 11,
    title: 'Use of cookies',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ],
    readMore: 'Read more about the use of cookies in Vivowire cookie policy',
    readMoreUrl: '/cookie-policy'
  },
  {
    id: 12,
    title: 'Third-Party Sites',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 13,
    title: 'Contact',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 14,
    title: '',
    text: [
      {
        textSubText: '',
        indent: true,
        bold: true
      }
    ]
  }
]
export default policy;
