import withStyles from '@mui/styles/withStyles';
import { Tooltip } from '@mui/material';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      padding: theme.spacing(1),
      backgroundColor: '#fff',
      width: '500px',
      fontFamily: 'Inter',
      fontWeight: '400',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.43',
      letterSpacing: 'normal',
      color: '#4a6582',
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(16),
      border: '1px solid #dadde9',
      boxShadow: '0 18px 48px 0 rgb(145 155 168 / 20%)'
    }
  }))(Tooltip);

  export default HtmlTooltip;