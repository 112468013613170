import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import TextField from '@mui/material/TextField';
import { styles } from './fieldStyles';

function FormikFieldIText(props) {
  const { name, label, type = 'text', required = false } = props;

  const classes = styles();
  return (
    <div className={classes.formFieldContainer}>
      <Field
        variant="filled"
        InputProps={{
          disableUnderline: true,
          className: classes.textfield,
          style: { padding: '45px 30px' }
        }}
        InputLabelProps={{ className: classes.inputLabel }}
        multiline
        minRows={4}
        required={required}
        autoComplete="off"
        as={TextField}
        label={label}
        name={name}
        fullWidth
        type={type}
        helperText={
          <span className={classes.helperText}>
            <ErrorMessage name={name} />
          </span>
        }
      />
    </div>
  );
}

FormikFieldIText.defaultProps = {
  name: '',
  label: '',
  type: 'text',
  required: false
};

FormikFieldIText.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool
};

export default FormikFieldIText;
