/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from 'react';
import camelCase from 'lodash/camelCase';
import { Translation, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Grid, Typography, Divider, Link, Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Facebook, Instagram, LinkedIn/* , Twitter */ } from '@mui/icons-material';
import { uniqueId } from 'lodash';
import ForestBackdrop from '../../../../assets/svg/ForestBackdrop.svg';
import footerLinks from './footerLinks';
import footerStyles from './footerStyles';
import support from '../../../../constants/support';
import PERMALINKS from '../../../../constants/permalinks';
import TwitterIcon from '../../../../assets/svg/Twitter-x-.svg';

const useStyles = footerStyles;
function Footer() {
  const classes = useStyles();
  const { t } = useTranslation(['landing-page-common']);

  const scrollToSection = (element) => {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleBrandCLick = () => {
    const headerElement = document.querySelector('#hero-section');
    const contactElement = document.querySelector('#contact-section');
    const cookiePolicyElement = document.querySelector('#cookie-policy-section');
    const companyAndTeamElement = document.querySelector('#company-and-team-section');
    const privacyPolicyElement = document.querySelector('#privacy-policy-section');
    const termsAndConditions = document.querySelector('#terms-and-conditions-section');
    const helpCenterElement = document.querySelector('#help-center-page-template-head');
    switch (window.location.pathname) {
      case PERMALINKS.HOME:
        return scrollToSection(headerElement);
      case PERMALINKS.CONTACT_US:
        return scrollToSection(contactElement);
      case PERMALINKS.COOKIE_POLICY:
        return scrollToSection(cookiePolicyElement);
      case PERMALINKS.COMPANY_AND_TEAM:
        return scrollToSection(companyAndTeamElement);
      case PERMALINKS.PRIVACY_POLICY:
        return scrollToSection(privacyPolicyElement);
      case PERMALINKS.TERMS_AND_CONDITIONS:
        return scrollToSection(termsAndConditions);
      case PERMALINKS.HELP_CENTER:
        return scrollToSection(helpCenterElement);
      default:
        break;
    }
    return false;
  };
  return (
    <Box id="desitination-section" component="section" sx={{ p: 2, mt: 15 }} className={classes.footerContainer}>
      <Grid container direction="row" className={classNames(classes.gridContainer)}>
        <Grid item xs={12} md={12} lg={3}>
          <Typography align="left" className={classNames(classes.vivowire)} onClick={handleBrandCLick}>
            <Translation ns={['landing-page-common']}>{(t) => t('brandNameLower')}</Translation>
          </Typography>
        </Grid>
        {footerLinks.map((item) => (
          <Grid item xs={12} md={12} lg={3} key={uniqueId()}>
            <Typography align="left" key={uniqueId()} className={classNames(classes.titleText)}>
              <Translation ns={['landing-page-common']}>{(t) => t(`${camelCase(item.title)}`)}</Translation>
            </Typography>
            <Grid item xs={12} md={12} lg={12} key={uniqueId()}>
              {item.children.map((child) => (
                <Typography align="left" key={uniqueId()} className={classNames(classes.linkText)}>
                  <a key={child.linkName} className={classes.linkText} href={child.path}>
                    {child.linkName}
                  </a>
                </Typography>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img
            className={classes.forestBackdropImg}
            src={ForestBackdrop}
            width="500"
            height="600"
            alt="Footer Backdrop"
          />
        </Box>
        <Divider className={classes.divider} />
      <Grid container style={{ padding: 16, textAlign: 'center', justifyContent: 'center' }}>
        <Grid item xs={12} md={12} lg={4}>
          <Typography align="left" className={classNames(classes.copyright)}>
            <Translation ns={['landing-page-common']}>
              {(t) => t('copyright', { thisYear: new Date().getFullYear() })}
            </Translation>
          </Typography>
          <Typography align="left" className={classNames(classes.companyAuthorization)}>
            <Translation ns={['landing-page-common']}>{(t) => t('companyAuthorization')}</Translation>
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <div className={classes.contacInfoContainer}>
            <div style={{ paddingRight: 8 }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 svg-icon-style">
                <path d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                <path d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
              </svg>
            </div>
            <div>
              <Typography className={classNames(classes.contacInfoTitle)}>{t('companyAddress')}</Typography>
             {/*  <Typography
                className={classNames(classes.contacInfo)}
              >{`${support.COMPANY_ADRESS}, ${support.COMPANY_ZIPCODE}`}</Typography> */}
              <Typography className={classNames(classes.contacInfo)}>{`${t(camelCase(support.COMPANY_CITY))}, ${t(
                camelCase(support.COMPANY_COUNTRY)
              )}`}</Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={3} className={classes.socialMediaContainer}>
          <Link
            color="inherit"
            aria-label="Facebook link"
            className={classes.itemLinkText}
            href={support.VIVOWIRE_FACEBOOK}
            target="_blank"
            rel="noreferrer"
          >
            <Facebook className={classes.socialMediaIcons} />
          </Link>
          <Link
            color="inherit"
            aria-label="Twitter link"
            className={classes.itemLinkText}
            href={support.VIVOWIRE_TWITTER}
            target="_blank"
            rel="noreferrer"
          >
            {/* <Twitter className={classes.socialMediaIcons} /> */}
            <img
              className={classes.socialMediaIcons}
              src={TwitterIcon}
              width="46"
              height="46"
              alt="X"
              style={{ width: 46, height: 46 }}
            />
          </Link>
          <Link
            color="inherit"
            aria-label="LinkedIn link"
            className={classes.itemLinkText}
            href={support.VIVOWIRE_LINKEDIN}
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIn className={classes.socialMediaIcons} />
          </Link>
          <Link
            color="inherit"
            aria-label="Instagram link"
            className={classes.itemLinkText}
            href={support.VIVOWIRE_INSTAGRAM}
            target="_blank"
            rel="noreferrer"
          >
            <Instagram className={classes.socialMediaIcons} />
          </Link>
        </Grid>
        <Grid
          className={classes.arrowTopContainer}
          title={<Translation ns={['landing-page-common']}>{(t) => t('scrollToTop')}</Translation>}
        >
          <ArrowUpwardIcon className={classes.arrowTopIcon} onClick={handleBrandCLick} id="scroll-to-top" />
        </Grid>
      </Grid>
    </Box>
  );
}

Footer.propTypes = {};

export default Footer;
