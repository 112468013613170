const headerRoutes = [
  {
    path: '#about-us-section',
    id: 'about-section',
    navbarName: 'Why Vivowire?',
    icon: 'fas fa-user',
    component: ''
  },
  {
    path: '#how-it-works-section',
    id: 'how-it-works-section',
    navbarName: 'How it works',
    icon: '',
    component: ''
  },
  {
    path: '#help-section',
    id: 'help-section',
    navbarName: 'Help',
    icon: 'fa fa-users',
    component: ''
  },
  {
    path: '#help-section-contact-button',
    id: 'help-section-contact-button',
    navbarName: 'Contact Us',
    icon: 'fas fa-exchange-alt',
    component: ''
  }
];

export default headerRoutes;
