export default class NumberFormatter {
  /**
   * Formats a number, or a number formatted as a string, with thousands separators. Input may contain dots.
   *
   * @param {*} unformattedNumber A number or number formatted as a string
   * @returns The formatted number as a string
   * Examples: 1000.50 and "1000.50" return 1 000.50
   * 11234 and "11234" return 11 234
   *
   */
  static formatWithThousandsSeparators(unformattedNumber) {
    if (unformattedNumber) {
      const parts = unformattedNumber.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts.join('.');
    }
    return unformattedNumber;
  }

  static phoneFormat(input) {
    parseInt(input, 10);
    if (!input || Number.isNaN(input)) return `input must be a number was sent ${input}`;
    // eslint-disable-next-line no-param-reassign
    if (typeof input !== 'string') input = input.toString();
    return input.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  static padWithLeadingZero(number) {
    const numberAsInt = Number.parseInt(number, 10);
    if (numberAsInt >= 0 && numberAsInt < 10) {
      return `0${numberAsInt}`;
    }
    return number;
  }

  static stringAmountFormat(amount) {
    if (typeof amount === 'string' && amount % 1 !== 0) {
      const currency = amount.split(' ').length > 0 ? amount.split(' ')[1] : '';
      return `${this.svAmountFormat(Number.parseFloat(amount))} ${currency}`;
    }
    return amount;
  }

  static numberAmountWithTwoPlacedFloat(amount) {
    /** String amount with currency */
    if(typeof amount === 'string' && amount.split(' ').length === 2 && amount % 1 !== 0) {
      return `${this.formatWithThousandsSeparators(Number.parseFloat(amount).toFixed(2))} ${amount.split(' ')[1]}`;
    }
    if (typeof amount === 'number' || typeof amount === 'string' && amount % 1 !== 0) {
      return Number.parseFloat(amount).toFixed(2);
    }
    return amount;
  }

  static numberAmountWithFivePlacedFloat(amount) {
    if (typeof amount === 'number' || typeof amount === 'string' && amount % 1 !== 0) {
      return Number.parseFloat(amount).toFixed(5);
    }
    return amount;
  }

  static numberAmountWithOnePlacedFloat(amount) {
    if (typeof amount === 'number' || typeof amount === 'string' && amount % 1 !== 0) {
      return Number.parseFloat(amount).toFixed(1);
    }
    return amount;
  }

  static svAmountFormat(amount) {
    if (!amount) {
      return amount;
    }
    return new Intl.NumberFormat('sv-SV', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  }

  static svAmountFormatNoFloat(amount) {
    if (!amount) {
      return amount;
    }
    return new Intl.NumberFormat('sv-SV', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  }
  
  static svAmountFormatFiveFloat(amount) {
    if (!amount) {
      return amount;
    }
    return new Intl.NumberFormat('sv-SV', {
      minimumFractionDigits: 5,
      maximumFractionDigits: 5
    }).format(amount);
  }

  static formatPhoneNumber(str) {
    // Filter only numbers from the input
    const cleaned = `${  str}`.replace(/\D/g, '');
    
    // Check if the input is of correct
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    
    if (match) {
      // Remove the matched extension code
      // Change this to format for any country code.
      const intlCode = match[1] ? '+1 ' : ''
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    
    return null;
  }

}
