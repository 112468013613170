import React from 'react';
import { Translation } from 'react-i18next';

/** Error categories */
// eslint-disable-next-line no-unused-vars
const ERROR_TYPES = {
  INPUT_ERROR: 'Input Error', // Message to user Change Something, Try Again. 
  AUTHORIZATION_ERROR: 'Authorization Error',
  AVAILABILITY_ERROR: 'Availability Error', // Message to user Try Again Later. 
  UEXPECTED_ERROR: 'Unexpected Errors' // Contact us. 
}

const ERROR_CODES = [
  {
    code: 1000,
    origin: 'Undefined',
    message: <Translation ns={['landing-page-common']}>{(t) => t('defaultErrorMessage')}</Translation>,
    title: '',
    severity: 'error'
  },
  {
    origin: 'Request failed with status code 500',
    code: 500,
    message: <Translation ns={['landing-page-common']}>{(t) => t('500')}</Translation>,
    title: '',
    severity: 'error'
  },
  {
    origin: '504 Gateway Timeout',
    code: 504,
    message: <Translation ns={['landing-page-common']}>{(t) => t('504')}</Translation>,
    title: '',
    severity: 'error'
  },
  {
    origin: 'Bad Request',
    code: 400,
    message: <Translation ns={['landing-page-common']}>{(t) => t('400')}</Translation>,
    title: '',
    severity: 'warning'
  },
  {
    origin: 'Unauthorized',
    code: 401,
    message: <Translation ns={['landing-page-common']}>{(t) => t('401')}</Translation>,
    title: '',
    severity: 'warning'
  },
  {
    origin: 'Not Found',
    code: 404,
    message: <Translation ns={['landing-page-common']}>{(t) => t('404')}</Translation>,
    title: '',
    severity: 'warning'
  },
  {
    origin: 'Authentication already in progress for pno',
    code: 403,
    type: 'BankIdError',
    message: <Translation ns={['landing-page-common']}>{(t) => t('bankId403')}</Translation>,
    title: <Translation ns={['landing-page-common']}>{(t) => t('authenticationInProgress')}</Translation>,
    severity: 'warning'
  },
  {
    origin: 'BankID session timed out',
    code: 408,
    type: 'BankIdError',
    message: <Translation ns={['landing-page-common']}>{(t) => t('bankId408')}</Translation>,
    title: <Translation ns={['landing-page-common']}>{(t) => t('authenticationTimedOut')}</Translation>,
    severity: 'warning'
  },
  {
    origin: 'Conflict',
    code: 409,
    message: <Translation ns={['landing-page-common']}>{(t) => t('409')}</Translation>,
    title: '',
    severity: 'warning'
  },
  /*   {
    origin: 'ValidationError',
    name: 'RecipientError',
    code: 409,
    message: <Translation ns={['dashboard-common']}>{(t) => t('409RecipientError')}</Translation>,
    title: ''
  }, */
  {
    origin: 'Authentication cancelled by the server',
    code: 444,
    type: 'BankIdError',
    message: <Translation ns={['landing-page-common']}>{(t) => t('bankId444')}</Translation>,
    title: <Translation ns={['landing-page-common']}>{(t) => t('authenticationCancelled')}</Translation>,
    severity: 'warning'
  },
  {
    origin: 'Authentication manually cancelled by the user',
    code: 499,
    type: 'BankIdError',
    message: <Translation ns={['landing-page-common']}>{(t) => t('bankId499')}</Translation>,
    title: <Translation ns={['landing-page-common']}>{(t) => t('authenticationCancelled')}</Translation>,
    severity: 'warning'
  },
  {
    origin: 'BankID session timed out',
    code: 408,
    type: 'BankIdError',
    message: <Translation ns={['landing-page-common']}>{(t) => t('bankId499')}</Translation>,
    title: <Translation ns={['landing-page-common']}>{(t) => t('authenticationIncomplete')}</Translation>,
    severity: 'warning'
  },
  {
    origin: 'Internal Server Error',
    code: 500,
    message: <Translation ns={['landing-page-common']}>{(t) => t('500')}</Translation>,
    title: '',
    severity: 'error'
  },
  {
    origin: 'Unavailable',
    code: 503,
    message: <Translation ns={['landing-page-common']}>{(t) => t('503')}</Translation>,
    title: '',
    severity: 'error'
  }
];

export default ERROR_CODES;
