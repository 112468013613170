/* eslint-disable import/no-unused-modules */
export const BANKID_PROCESS_INFO_DESKTOP = {
    "outstandingTransaction": "Försöker starta BankID Säkerhetsprogram",
    "started": "Starta BankID-appen och tryck på QR-ikonen. Skanna sedan QR-koden nedan.",
    "userSign": "Skriv din säkerhetskod i BankID Säkerhetsprogram och välj Identifiera eller Skriv under."
}

export const BANKID_PROCESS_INFO_MOBILE = {
    "outstandingTransaction": "Klicka på knappen nedan för att starta BankID-appen.",
    "started": "Klicka på knappen nedan för att starta BankID-appen.",
    "userSign": "Skriv din säkerhetskod i BankID- appen och välj Identifiera eller Skriv under."
}