import { ACTION_TYPES } from '../ReduxActionTypes';

const initialState = {
  isGetStartedLogin: false,
  loginModalVisible: false
};

const loginModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_IS_GET_STARTED_LOGIN:
      return { ...state, isGetStartedLogin: action.payload };
    case ACTION_TYPES.SET_LOGIN_MODAL_VISIBLE:
      return { ...state, loginModalVisible: action.payload };
    default:
      return state;
  }
};

export default loginModalReducer;
