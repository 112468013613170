import React, { useState } from 'react';

import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { Alert , Grid, Fade, Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { mapFormikErrorFields } from './mapFormikErrorFields';
import { FORM_TRANSLATIONS } from '../formTranslations';
import { errorHandler } from '../../../utils/errorMapper';
import TextField from '../../common/TextfieldWrapper';
import SignUpFormSchema from './signUpFormValidationSchema';

import API from '../../../utils/api';
import { setGlobalUser } from '../../../store/redux/actions/userAction';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  gridContainer: {
    justifyContent: 'end',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  gridItem: {
    padding: 16
  },
  form: {
    textAlign: 'left',
    background: '#fff',
    width: '100%',
    maxWidth: 479,
    borderRadius: 10,
    margin: '16px 0 16px 0',
    padding: 16
  },
  infoTitle: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662',
    padding: '34px 0px 0px 8px'
  },
  inputLabel: {
    width: 'fit-content',
    height: '32px',
    margin: '10px 83px 0 30px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  innerLabel: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    marginLeft: theme.spacing(0),
    color: theme.palette.globalAppTheme.globalAppTextColor
  },
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.globalAppTheme.globalAppTextColor
    }
  },
  inputBackground: {
    borderRadius: theme.borderRadius,
    backgroundColor: '#f8fafc'
  },
  buttonContainer: {
    textAlign: 'center',
    justifyContent: 'center',
    display: 'grid'
  },
  saveDetailsButtonText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'initial',
    color: '#21a1c5',
    cursor: 'pointer',
    textAlign: 'left',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal'
  },
  cancelButtonText: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#919ba8',
    textTransform: 'none'
  },
  emailConcentTitle: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  emailConcentAnswer: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662'
  },
  emailConcentContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr'
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  switchRoot: {
    width: '90px',
    height: '50px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  track: {
    width: '50px',
    height: '30px',
    borderRadius: '20px'
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.globalAppTheme.globalAppButtonColor,
      transform: 'translateX(40px)'
    },
    '& + $track': {
      backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  checked: {
    color: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover': {
      backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  thumb: {
    width: '32px',
    height: '32px',
    transform: 'translate(0px, -1px)'
  }
}));

function UpdateEmail() {
  const classes = useStyles();
  const { t } = useTranslation(['dashboard-common']);
  const { user } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const [saveError, setSaveError] = useState(undefined);
  const [resendConfirmationEmailError, setResendConfirmationEmailError] = useState(undefined);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);

  const initialValues = {
    email: user.email
  };

  const updateEmail = async (values) => {
    if (values.email !== null) {
      API.put('/user', values)
        .then((res) => {
          if (res && res.status === 200) {
            dispatch(setGlobalUser(res.data));
            API.get('/confirm')
              .then((_res) => {
                if (_res.status === 200) {
                  setIsSaveSuccess(true);
                }
              })
              .catch((_error) => setResendConfirmationEmailError(_error));

            setTimeout(() => {
              setIsSaveSuccess(false);
            }, 10000);
          }
        })
        .catch((error) => {
          setSaveError(error);
        });
    }
  };

  const alert = (fadeIn, severity, children) => (
    <Fade in={fadeIn}>
      <Alert severity={severity}>{children}</Alert>
    </Fade>
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => updateEmail(values)}
      validationSchema={SignUpFormSchema}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <Form className={classes.form} onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
          {resendConfirmationEmailError &&
            alert(resendConfirmationEmailError, 'error', errorHandler(resendConfirmationEmailError))}
          {saveError && alert(saveError, saveError?.type, errorHandler(saveError))}
          {isSaveSuccess && alert(isSaveSuccess, 'success', t('changesSavedAndEmailSent', { email: values.email }))}
          {isSubmitting && !_isEmpty(errors) && (
            <Alert severity="warning" style={{ textAlign: 'justify', width: '100%' }} key={errors}>
              {FORM_TRANSLATIONS.formMustBeFilledIn}
              {mapFormikErrorFields(errors, t, FORM_TRANSLATIONS)}
            </Alert>
          )}
          <Grid container direction="row" className={classes.gridContainer}>
            <TextField onChange={handleChange} value={values.email} label={t('formEmail')} name="email" shrink />
            <Button
              className={classes.saveDetailsButtonText}
              disabled={!values.email}
              onKeyDown={() => updateEmail(values)}
              tabIndex={0}
              onClick={() => updateEmail(values)}
            >
              {t('saveAndResendEmail')}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

UpdateEmail.defaultProps = {
  initialValues: undefined
};

UpdateEmail.propTypes = {
  initialValues: PropTypes.shape({
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    street: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    emailConsent: PropTypes.bool
  })
};

export default UpdateEmail;
