import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import Button from '../../../common/Button';
import PageNoFound from '../../../../assets/svg/PageNotFound.svg';

const styles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    justifyContent: 'center'
  },
  background: {
    backgroundImage: `url(${PageNoFound})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `calc(14vw + 85px)`,
    width: `calc(16vw + 10px)`,
    margin: '30px auto',
    padding: theme.spacing(5),
    zIndex: -1,
    opacity: 0.3
  },
  notFound: {
    position: 'relativ'
  },
  subTitle: {
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: theme.palette.globalAppTheme.globalAppTextColor,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(18),
      padding: 0,
      width: 'auto'
    }
  },
  title: {
    margin: 'auto',
    width: '100%',
    maxWidth: '70%',
    textAlign: 'center',
    color: theme.palette.globalAppTheme.globalAppTextColor,
    fontWeight: 600,
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(35),
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      fontSize: theme.typography.pxToRem(34),
      width: 'auto'
    }
  },
  button: {
    width: 'auto',
    minWidth: 205,
    height: '50px',
    fontSize: '1rem',
    margin: 10,
    borderRadius: theme.borderRadiusButton,
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover,&:focus': {
      background: '#21a2c6'
    }
  }
}));

function NotFoundPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = styles();
  const { t } = useTranslation(['landing-page-common']);
  return (
    <Box id="notfound" className={classes.container}>
      <div className={classes.background} />
      <Box className={classes.notFound}>
        <Box className="notfound-404">
          <Typography className={classes.title}>{t('couldNotBeFound')}</Typography>
        </Box>
        <Typography className={classNames(classes.subTitle, 'lg-mg-bottom-custom')}>
          {t('pageNotFoundMessage', { pathname: `[${location && location.pathname}]` })}
        </Typography>

        <Button className={classNames("home-button", classes.button)} onClick={() => navigate(-1)} variant="contained" color="primary" disableElevation>
          {t('goBack')}
        </Button>
      </Box>
    </Box>
  );
}

export default NotFoundPage;
