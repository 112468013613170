import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import _groupBy from 'lodash/groupBy';
import _isEmpty from 'lodash/isEmpty';
import _camelCase from 'lodash/camelCase';
import {
  Typography,
  Button,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  ButtonGroup
, Alert } from '@mui/material';
import { useTranslation, Translation } from 'react-i18next';

import classNames from 'classnames';
import { uniqueId } from 'lodash';
import RecentTransactionsStatus from './RecentTransactionsStatus';
import NumberFormatter from '../../../utils/NumberFormatter';
import { MONTHS } from '../../../constants/dashboardContants';
import RecentTransactionDetails from './RecentTransactionDetails';
import TRANSACTION_STATUS from '../../../constants/transactionStatus';
import { errorHandler } from '../../../utils/errorMapper';
import Loading from '../../common/Loading';
import HelpPopOver from '../../common/HelpPopOver';

const useStyles = makeStyles((theme) => ({
  recentTransactionsTitle: {
    width: '100%',
    maxWidth: 'fit-content',
    fontSize: 18,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.4,
    color: theme.palette.globalAppTheme.globalAppTextColor
  },
  recentTransactionsSubTitle: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.67,
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  recentTransactionAmount: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.62,
    color: '#2f4662'
  },
  avatarNameContainer: {
    display: 'grid',
    gridTemplateColumns: '60px auto auto',
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down('md')]: {
      rowGap: '117px'
    }
  },
  recipientsListAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor
  },
  recentTransactionListSecondary: {
    fontFamily: 'Inter',
    fontSize: 13,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  recipientsListName: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.62,
    color: '#2f4662'
  },
  recipientsListSecondary: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  nameAndAmountContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 32,
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr 1fr'
    }
  },
  divider: {
    width: 374,
    height: 0,
    margin: '7px 0 22px 0px',
    opacity: 0.2,
    backgroundColor: '#8b91a0'
  },
  gridListTransactions: {
    width: '100%',
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: '100%',
    minHeight: 500,
    maxHeight: 600,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2f4662',
      borderRadius: 50
    },
    [theme.breakpoints.down('md')]: {
      height: '100%',
      // minHeight: theme.spacing(47),
      width: '100%'
      // minWidth: '455px'
    }
  },
  gridContainer: {
    width: '100%',
    maxWidth: 'max-content',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'inherit'
    }
  },
  filterTransactionStatusTitle: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2',
    letterSpacing: 'normal',
    color: '#4a6582'
  }
}));

function RecentTransactions({ error, loading, reload }) {
  const classes = useStyles();
  const { userTransactions } = useSelector((state) => state.dashboard);
  const { t } = useTranslation(['dashboard-common']);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [transaction, setTransaction] = useState();

  const TYPES = { ALL: 'All', COMPLETED: 'Transaction Complete', PENDING: 'Transaction Pending', FAILED: 'Transaction Failed' };
  const [transactionsStatus, setTransactionsStatus] = useState(TYPES.ALL);

  const status = [
    TYPES.ALL,
    TYPES.COMPLETED,
    TYPES.PENDING,
    TYPES.FAILED
  ]


  const toggleDrawer = (event, item) => {
    if (
      (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) ||
      event.currentTarget === event.target
    ) {
      return;
    }
    setTransaction(item);
    setOpenDrawer(!openDrawer);
  };

  const formatRecentTransactionDate = (date) => {
    const d = new Date(date);
    const minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    return `${d.getUTCDate()} ${t(
      d.toLocaleString('en-En', { month: 'long' }).toLocaleLowerCase()
    )} ${d.getHours()}:${minutes}`;
  };

  const displayDescriptionOrMonth = (month) => {
    const date = new Date();
    const currentMonth = date.toLocaleString('en-En', { month: 'long' });

    const oneMonthsEpoch = date.setMonth(date.getMonth() - 1);
    const oneMonth = new Date(oneMonthsEpoch);

    const twoMonthsEpoch = date.setMonth(date.getMonth() - 2);
    const twoMonths = new Date(twoMonthsEpoch);

    if (month === currentMonth.toLocaleLowerCase()) {
      return `${t('currentMonth')} (${t(month.toLocaleString('en-En', { month: 'long' }).toLocaleLowerCase())})`;
    }
    if (month === oneMonth.toLocaleString('en-En', { month: 'long' }).toLocaleLowerCase()) {
      return `${t('lastMonth')} (${t(oneMonth.toLocaleString('en-En', { month: 'long' }).toLocaleLowerCase())})`;
    }
    if (month === twoMonths.toLocaleString('en-En', { month: 'long' }).toLocaleLowerCase()) {
      return month;
    }
    return month;
  };

  const filterAndSortTransactions =
    userTransactions.length > 0 ?
    userTransactions
      .sort((t1, t2) => {
        if (t1.createdAt < t2.createdAt) return 1;
        if (t1.createdAt > t2.createdAt) return -1;
        return 0;
      })
      /*       .filter((_transaction) => {
              const today = new Date().toISOString();
              const threeMonthsAgo = new Date();
              threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
              return _transaction.createdAt >= threeMonthsAgo.toISOString() && _transaction.createdAt <= today;
            }) */
      .map((item) => ({
        id: item.id,
        orderId: item.orderId,
        firstName: item.recipientFirstName,
        lastName: item.recipientLastName,
        name: `${item.recipientFirstName} ${item.recipientLastName}`,
        date: formatRecentTransactionDate(item.createdAt),
        month: MONTHS[item.createdAt.slice(5, 7)],
        fullDate: `${item.createdAt.substring(0, 10)} ${item.createdAt.substring(11, 19)}`,
        sent: `${NumberFormatter.svAmountFormatNoFloat(item.senderAmount)} ${item.senderCurrency}`,
        received: `${Number.parseFloat(item.receiverAmount).toFixed(2)} ${item.receiverCurrency}`,
        exchangeRate: `1 ${item.senderCurrency} = ${Number.parseFloat(item.exchangeRate).toFixed(5)} ${item.receiverCurrency
          }`,
        status: item.status,
        fee: `${NumberFormatter.svAmountFormatNoFloat(item.fee)} ${item.senderCurrency}`,
        total: `${NumberFormatter.svAmountFormatNoFloat(item.totalToPay)} ${item.senderCurrency}`
      })) : [];

  const filterTransactionsByStatus = (_transactionsStatus, _transactions) => {
    const filterStatus = (_transaction, statusToMatch) => _transaction?.status?.replace(/_/g, ' ') === statusToMatch;
    switch (_transactionsStatus) {
      case TYPES.ALL:
        return _transactions;
      case TYPES.COMPLETED:
        return _transactions?.filter((_t) => filterStatus(_t, TRANSACTION_STATUS.completed));
      case TYPES.PENDING:
        return _transactions?.filter((_t) => filterStatus(_t, TRANSACTION_STATUS.initiated) ||
          filterStatus(_t, TRANSACTION_STATUS.createdTransfer) ||
          filterStatus(_t, TRANSACTION_STATUS.submittedTransfer) ||
          filterStatus(_t, TRANSACTION_STATUS.availableTransfer) ||
          filterStatus(_t, TRANSACTION_STATUS.pending) ||
          filterStatus(_t, TRANSACTION_STATUS.pendingTransfer));
      case TYPES.FAILED:
        return _transactions?.filter((_t) => filterStatus(_t, TRANSACTION_STATUS.failedPayment) ||
          filterStatus(_t, TRANSACTION_STATUS.declinedTransfer) ||
          filterStatus(_t, TRANSACTION_STATUS.canceledPayment) ||
          filterStatus(_t, TRANSACTION_STATUS.cancelledTransfer)
        );
      default:
        return _transactions;
    }
  }

  const filteredByStatus = useMemo(() => filterTransactionsByStatus(transactionsStatus, filterAndSortTransactions), [filterAndSortTransactions]);

  const groupedTransactions = _groupBy(filteredByStatus, 'month');

  const handleFilterButtonClick = (_status) => {
    setTransactionsStatus(_status);
    filterTransactionsByStatus(_status, filterAndSortTransactions);
  }

  const transactionList = () => {
    if (groupedTransactions && !_isEmpty(groupedTransactions)) {
      return Object.keys(groupedTransactions).map((group) => (
        <>
          <Grid item xs={12} key={uniqueId()}>
            <Typography className={classes.recentTransactionsSubTitle}>
              <Translation ns="common">{(t2) => t2(displayDescriptionOrMonth(group))}</Translation>
            </Typography>
          </Grid>
          {groupedTransactions[group].map((item) => (
            <Grid item xs={12} key={uniqueId()} className="transactions-container" style={{ height: 'auto' }}>
              <ListItem
                style={{ textAlign: 'justify', textTransform: 'capitalize', padding: 8 }}
                onClick={(e) => toggleDrawer(e, item)}
                key={uniqueId()}
                component={Button}
              >
                <RecentTransactionDetails open={openDrawer} transaction={transaction} />
                <ListItemAvatar>
                  <Avatar className={classes.recipientsListAvatar}>
                    {item?.firstName?.charAt(0)}
                    {item?.lastName?.charAt(0)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  style={{ marginLeft: 7 }}
                  classes={{
                    primary: classes.recipientsListName,
                    secondary: classes.recipientsListSecondary
                  }}
                  primary={
                    <div key={item.id} className={classes.nameAndAmountContainer}>
                      <Typography className={classes.recipientsListName} noWrap>
                        {item.name}
                      </Typography>
                      <Typography className={classes.recentTransactionAmount}>{item.sent}</Typography>
                    </div>
                  }
                  secondary={
                    <>
                      <Typography key={item.id} className={classes.recentTransactionListSecondary} noWrap>
                        {t('sentOn', { date: item.date })}
                      </Typography>
                      <RecentTransactionsStatus status={item.status} />
                    </>
                  }
                />
                <ListItemSecondaryAction key={item.id} style={{ top: 21 }}>
                  {item?.status?.replace(/_/g, ' ') === TRANSACTION_STATUS.completedTransfer ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 svg-icon-style"
                      style={{ color: '#4caf50' }}
                    >
                      <path d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  ) : null}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider key={item.id} variant="middle" className={classes.divider} />
            </Grid>
          ))}
        </>
      ));
    }
    return null;
  };

  useEffect(() => {
    /* TODO document why this arrow function is empty */
  }, [userTransactions]);

  return (
    <Grid item sm={12} md={12} lg={4} className={classNames(classes.gridContainer, 'lg-p-custom')}>
      <Grid item sm={12} md={12} lg={12}>
        <Typography className={classes.recentTransactionsTitle}>
          {t('recentTransactions')}
          <Button
            title={t('updateTransactions')}
            onClick={reload}
            className={classes.appBarButtons}
            style={{ color: '#2f4662' }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-6 h-6 svg-icon-style">
              <path
                stroke="rgb(47, 70, 98)"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </Button>
        </Typography>
      </Grid>
      <Grid item sm={12} md={12} lg={12}>
        <Typography className={classes.filterTransactionStatusTitle} gutterBottom>
          {t('filterTransactions')}
          <HelpPopOver content={
            <>
              <p>{t('youCanSeeBasicInformationAboutTransactions')}</p>
              <p>{t('toSeeTheSetailsOfYourTransaction')}</p>
              <p>{t('importantToNote')}</p>
            </>
          }
            title={t('transactionsAndStatus')} iconWidth=".8" />
        </Typography>
        <ButtonGroup variant="outlined" aria-label="outlined primary button group" size='small' sx={{ pt: 1, pb: 1 }}>
          {status.map((type) => <Button
            size='small'
            variant={type === transactionsStatus ? "contained" : "outlined"}
            color='primary'
            key={type}
            title={t(type)}
            onClick={() => handleFilterButtonClick(type)}
            className={classes.appBarButtons}
            style={{ color: type === transactionsStatus && '#fff' }}
          >
            {t(_camelCase(type))}
          </Button>)}
        </ButtonGroup>
      </Grid>
      <Grid item sm={12} md={12} lg={12} key={uniqueId()} style={{ width: '100%' }}>
        <Grid item sm={12} md={12} lg={9}>
          {_isEmpty(error) ? null :
            <Alert severity={error?.type || 'error'}>{errorHandler(error)}</Alert>
          }
        </Grid>
        {loading ?
          <Loading /> :
          (
            <List className={classes.gridListTransactions} cols={1}>
              {transactionList()}
            </List>
          )}
      </Grid>
    </Grid>
  );
}

RecentTransactions.defaultProps = {
  error: undefined,
  loading: false,
  reload: undefined
};

RecentTransactions.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
  reload: PropTypes.func
};

export default RecentTransactions;
