import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Hidden, Box, Container } from '@mui/material';

import { useSelector } from 'react-redux';
import heroStyles from './heroStyles';
import MTFBox from '../../../mtf/MtfBox';
import GlobalErrorAlert from '../../../common/GlobalErrorAlert';

const useStyles = makeStyles(heroStyles);
function Hero() {
  const classes = useStyles();
  const { error, maintenanceMessage } = useSelector((state) => state.app);
  const { t } = useTranslation(['landing-page-common']);
  const globalServiceError = {
    name: 'Service Error',
    type: 'error',
    origin: 'Service unvailable',
    message: maintenanceMessage,
    code: 1000
  }

  return <>
    <Grid item xs={12} md={12}>
      {error && <GlobalErrorAlert error={error} />}
      {maintenanceMessage ? <GlobalErrorAlert error={globalServiceError} /> : null}
    </Grid>
    <section id="hero-section" className={classNames('lg-p-top', classes.wrapper)}>
      <Container maxWidth="md" className={classNames(classes.container)}>
        <Grid item xs={12} sx={{ pb: 5 }}>
            <Box mb={10} className={classes.mainText}>
              <Typography className={classNames(classes.mainText)}>{t('heroTitle')}</Typography>
              <Grid item xs={12}>
                <Typography className={classNames(classes.mainTextSub)}>{t('heroSubTitle')}</Typography>
              </Grid>
            </Box>
            <MTFBox sendMoneyButtonText={t('getStartedButtonLabel')} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={6} />
        </Hidden>
      </Container>
    </section>
  </>;
}

Hero.defaultProps = {};

Hero.propTypes = {};

export default Hero;
