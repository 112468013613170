/* eslint-disable react/jsx-props-no-spreading */
import React, { createRef, useEffect } from 'react';
import { Field, useFormik } from 'formik';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { CardMedia, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import BriteSwedenDefault2 from '../../../assets/svg/BriteSwedenDefault2.svg';
import UnlimitLogo from '../../../assets/svg/unlimit_logo_white.svg';
import RadioButtonGroup from '../../common/RadioButtonGroup';
import RadioButton from '../../common/RadioButton';
import { PAYMENT_SERVICE, CREDIT_DEBIT_CARD_LIST, PAYMENT_METHODS } from './constants';
import CreditCardPaymentsDisplay from './CreditCardPaymentsDisplay';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles((theme) => ({
  transactionDetailsDialogContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  cardMediaRoot: {
    objectFit: 'contain'
  },
  cardMediaContainer: {
    display: 'grid',
    gridTemplateColumns: '180px 1fr',
    paddingTop: theme.spacing(2)
  },
  transactionDetailsHeading: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.78,
    letterSpacing: 'normal',
    color: '#2f4662'
  },
  transactionDetailsSub1: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.8,
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  transactionDetailsSub2: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  totalToPay: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(24)
    }
  },
  coloredHorizontalLine: {
    transform: 'rotate(-180deg)',
    borderBottom: '1px solid',
    color: '#21a1c5'
  },
  svg: {
    stroke: '#21a1c5',
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: 2
  },
  paymentMethodRadioButtonGroupContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  paymentMethodRadioButtonContainer: {
    display: 'grid',
    rowGap: '10px',
    boxShadow: '4px 0px 18px -3px rgb(0 0 0 / 20%)',
    borderRadius: theme.borderRadius,
    cursor: 'pointer',
    '& div > input': {
      cursor: 'pointer'
    },
    '& div > label': {
      display: 'block',
      position: 'absolute',
      cursor: 'pointer',
      height: 270,
      width: 280,
      margin: '-3px -25px',
      top: 200,
      left: 'auto',
      [theme.breakpoints.down('md')]: {
        display: 'inline-block',
        width: 'auto',
        top: 0,
        left: 0,
        position: 'relative',
        height: 0,
        margin: 0
      }
    },
    '&:hover': {
      outline: '1px solid rgba(33, 161, 197, 1)',
      transition: 'all 0.1s ease-in-out'
    },
    '&:focused': {
      backgroundColor: '#37aacb'
    },
    padding: theme.spacing(3),
    border: '1px solid #21a1c5',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
      display: 'block'
    }
  },
  paymentMethodTitle: {
    color: '#2f4662'
  },
  paymentOptionIsRequired: {},
  paymentGatewayCommingSoon: {
    position: 'relative',
    top: 125,
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: 'normal',
    fontStretch: 'normal',
    color: '#0c5f60',
    filter: 'drop-shadow(3px 3px 2px rgb(0 0 0 / 0.4))'
  }
}));

const PaymentRadioButton = withStyles(styles)((props) => {
  const { classes, ...other } = props;
  return <RadioButton className={classes} {...other} feedback />
});

function FormStepPaymentMethods() {
  const classes = useStyles();

  const { t } = useTranslation(['dashboard-common']);

  const formik = useFormik({
    initialValues: {
      paymentOption: ''
    }
  });

  const paymentMethodImage = (method) => {
    switch (method) {
      case PAYMENT_SERVICE.brite:
        return (
          <CardMedia
            classes={{ root: classes.cardMediaRoot }}
            component="img"
            width="100"
            height="100"
            image={BriteSwedenDefault2}
            alt="Bank Transfer"
          />
        );
      case PAYMENT_SERVICE.unlimit:
        return (
          <>
            <CardMedia
              classes={{ root: classes.cardMediaRoot }}
              component="img"
              width="30"
              height="30"
              image={UnlimitLogo}
              alt="Card Payment"
            />
            <CreditCardPaymentsDisplay list={CREDIT_DEBIT_CARD_LIST} width={55} height={55} />
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {}, []);

  return (
    <DialogContent className={classes.transactionDetailsDialogContent}>
      <RadioButtonGroup
        id="radioGroup"
        customStyle={classes.paymentMethodRadioButtonGroupContainer}
        error={formik.errors.paymentOption}
        touched={formik.touched.paymentOption}
        onChange={formik.setFieldValue}
        onBlur={formik.setFieldTouched}
      >
        <Grid container direction="row" spacing={1} justifyContent='center'>
          {PAYMENT_METHODS.map((item) => {
            const newRef = createRef();
            return (
              <Grid key={item.value} item xs={12} sm={12} md={4} lg={4}>
                {item?.disabled ? 
                <div className={classes.paymentGatewayCommingSoon}>
                  {t('paymentGatewayComingSoon')}
                  </div> : null}
                <div
                  key={item.value}
                  aria-hidden="true"
                  ref={newRef}
                  className={`${classes.paymentMethodRadioButtonContainer} ${item.disabled ? 'disabled-element' : null}`}
                >
                  <Typography variant="h6" gutterBottom className={classes.paymentMethodTitle}>
                    {t(item.label)}
                  </Typography>
                  {paymentMethodImage(item.value)}
                  <Field
                    key={item.value}
                    label={/* t(item.label) */''}
                    id={item.value}
                    name="paymentOption"
                    component={PaymentRadioButton}
                  />
                </div>
              </Grid>
            );
          })}
        </Grid>
      </RadioButtonGroup>
    </DialogContent>
  );
}

FormStepPaymentMethods.defaultProps = {};

FormStepPaymentMethods.propTypes = {};

export default FormStepPaymentMethods;
