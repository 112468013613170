import React, { useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import BriteLanguageImage from '../../../assets/img/brite-language-button.png';
import HelpPopOver from '../../common/HelpPopOver';

const styles = makeStyles(() => ({
  briteHelpPopover: {}
}));

function BankTransferPayment() {
  const classes = styles();
  const { t } = useTranslation(['dashboard-common']);

  const briteIFrameContainerRef = useRef(null);
  return (
    <div
      ref={briteIFrameContainerRef}
      id="brite-payment-client"
      style={{ width: '100%', textAlign: 'center', justifyContent: 'center', fontFamily: 'Inter' }}
    >
      <div className={classes.briteHelpPopover}>
        <HelpPopOver
          content={
            <>
              <p>{t('changeLanguageForBriteClient')}</p>
              <img width="40" height="40" src={BriteLanguageImage} alt="Brite Language Change" />
            </>
          }
          title={t('britePayments')}
          fullWidth
        />
      </div>
    </div>
  );
}

export default BankTransferPayment;
