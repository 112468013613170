/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from './layout/Layout';
import CookieConsent from '../common/CookieConsent';
import content from './content';
import isAuthenticated from '../../context/auth';

function LandingPage(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { ...rest } = props;

  if (isAuthenticated()) {
    navigate("/dashboard");    
  }
  return (
    <CookieConsent>
      <Layout route={location.pathname}>{content(location.pathname, rest)}</Layout>
    </CookieConsent>
  );
}

export default LandingPage;
