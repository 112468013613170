import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import API from '../../utils/api';
import { setGlobalConvertedAmount, setGlobalAmountToReceive, setGlobalConversionMode, setGlobalAmount, setGlobalConvertedAmountError, setGlobalIsConverting } from '../../store/redux/actions/moneyTransferBoxAction';
import NumberFormatter from '../../utils/NumberFormatter';
import { moneyTransferBoxInitialValues } from '../../store/constants';

export default function convertAmount() {
  const [loading, setLoading] = useState(false);
  const [convertedAmount, setConvertedAmount] = useState();
  const [convertMode, setConvertMode] = useState(undefined);
  const [error, setError] = useState();

  const dispatch = useDispatch();

  const formatExcahngeRate = (rate, exchangeRate) =>
  (rate &&
    exchangeRate &&
    `${rate.split('=')[0]}= ${NumberFormatter.svAmountFormatFiveFloat(exchangeRate)} ${rate.split(' ')[4]}`) ||
  0;

  function mapConvertedAmount(_convertedAmount) {
    const mappedObject = {
        amount: _convertedAmount?.senderAmount,
        senderCountry: _convertedAmount.senderCountry,
        recieverCountry: _convertedAmount.recieverCountry,
        senderCurrency: _convertedAmount.senderCurrency,
        recieverCurrency: _convertedAmount.recieverCurrency,
        rate: _convertedAmount.rate,
        exchangeRate: formatExcahngeRate(_convertedAmount.rate, _convertedAmount.exchangeRate),
        fee: _convertedAmount?.fee.label,
        convertedAmount: `${NumberFormatter.svAmountFormat(
          NumberFormatter.numberAmountWithOnePlacedFloat(_convertedAmount?.recieverAmount)
        )} ${_convertedAmount.recieverCurrency}`,
        convertedAmountNumber: _convertedAmount.recieverAmount,
        totalToPay: _convertedAmount.totalToPay.label,
        totalToPayNumber: _convertedAmount.totalToPay.value,
        payer: _convertedAmount.payer
      }
      return mappedObject;
  }
  
  const getConvertedRate = (amount, payerId, mode = moneyTransferBoxInitialValues.SOURCE_AMOUNT) => {
    setLoading(true);
    setError(undefined);
    dispatch(setGlobalConvertedAmountError());
    dispatch(setGlobalIsConverting(true));
    if (amount && payerId) {
      setConvertMode(mode)
      dispatch(setGlobalConversionMode(mode))
      API.get(`/payer/${payerId}/calculate`, {
        params: {
          amount: NumberFormatter.numberAmountWithTwoPlacedFloat(amount),
          mode
        }
      })
      .then((response) => {
        setConvertedAmount(response.data);
        dispatch(setGlobalAmount(response.data?.senderAmount))
        dispatch(setGlobalAmountToReceive(response.data?.recieverAmount));
        dispatch(setGlobalConvertedAmount(mapConvertedAmount(response.data)));
        setLoading(false);
        dispatch(setGlobalIsConverting(false));
      })
      .catch((_error) => {
        setError(_error);
        setConvertedAmount('0');
        dispatch(setGlobalAmountToReceive('0'));
        dispatch(setGlobalConvertedAmount({}));
        dispatch(setGlobalAmount(amount));
        dispatch(setGlobalConvertedAmountError(_error));
        setLoading(false);
        dispatch(setGlobalIsConverting(false));
      });
    } else {
      dispatch(setGlobalAmountToReceive('0'));
      dispatch(setGlobalConvertedAmount(''));
      setLoading(false);
    }
  };

  React.useEffect(() => {}, [error]);
  
  return {
    convertedAmount: convertedAmount && convertedAmount.convertedAmount,
    error,
    getConvertedRate,
    loading,
    convertMode
  };
}
