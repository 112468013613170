import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 5
  },
  title: {
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
    fontStyle: 'normal',
    color: '#4a6582',
    opacity: 0.8,
    whiteSpace: 'pre-line'
  },
  subTitle: {
      whiteSpace: 'pre-line',
      fontFamily: 'Inter',
      fontSize: theme.typography.pxToRem(19),
      fontWeight: 'normal',
      fontStyle: 'normal',
      color: '#4a6582',
      textTransform: 'none',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(16)
      }
  }
}));

export default function Title(props) {
  const classes = useStyles();
  const { children, subtitle, customStyleTitle, customStyleSubtitle } = props;
  return (
    <div className={classes.root}>
      <Typography className={classes.title} style={customStyleTitle} gutterBottom>
        {children}
      </Typography>
      {subtitle ? (
        <Typography className={classes.subTitle} style={customStyleSubtitle}>
          {subtitle}
        </Typography>
      ) : null}
    </div>
  );
}

Title.defaultProps = {
  subtitle: '',
  customStyleTitle: null,
  customStyleSubtitle: null
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customStyleTitle: PropTypes.object,
  customStyleSubtitle: PropTypes.object
};
