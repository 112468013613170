import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { Alert , InputAdornment } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import NumberFormatter from '../../utils/NumberFormatter';
import convertAmount from "./convertAmount";
import { setGlobalAmount, setGlobalIsOpenMtfPriceDetails } from '../../store/redux/actions/moneyTransferBoxAction';
import CountryCurrencySelectMenu from '../country-currency-select/CountryCurrencySelectMenu';
import HtmlTooltip from './HtmlTooltip';
import { moneyTransferBoxInitialValues } from '../../store/constants';
import Loading from '../common/Loading';
/* import { errorHandler } from '../../utils/errorMapper'; */

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      background: '#F8FAFC',
      padding: theme.spacing(2)
    }
  },
  'MuiFormHelperText-root': {
    marginTop: '50px'
  },
  senderAmountInput: {
    width: '100%',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    flex: 1,
    color: theme.palette.globalAppTheme.globalAppTextColor,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18)
    }
  },
  senderAmountInputLabel: {
    width: 'max-content',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#4a6582',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  title: {
    marginRight: '2px',
    marginLeft: '2px'
  },
  errorText: {
    background: 'none',
    color: '#989898',
    padding: '3px',
    borderRadius: '0px',
    width: 'max-content',
    position: 'absolute'
  },
  helperText: {
    fontSize: '0.70rem',
    fontFamily: 'Inter'
  },
  gridItem: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  }
}));

function SenderAmount() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['landing-page-common']);
  const { error: appError, setting } = useSelector((state) => state.app);
  const { selectedDeliveryMethod, deliveryMethods, isInvalidSenderAmount, convertedAmount, amount: globalAmount, isConverting } = useSelector((state) => state.moneyTransfer);
  const { sendMoneyDialogIsOpen } = useSelector((state) => state.dashboard);
  const [amount, setAmount] = useState(convertedAmount?.amount || globalAmount);
  const { amountDayLimit, minimumAmount } = useSelector((state) => state.app.setting);
  const { getConvertedRate } = convertAmount();
  const [isFocusedInput, setIsFocusedInput] = useState(false);
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const getRate = (value) => {
    if (value && parseInt(value, 10) >= setting.minimumAmount) {
      getConvertedRate(
        value,
        selectedDeliveryMethod && selectedDeliveryMethod?.id,
        moneyTransferBoxInitialValues.SOURCE_AMOUNT
      )
    }
  }

  const handleChangeAmount = (e) => {
    const { value } = e.target;
    setAmount(value);
    getRate(value);
    dispatch(setGlobalAmount(value));
    if (deliveryMethods.length > 0 && !isInvalidSenderAmount) dispatch(setGlobalIsOpenMtfPriceDetails(true));
  };

  const handleInputClick = (e) => {
    e.preventDefault();
    setIsFocusedInput(true);
    setAmount((prevState) => {
      if (prevState !== convertedAmount.amount) {
        return convertedAmount.amount
      }
      return amount
    });
  }

  useEffect(() => {}, [convertedAmount, sendMoneyDialogIsOpen]);

  const minTransactionAmount = `${NumberFormatter.formatWithThousandsSeparators(minimumAmount)} SEK`;
  const maxTransactionAmount = `${NumberFormatter.formatWithThousandsSeparators(amountDayLimit)} SEK`;

  const message = () => <Alert className="amount-tooltip-message" style={{ textAlign: 'left' }} severity="info">{t('amountTooltip', { minAmount: minTransactionAmount, maxAmount: maxTransactionAmount })}</Alert>

  return (
    <div className='sender-amount-input-container' style={{ display: 'grid' }}>
      {isInvalidSenderAmount && !appError && matchesSm ? message() : null}
      <HtmlTooltip
        arrow
        open={isInvalidSenderAmount && !sendMoneyDialogIsOpen && !appError && !matchesSm}
        title={message()}
        aria-label="sender-amount"
        placement="top-start"
        enterDelay={500}
        leaveDelay={200}
      >
        <Grid container justifyContent="space-evenly" className={classes.gridContainer}>
          <Grid item xs={7} sm={7} md={7} lg={7} className={classes.gridItem}>
            <div role="presentation" onClick={(e) => handleInputClick(e)} onBlur={() => setIsFocusedInput(false)} style={{ display: 'flex' }}>
              <TextField
                variant="standard"
                autoComplete="off"
                id="sender-amount-input"
                key="senderAmountInput"
                onChange={handleChangeAmount}
                autoFocus
                type={isFocusedInput ? "number" : "string"}
                InputProps={{
                  disableUnderline: true,
                  className: classes.senderAmountInput,
                  endAdornment: isConverting ? (
                    <InputAdornment position='start'>
                      <Loading size={20} style={{ padding: 0 }} />
                    </InputAdornment>
                  ) : null 
                }}
                InputLabelProps={{
                  className: classes.senderAmountInputLabel,
                  shrink: true
                  
                }}
                value={isFocusedInput || !convertedAmount ? amount : NumberFormatter.svAmountFormat(convertedAmount.amount)}
                name="senderAmount"
                label={t('mtfBoxSenderLabel')}
                required
                aria-label="sender-amount-input"
                placement="top-start"
                disabled={!!appError}
              />
            </div>
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <CountryCurrencySelectMenu type="sender" />
          </Grid>
        </Grid>
      </HtmlTooltip>
    </div>
  );
}

export default SenderAmount;
