import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Divider, Typography } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { camelCase } from 'lodash';
import classNames from 'classnames';
import BriteSwedenDefault2 from '../../../assets/svg/BriteSwedenDefault2.svg';
import NumberFormatter from '../../../utils/NumberFormatter';
/* import CreditCardPaymentsDisplay from './CreditCardPaymentsDisplay';
import { CREDIT_DEBIT_CARD_LIST } from './constants'; */
import InfoMessageChecklist from '../../common/InfoMessageChecklist';

const useStyles = makeStyles((theme) => ({
  transactionDetailsDialogContent: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  cardMediaRoot: {
    objectFit: 'contain'
  },
  cardMediaContainer: {
    display: 'flex',
    paddingTop: theme.spacing(2)
  },
  transactionDetailsHeading: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.78,
    letterSpacing: 'normal',
    color: '#2f4662'
  },
  transactionDetailsSub1: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.8,
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  transactionDetailsSub2: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  totalToPay: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(24)
    }
  },
  coloredHorizontalLine: {
    transform: 'rotate(-180deg)',
    borderBottom: '1px solid',
    color: '#21a1c5'
  }
}));

function FormStepTransactionDetail() {
  const classes = useStyles();
  const { t } = useTranslation(['dashboard-common']);
  const { amount } = useSelector((state) => state.moneyTransfer);
  const { fee, rate, totalToPay, senderCurrency, convertedAmount } = useSelector(
    (state) => state.moneyTransfer.convertedAmount
  );

  const { selectedDeliveryMethod } = useSelector((state) => state.moneyTransfer);
  const { selectedRecipient } = useSelector((state) => state.dashboard);

  return (
    <DialogContent className={classes.transactionDetailsDialogContent}>
      <Grid container direction="column">
        <Grid container>
          <Grid container justifyContent="flex-start">
            <Typography fontWeight="fontWeightLight" align="center" className={classes.transactionDetailsHeading}>
              {t('recipient')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.transactionDetailsSub1}>{t('name')}</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography className={classes.transactionDetailsSub2}>
              {selectedRecipient?.firstName} {selectedRecipient?.lastName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.transactionDetailsSub1}>{t('payoutAccount')}</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography className={classes.transactionDetailsSub2}>{selectedRecipient?.recipientPayoutAccount}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.transactionDetailsSub1}>{t('deliveryMethod')}</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography className={classes.transactionDetailsSub2}> {`${t(camelCase(selectedDeliveryMethod?.type))} (${selectedDeliveryMethod?.name})`}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Divider className={classNames(classes.dottedLine, classes.spaceTop)} />
        </Grid>
        <Grid container>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <Typography fontWeight="fontWeightLight" align="center" className={classes.transactionDetailsHeading}>
              {t('priceDetails')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.transactionDetailsSub1}>{t('youSend')}</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography className={classes.transactionDetailsSub2}>
              {`${NumberFormatter.svAmountFormat(amount)} ${senderCurrency}` || 0}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.transactionDetailsSub1}>{t('recipientGets')}</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography className={classes.transactionDetailsSub2}>
              {convertedAmount}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.transactionDetailsSub1}>{t('exchangeRate')}</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography className={classes.transactionDetailsSub2}>{rate}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.transactionDetailsSub1}>{t('ourFee')}</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography className={classes.transactionDetailsSub2}>
              {fee}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Divider className={classNames(classes.coloredHorizontalLine, classes.spaceTop)} />
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.totalToPay}>{t('totalToPay')}</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography className={classes.totalToPay}>
              {totalToPay}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Divider className={classNames(classes.coloredHorizontalLine)} />
        </Grid>
        <Grid container justifyContent="center" item direction="row">
          <div className={classes.cardMediaContainer}>
            <CardMedia
              classes={{ root: classes.cardMediaRoot }}
              component="img"
              width="50"
              height="50"
              image={BriteSwedenDefault2}
              alt="CreditCard"
            />
            {/* <CreditCardPaymentsDisplay list={CREDIT_DEBIT_CARD_LIST} width="60" height="40" /> */}
          </div>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
            <InfoMessageChecklist
              title={t('fundsToEnteredAccountInfo')}
              listItems={[
                { text: t('ensureCorrectPayoutAccount'), element: null },
                { text: t('checkAccountNumberForTypos'), element: null },
                { text: t('accountNumberWillBeSaveToRecipient'), element: null }
              ]}
            />
          </Grid>
      </Grid>
    </DialogContent>
  );
}

FormStepTransactionDetail.defaultProps = {
  selectedRecipient: {}
};

FormStepTransactionDetail.propTypes = {
  selectedRecipient: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    nationality: PropTypes.string,
    networkId: PropTypes.string,
    phoneNumber: PropTypes.string,
    street: PropTypes.string,
    service: PropTypes.string,
    recipientPayoutAccount: PropTypes.string
  })
};

export default FormStepTransactionDetail;
