/* eslint-disable import/no-unused-modules */
import * as yup from 'yup';
import { PAYOUT_ACCOUNT_TYPES } from '../../../constants/payoutAccountTypes';
import { FORM_TRANSLATIONS } from '../formTranslations';

export const recipientPayoutAccountValidationSchema = (type, t, recipientPayoutAccount) => {
  const phoneRegExp = /^\+?\d{1,3}[ .-]?\(?\d{1,3}\)?(?:[ .-]?\d{3,4}){2}$/;
  const bankRegExpStringDigitCombinationAndDigits = /^(?=.*\d)[\dA-Za-z]+$/
  
  if(recipientPayoutAccount && recipientPayoutAccount.length >= 8 && recipientPayoutAccount.length <= 30) {
    return {
      recipientPayoutAccount: yup
        .string()
    };
  }
  if (PAYOUT_ACCOUNT_TYPES.MOBILE_WALLET.includes(type)) {
    return {
      recipientPayoutAccount:
        yup
            .string()
            .matches(phoneRegExp, t('formEnterValidMobileNumber'))
            .min(9, FORM_TRANSLATIONS.formEnterValidMobileNumber)
            .max(30, FORM_TRANSLATIONS.formEnterValidMobileNumber)
            .required(FORM_TRANSLATIONS.formEnterValidMobileNumber)
    };
  }
  if (PAYOUT_ACCOUNT_TYPES.CASH_PICKUP.includes(type)) {
    return {
      recipientPayoutAccount:
        yup
            .string()
            .matches(phoneRegExp, t('formEnterValidMobileNumber'))
            .min(9, FORM_TRANSLATIONS.formEnterValidMobileNumber)
            .max(30, FORM_TRANSLATIONS.formEnterValidMobileNumber)
            .required(FORM_TRANSLATIONS.formEnterValidMobileNumber)
    };
  }
  if (PAYOUT_ACCOUNT_TYPES.BANK_ACCOUNT.includes(type)) {
    return {
      recipientPayoutAccount:
        yup
            .string()
            .matches(bankRegExpStringDigitCombinationAndDigits, t('formEnterValidBankAccountNumber')) 
            .min(8, FORM_TRANSLATIONS.formEnterValidBankAccountNumber)
            .max(18, FORM_TRANSLATIONS.formEnterValidBankAccountNumber)
            .required(FORM_TRANSLATIONS.formEnterValidBankAccountNumber)
    };
  }
  return {
    recipientPayoutAccount: yup
      .string()
      .min(9, FORM_TRANSLATIONS.formEnterValidMobileNumber)
      .max(18, FORM_TRANSLATIONS.formEnterValidMobileNumber)
      .required(FORM_TRANSLATIONS.formEnterValidMobileNumber)
  };
};
