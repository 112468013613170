/**
 * Here we've decided to outsource the detection of desktop/mobile for our redirect
 * to the library 'is-mobile'. At the time of writing this library is safe to use.
 * But you should always do your research and not use libraries you do not trust.
 */
import isMobileLib from 'is-mobile';

const useDevice = () => {
  const isMobileOrTablet = isMobileLib({ tablet: false, featureDetect: true });
  const isChromeOnAppleDevice = Boolean(navigator.userAgent.match(/CriOS/));
  const isFirefoxOnAppleDevice = Boolean(navigator.userAgent.match(/FxiOS/));
  const isOperaTouchOnAppleDevice = Boolean(navigator.userAgent.match(/OPT/));
  const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) || !!window.chrome;

  return {
    isMobileOrTablet,
    isChromeOnAppleDevice,
    isFirefoxOnAppleDevice,
    isOperaTouchOnAppleDevice,
    isIOS,
    isChrome
  };
};

export default useDevice;
