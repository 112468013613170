import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Fade } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  errorFeedback: {
    color: '#f44336',
    margin: 5
  },
  helperText: {
    fontSize: '0.9rem',
    fontFamily: 'Inter',
    color: '#f44336'
  }
}));

function InputFeedback({ error }) {
  const classes = useStyles();
  if (error) {
    return (
      <Fade in={!!error}>
        <Typography
          component="span"
          gutterBottom
          className={classNames('input-feedback', classes.errorFeedback, classes.helperText)}
        >
          {error}
        </Typography>
      </Fade>
    );
  }
  return null;
}

InputFeedback.defaultProps = {
  error: null
};

InputFeedback.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node])
};

export default InputFeedback;
