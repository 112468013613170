import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Fade, Grid , Alert } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';



const useStyles = makeStyles(() => ({
  diffrentReceiverCountryInfoMessage: {
    margin: '8px 0'
  }
}));

function DiffrentReceiverCountryMessage({ isDiffrentRecipientCountry }) {
  const classes = useStyles();
  const { t } = useTranslation(['dashboard-common']);

  const {
    recipientCountryOptionValue
  } = useSelector((state) => state.moneyTransfer);

  const {
    selectedRecipient
  } = useSelector((state) => state.dashboard);

  useEffect(() => {}, [isDiffrentRecipientCountry]);

  return selectedRecipient?.country && isDiffrentRecipientCountry ? (
    <Grid xs={12} item>
      <Fade in={isDiffrentRecipientCountry} timeout={{ enter: 800, exit: 800 }} style={{ transitionDelay: isDiffrentRecipientCountry ? '1000ms' : '500ms' }} unmountOnExit>
        <Alert severity="info" className={classes.diffrentReceiverCountryInfoMessage}>
          {t('currencyNotAvailiableInRecipientCountry', { selectedCurrency: recipientCountryOptionValue?.currency, recipientCountry: selectedRecipient?.country, selectedCountry: recipientCountryOptionValue?.countryName })}
        </Alert>
      </Fade>
    </Grid>
  ) : null;
}

DiffrentReceiverCountryMessage.defaultProps = {
  isDiffrentRecipientCountry: undefined
}

DiffrentReceiverCountryMessage.propTypes = {
  isDiffrentRecipientCountry: PropTypes.bool
}

export default DiffrentReceiverCountryMessage;
