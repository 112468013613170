import makeStyles from '@mui/styles/makeStyles';

const footerStyles = makeStyles((theme) => ({
  footerContainer: {
    '@media print': {
      display: 'none'
    }
  },
  vivowire: {
    fontFamily: 'Rubik',
    fontSize: '26px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.93px',
    color: '#21a2c6',
    textAlign: 'center',
    marginBottom: '10px',
    cursor: 'pointer'
  },
  contacInfoContainer: {
    display: 'flex', 
    justifyContent: 'center', 
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  contacInfoTitle: {
    padding: '0px 0px 5px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  contacInfo: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  titleText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.13',
    letterSpacing: 'normal',
    color: '#4a6582',
    textAlign: 'center',
    marginBottom: '22px',
    opacity: 0.9,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      paddingTop: '20px'
    }
  },
  linkText: {
    height: '30px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.13',
    letterSpacing: 'normal',
    color: '#2f4662',
    textAlign: 'center',
    textDecoration: 'none',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      paddingTop: '20px'
    }
  },
  copyright: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    textAlign: 'start',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      marginLeft: 0
    }
  },
  companyAuthorization: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(5),
    textAlign: 'start',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      marginLeft: 0
    }
  },
  socialMediaContainer: {
    float: 'right',
    paddingTop: theme.spacing(-6.5),
    [theme.breakpoints.down('md')]: {
      float: 'none',
      paddingTop: theme.spacing(3)
    }
  },
  socialMediaIcons: {
    color: '#8b91a0',
    width: '55px',
    height: '45px',
    [theme.breakpoints.down('md')]: {
      width: '50px',
      height: '40px'
    }
  },
  arrowTopContainer: {
    float: 'right',
    [theme.breakpoints.down('md')]: {
      float: 'none'
    }
  },
  arrowTopIcon: {
    borderRadius: theme.borderRadius,
    color: '#2f4662',
    width: '50px',
    height: '50px',
    border: '1px solid #2f4662',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '45px',
      height: '45px',
      marginTop: '70px'
    },
    '&:hover': {
      color: 'inherit',
      background: '#106982'
    }
  },
  divider: {
    height: '1px',
    opacity: '0.2',
    border: 'solid 1px #8b91a0',
    margin: '2em',
    marginTop: '4em'
  },
  forestBackdropImg: {
    position: 'absolute',
    width: 'auto',
    height: 500,
    opacity: 0.3,
    zIndex: '-1',
    margin: '0 auto',
    bottom: 0,
    [theme.breakpoints.down('lg')]: {
      height: 'auto'
    }
  }
}));

export default footerStyles;
