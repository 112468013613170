import React from 'react';
import SectionTemplate from '../../sections/SectionTemplate';
import AgreementAmico1 from '../../../../assets/img/AgreementAmico1-2x.png';
import SectionPolicyContentTemplate from '../../sections/SectionPolicyContentTemplate';
import terms from './terms';

function PrivacyPolicySection() {
  return (
    <SectionTemplate
      sectionId="terms-and-conditions-section"
      sectionImg={AgreementAmico1}
      sectionImgTitle="Agreement"
      sectionTitle="Terms and Conditions"
      sectionSubTitle="sectionSubTitle"
      pathName="Terms and Conditions"
      translation="terms-and-conditions"
    >
      <SectionPolicyContentTemplate translation="terms-and-conditions" policy={terms} />
    </SectionTemplate>
  );
}

export default PrivacyPolicySection;
