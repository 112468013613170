const support = {
  VIVOWIRE_SUPPORT_EMAIL: 'support@vivowire.com',
  VIVOWIRE_CONTACT_EMAIL: 'contact@vivowire.com',
  VIVOWIRE_SUPPORT_PHONE: '031-375 79 05',
  VIVOWIRE_FACEBOOK: 'https://www.facebook.com/vivowire',
  VIVOWIRE_TWITTER: 'https://twitter.com/vivowire',
  VIVOWIRE_INSTAGRAM: 'https://www.instagram.com/vivowire/',
  VIVOWIRE_LINKEDIN: 'https://www.linkedin.com/company/vivowire',
  COMPANY_ADRESS: 'Box 337',
  COMPANY_ZIPCODE: '415 33',
  COMPANY_CITY: 'Gothenburg',
  COMPANY_COUNTRY: 'Sweden' 
};

export default support;
