import makeStyles from '@mui/styles/makeStyles';

const mtfBoxPadding = 20;

const mtfBoxStyles = makeStyles((theme) => ({
  mtfBox: {
    minWidth: 740,
    maxWidth: 900,
    width: 1200,
    background: '#FFF',
    height: '100px',
    margin: 'auto',
    borderRadius: theme.borderRadiusInput,
    justifyContent: 'space-around',
    [theme.breakpoints.up('md')]: {
      margin: 0,
      width: 'auto',
      height: 'auto',
      maxWidth: 750,
      minWidth: 920
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
      width: 'auto',
      height: 'auto',
      maxWidth: 320,
      minWidth: 320
    }
  },
  divider: {
    width: '1px',
    height: '34px',
    margin: '31px 31px 11px 38px',
    opacity: '0.2',
    border: 'solid 1px #919ba8',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  gridContainer: {
    margin: 0
  },
  gridItem: {
    padding: mtfBoxPadding
  },
  gridItemRecipientInput: {
    padding: mtfBoxPadding,
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  },
  gridItemSelectSender: {
    padding: mtfBoxPadding,
    marginRight: 75,
    marginLeft: 30,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: '-110px',
      margin: '0'
    }
  },
  gridItemSelectRecipient: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 20,
    padding: mtfBoxPadding,
    marginLeft: '60px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      position: 'relative',
      top: '-110px',
      margin: '0'
    }
  },
  gridItemButton: {
    [theme.breakpoints.down('md')]: {
      padding: 20
    }
  },
  gridItemInput: {
    padding: mtfBoxPadding
  },
  exchangeRateContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)} 0 0 0`
    }
  }
}));

export default mtfBoxStyles;
