/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import _camelCase from 'lodash/camelCase';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Divider, Typography , Alert } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import SenderAmount from '../../mtf/SenderAmount';
import RecipientAmount from '../../mtf/RecipientAmount';
import BriteSwedenDefault2 from '../../../assets/svg/BriteSwedenDefault2.svg';
import Selectfield from '../../common/SelectField';
import AddRecipient from '../recipient/AddRecipient';
import Hint from '../../common/Hint';
import { reasonForSendingList } from '../../../constants/dashboardContants';
import DeliveryMethodSelect from '../../mtf/DeliveryMethodSelect';
import PayerSelect from '../../mtf/PayerSelect';
import API from '../../../utils/api';
import {
  setGlobalSelectedRecipient,
  setGlobalSelectedRecipientPayers
} from '../../../store/redux/actions/dashboardAction';
import NumberFormatter from '../../../utils/NumberFormatter';
import RecipientPayoutAccount from '../recipient-payout-account/RecipientPayoutAccount';
import Util from '../../../utils/Util';
import { errorHandler } from '../../../utils/errorMapper';
import { useHandleRecipientChange } from './handleRecipientChange';
import { handleDeviantPayoutSpeed } from '../../mtf/handleDeviantPayoutSpeed';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    padding: theme.spacing(1)
  },
  addRecipientGridItem: {
    float: 'right',
    padding: theme.spacing(1.5)
  },
  inputBackground: {
    borderRadius: theme.borderRadius,
    backgroundColor: '#eef3f9'
  },
  spaceBottom: {
    marginBottom: 15
  },
  feePercentage: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#4a6582'
  },
  feeTotal: {
    with: '100%',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    fontStyle: 'normal',
    color: theme.palette.globalAppTheme.globalAppTextColor,
    textAlign: 'end'
  },
  dottedLine: {
    transform: 'rotate(-180deg)',
    opacity: 0.2,
    borderBottom: '2px dashed'
  },
  totalText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#4a6582',
    textShadow: '0 0 0 #4a6582'
  },
  totalSum: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: -0.86,
    color: theme.palette.globalAppTheme.globalAppTextColor,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 800
    }
  },
  verticalDivider: {
    width: 1,
    height: 410,
    opacity: 0.2,
    backgroundColor: '#8b91a0'
  },
  horizontalDivider: {
    width: 410,
    height: 1,
    opacity: 0.2,
    backgroundColor: '#8b91a0'
  },
  innerLabelRecipient: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    marginLeft: theme.spacing(0),
    color: theme.palette.globalAppTheme.globalAppTextColor
  },
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.globalAppTheme.globalAppTextColor,
      opacity: 0.8
    }
  },
  cardMediaRoot: {
    objectFit: 'contain'
  },
  cardMediaContainer: {
    display: 'flex',
    paddingTop: theme.spacing(2)
  },
  feeAndSum: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
  recipientDetailsLabel: {
    width: 'max-content',
    height: '32px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#4a6582',
    zIndex: 10,
    padding: '20px'
  },
  recipientDetails: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: theme.palette.globalAppTheme.globalAppTextColor,
    padding: '8px 16px 0px'
  },
  recicpientSelectionContainer: {
    marginTop: '-32px',
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  },
  addRecipientButton: {
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: 500,
    paddingRight: 4,
    textTransform: 'none',
    color: '#fff',
    height: 62,
    marginTop: 5,
    marginBottom: 5,
    objectFit: 'contain',
    borderRadius: theme.borderRadiusButton,
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover,&:focus': {
      background: theme.palette.globalAppTheme.globalAppButtonColor
    }
  }
}));

function FormStepSelectOrAddRecipient({ selectedRecipient, reasonForSending, getBenificieryMessage, getBenificieryError }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width: 667px) and (min-width: 50px)');
  const { t } = useTranslation(['dashboard-common']);
  const { selectedDeliveryMethod } = useSelector((state) => state.moneyTransfer);
  const { userRecipients } = useSelector((state) => state.dashboard);
  const { fee, totalToPay, rate } = useSelector(
    (state) => state.moneyTransfer.convertedAmount
  );
  const [error, setError] = useState();
  const { handleRecipientChange } = useHandleRecipientChange();

  const handleRecipientSelect = (recipientId) => {
    setError();
    API.get(`/recipient/${recipientId}`)
      .then((res) => {
        const { data } = res;
        dispatch(setGlobalSelectedRecipientPayers(data.recipientPayer));
        dispatch(setGlobalSelectedRecipient(data));
        handleRecipientChange(data);
      })
      .catch((_error) => setError(_error));
  };

  React.useEffect(() => {}, [selectedRecipient])


  const displayRecipientSelect = () => (
    <Grid item sm={5} md={5} lg={5} sx={{ width: '100%' }}>
      <Grid item sm={12} md={12} lg={12} className={classes.gridItem}>
        <span className={classes.addRecipientGridItem}>
          {_isEmpty(userRecipients) ? (
            <Hint>
              <AddRecipient text={t('addNewRecipient')} />
            </Hint>
          ) :
            <AddRecipient text={t('addNewRecipient')} />
          }
        </span>
        <Selectfield
          handleSelectChange={handleRecipientSelect}
          fullWidth
          name="id"
          value={selectedRecipient?.id}
          InputProps={{
            className: classes.innerLabelRecipient
          }}
          options={userRecipients.map((recipient) => ({
            id: recipient?.id,
            name: recipient?.id,
            override: {
              recipient,
              name: (
                <>
                  <span>{`${recipient.firstName} ${recipient.lastName}`}</span> <br />
                  <small>{Util.getCountryNameByISO3Code(recipient.country)}</small>
                </>
              )
            }
          }))}
          label={t('selectARecipientOrAddOne')}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <Selectfield
          fullWidth
          name="reasonForSending"
          value={reasonForSending}
          InputProps={{
            className: classes.innerLabelRecipient
          }}
          options={reasonForSendingList.map((reason) => ({
            id: reason?.id,
            name: reason.reason,
            override: {
              reason,
              name: t(_camelCase(reason.value))
            }
          }))}
          label={t('reasonForSending')}
        />
      </Grid>
      <Grid
        item
        xs={12}
        className={`${selectedRecipient.country || selectedRecipient?.payer ? null : 'disabled-element'} ${classes.gridItem
          }`}
      >
        {!selectedRecipient?.payer?.phoneNumber && !selectedRecipient?.payer?.bankAccountNumber ?
          <Typography gutterBottom className={classes.recipientPayoutAccountValidate}>
            {t('addNewPayoutAccountToRecipient', { recipientName: selectedRecipient.firstName ? `${selectedRecipient.firstName} ${selectedRecipient.lastName}` : '' })}
          </Typography> : null}
        {getBenificieryMessage}
        {!!error && <Alert severity="error">{errorHandler(getBenificieryError)}</Alert>}
        <RecipientPayoutAccount
          selectedRecipient={selectedRecipient}
          type={selectedDeliveryMethod?.type}
          recipientPayer={selectedRecipient?.payer}
        />
      </Grid>
    </Grid>
  )

  return (
    <DialogContent>
      <Grid container justifyContent="center" className={classes.gridContainer}>
        <Grid className={classes.gridItem} item xs={12}>
          {!!error && <Alert severity="error">{errorHandler(error)}</Alert>}
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <Grid container justifyContent="center" direction="row">
            <div className={classNames(classes.inputBackground, classes.spaceBottom)} style={{ padding: 16 }}>
              <SenderAmount />
            </div>
            <div className={classNames(classes.inputBackground, classes.spaceBottom)} style={{ padding: 16 }}>
              <RecipientAmount />
            </div>
            <Grid container justifyContent="center" direction="row">
              <Grid item xs={12} className={classes.gridItemSelect} style={{ paddingBottom: 16 }}>
                <DeliveryMethodSelect selectedDeliveryMethod={selectedDeliveryMethod} isInputStyle filter />
              </Grid>
              <Grid item xs={12} className={classes.gridItemSelect} style={{ paddingBottom: 16 }}>
                <PayerSelect selectedDeliveryMethod={selectedDeliveryMethod} logo isInputStyle />
              </Grid>
            </Grid>
            {matches ? displayRecipientSelect() : null}
            <Grid container item direction="row" className={classNames(classes.spaceBottom, classes.feeAndSum)}>
              <Grid className={classes.gridItem} item xs={6} sm={6} md={6}>
                <Typography className={classes.feePercentage}>{t('payoutSpeed')}</Typography>
              </Grid>
              <Grid className={classes.gridItem} item xs={6} sm={6} md={6}>
                <Typography className={classes.feeTotal}>
                  {t(_camelCase(handleDeviantPayoutSpeed(selectedDeliveryMethod).speed || ''))}
                  {' '} {handleDeviantPayoutSpeed(selectedDeliveryMethod).icon}
                </Typography>
              </Grid>
              <Grid className={classes.gridItem} item xs={6} sm={6} md={6}>
                <Typography className={classes.feePercentage}>{t('exchangeRate')}</Typography>
              </Grid>
              <Grid className={classes.gridItem} item xs={6} sm={6} md={6}>
                <Typography className={classes.feeTotal}>{rate}</Typography>
              </Grid>
              <Grid className={classes.gridItem} item xs={6} sm={6} md={6}>
                <Typography className={classes.feePercentage}>{t('fees')}</Typography>
              </Grid>
              <Grid className={classes.gridItem} item xs={6} sm={6} md={6}>
                <Typography className={classes.feeTotal}>{fee}</Typography>
              </Grid>
              <Grid className={classes.gridItem} item xs={6} sm={6} md={6}>
                <Typography className={classes.totalText}>{t('total')}</Typography>
              </Grid>
              <Grid className={classes.gridItem} container item justifyContent="flex-end" xs={6} sm={6} md={6}>
                <Typography className={classes.totalSum}>
                  {NumberFormatter.numberAmountWithTwoPlacedFloat(totalToPay)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Divider className={classNames(classes.dottedLine, classes.spaceBottom)} />
            </Grid>
            <Grid container item direction="row" className={classes.spaceBottom}>
              <Grid container justifyContent="center" item direction="row" xs={12} sm={12} md={12}>
                <div className={classes.cardMediaContainer}>
                  <CardMedia
                    classes={{ root: classes.cardMediaRoot }}
                    component="img"
                    width="80"
                    height="80"
                    image={BriteSwedenDefault2}
                    alt="Brite Sweden"
                  />
                  {/* <CreditCardPaymentsDisplay list={CREDIT_DEBIT_CARD_LIST} width="60" height="40" /> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={1} direction="column" alignContent="center">
          <Divider
            variant="fullWidth"
            orientation={matches ? 'horizontal' : 'vertical'}
            className={matches ? classes.horizontalDivider : classes.verticalDivider}
          />
        </Grid>
        {!matches ? displayRecipientSelect() : null}
      </Grid>
    </DialogContent>
  );
}

FormStepSelectOrAddRecipient.defaultProps = {
  reasonForSending: undefined,
  getBenificieryMessage: undefined,
  getBenificieryError: undefined
};

FormStepSelectOrAddRecipient.propTypes = {
  getBenificieryMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  getBenificieryError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedRecipient: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    citizenship: PropTypes.string,
    phoneNumber: PropTypes.string,
    street: PropTypes.string,
    relationship: PropTypes.string,
    payer: PropTypes.object
  }).isRequired,
  reasonForSending: PropTypes.string
};

export default FormStepSelectOrAddRecipient;
