import { ACTION_TYPES } from '../ReduxActionTypes';

const initialState = {
  user: null,
  transaction: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_USER:
      return { ...state, user: action.payload };
    case ACTION_TYPES.SET_TRANSACTION:
      return { ...state, transaction: action.payload };
    case ACTION_TYPES.SET_TRANSACTION_STATS:
      return { ...state, transactionStats: action.payload };
    case ACTION_TYPES.SET_RECIPIENTS:
      return { ...state, user: { ...state.user, recipient: [...state.user.recipient, action.payload] } };
    case ACTION_TYPES.SET_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
