/* eslint-disable import/no-unused-modules */
import { ACTION_TYPES } from '../ReduxActionTypes';
/* import API from '../../../utils/api'; */

export const setGlobalPaymentBody = (paymentBody) => ({
  type: ACTION_TYPES.SET_PAYMENT_BODY,
  payload: paymentBody
});

export const setGlobalSelectedRecipient = (recipient) => ({
  type: ACTION_TYPES.SET_SELECTED_RECIPIENT,
  payload: recipient
});

export const setGlobalSelectedRecipientPayers = (payers) => ({
  type: ACTION_TYPES.SET_SELECTED_RECIPIENT_PAYERS,
  payload: payers
});

export const setGlobalUserRecipients = (recipients) => ({
  type: ACTION_TYPES.SET_USER_RECIPIENTS,
  payload: recipients
});

export const setGlobalUserTransactions = (transactions) => ({
  type: ACTION_TYPES.SET_USER_TRANSACTIONS,
  payload: transactions
});

export function setGlobalIsRegistred(bool) {
  return {
    type: ACTION_TYPES.SET_IS_REGISTRED,
    payload: bool
  };
}

export function setGlobalIsEmailConfirmed(bool) {
  return {
    type: ACTION_TYPES.SET_IS_EMAIL_CONFIRMED,
    payload: bool
  };
}

export function setGlobalIsVerifiedPep(bool) {
  return {
    type: ACTION_TYPES.SET_IS_VERIFIED_PEP,
    payload: bool
  };
}

export function setGlobalIsPep(bool) {
  return {
    type: ACTION_TYPES.SET_IS_PEP,
    payload: bool
  };
}

export function setGlobalUserTransactionAmountMonthLimitReached(userTransactionAmountMonthLimitReached) {
  return {
    type: ACTION_TYPES.SET_USER_TRANSACTIONS_AMOUNT_MONTH_LIMIT_REACHED,
    payload: userTransactionAmountMonthLimitReached
  };
}

export function setGlobalUserTotalTransactionAmountCurrentMonth(userTotalTransactionAmountCurrentMonth) {
  return {
    type: ACTION_TYPES.SET_USER_TOTAL_TRANSACTION_AMOUNT_CURRENT_MONTH,
    payload: userTotalTransactionAmountCurrentMonth
  };
}

export function setGlobalTransactionPaymentStatus(transactionPaymentStatus) {
  return {
    type: ACTION_TYPES.SET_TRANSACTION_PAYMENT_STATUS,
    payload: transactionPaymentStatus
  };
};

export function setGlobalSendMoneyDialogIsOpen(bool) {
  return {
    type: ACTION_TYPES.SET_SEND_MONEY_DIALOG_IS_OPEN,
    payload: bool
  };
}

/* export const setGlobalUserTotalTransactionsAmountCurrentMount = () => async (dispatch) => {
  try {
    const res = await API.get('/transaction-dashboard');
    dispatch({
      type: ACTION_TYPES.SET_USER_TOTAL_TRANSACTIONS_AMOUNT_CURRENT_MOUNT,
      payload: res.data?.totalTransactionsCurrentMonth
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.SET_USER_TOTAL_TRANSACTIONS_AMOUNT_CURRENT_MOUNT,
      payload: undefined
    });
  }
}; */
