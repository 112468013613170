/* eslint-disable import/no-unused-modules */
import { useState } from 'react';
import _get from 'lodash/get';
import API from '../../utils/api';

import { dispatchPayersForRecipientCountry } from './dispatchPayers';

const SOMALIA_SOMALILAND_ISO_CODE_3 = { SOMALILAND: 'SQM', SOMALIA: 'SOM' };
const SOMALILAND_PAYERS = ['Telesom Zaad', 'Premier Wallet', 'JubaExpress'];
const SOMALIA_SOMALILAND = { SOMALILAND: 'Somaliland', SOMALIA: 'Somalia' };

export const getPayersForRecipientCountry = () => {

  const [loading, setLoading] = useState(false);
  const [payers, setPayers] = useState([]);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState();
  const dispatchPayers = dispatchPayersForRecipientCountry();

  const dispatchPayersEvent = (data) => {
    const payersEvent = new CustomEvent('fetched-payers-for-country', {
      detail: { data },
      bubbles: true
    });

    window.dispatchEvent(payersEvent);
  }

  const getPayersForCountry = (isoCode, countryName) => {
    if (isoCode) {
      setLoading(true);
      API.get(`/recipient-country/${isoCode}`)
        .then((res) => {
          setError(null);
          const payersForCountry = _get(res.data, 'payers', []);
          /* Special case for Somaliland. Somaliland uses Somalia iso code to fetch payers. 
           Then payers are filtered to pick out special payers for Somaliland */
          if (countryName === SOMALIA_SOMALILAND.SOMALILAND) {
            const filteredPayers = payersForCountry.filter((v) => SOMALILAND_PAYERS.includes(v.name)) || [];
            setPayers(filteredPayers);
            dispatchPayers(filteredPayers);
            dispatchPayersEvent(filteredPayers);
            setLoading(false);
          } else {
            setPayers(payersForCountry);
            dispatchPayers(payersForCountry);
            dispatchPayersEvent(payersForCountry);
            setLoading(false);
          }
        })
        .catch((_error) => {
          setLoading(false);
          setIsError(true);
          setError(_error);
        });
    }
  };

  /**
   * Special handling for Somaliland. Backend returns special isocode for Somaliland that is then mapped to Somalia to get payers.
   * Payers are then filtered to custom payers for Somaliland (SOMALILAND_PAYERS).
   * @param {*} isoCode
   * @param {*} countryName
   */
  const mapIsoCodeAndGetPayers = async (isoCode, countryName) => {
    if (isoCode === SOMALIA_SOMALILAND_ISO_CODE_3.SOMALILAND) {
      getPayersForCountry(SOMALIA_SOMALILAND_ISO_CODE_3.SOMALIA, countryName);
    } else {
      getPayersForCountry(isoCode, countryName);
    }
  };

  return {
    mapIsoCodeAndGetPayers,
    error,
    payers,
    loading,
    isError
  };
};
