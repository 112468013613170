/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import kebabCase from 'lodash/kebabCase';
import PropTypes, { oneOfType } from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import InputFeedback from './InputFeedback';

const style = makeStyles((theme) => ({
  radioButton: {
    width: '1.8em',
    height: '1.8em',
    borderRadius: '50%',
    border: '0.1em solid currentColor',
    [theme.breakpoints.down('md')]: {
      width: '2em',
      height: '2em'
    }
  },
  radioButtonLabel: {
    color: '#2f4662',
    margin: '5px',
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: '500',
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0px',
    cursor: 'pointer',
    padding: '5px',
    zIndex: 10
  }
}));

function RadioButton({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
  label,
  className,
  feedback,
  ...props
}) {
  const classes = style();
  const { t } = useTranslation('common');
  return (
    <div id={`radio-button-${kebabCase(name)}-${kebabCase(id)}`}>
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames('radio-button', classes.radioButton)}
        {...props}
      />
      <label htmlFor={id} className={classes.radioButtonLabel}>
        {label}
      </label>
      {feedback ? <div>{touched[name] && <InputFeedback error={t(camelCase(errors[name]))} />}</div> : null}
    </div>
  );
}

RadioButton.defaultProps = {
  form: undefined,
  field: undefined,
  id: '',
  label: '',
  className: '',
  feedback: false
};

RadioButton.propTypes = {
  feedback: PropTypes.bool,
  form: PropTypes.shape({
    errors: PropTypes.object, 
    touched: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  }),
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  }),
  id: PropTypes.string,
  label: PropTypes.string,
  className: oneOfType([PropTypes.string, PropTypes.object])
};

export default RadioButton;
