import React from 'react';
import SectionTemplate from '../../sections/SectionTemplate';
import AgreementAmico1 from '../../../../assets/img/AgreementAmico1-2x.png';
import SectionPolicyContentTemplate from '../../sections/SectionPolicyContentTemplate';
import policy from './policy';

function PrivacyPolicySection() {
  return (
    <SectionTemplate
      sectionId="privacy-policy-section"
      sectionImg={AgreementAmico1}
      sectionImgTitle="Agreement"
      sectionTitle="Privacy Policy"
      sectionSubTitle="sectionSubTitle" // Value is a translation identifier
      pathName="Privacy Policy"
      translation="privacy-policy"
    >
      <SectionPolicyContentTemplate translation="privacy-policy" policy={policy} />
    </SectionTemplate>
  );
}

export default PrivacyPolicySection;
