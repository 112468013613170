import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const styles = makeStyles((theme) => ({
  pulseHint: {
    borderRadius: 10,
    padding: theme.spacing(1),
    textAlign: 'center',
    width: 'fit-content',
    background: 'transparent',
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
    transform: 'scale(1)',
    animation: `$pulsate 2s infinite`
  },
  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(222, 222, 222, 0.7)'
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)'
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
    }
  }
}));

function Hint({ children }) {
  const classes = styles();
  return <div className={clsx(classes.pulseHint)}>{children}</div>;
}

Hint.defaultProps = {
  children: undefined
};

Hint.propTypes = {
  children: PropTypes.any
};

export default Hint;
