import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import RecipientAmount from './RecipientAmount';
import LoginToSendModal from '../sign-in-modal/LoginModal';
import mtfBoxStyles from './mtfBoxStyles';
import ErrorBoundary from '../common/ErrorBoundary';
import { setGlobalIsInvalidSenderAmount } from '../../store/redux/actions/moneyTransferBoxAction';
import MtfPriceDetails from './MtfPriceDetails';
import DeliveryMethodSelect from './DeliveryMethodSelect';
import ExpandMtfPriceDetailsButton from './ExpandMtfPriceDetailsButton';
import SenderAmount from './SenderAmount';

const useStyles = mtfBoxStyles;

function MTFBox(props) {
  const { shadow, fullWidth } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.app);

  const {
    amount: senderAmount,
    selectedDeliveryMethod
  } = useSelector((state) => state.moneyTransfer);
  const { minimumAmount, amountDayLimit } = useSelector((state) => state.app.setting);

  React.useEffect(() => {
    if (!senderAmount || senderAmount === '' || senderAmount === null || senderAmount < minimumAmount || senderAmount > amountDayLimit || !!error) {
      dispatch(setGlobalIsInvalidSenderAmount(true));
    } else {
      dispatch(setGlobalIsInvalidSenderAmount(false));
    }
  }, [senderAmount]);

  return (
    <>
      <Paper
        elevation={shadow ? 10 : 0}
        style={{ maxWidth: fullWidth ? '1200px' : '1000px', margin: fullWidth ? 0 : 'auto' }}
        className={classes.mtfBox}
        id="mtfbox-container"
      >
        <Grid container className={classes.gridContainer}>
          <ErrorBoundary>
            <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItemInput}>
              <SenderAmount />
            </Grid>
            <Divider className={classes.divider} orientation="vertical" />
            <Grid item xs={12} sm={12} md={4} lg={4} className={classes.gridItemRecipientInput}>
              <RecipientAmount />
            </Grid>
            <Divider className={classes.divider} orientation="vertical" />
            <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItemRecipientInput}>
              <DeliveryMethodSelect selectedDeliveryMethod={selectedDeliveryMethod} filter />
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1}>
              <ExpandMtfPriceDetailsButton />
            </Grid>
            <Grid container justifyContent="center" className={classes.gridContainer}>
              <MtfPriceDetails />
            </Grid>
          </ErrorBoundary>
        </Grid>
      </Paper>
    <LoginToSendModal />
    </>
  );
}

MTFBox.defaultProps = {
  shadow: 0,
  fullWidth: 0
};

MTFBox.propTypes = {
  shadow: PropTypes.number,
  fullWidth: PropTypes.number
};

export default MTFBox;
