import React from 'react';
/**
 * Special handling for the Mobile Wallet [Hormuud-EVC] in Somalia.
 * The payout speed stated by the Payer differs from reality.
 * If it turns out to be correct in the future, this handling can be removed.
 * @export
 * @param {*} payer
 * @return {*}
 */
const HORMUUD_EVC_TRANSFER_SERVICE_ID = 5304;
export function handleDeviantPayoutSpeed(payer) {
  const icon = <span style={{ color: 'orange' }}>&#9888;</span>

  if (payer?.idInTransferService === HORMUUD_EVC_TRANSFER_SERVICE_ID) {
    return { speed: 'Within Three Working Days', icon };
  }
  return { speed: payer?.payoutSpeed, icon: '' };
}
