import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Grid, Button, Box } from '@mui/material';
import { Formik } from 'formik';
import Alert from '@mui/material/Alert';
import API from '../../../../utils/api';
import FormikFieldInput from '../../../formik-field/FormikFieldInput';
import FormikFieldSelect from '../../../formik-field/FormikFieldSelect';
import FormikFieldIText from '../../../formik-field/FormikFieldIText';
import supportTypes from './supportTypes';
import ContactSchema from './contactValidationSchema';
import contactFormStyle from './contactFormStyle';
import support from '../../../../constants/support';
import { errorHandler } from '../../../../utils/errorMapper';

const useStyles = contactFormStyle;

const initialValues = {
  name: '',
  email: '',
  subject: '',
  phoneNumber: '',
  supportType: '',
  description: ''
};

function ContactForm() {
  const classes = useStyles();
  const { t } = useTranslation(['contact-page']);
  const [submitStatus, setSubmitStatus] = useState('');
  const [submitError, setSubmitError] = useState('');


  const handleFormikSubmit = (values, actions) => {
    setTimeout(() => {
      setSubmitError(undefined);
      API.post('/contact-us', values)
        .then((res) => {
          actions.setSubmitting(false);
          actions.resetForm({});
          actions.setStatus({ success: true });
          setSubmitStatus(res && res.status);
        })
        .catch((error) => {
          setSubmitError(error);
        });
    }, 1000);
  };

  useEffect(() => {}, []);

  return (
    <Box className={classNames(classes.contactFormContainer)} sx={{ p: 2, display: 'flex' }}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormikSubmit}
        validationSchema={ContactSchema}
        validateOnBlur
      >
        {({ isValid, isSubmitting, errors, values, touched, handleSubmit, handleChange, handleBlur }) => (
          <form
            className={classes.form}
            onSubmit={handleSubmit}
            loading={String(isSubmitting)}
          >
            {submitStatus === 201 ? (
              <Grid item xs={12} md={12} lg={12} style={{ paddingBottom: 16 }}>
                <Alert severity="success">{t('submitStatusSuccess')}</Alert>
              </Grid>
            ) : null}
            {!!submitError && (
              <Grid item xs={12} md={12} lg={12}>
                <Alert severity={submitError?.type || 'error'}>{errorHandler(submitError)}</Alert>
              </Grid>
            )}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.fieldContainer}
            >
              <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                <FormikFieldInput
                  name="name"
                  label={t('inputLabelName')}
                  type="text"
                  required
                  value={values.name}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                  isInvalid={touched.name && errors.name}
                  errors={errors}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                <FormikFieldInput
                  name="email"
                  label={t('inputLabelEmail')}
                  type="email"
                  required
                  value={values.name}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                  isInvalid={touched.name && errors.name}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.fieldContainer}
            >
              <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                <FormikFieldSelect
                  name="supportType"
                  label={t('inputLabelSupportType')}
                  required
                  value={values.name}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                  isInvalid={touched.name && errors.name}
                  errors={errors}
                  items={supportTypes}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                <FormikFieldInput
                  name="phoneNumber"
                  label={t('inputLabelPhone')}
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                  isInvalid={touched.name && errors.name}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.fieldContainer}
            >
              <Grid item xs={12} md={12} lg={12} className={classes.gridItem}>
                <FormikFieldInput
                  name="subject"
                  label={t('inputLabelSubject')}
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                  isInvalid={touched.name && errors.name}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12} className={classes.gridItem}>
                <FormikFieldIText
                  required
                  name="description"
                  label={t('inputLabelMessage')}
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                  isInvalid={touched.name && errors.name}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} container justifyContent="center" className={classes.buttonsContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.extraLargeButton}
                classes={{ label: classes.extraLargeButtonLabel }}
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                {t('buttonSend')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.callButton}
                classes={{ label: classes.extraLargeButtonLabel }}
                onClick={null}
              >
                <a href={`tel:${support.VIVOWIRE_SUPPORT_PHONE}`} className={classes.callButton}>
                  {t('buttonCall')} {support.VIVOWIRE_SUPPORT_PHONE}
                </a>
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
}

ContactForm.propTypes = {};

export default ContactForm;
