import { createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material';

// colors
const primary = '#21a2c6';
const secondary = '#4829B2';
const black = '#343a40';
const darkBlack = 'rgb(36, 40, 44)';
const background = '#f5f5f5';

const primaryColor = '#21a1c5';
const warningColor = '#ff9800';
const dangerColor = '#f44336';
const successColor = '#4caf50';
const infoColor = '#00acc1';
const roseColor = '#e91e63';
const grayColor = '#999999';

// App desig specific color
const globalAppColor = '#21a2c6';
const globalAppButtonColor = '#21a1c5';
const globalAppTextColor = '#2f4662';
const globalAppTextColorSecondary = '#4a6582';

// border
const borderWidth = 2;
const borderColor = 'rgba(0, 0, 0, 0.13)';

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const tiny = 300;
const xs = 0;
const tablet = 640;
const laptop = 1024;
const desktop = 1280;

// custom breakpoints
const dashNavBackground = 1790;

// Custom width

// spacing
const spacing = 8;

const theme = createTheme(adaptV4Theme({
  borderRadius: 5,
  borderRadiusButton: 5,
  borderRadiusInput: 10,
  palette: {
    globalAppTheme: {
      globalAppColor,
      globalAppTextColor,
      globalAppButtonColor,
      globalAppTextColorSecondary
    },
    primary: { main: primary },
    secondary: { main: secondary },
    common: {
      black,
      darkBlack,
      grayColor,
      roseColor,
      primaryColor,
      infoColor,
      successColor,
      warningColor,
      dangerColor
    },
    tonalOffset: 0.2,
    background: {
      default: background
    },
    spacing
  },
  props: {
    // withWidth component ⚛️
    MuiWithWidth: {
      // Initial width property
      initialWidth: 'lg'
    }
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      tiny,
      xs,
      dashNavBackground,
      tablet,
      laptop,
      desktop
    }
  },
  border: {
    borderColor,
    borderWidth
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: 'static'
      }
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth
      }
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth
      }
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`
      }
    },
    MuiDialog: {
      paper: {
        width: '100%',
        maxWidth: 600,
        marginLeft: spacing,
        marginRight: spacing
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {}
      }
    },
    MuiStepIcon: {
      text: {
        fill: '#FFFFFF'
      }
    }
  },
  '@media print': {
    footer: {
      display: 'none',
      position: 'fixed',
      bottom: 0
    },
    header: {
      display: 'none',
      position: 'fixed',
      top: 0
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["'Inter', 'Rubik', '\"Helvetica\"', \"Arial\", sans-serif"].join(','),
    color: '#2f4662',
    '@media (min-width: 576px)': {
      fontSize: '1rem'
    }
  },
  customWidth: {
    mtfBoxLandingPageWidth: '80%',
    mtfBoxDashboardWidth: '100%'
  },
  container: {
    paddingRight: '16px',
    paddingLeft: '16px',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
    '@media (min-width: 576px)': {
      maxWidth: '540px'
    },
    '@media (min-width: 768px)': {
      maxWidth: '720px'
    },
    '@media (min-width: 992px)': {
      maxWidth: '960px'
    },
    '@media (min-width: 1200px)': {
      maxWidth: '1919px'
    }
  },
  transitions: {
    duration: {
      enteringScreen: 1000,
      leavingScreen: 1000
    }
  }
}));

export default responsiveFontSizes(theme);
