// import _get from 'lodash/get';
import { ACTION_TYPES } from '../ReduxActionTypes';

export const setGlobalCookieConcentVisible = (isVisible) => ({
  type: ACTION_TYPES.SET_COOCKIE_CONCENT_VISIBLE,
  payload: isVisible
});

export const setGlobalAppLanguage = (language) => ({
  type: ACTION_TYPES.SET_APP_LANGUAGE,
  payload: language
});

export const setGlobalAppLoading = (isLoading) => ({
  type: ACTION_TYPES.SET_APP_LOADING,
  payload: isLoading
});

export const setGlobalSetting = (setting) => ({
  type: ACTION_TYPES.SET_SETTING,
  payload: setting
});

export const setGlobalSenderCountries = (senderCountries) => ({
  type: ACTION_TYPES.SET_SENDER_COUNTRIES,
  payload: senderCountries
});

export const setGlobalRecipientCountries = (recipientCountries) => ({
  type: ACTION_TYPES.SET_RECIPIENT_COUNTRIES,
  payload: recipientCountries
});

export const setGlobalAppError = (error) => ({
  type: ACTION_TYPES.SET_APP_ERROR,
  payload: error
});

export const setGlobalFaqItems = (rated) => ({
  type: ACTION_TYPES.SET_FAQ_ITEMS,
  payload: rated
});

export const setGlobalMaintenanceMessage = (message) => ({
  type: ACTION_TYPES.SET_MAINTENANCE_MESSAGE,
  payload: message
});
