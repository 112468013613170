import LANGUAGES from '../constants/languages';
import { worldCountries } from '../constants/worldCountries';

class Util {
  static getLanguageNameByCode(code) {
    const language = LANGUAGES.filter((lan) => lan.code === code);
    return language[0].language;
  }

  static getNavigatorLanguageCode() {
    const availableLanguages = LANGUAGES.filter((language) => language.active).map((item) => item.code);
    const languageCode = [
      ...window.navigator.languages || [],
      window.navigator.language,
      window.navigator.browserLanguage,
      window.navigator.userLanguage,
      window.navigator.systemLanguage
    ]
      .filter(Boolean)
      .map((language) => language.substr(0, 2))
      .find((language) => availableLanguages.includes(language));
    return languageCode;
  }

  static getBrowser() {
    const { userAgent } = navigator;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'edge';
    } else {
      browserName = 'No browser detection';
    }

    return browserName;
  }

  static stringToBoolean(string) {
    switch (string && string.toLowerCase().trim()) {
      case 'true':
      case 'yes':
      case '1':
        return true;
      case 'false':
      case 'no':
      case '0':
      case null:
        return false;
      default:
        return Boolean(string);
    }
  }

  /** Converts string to kebab case. Replaced in code by lodash  _.kebabCase([string='']) */
  static camelToDashed(string) {
    return string.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`);
  }

  static capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static isObject(object) {
    return object != null && typeof object === 'object';
  }

  static isValidInternationalPhoneNumber(phoneNumber) {
    // phoneNumber = '+1 (555) 123-4567';
    const phoneNumberRegExp = /^\+?\d{1,3}[ .-]?\(?\d{1,3}\)?(?:[ .-]?\d{3,4}){2}$/;
    if (phoneNumberRegExp.test(phoneNumber)) {
      return true;
    }
    return false;
  }

  static isIBAN(iban) {
    const ibanRegExp = /^[A-Z]{2}\d{2}[\dA-Z]{1,30}$/;
    // iban = 'DE89370400440532013000';
    if (ibanRegExp.test(iban)) {
      return true;
    }
    return false;
  }

  static getISO3Code(countryName) {
    const country = worldCountries.find((c) => {
      if (countryName) {
        return c.countryName.toLowerCase() === countryName.toLowerCase();
      }
      return null;
    });

    return country ? country.isoCode3 : null;
  }

  static getCountryNameByISO3Code(isoCode3) {
    const country = worldCountries.find((c) => {
      if (isoCode3) {
        return c.isoCode3.toLowerCase() === isoCode3.toLowerCase();
      }
      return null;
    });

    return country ? country.countryName : null;
  }

  static isNegative(number) {
    return number < 0;
  }

  static deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        // eslint-disable-next-line no-mixed-operators
        (areObjects && !this.deepEqual(val1, val2)) ||
        // eslint-disable-next-line no-mixed-operators
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  }

  static isTouchDevice() {  return 'ontouchstart' in window || 'onmsgesturechange' in window; };

  static isDesktop() { return !!(window.screenX !== 0 && !this.isTouchDevice()); };

  static formatPnr(pnr) {
    const SHORT_SSN = 10;
    const LONG_SSN = 12;
    const DATE = 8;
    // const CONTROL = 4;
    // const DASH = "-";
    const regex = /\d+/g;
    let integers = pnr && pnr.match(regex).toString();
    integers = integers.split(',').join('');

    if (integers.length === SHORT_SSN) {
      const firstTwo = integers.substring(0, 2);
      if (parseInt(firstTwo, 10) > 15) {
        integers = `19${integers}`;
      } else {
        integers = `20${integers}`;
      }
    }

    if (integers.length === LONG_SSN) {
      const date = integers.slice(0, DATE);
      // const dash = DASH;
      const control = integers.slice(-4);
      return `${date}${control}`;
    }

    if (integers.length < LONG_SSN) {
      // eslint-disable-next-line no-console
      console.log('Too few');
    } else {
      // eslint-disable-next-line no-console
      console.log('Too many');
    }
    return pnr;
  }
}

export default Util;
