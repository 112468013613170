import React from 'react';
import { Translation } from 'react-i18next';
import PERMALINKS from '../../../../constants/permalinks';

const footerLinks = [
  {
    title: 'Company and team',
    children: [
      {
        path: PERMALINKS.COMPANY_AND_TEAM,
        id: 'company-and-team',
        linkName: <Translation ns={['landing-page-common']}>{(t) => t('companyAndTeam')}</Translation>
      }
 /*      {
        path: LOCATIONS.NEWS,
        id: 'whats-new-in-vivowire',
        linkName: <Translation ns={['landing-page-common']}>{(t) => t('whatsNewInVivowire')}</Translation>
      } */
    ]
  },
  {
    title: 'Important',
    children: [
      {
        path: PERMALINKS.TERMS_AND_CONDITIONS,
        id: 'terms-and-conditions',
        linkName: <Translation ns={['landing-page-common']}>{(t) => t('termsAndConditions')}</Translation>
      },
      {
        path: PERMALINKS.PRIVACY_POLICY,
        id: 'privacy-policy',
        linkName: <Translation ns={['landing-page-common']}>{(t) => t('privacyPolicy')}</Translation>
      },
      {
        path: PERMALINKS.COOKIE_POLICY,
        id: 'cookie-policy',
        linkName: <Translation ns={['landing-page-common']}>{(t) => t('cookiePolicy')}</Translation>
      }
    ]
  },
  {
    title: 'Help and support',
    children: [
      {
        path: PERMALINKS.HELP_CENTER,
        id: 'help-center',
        linkName: <Translation ns={['landing-page-common']}>{(t) => t('helpCenter')}</Translation>
      },
      {
        path: PERMALINKS.CONTACT_US,
        id: 'contact-us',
        linkName: <Translation ns={['landing-page-common']}>{(t) => t('contactUs')}</Translation>
      }
/*       {
        path: LOCATIONS.MID_MARKET_RATE,
        id: 'mid-market rate',
        linkName: <Translation ns={['landing-page-common']}>{(t) => t('midMarketRate')}</Translation>
      } */
    ]
  }
];

export default footerLinks;
