// The text i these file are place holders to generate translation properties and are mapped i the *-policy.json file in the locales folder
const policy = [
  {
    id: 1,
    title: '',
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ],
    readMore: '',
    readMoreUrl: ''
  },
  {
    id: 2,
    title: 'What are cookies',
    indent: true,
    text: [
      {
        textSubText: ''
      }
    ],
    readMore: 'For more general information on cookies',
    readMoreUrl: 'https://en.wikipedia.org/wiki/HTTP_cookie'
  },
  {
    id: 3,
    title: 'What type of cookies does Vivowire use',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      }
    ],
    readMore: '',
    readMoreUrl: ''
  },
  {
    id: 4,
    title: 'How can you manage cookies?',
    indent: true,
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ],
    readMore: '',
    readMoreUrl: ''
  },
  {
    id: 5,
    title: 'Your rights',
    indent: true,
    text: [
      {
        textSubText: ''
      }
    ],
    readMore: '',
    readMoreUrl: ''
  },
  {
    id: 6,
    title: 'Changes in our cookie policy',
    indent: true,
    text: [
      {
        textSubText: ''
      }
    ],
    readMore: '',
    readMoreUrl: ''
  },
  {
    id: 7,
    title: '',
    text: [
      {
        textSubText: '',
        indent: true,
        bold: true
      }
    ],
    readMore: '',
    readMoreUrl: ''
  }
];

export default policy;
