/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import kebabCase from 'lodash/kebabCase';
import InputFeedback from './InputFeedback';

const style = makeStyles((theme) => ({
  checkboxInput: {
    width: '1.8em',
    height: '1.8em',
    borderRadius: '50%',
    border: '0.1em solid currentColor'
  },
  checkboxInputLabel: {
    width: '100%',
    maxWidth: '570px',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#2f4662',
    cursor: 'pointer',
    height: '100%',
    display: 'block'
  },
  checkboxCheckedInputLabel: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    cursor: 'pointer',
    height: '100%',
    display: 'block'
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: '10px',
    marginBottom: '10px',
    padding: '20px',
    borderRadius: theme.borderRadius,
    cursor: 'pointer',
    '& div > input': {
      float: 'right',
      cursor: 'pointer'
    },
    '& div > label': {
      display: 'block',
      cursor: 'pointer',
      height: '100%',
      margin: 'auto'
    },
    '&:hover': {
      outline: '1px solid rgba(33, 161, 197, 1)',
      transition: 'all 0.1s ease-in-out'
    },
    '&:focused': {
      backgroundColor: '#37aacb'
    }
  }
}));

function Checkbox({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
  label,
  className,
  noShadow,
  ...props
}) {
  const classes = style();
  return (
    <>
      <div className={classes.container} id={`checkbox${kebabCase(name)}-${kebabCase(id)}`} 
        style={{ 
            background: value? 'aliceblue' : 'none', boxShadow: noShadow ? 'none' : '4px 0px 18px -3px rgb(0 0 0 / 20%)',
            border: value ? '1px solid #37aacb' : 'none'
        }}>
      <label htmlFor={id} className={value ? classes.checkboxCheckedInputLabel : classes.checkboxInputLabel}>
          {label}
        </label>
        <input
          name={name}
          id={id}
          type="checkbox"
          value={value} // could be something else for output?
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          className={classNames('radio-button', classes.checkboxInput)}
          {...props}
        />
      </div>
      {touched[name] && <InputFeedback error={errors[name]} />}
    </>
  );
}

Checkbox.defaultProps = {
  field: undefined,
  form: undefined,
  id: '',
  label: '',
  className: undefined,
  error: null,
  errors: null,
  noShadow: false
};

Checkbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  }),
  form: PropTypes.shape({
    errors: PropTypes.shape({
      city: PropTypes.shape({
        type: PropTypes.func
      }),
      country: PropTypes.shape({
        type: PropTypes.func
      }),
      email: PropTypes.shape({
        type: PropTypes.func
      }),
      phoneNumber: PropTypes.shape({
        type: PropTypes.func
      }),
      street: PropTypes.shape({
        type: PropTypes.func
      }),
      userIsPep: PropTypes.shape({
        type: PropTypes.func
      }),
      zipCode: PropTypes.shape({
        type: PropTypes.func
      })
    }),
    touched: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool])
  }),
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(PropTypes.object)]),
  error: PropTypes.shape(PropTypes.string),
  errors: PropTypes.shape(PropTypes.string),
  noShadow: PropTypes.bool
};

InputFeedback.defaultProps = {
  error: null
};

InputFeedback.propTypes = {
  error: PropTypes.shape(PropTypes.string)
};

export default Checkbox;