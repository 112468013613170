import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { Button, DialogActions, Divider , Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import API from '../../../utils/api';
import { errorHandler } from '../../../utils/errorMapper';
import { setGlobalTransactionPaymentStatus } from '../../../store/redux/actions/dashboardAction';
import { PAYMENT_SERVICE, PAYMENT_TYPE } from './constants';
import CommonDialogTitle from '../../common/DialogTitle';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: '100%',
    height: 'auto'
    /* backgroundColor: 'rgba(0, 0, 0, 0.5)' */
  },
  DialogContent: {
    [theme.breakpoints.down('md')]: {
      overflow: 'auto'
    }
  },
  cardMediaRoot: {
    objectFit: 'contain'
  },
  cardMediaContainer: {
    display: 'grid',
    gridTemplateColumns: '180px 1fr',
    paddingTop: theme.spacing(2)
  },
  divider: {
    width: '100%',
    height: 1,
    opacity: 0.2,
    backgroundColor: '#8b91a0'
  }
}));

function PaymentModal({ children, handleClose, open, maxWidth, transactionId, paymentService }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['dashboard-common']);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const controller = new AbortController();
  const { signal } = controller;

  const paymentType = (service) => {
    switch (service) {
      case PAYMENT_SERVICE.brite:
        return {
          title: t(PAYMENT_SERVICE.brite),
          subTitle: t(PAYMENT_TYPE.bankTransfer),
          avatar: <svg className="h-6 w-6 svg-icon-style" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
          </svg>
        }
      case PAYMENT_SERVICE.unlimit:
        return {
          title: t(PAYMENT_SERVICE.unlimit),
          subTitle: t(PAYMENT_TYPE.creditDebit),
          avatar: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 svg-icon-style">
            <path d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
          </svg>

        }
      default:
        return null;
    }
  }

  useEffect(() => {
    setError(null);
    let timer;
    if (paymentService === PAYMENT_SERVICE.unlimit) {
      timer = setInterval(() => {
        API.get(`transaction/${transactionId}`, { signal })
          .then((res) => {
            if (res.data?.status) {
              setStatus(res.data?.status)
              dispatch(setGlobalTransactionPaymentStatus(res.data?.status));
            }
          })
          .catch((_error) => setError(_error))
      }, 10000); // TODO remove poll och change timer interval to 3000ms
    }
    if (!open || !!error || signal.aborted) {
      controller.abort();
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [transactionId, open, error, status]);

  return (
    <Dialog
      maxWidth={maxWidth}
      classes={{ paper: classes.dialogPaper }}
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="payment-dialog"
      transitionDuration={500}
    >
      <CommonDialogTitle
        title={paymentType(paymentService)?.title}
        subTitle={paymentType(paymentService)?.subTitle}
        icon={paymentType(paymentService)?.avatar}
        onClose={handleClose}
      />
      <Divider variant="middle" className={classes.divider} />
      <DialogContent className={classes.DialogContent}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          {!!error && <Alert severity={error?.type || 'error'}>{errorHandler(error)}</Alert>}
        </Grid>
        <Grid container justifyContent="center" item direction="row">
          {children}
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={handleClose}>
          <span className={classes.abortButtonText}>{t('cancel')}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PaymentModal.defaultProps = {
  children: undefined,
  handleClose: undefined,
  open: false,
  maxWidth: 'md',
  transactionId: '',
  paymentService: ''
};

PaymentModal.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  handleClose: PropTypes.func,
  maxWidth: PropTypes.string,
  transactionId: PropTypes.string,
  paymentService: PropTypes.string
};

export default PaymentModal;