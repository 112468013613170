import * as yup from 'yup';
import { FORM_TRANSLATIONS } from '../formTranslations';

const recipientDetailsValidation = yup.object().shape({
  firstName: yup
    .string()
     .matches(/[A-Za-zÄÅÇÖÜçöüĞğİıŞş]+/g, {
      message: FORM_TRANSLATIONS.formEnterValidFirstName,
      excludeEmptyString: false
    }) 
    .min(2, FORM_TRANSLATIONS.formNameMustBeXOrMore)
    .required(FORM_TRANSLATIONS.formFirsNameIsRequired),
  lastName: yup
    .string()
     .matches(/[A-Za-zÄÅÇÖÜçöüĞğİıŞş]+/g, {
      message: FORM_TRANSLATIONS.formEnterValidLastName,
      excludeEmptyString: false
    }) 
    .min(2, FORM_TRANSLATIONS.formNameMustBeXOrMore)
    .required(FORM_TRANSLATIONS.formLastNameIsRequired),
  street: yup
    .string()
    .min(2, FORM_TRANSLATIONS.formStreetMustBeXOrMore)
    .required(FORM_TRANSLATIONS.formStreetNameIsRequired),
  city: yup.string().min(2, FORM_TRANSLATIONS.formCityMustBeXOrMore).required(FORM_TRANSLATIONS.formCityIsRequired),
  citizenship: yup
    .string()
    .min(3, FORM_TRANSLATIONS.formNationalityIsRequired)
    .required(FORM_TRANSLATIONS.formNationalityIsRequired),
  relationship: yup.string().required(FORM_TRANSLATIONS.formRelationshipIsRequired) 
});

export { recipientDetailsValidation };
