export const MONTHS = {
  '01': 'january',
  '02': 'february',
  '03': 'march',
  '04': 'april',
  '05': 'may',
  '06': 'june',
  '07': 'july',
  '08': 'august',
  '09': 'september',
  10: 'october',
  11: 'november',
  12: 'december'
};

export const reasonForSendingList = [
  { id: 1, reason: 'COMPUTER_SERVICES', value: 'COMPUTER_SERVICES' },
  { id: 2, reason: 'FAMILY_SUPPORT', value: 'FAMILY_SUPPORT' },
  { id: 3, reason: 'EDUCATION', value: 'EDUCATION' },
  { id: 4, reason: 'GIFT_AND_DONATION', value: 'GIFT_AND_DONATION' },
  { id: 5, reason: 'MEDICAL_TREATMENT', value: 'MEDICAL_TREATMENT' },
  { id: 6, reason: 'MAINTENANCE_EXPENSES', value: 'MAINTENANCE_EXPENSES' },
  { id: 7, reason: 'TRAVEL', value: 'TRAVEL' },
  { id: 8, reason: 'SMALL_VALUE_REMITTANCE', value: 'SMALL_VALUE_REMITTANCE' },
  { id: 9, reason: 'LIBERALIZED_REMITTANCE', value: 'LIBERALIZED_REMITTANCE' },
  { id: 10, reason: 'CONSTRUCTION_EXPENSES', value: 'CONSTRUCTION_EXPENSES' },
  { id: 11, reason: 'HOTEL_ACCOMMODATION', value: 'HOTEL_ACCOMMODATION' },
  { id: 12, reason: 'ADVERTISING_EXPENSES', value: 'ADVERTISING_EXPENSES' },
  { id: 13, reason: 'ADVISORY_FEES', value: 'ADVISORY_FEES' },
  { id: 14, reason: 'BUSINESS_INSURANCE', value: 'BUSINESS_INSURANCE' },
  { id: 15, reason: 'INSURANCE_CLAIMS', value: 'INSURANCE_CLAIMS' },
  { id: 16, reason: 'DELIVERY_FEES', value: 'DELIVERY_FEES' },
  { id: 17, reason: 'EXPORTED_GOODS', value: 'EXPORTED_GOODS' },
  { id: 18, reason: 'SERVICE_CHARGES', value: 'SERVICE_CHARGES' },
  { id: 19, reason: 'LOAN_PAYMENT', value: 'LOAN_PAYMENT' },
  { id: 20, reason: 'OFFICE_EXPENSES', value: 'OFFICE_EXPENSES' },
  { id: 21, reason: 'PROPERTY_PURCHASE', value: 'PROPERTY_PURCHASE' },
  { id: 22, reason: 'PROPERTY_RENTAL', value: 'PROPERTY_RENTAL' },
  { id: 23, reason: 'ROYALTY_FEES', value: 'ROYALTY_FEES' },
  { id: 24, reason: 'SHARES_INVESTMENT', value: 'SHARES_INVESTMENT' },
  { id: 25, reason: 'FUND_INVESTMENT', value: 'FUND_INVESTMENT' },
  { id: 26, reason: 'TAX_PAYMENT', value: 'TAX_PAYMENT' },
  { id: 27, reason: 'TRANSPORTATION_FEES', value: 'TRANSPORTATION_FEES' },
  { id: 28, reason: 'UTILITY_BILLS', value: 'UTILITY_BILLS' },
  { id: 29, reason: 'PERSONAL_TRANSFER', value: 'PERSONAL_TRANSFER' },
  { id: 30, reason: 'SALARY_PAYMENT', value: 'SALARY_PAYMENT' },
  { id: 31, reason: 'REWARD_PAYMENT', value: 'REWARD_PAYMENT' },
  { id: 32, reason: 'INFLUENCER_PAYMENT', value: 'INFLUENCER_PAYMENT' }
];

export const relationshipList = [
  { id: 1, relationship: 'PARENT', value: 'PARENT' },
  { id: 2, relationship: 'SPOUSE', value: 'SPOUSE' },
  { id: 3, relationship: 'SIBLING', value: 'SIBLING' },
  { id: 4, relationship: 'CHILD', value: 'CHILD' },
  { id: 5, relationship: 'FRIEND', value: 'FRIEND' },
  { id: 6, relationship: 'OTHER', value: 'OTHER' }
]

export const userCountry = {
    sweden: 'SWEDEN'
}

export const externalUrl = {
  BRITE_CLIENT_URL: 'https://client.britepaymentgroup.com/client.js'
}