/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TextfieldWrapper from '../../common/TextfieldWrapper';
import { PAYOUT_ACCOUNT_TYPES } from '../../../constants/payoutAccountTypes';
import HelpPopOver from '../../common/HelpPopOver';
import PhoneField from '../../common/PhoneField';

const useStyles = makeStyles((theme) => ({
  accountContainer: {
    borderRadius: theme.borderRadiusInput,
    padding: '20px 15px 10px',
    width: '100%',
    maxWidth: 370,
    border: '1px solid #21a1c5'
  },
  accountTypography: {
    textAlign: 'justify',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(26),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    padding: '8px 20px 10px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      fontSize: theme.typography.pxToRem(16)
    }
  },
  accountLabel: {
    width: 'max-content',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#4a6582',
    zIndex: 10
  }
}));

function RecipientPayoutAccount({ selectedRecipient, type, recipientPayer }) {
  const { t } = useTranslation(['dashboard-common']);
  const classes = useStyles();

  const help = (
    <span style={{ float: 'right' }}>
      <HelpPopOver content={<p>{t('ifYouWishToDeleteAPayoutAccount')}</p>} title={t('deletePayoutAccount')} />
    </span>
  );

  const typography = (label, account) => (
    <Grid item className={classes.accountContainer}>
      <Typography className={classes.accountLabel}>
        {t(label)}
        {help}
      </Typography>
      <Typography className={classes.accountTypography}>
        {`${recipientPayer?.phoneNumber ? '+' : ''}${account}`}
      </Typography>
    </Grid>
  );

  const mobileField = (label) => {
    if (!recipientPayer?.phoneNumber || !recipientPayer?.bankAccountNumber) {
      return (
        <>
          {recipientPayer?.phoneNumber || recipientPayer?.bankAccountNumber ? help : null}
          <PhoneField
            country={selectedRecipient.country}
            value={selectedRecipient.recipientPayoutAccount || ''}
            name="recipientPayoutAccount"
            label={`${t('add')} ${t(label).toLocaleLowerCase()}`}
          />
        </>
      );
    }
    return null;
  };

  useEffect(() => {}, [recipientPayer, selectedRecipient, type]);

  if (recipientPayer?.bankAccountNumber || recipientPayer?.phoneNumber) {
    if (PAYOUT_ACCOUNT_TYPES.MOBILE_WALLET.includes(type) || PAYOUT_ACCOUNT_TYPES.CASH_PICKUP.includes(type)) {
      return typography('payoutAccountPhoneNumber', recipientPayer?.phoneNumber);
    }
    return typography('payoutAccountPhoneNumber', recipientPayer?.bankAccountNumber);
  }
  if (PAYOUT_ACCOUNT_TYPES.MOBILE_WALLET.includes(type) || PAYOUT_ACCOUNT_TYPES.CASH_PICKUP.includes(type)) {
    return <>{mobileField('payoutAccountPhoneNumber')}</>;
  }
  return (
    <TextfieldWrapper
      value={selectedRecipient.recipientPayoutAccount || ''}
      label={`${t('add')} ${t('payoutAccountNumber').toLocaleLowerCase()}`}
      name="recipientPayoutAccount"
    />
  );
}

RecipientPayoutAccount.defaultProps = {
  recipientPayer: undefined,
  type: 'Mobile Wallet'
};

RecipientPayoutAccount.propTypes = {
  selectedRecipient: PropTypes.object.isRequired,
  type: PropTypes.string,
  recipientPayer: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default RecipientPayoutAccount;
