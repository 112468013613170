export const pepRolls = [
  { value: 'headOfStateOrGovernment' },
  { value: 'ministerOrDeputyMinister' },
  { value: 'memberOfParliament' },
  { value: 'memberOfCentralBank' },
  { value: 'boardMemberOfPoliticalParty' },
  { value: 'seniorOfficialOfAnAuditAuthority' },
  { value: 'ambassadorOrHeadOfMission' },
  { value: 'seniorOfficerInTheArmedForces' },
  { value: 'personsInManagementOrControlBodies' },
  { value: 'seniorMembersOfAnInternationalOrganization' },
  { value: 'judgesOfSupremeCourt' },
  { value: 'seniorExecutivesAndMembersOfGoverningBodies' }
];

export const pepFamilyRelation = [
  { value: 'spouseOfAPEP' },
  { value: 'registeredPartnerOfAPEP' },
  { value: 'liveWithAPEP' },
  { value: 'childOfAPEP' },
  { value: 'childsSpouseIsAPEP' },
  { value: 'parentOfAPEP' }
];

export const pepAssociateRelation = [
  { value: 'ownOrHaveInfluenceOverAcompanyWithPEP' },
  { value: 'ownOrHaveInfluenceOverAcompanyForBenefitOfAPEP' },
  { value: 'closeRelationshipWithAPEP' }
];


export const transferHabitItems = [
  { value: 'lessThanfiveThousandSEK' },
  { value: 'fiveToTenThousandSEK' },
  { value: 'moreThanTenThousandSEK' },
  { value: 'willNotSendRegularly' }
];

export const frequencyOfTransactionItems = [
  { value: 'atleastTwoTimesPerMonth' },
  { value: 'monthly' },
  { value: 'lessOftenThanOncePerMonth' }
];

export const userRecipientRelationshipItems = [
  { value: 'family' },
  { value: 'friends' },
  { value: 'other' }
];

export const originsOfFundsItems = [
  { value: 'salary' },
  { value: 'incomeSupport' },
  { value: 'ownBusinessActivity' },
  { value: 'giftOrInheritance' },
  { value: 'saleOfAssets' },
  { value: 'pension' },
  { value: 'otherIncome' }
];

/* export const pepOriginsOfFunds = [
  { value: 'salary' },
  { value: 'saleOfAssets' },
  { value: 'pension' },
  { value: 'investmentFunds' },
  { value: 'inheritance' },
  { value: 'fortune' },
  { value: 'gameWin' },
  { value: 'saving' },
  { value: 'other' }
]; */
