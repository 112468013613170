const headerRoutes = [
  {
    path: '#help-section',
    id: 'help-section',
    navbarName: 'Help',
    icon: 'fa fa-users',
    component: ''
  },
  {
    path: '#help-section-contact-button',
    id: 'help-section-contact-button',
    navbarName: 'Contact Us',
    icon: 'fas fa-exchange-alt',
    component: ''
  }
];

export default headerRoutes;
