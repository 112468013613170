import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const styles = makeStyles((theme) => ({
  confirmationMessage: {
      padding: theme.spacing(1),
      fontFamily: 'Inter',
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.78',
      letterSpacing: 'normal'
      /* color: '#4a6582' */
  }
}));


function ConfirmDialog({ handleContinue, handleCancel, open, item, message }) {
  const { t } = useTranslation(['dashboard-common']);
  const classes = styles();

  useEffect(() => {}, [open]);

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open session dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        transitionDuration={500}
      >
        <DialogTitle id="confirm-dialog-title">{item}</DialogTitle>
         <DialogContent>
          <DialogContentText id="confirm-dialog-description">
             <Typography className={classes.confirmationMessage}>{`${t('areYouSureUWantToDelete')} ${message}?`}</Typography>
          </DialogContentText>
        </DialogContent> 
        <DialogActions>
          <Button onClick={handleContinue} color="primary" autoFocus>
            {t('continue')}
          </Button>
          <Button onClick={handleCancel} color="primary">
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmDialog.defaultProps = {
  handleContinue: undefined,
  handleCancel: undefined,
  open: false,
  message: undefined,
  item: undefined
}

ConfirmDialog.propTypes = {
  handleContinue: PropTypes.func, 
  handleCancel: PropTypes.func, 
  open: PropTypes.bool,
  message: PropTypes.string,
  item: PropTypes.string
}
export default ConfirmDialog;
