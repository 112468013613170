import React from 'react';
import { Translation } from 'react-i18next';

const NS = ['dashboard-common', 'landing-page-common'];
const LANDING_PAGE = ['landing-page-common'];
export const FORM_TRANSLATIONS = {
  formSupportTypeIsRequired: <Translation ns={NS}>{(t) => t('formSupportTypeIsRequired')}</Translation>,
  formMessageIsRequired: <Translation ns={NS}>{(t) => t('formMessageIsRequired')}</Translation>,
  formTypeAValidMessage: <Translation ns={NS}>{(t) => t('formTypeAValidMessage')}</Translation>,
  formSelectACountry: <Translation ns={NS}>{(t) => t('formSelectACountry')}</Translation>,
  formEnterValidName: <Translation ns={NS}>{(t) => t('formEnterValidName')}</Translation>,
  formEnterValidFirstName: <Translation ns={NS}>{(t) => t('formEnterValidFirstName')}</Translation>,
  formNameMustBeXOrMore: (len) => 
    <Translation ns={NS}>{(t) => t('formNameMustBeXOrMore', { min: len.min })}</Translation>
  ,
  formOriginOfFundsTitle: <Translation ns={NS}>{(t) => t('originOfTransferFunds')}</Translation>,
  formSednMoneyToMultipleRecipientTitle: <Translation ns={NS}>{(t) => t('planToSednMoneyToMultipleRecipient')}</Translation>,
  formUserRecipientRelationshipTitle: <Translation ns={NS}>{(t) => t('formWhoWillYouSendMoneyTo')}</Translation>,
  formIntendedRecipientCountries: <Translation ns={NS}>{(t) => t('formIntendedRecipientCountries')}</Translation>,
  formFirsNameIsRequired: <Translation ns={NS}>{(t) => t('formFirsNameIsRequired')}</Translation>,
  formEnterValidLastName: <Translation ns={NS}>{(t) => t('formEnterValidLastName')}</Translation>,
  formLastNameIsRequired: <Translation ns={NS}>{(t) => t('formLastNameIsRequired')}</Translation>,
  formEnterValidEmail: <Translation ns={NS}>{(t) => t('formEnterValidEmail')}</Translation>,
  formEmailIsRequired: <Translation ns={NS}>{(t) => t('formEmailIsRequired')}</Translation>,
  formStreetMustBeXOrMore: <Translation ns={NS}>{(t) => t('formStreetMustBeXOrMore')}</Translation>,
  formStreetNameIsRequired: <Translation ns={NS}>{(t) => t('formStreetNameIsRequired')}</Translation>,
  formZipCodeMustBeXOrMore: <Translation ns={NS}>{(t) => t('formZipCodeMustBeXOrMore')}</Translation>,
  formZipCodeIsRequired: <Translation ns={NS}>{(t) => t('formZipCodeIsRequired')}</Translation>,
  formCityMustBeXOrMore: <Translation ns={NS}>{(t) => t('formCityMustBeXOrMore')}</Translation>,
  formCityIsRequired: <Translation ns={NS}>{(t) => t('formCityIsRequired')}</Translation>,
  formMinLengthSeven: <Translation ns={NS}>{(t) => t('formMinLengthSeven')}</Translation>,
  formMaxLength15: <Translation ns={NS}>{(t) => t('formMaxLength15')}</Translation>,
  formMaxLength: (length) => <Translation ns={NS}>{(t) => t('formMaxLength', { max: length })}</Translation>,
  formMinLength: (length) => <Translation ns={NS}>{(t) => t('formMinLength', { min: length })}</Translation>,
  formEnterValidMobileNumber: <Translation ns={NS}>{(t) => t('formEnterValidMobileNumber')}</Translation>,
  formEnterValidBankAccountNumber: <Translation ns={NS}>{(t) => t('formEnterValidBankAccountNumber')}</Translation>,
  formEnterValidPayoutAccountNumber: <Translation ns={NS}>{(t) => t('formEnterValidPayoutAccountNumber')}</Translation>,
  formPayoutAccountNumber: <Translation ns={NS}>{(t) => t('formPayoutAccountNumber')}</Translation>,
  formMobileNumberIsRequired: <Translation ns={NS}>{(t) => t('formMobileNumberIsRequired')}</Translation>,
  formAnswerYesOrNo: <Translation ns={NS}>{(t) => t('formAnswerYesOrNo')}</Translation>,
  formCountryMustBeXOrMore: <Translation ns={NS}>{(t) => t('formCountryMustBeXOrMore')}</Translation>,
  formCountryIsRequired: <Translation ns={NS}>{(t) => t('formCountryIsRequired')}</Translation>,
  formOrganizationIsRequired: <Translation ns={NS}>{(t) => t('formOrganizationIsRequired')}</Translation>,
  formTitleMustBeXOrMore: <Translation ns={NS}>{(t) => t('formTitleMustBeXOrMore')}</Translation>,
  formTitleIsRequired: <Translation ns={NS}>{(t) => t('formTitleIsRequired')}</Translation>,
  formAtleastOneRoleIsRequired: <Translation ns={NS}>{(t) => t('formAtleastOneRoleIsRequired')}</Translation>,
  formAtleastOneRelationshipIsRequired: 
    <Translation ns={NS}>{(t) => t('formAtleastOneRelationshipIsRequired')}</Translation>,
  formRelationshipIsRequired: <Translation ns={NS}>{(t) => t('formRelationshipIsRequired')}</Translation>,
  formPepFullNameMustBeXOrMore: <Translation ns={NS}>{(t) => t('formPepFullNameMustBeXOrMore')}</Translation>,
  formFullNameIsRequired: <Translation ns={NS}>{(t) => t('formFullNameIsRequired')}</Translation>,
  formOrganizationMustBeXOrMore: <Translation ns={NS}>{(t) => t('formOrganizationMustBeXOrMore')}</Translation>,
  formOragnizationIsRequired: <Translation ns={NS}>{(t) => t('formOragnizationIsRequired')}</Translation>,
  formDateOfBirthMustBeXOrMore: <Translation ns={NS}>{(t) => t('formDateOfBirthMustBeXOrMore')}</Translation>,
  formDateIsRequired: <Translation ns={NS}>{(t) => t('formDateIsRequired')}</Translation>,
  formEmail: <Translation ns={NS}>{(t) => t('formEmail')}</Translation>,
  formPhoneNumber: <Translation ns={NS}>{(t) => t('formPhoneNumber')}</Translation>,
  formFirstName: <Translation ns={NS}>{(t) => t('formFirstName')}</Translation>,
  formLastName: <Translation ns={NS}>{(t) => t('formLastName')}</Translation>,
  formStreet: <Translation ns={NS}>{(t) => t('formStreet')}</Translation>,
  formZipCode: <Translation ns={NS}>{(t) => t('formZipCode')}</Translation>,
  formCity: <Translation ns={NS}>{(t) => t('formCity')}</Translation>,
  formLocality: <Translation ns={NS}>{(t) => t('formLocality')}</Translation>,
  formCountry: <Translation ns={NS}>{(t) => t('formCountry')}</Translation>,
  formReceivingCountry: <Translation ns={NS}>{(t) => t('formReceivingCountry')}</Translation>,
  formNationality: <Translation ns={NS}>{(t) => t('formNationality')}</Translation>,
  formNationalityIsRequired: <Translation ns={NS}>{(t) => t('formNationalityIsRequired')}</Translation>,
  formNetworkMustbeSelected: <Translation ns={NS}>{(t) => t('formNetworkMustbeSelected')}</Translation>,
  formServiceMustbeSelected: <Translation ns={NS}>{(t) => t('formServiceMustbeSelected')}</Translation>,
  formPayerIsRequired: <Translation ns={NS}>{(t) => t('formPayerIsRequired')}</Translation>,
  formRecipientMustbeSelected: <Translation ns={NS}>{(t) => t('formRecipientMustbeSelected')}</Translation>,
  formReasonForSendingMustbeSelected: 
    <Translation ns={NS}>{(t) => t('formReasonForSendingMustbeSelected')}</Translation>
  ,
  formTransferHabitTitle: <Translation ns={NS}>{(t) => t('sendEachMonth')}</Translation>,
  formFrequencyOfTransactionTitle: <Translation ns={NS}>{(t) => t('frequencyOfTransaction')}</Translation>,
  formFrequencyOfTransactionRequired: <Translation ns={NS}>{(t) => t('frequencyOfTransactionRequired')}</Translation>,
  formCountriesToSendTo: <Translation ns={NS}>{(t) => t('countriesToSendTo')}</Translation>,
  formTransferHabitRequired: <Translation ns={NS}>{(t) => t('optionThatBestDescribes')}</Translation>,
  formMustBeFilledIn: <Translation ns={NS}>{(t) => t('mustBeFilledIn')}</Translation>,
  dateNotGreaterThanToday: <Translation ns={NS}>{(t) => t('dateNotGreaterThanToday')}</Translation>,
  pep: <Translation ns={NS}>{(t) => t('pep')}</Translation>,
  familyMemberOfPep: <Translation ns={NS}>{(t) => t('familyMemberOfPEP')}</Translation>,
  isAssociateOfPep: <Translation ns={NS}>{(t) => t('associateOfPEP')}</Translation>,
  headOfStateOrGovernment: <Translation ns={NS}>{(t) => t('headOfStateOrGovernment')}</Translation>,
  ministerOrDeputyMinister: <Translation ns={NS}>{(t) => t('ministerOrDeputyMinister')}</Translation>,
  memberOfParliament: <Translation ns={NS}>{(t) => t('memberOfParliament')}</Translation>,
  memberOfCentralBank: <Translation ns={NS}>{(t) => t('memberOfCentralBank')}</Translation>,
  boardMemberOfPoliticalParty: <Translation ns={NS}>{(t) => t('boardMemberOfPoliticalParty')}</Translation>,
  seniorOfficialOfAnAuditAuthority: <Translation ns={NS}>{(t) => t('seniorOfficialOfAnAuditAuthority')}</Translation>,
  ambassadorOrHeadOfMission: <Translation ns={NS}>{(t) => t('ambassadorOrHeadOfMission')}</Translation>,
  seniorOfficerInTheArmedForces: <Translation ns={NS}>{(t) => t('seniorOfficerInTheArmedForces')}</Translation>,
  personsInManagementOrControlBodies: 
    <Translation ns={NS}>{(t) => t('personsInManagementOrControlBodies')}</Translation>
  ,
  seniorMembersOfAnInternationalOrganization: 
    <Translation ns={NS}>{(t) => t('seniorMembersOfAnInternationalOrganization')}</Translation>
  ,
  judgesOfSupremeCourt: <Translation ns={NS}>{(t) => t('judgesOfSupremeCourt')}</Translation>,
  seniorExecutivesAndMembersOfGoverningBodies: 
    <Translation ns={NS}>{(t) => t('seniorExecutivesAndMembersOfGoverningBodies')}</Translation>
  ,
  spouseOfAPEP: <Translation ns={NS}>{(t) => t('spouseOfAPEP')}</Translation>,
  registeredPartnerOfAPEP: <Translation ns={NS}>{(t) => t('registeredPartnerOfAPEP')}</Translation>,
  liveWithAPEP: <Translation ns={NS}>{(t) => t('liveWithAPEP')}</Translation>,
  childOfAPEP: <Translation ns={NS}>{(t) => t('childOfAPEP')}</Translation>,
  childsSpouseIsAPEP: <Translation ns={NS}>{(t) => t('childsSpouseIsAPEP')}</Translation>,
  parentOfAPEP: <Translation ns={NS}>{(t) => t('parentOfAPEP')}</Translation>,
  ownOrHaveInfluenceOverAcompanyWithPEP: 
    <Translation ns={NS}>{(t) => t('ownOrHaveInfluenceOverAcompanyWithPEP')}</Translation>
  ,
  ownOrHaveInfluenceOverAcompanyForBenefitOfAPEP: 
    <Translation ns={NS}>{(t) => t('ownOrHaveInfluenceOverAcompanyForBenefitOfAPEP')}</Translation>
  ,
  closeRelationshipWithAPEP: <Translation ns={NS}>{(t) => t('closeRelationshipWithAPEP')}</Translation>,
  lessThanfiveThousandSEK: <Translation ns={NS}>{(t) => t('lessThanfiveThousandSEK')}</Translation>,
  fiveToTenThousandSEK: <Translation ns={NS}>{(t) => t('fiveToTenThousandSEK')}</Translation>,
  moreThanTenThousandSEK: <Translation ns={NS}>{(t) => t('moreThanTenThousandSEK')}</Translation>,
  willNotSendRegularly: <Translation ns={NS}>{(t) => t('willNotSendRegularly')}</Translation>,
  originOfTransferFunds: <Translation ns={NS}>{(t) => t('originOfTransferFunds')}</Translation>,
  selectOneOption: <Translation ns={NS}>{(t) => t('selectOneOption')}</Translation>,
  pnrHelperText: <Translation ns={LANDING_PAGE}>{(t) => t('pnrInputHelperText')}</Translation>,
  enterAValidPin: <Translation ns={LANDING_PAGE}>{(t) => t('enterAValidPin')}</Translation>,
  acceptTemsAndConditions: <Translation ns={LANDING_PAGE}>{(t) => t('termsAndConditionsInfoMessage')}</Translation>
};
