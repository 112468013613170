const termsAppendixA = [
  {
    id: 21,
    title: 'Appendix A',
    text: [
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      },
      {
        textSubText: '',
        indent: true
      }
    ],
    readMore: '',
    readMoreUrl: ''
  }
];

export default termsAppendixA;
