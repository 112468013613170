import React, { useRef, useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, Divider, Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import LoadingSmall from '../common/LoadingSmall';
import { InputErrorDisplay } from '../common/ErrorDisplay';
import Title from '../common/Title';
import { payerLogo } from './payerLogo';
import {
  setGlobalIsOpenMtfPriceDetails,
  setGlobalMaxTransactionAmountForSelectedDeliveryMethod,
  setGlobalSelectedDeliveryMethod
} from '../../store/redux/actions/moneyTransferBoxAction';
import convertAmount from './convertAmount';
import { moneyTransferBoxInitialValues } from '../../store/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'start',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
/*       background: '#F8FAFC',
      padding: theme.spacing(2) */
    }
  },
  inputAltStyle: {
    borderRadius: theme.borderRadiusInput,
    backgroundColor: '#eef3f9',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    padding: theme.spacing(2)
  },
  title: {
    margin: '10px'
  },
  menuItemText: {
    textTransform: 'capitalize',
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#4a6582',
    opacity: 0.8,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  avatar: {
    margin: '5px',
    '&.MuiAvatar-colorDefault': {
      backgroundColor: 'none'
    }
  },
  payerImg: {
    width: 'auto',
    height: 50,
    objectFit: 'fill',
    borderRadius: 10,
    border: '1px solid #00acc1',
    padding: '1px',
    background: '#ffffff'
  },
  label: {
    marginBottom: '-8px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#4a6582',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  mainButton: {
    marginLeft: '-11px',
    maxWidth: 335,
    [theme.breakpoints.down('sm')]: {
      padding: 13,
      marginLeft: '-15px'
    }
  },
  mainButtonAlt: {
    borderRadius: 5,
    justifyContent: 'start',
    width: '100%',
    minWidth: 'auto',
    margin: '-7px',
    background: 'aliceblue',
    maxWidth: 'fit-content',
    textAlign: 'left'
  },
  menuItem: {
    width: 'auto',
    maxWidth: 440,
    height: 'auto',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  deliveryMethod: {
    width: 'max-content',
    padding: '5px 6px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    textTransform: 'none',
    textAlign: 'left',
    color: theme.palette.globalAppTheme.globalAppTextColor,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  deliveryMethodAlt: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: 'normal',
    color: theme.palette.globalAppTheme.globalAppTextColor,
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16),
      width: 'auto'
    }
  },
  deliveryMethodMenu: {
    '& div': {
      width: 'auto'
    }
  }
}));

function PayerSelect({ isInputStyle, altStyle, logo, label }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('landing-page-common');
  const buttonReference = useRef(null);
  const [anchorElement, setAnchorElement] = useState(null);
  const {
    recipientCountryOptionValue,
    deliveryMethods,
    selectedDeliveryMethod,
    amount,
    amountToReceive,
    conversionMode
  } = useSelector((state) => state.moneyTransfer);
  const [deliveryMethod, setDeliveryMethod] = useState(selectedDeliveryMethod?.name || t('noPayers'));
  const { error: appError, loading: appLoading } = useSelector((state) => state.app);
  const { getConvertedRate } = convertAmount();

  const handleMenuButtonClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuItemClick = (e, method) => {
    const listItemClickEvent = new CustomEvent('payer-change', {
      detail: method,
      bubbles: true
    });
    e.target.dispatchEvent(listItemClickEvent);
    const amountToSend = conversionMode === moneyTransferBoxInitialValues.SOURCE_AMOUNT ? amount : amountToReceive;

    setDeliveryMethod(method.name);
    getConvertedRate(amountToSend, method?.id, conversionMode);
    dispatch(setGlobalSelectedDeliveryMethod(method));
    dispatch(
      setGlobalMaxTransactionAmountForSelectedDeliveryMethod({
        maxAmount: method?.maxTransaction,
        minAmount: method?.minTransaction,
        currency: method?.currency,
        name: method?.name
      })
    );
    dispatch(setGlobalIsOpenMtfPriceDetails(true));
    setAnchorElement(null);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const displayDefaultMenuItem = () => {
    if (appError) {
      return <InputErrorDisplay message={t('defaultErrorMessage')} />;
    }
    if (appLoading) {
      return <LoadingSmall />;
    }
    return (
      <>
        {logo ? (
          <Avatar alt={deliveryMethod} className={classes.avatar}>
            <img
              height="40"
              width="40"
              className={classes.payerImg}
              src={payerLogo(selectedDeliveryMethod?.name)}
              alt={`${selectedDeliveryMethod?.name} Logo`}
            />
          </Avatar>
        ) : null}
        <span className={altStyle ? classes.deliveryMethodAlt : classes.deliveryMethod}>
          {selectedDeliveryMethod?.name}
        </span>
        <span style={{ marginLeft: 16, display: 'flex' }}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style">
            <path d="M19 9l-7 7-7-7" />
          </svg>
        </span>
      </>
    );
  };

  const inActivePayer = () => <span style={{ display: 'inline-flex', width: '100%', gap: 10 }}>
    <span className='serivice-unavailable-icon'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-6 h-6 svg-icon-style">
      <path d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
    </svg></span>{' '}
    <span className='serivice-unavailable-message'>{t('serviceNotAvailable', { type: t('payer') })}</span>
  </span>

  useEffect(() => {}, [deliveryMethod, deliveryMethods, recipientCountryOptionValue, selectedDeliveryMethod]);

  return (
    <div className={classNames(classes.root, isInputStyle ? classes.inputAltStyle : null)}>
      {label ? <Typography className={classes.label}>{t('payer')}</Typography> : null}
      <Button
        id={`selected-${_kebabCase(deliveryMethod)}`}
        className={altStyle ? classes.mainButtonAlt : classes.mainButton}
        ref={buttonReference}
        aria-label="delivery-method-button"
        aria-controls={`${_kebabCase(deliveryMethod)}-menu`}
        aria-haspopup="true"
        onClick={handleMenuButtonClick}
        disabled={appError || !selectedDeliveryMethod?.name}
      >
        {displayDefaultMenuItem()}
      </Button>
      <Menu
        disableScrollLock
        className={classes.deliveryMethodMenu}
        id={`${_kebabCase(deliveryMethod)}-menu`}
        aria-label="delivery-method-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
      /*         disableScrollLock */
      >
        {deliveryMethods.length > 0 &&
          deliveryMethods
            .filter((payer) => payer.type === selectedDeliveryMethod?.type)
            .map((payer) => (
              <div key={payer.id}>
                <MenuItem
                  disabled={!payer.isActive}
                  id={`${kebabCase(deliveryMethod)}-menu-item-${payer.type}`}
                  onClick={(e) => handleMenuItemClick(e, payer)}
                  value={payer.name}
                  key={payer.id}
                  className={classNames(classes.menuItem, 'menu-item-delivery-method')}
                >
                  <img className={classes.payerImg} src={payerLogo(payer.name)} alt={`${payer.name} Logo`} />
                  <div className={classes.title}>
                    <Title subtitle={payer.isActive ? `${t('payoutIn')}: ${payer.currency}` : inActivePayer()}>{payer.name}</Title>
                  </div>
                </MenuItem>
                {deliveryMethods.length > 1 ? <Divider variant="middle" style={{ height: 1 }} /> : null}
              </div>
            ))}
      </Menu>
    </div>
  );
}

PayerSelect.defaultProps = {
  isInputStyle: false,
  logo: false,
  label: true,
  altStyle: false
};

PayerSelect.propTypes = {
  isInputStyle: PropTypes.bool,
  logo: PropTypes.bool,
  label: PropTypes.bool,
  altStyle: PropTypes.bool
};

export default PayerSelect;
