import { ACTION_TYPES } from '../ReduxActionTypes';

export const setGlobalUser = (user) => ({
  type: ACTION_TYPES.SET_USER,
  payload: user
});

export const setTransaction = (transaction) => ({
  type: ACTION_TYPES.SET_TRANSACTION,
  payload: transaction
});

export const setTransactionStats = (stats) => ({
  type: ACTION_TYPES.SET_TRANSACTION_STATS,
  payload: stats
});
