import React from 'react';
import { Translation } from 'react-i18next';

const NS = ['contact-page'];

const supportTypes = [
/*   {
    label: <Translation ns={NS}>{(t) => t('login')}</Translation>,
    value: 'LOGIN'
  },
  {
    label: <Translation ns={NS}>{(t) => t('transaction')}</Translation>,
    value: 'TRANSACTION'
  },
  {
    label: <Translation ns={NS}>{(t) => t('signUp')}</Translation>,
    value: 'SIGNUP'
  },
  {
    label: <Translation ns={NS}>{(t) => t('service')}</Translation>,
    value: 'GENERALLY'
  } */
  {
    label: <Translation ns={NS}>{(t) => t('service')}</Translation>,
    value: 'SERVICE'
  },
  {
    label: <Translation ns={NS}>{(t) => t('account')}</Translation>,
    value: 'ACCOUNT'
  },
  {
    label: <Translation ns={NS}>{(t) => t('payment')}</Translation>,
    value: 'PAYMENT'
  },
  {
    label: <Translation ns={NS}>{(t) => t('fraud')}</Translation>,
    value: 'FRAUD'
  },
  {
    label: <Translation ns={NS}>{(t) => t('other')}</Translation>,
    value: 'OTHER'
  }
];

export default supportTypes;
