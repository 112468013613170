/* eslint-disable no-console */
import { useSelector, useDispatch } from 'react-redux';

import {
  setGlobalIsOpenMtfPriceDetails,
  setGlobalDeliveryMethods,
  setGlobalSelectedDeliveryMethod,
  setGlobalMaxTransactionAmountForSelectedDeliveryMethod,
  setGlobalConvertedAmount
} from '../../store/redux/actions/moneyTransferBoxAction';
import convertAmount from './convertAmount';

export const dispatchPayersForRecipientCountry = () => {
  const dispatch = useDispatch();
  const { amount, convertedAmount } = useSelector((state) => state.moneyTransfer);
  const { getConvertedRate } = convertAmount();
 
  const dispatchPayers = (payers) => {
    const activePayer = payers.find((p) => p.isActive)
    if (payers.length > 0 && activePayer?.id) {

      getConvertedRate(amount, activePayer?.id);
      dispatch(setGlobalDeliveryMethods(payers));
      
      dispatch(setGlobalSelectedDeliveryMethod(activePayer));
      dispatch(
        setGlobalMaxTransactionAmountForSelectedDeliveryMethod({
          maxAmount: activePayer?.maxTransaction,
          minAmount: activePayer?.minTransaction,
          currency: activePayer?.currency,
          name: activePayer.name
        })
      );
    } else {
      dispatch(setGlobalDeliveryMethods([]));
      if(payers.every((item) => !item.isActive)) {
        dispatch(setGlobalSelectedDeliveryMethod({ type: 'deliveryMethodNotAvailableForThisPayer' }));
      } else {
        dispatch(setGlobalSelectedDeliveryMethod({ type: 'missingDeliveryMethod', empty: true }));
      }
      
      dispatch(setGlobalMaxTransactionAmountForSelectedDeliveryMethod(null));
      dispatch(
        setGlobalConvertedAmount(
          Object.assign(convertedAmount, { ...convertedAmount, rate: '', totalToPay: '', convertedAmount: '' })
        )
      );
      dispatch(setGlobalIsOpenMtfPriceDetails(false));
    }
  };

  return dispatchPayers;
};