const policy = [
  {
    id: 1,
    title: 'Our Service',
    text: [
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ],
    readMore: '',
    readMoreUrl: ''
  },
  {
    id: 2,
    title: 'Our Company',
    text: [
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ],
    readMore: '',
    readMoreUrl: ''
  },
  {
    id: 3,
    title: 'Our Team',
    text: [
      {
        textSubText: '',
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ],
    readMore: '',
    readMoreUrl: ''
  }
];

export default policy;
