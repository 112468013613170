import React from 'react';
import {/*  Box, */ Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import SectionTemplate from '../../sections/SectionTemplate';
import AgreementAmico1 from '../../../../assets/img/Group.svg';
import SectionPolicyContentTemplate from '../../sections/SectionPolicyContentTemplate';
import BridgingBordersImg from '../../../../assets/img/bridging-borders-with-brand.jpg';
import about from './about';
/* import ProfileCard from './ProfileCard';
import FeatureFlag from '../../../feature-flag/FeatureFlag'; */

const style = makeStyles((theme) => ({
  container: {
    justifyContent: 'center', 
    display: 'inline-block', 
    width: '100%', 
    position: 'relative'
  },
  title: {
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(38),
    fontWeight: 500,
    zIndex: 100,
    position: 'absolute',
    padding: theme.spacing(3),
    width: '100%',
    color: '#fff',
    textAlign: 'left',
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'inherit',
      maxWidth: 'fit-content',
      padding: theme.spacing(1)
    }
  },
  img: {
    height: '100%', 
    width: '100%'
  }
}));

function CompanyAndTeam() {
  const classes = style();
  const { t } = useTranslation(['company-team-page']);
  return (
    <SectionTemplate
      sectionId="company-and-team-section"
      sectionImg={AgreementAmico1}
      sectionImgTitle="Agreement"
      sectionTitle="About Us"
      sectionSubTitle="sectionSubTitle"
      pathName="company-and-team"
      translation="company-team-page"
    >
      <div className={classes.container}>
        <Typography className={classes.title}>{t('bridgingBordersAndEnablingConnections')}</Typography>
        {/* <Typography style={{ color: '#000', fontFamily: 'Rubik', fontSize: 18, gridColumn: 1, gridRow: 2, zIndex: 100, position: 'relative', bottom: 120, padding: 20 }}>{t('joinUsOnThisJourney')}</Typography> */}
        <img
          className={classes.img}
          src={BridgingBordersImg}
          width="auto"
          height="auto"
          alt="Bridgin Borders"
        />
      </div>
      <SectionPolicyContentTemplate translation="company-team-page" policy={about} />
        {/* <FeatureFlag component={<ProfileCard />} fallbackComponent={null} name="team-profilecard-feature"/> */}
    </SectionTemplate>
  );
}

CompanyAndTeam.propTypes = {};

export default CompanyAndTeam;
