/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import { Grid, Dialog, Typography, Box, Link , Alert } from '@mui/material';
import Button from '@mui/material/Button';

import { withRouter } from '../../hooks/withRouter';
import CommonDialogTitle from '../common/DialogTitle';
import CustomButton from '../common/Button';
import API from '../../utils/api';
import { setGlobalUser } from '../../store/redux/actions/userAction';
import { FORM_TRANSLATIONS } from '../dashboard/formTranslations';
import { setGlobalLoginModalVisible, setGlobalIsGetStartedLogin } from '../../store/redux/actions/loginModalAction';
import Loading from '../common/Loading';
import BankIdSvgWhite from '../common/BankIdSvgWhite';
import loginToSendModalStyle from './loginToSendModalStyle';
import PERMALINKS from '../../constants/permalinks';
import InfoMessageChecklist from '../common/InfoMessageChecklist';
import CheckboxPlain from '../common/CheckboxPlain';
import useDevice from '../../hooks/useDevice';
import ScanQR from './ScanQR';
import { BANKID_PROCESS_INFO_DESKTOP, BANKID_PROCESS_INFO_MOBILE } from './constants';
import { errorHandler } from '../../utils/errorMapper';
import Title from '../common/Title';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    overflow: 'visible'
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogActions);

const useStyles = loginToSendModalStyle;

const PINSchema = Yup.object().shape({
  termsConsent: Yup.boolean().oneOf([true], FORM_TRANSLATIONS.acceptTemsAndConditions).default(true)
});

const createReturnUrl = (device) => {
  // Here we create the URL that the BankID app should open when the authentication
  // is completed. On iOS all links open in Safari, even when another browser is
  // set as the default browser. So here we check if we're in another browser and
  // if so we replace the protocol with one provided by the specific browser so we
  // know it opens correctly. In some browsers we need to specify the full URL, in
  // some we can just say "Go back to Chrome".
  if (device.isChromeOnAppleDevice || device.isChrome) {
    return encodeURIComponent('googlechrome://');
  }
  if (device.isFirefoxOnAppleDevice) {
    return encodeURIComponent('firefox://');
  }
  if (device.isOperaTouchOnAppleDevice) {
    return encodeURIComponent(`${window.location.href.replace('http', 'touch-http')}#initiated=true`);
  }

  return encodeURIComponent(`${window.location.href}#initiated=true`);
};

function LoginToSendModal() {
  const history = useNavigate();
  const classes = useStyles();
  const device = useDevice();
  const controller = new AbortController();
  const { signal } = controller;
  const { t } = useTranslation(['landing-page-common']);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(useSelector((state) => state.loginModal.loginModalVisible));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [bankidAutoStartLink, setBankidAutoStartLink] = useState(null);
  const [bankidTransactionId, setBankidTransactionId] = useState();
  const { isMobileOrTablet } = device;
  const [inProgress, setInProgress] = useState(false);
  const [bankidQRData, setBankidQRData] = useState();
  const [bankidHint, setBankidHint] = useState();

  const login = (_user) => {
    dispatch(setGlobalUser(_user));
    dispatch(setGlobalLoginModalVisible(false));
    const token = Cookies.get('authentication');
    localStorage.setItem('authentication', token);
    history('/dashboard');
  }

  const pollBankIdStatus = async (transactionId) => {
    setLoading(true);
    try {
      const bankidData = await API.post('/login', { transactionId }, { headers: { 'login-type': 'bankid' } }, { signal });
      const { qrData, processStatusInfo } = bankidData.data;
      if (isMobileOrTablet) {
        setBankidHint(BANKID_PROCESS_INFO_MOBILE[processStatusInfo])
      }
      setBankidHint(BANKID_PROCESS_INFO_DESKTOP[processStatusInfo])
      setInProgress(true);
      setBankidQRData(qrData);
      if (bankidData.data.personalIdentityNumber) {
        setInProgress(false);
        login(bankidData.data);
      }
    } catch (_error) {
      const cancledMappedError = {
        name: 'CanceledError',
        type: 'warning',
        origin: _error,
        message: 'Authentication manually cancelled by the user',
        code: 400
      };
      if (_error.name === 'CanceledError') {
        setError(cancledMappedError);
      } else {
        setError(_error);
      }
      setLoading(false);
      controller.abort();
    }
  }

  const getLoginToken = async () =>
    API.get('/login-token', { signal })
      .then((response) => {
        const { autoStartToken, transactionId } = response.data;
        setBankidTransactionId(transactionId);

        return autoStartToken;
      })
      .catch((_error) => {
        setError(_error);
        setLoading(false);
        controller.abort();
      });

  const generateBankIDAutoStartLink = async () => {
    const autoStartToken = await getLoginToken();
    if (isMobileOrTablet) {
      const redirectUrl = createReturnUrl(device);
      window.location.href = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=${redirectUrl}`
    }
    return `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
  }

  const handleSubmit = async () => {
    setError(undefined);
    setLoading(true);
    const url = await generateBankIDAutoStartLink();
    setBankidAutoStartLink(url);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setLoading(false);
    setError(undefined);
    setBankidTransactionId(undefined);
    setInProgress(false);
    setOpen(false);
    dispatch(setGlobalLoginModalVisible(false));
    dispatch(setGlobalIsGetStartedLogin(false));
  };

  const handleAbort = () => {
    setLoading(false);
    setBankidQRData(undefined);
    setBankidTransactionId(undefined);
    setInProgress(false);
    setBankidHint(undefined);
    setError(undefined);
    controller.abort();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (bankidTransactionId) {
        pollBankIdStatus(bankidTransactionId);
      }
    }, 1500);

    if (error || signal?.aborted) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    }
  }, [open, bankidAutoStartLink, bankidQRData, inProgress, error, loading, signal]);


  return (
    <Dialog
      id="login-modal-dialog"
      classes={{ paper: classes.dialogPaper }}
      onClose={handleClose}
      aria-labelledby="login-modal-dialog-title"
      /*  TransitionComponent={Transition} */
      disableScrollLock
      keepMounted
      open={useSelector((state) => state.loginModal.loginModalVisible)}
      transitionDuration={500}
      fullWidth
    >
      <CommonDialogTitle
        title={t('login')}
        subTitle={t('loginModalTitle')}
        onClose={handleClose}
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style" viewBox="0 0 24 24">
            <path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
          </svg>
        }
      />
      <DialogContent dividers className={classes.dialogContent}>
        <Grid container className={classes.mainGridContainer}>
          <Grid item xs={12} sm={12}>
            {!loading && <Title subtitle={<small className={classes.dialogSubTitle}>
              {t('loginModalSubTitle1')}
              <a
                href={PERMALINKS.BANKID_SUPPORT}
                target="_blank"
                rel="noreferrer"
                className={classes.expandedLinkText}
              >
                {' '}
                {t('loginModalSubTitle2')}
              </a>
            </small>}>{t('identifyWithBankID')}</Title>}
            {!!error && <Alert severity={error?.type || 'error'}>{errorHandler(error)}</Alert>}
            {loading ? (
              <div style={{ textAlign: 'center' }}>
                <Loading
                  style={{ padding: 0 }}
                  message={
                    <span style={{ display: 'inline-flex' }}>
                      {bankidHint && <Loading size={15} style={{ padding: 0 }} />}&nbsp;{t(bankidHint)}
                    </span>
                  }
                  title={t('identifyWithBankID')}
                  subtitle={bankidQRData && !isMobileOrTablet ? t('scanTheQRCode') : null}
                  size={bankidQRData ? 0 : 50}
                >
                  <Box>
                    {inProgress && bankidAutoStartLink && !isMobileOrTablet ?
                      <Box justifyContent='center'>
                        <ScanQR qrCode={bankidQRData} />
                        <Button
                          className={classes.bankidAutostartLink}
                          tabIndex={0}
                        >
                          <Link className='bankid-autostart-link' style={{ fontSize: 'large', textDecoration: 'none' }} href={bankidAutoStartLink} alt={t('openBankIDOnThisDevice')}>{t('openBankIDOnThisDevice')}</Link>
                        </Button>
                      </Box> :
                      null}
                  </Box>
                </Loading>
              </div>
            ) : (
              <Formik
                initialValues={{
                  termsConsent: true
                }}
                onSubmit={handleSubmit}
                validationSchema={PINSchema}
              >
                {() => (
                  <Form>
                    <InfoMessageChecklist
                      title={t('byClickingContinueYouAgree')}
                      listItems={[
                        { text: t('accurateInformationItem'), element: null },
                        { text: t('onlySendMoneyToPeopleYouKnowItem'), element: null },
                        { text: t('onlySendOwnMoney'), element: null }
                      ]} />
                    <Field
                      label={
                        <Typography paragraph gutterBottom className={classes.termsAndConditionsConcent}>
                          {t('termsAndConditions1')}{' '}
                          <a
                            href="/terms-and-conditions"
                            target="_blank"
                            rel="noreferrer"
                            className={classes.expandedLinkText}
                          >
                            {t('termsAndConditions2')}
                          </a>{' '}
                          {t('termsAndConditions3')}{' '}
                          <a
                            href={PERMALINKS.PRIVACY_POLICY}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.expandedLinkText}
                          >
                            {t('termsAndConditions4')}
                          </a>
                        </Typography>
                      }
                      id="termsConsent"
                      className="terms-conditions-checkbox"
                      name="termsConsent"
                      component={CheckboxPlain}
                    />
                    <Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
                      <CustomButton
                        type="submit"
                        id="login-modal-continue-button"
                        className={classes.finishLoginButton}
                        style={{ width: '100%', fontSize: '1rem' }}
                        color="transparent"
                        size="md"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span style={{ fontSize: '1.3rem' }}><BankIdSvgWhite />&nbsp;{t('loginWithBankId')}</span>
                      </CustomButton>
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs className={classes.loginModalCancelButtonGrid}>
            {loading ?
              <Button className={classes.loginModalCancel} onClick={handleAbort}>
                {t('loginModalCancelButton')}
              </Button> :
              <Button className={classes.loginModalCancel} onClick={handleClose}>
                {t('close')}
              </Button>
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default withRouter(LoginToSendModal);