const PERMALINKS = {
  HOME: '/',
  GET_STARTED: '/get-started',
  CONTACT_US: '/contact-us',
  COOKIE_POLICY: '/cookie-policy',
  COMPANY_AND_TEAM: '/company-and-team',
  WHATS_NEW_IN_VIVOWIRE: '/whats-new-in-vivowire',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  HELP_CENTER: '/help-center',
  MID_MARKET_RATE: '/mid-market-rate',
  DASHBOARD_PROFILE: '/dashboard/profile',
  APP_STORE: 'https://www.apple.com/app-store/',
  GOOGLE_PLAY: 'https://play.google.com/',
  BANKID_SUPPORT: 'https://support.bankid.com/sv/'
};

export default PERMALINKS;
