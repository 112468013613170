import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import _isEmpty from 'lodash/isEmpty';
import InputFeedback from './InputFeedback';

const style = makeStyles((theme) => ({
  container: {
    textAlign: 'left',
    borderBottom: '2px solid #fff',
    borderRadius: theme.borderRadius
  },
  children: {
    display: 'grid',
    rowGap: 8,
    gridTemplateColumns: '1fr',
    textAlign: 'left',
    justifyContent: 'left',
    paddingTop: 0
  }
}));

function RadioButtonGroup({ value, error, touched, label, className, children, customStyle }) {
  const localClasses = style();
  const classes = classNames(
    'input-field',
    {
      'is-success': value || (!error && touched), // handle prefilled or user-filled
      'is-error': !!error && touched
    },
    localClasses.container,
    className
  );

  return (
    <div className={classes}>
      <div>{label}</div>
      <div className={classNames(customStyle || localClasses.children)}>{children}</div>
      {touched && !_isEmpty(error) ? <InputFeedback error={error} /> : null}
    </div>
  );
}

RadioButtonGroup.defaultProps = {
  value: undefined,
  error: null,
  touched: undefined,
  label: undefined,
  className: undefined,
  children: undefined,
  customStyle: undefined
};

RadioButtonGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.shape(PropTypes.object)]),
  touched: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.shape(PropTypes.object)]),
  className: PropTypes.shape(PropTypes.object),
  children: PropTypes.node,
  customStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(PropTypes.object)])
};

InputFeedback.defaultProps = {
  error: null
};

InputFeedback.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node])
};

export default RadioButtonGroup;