import React, { useEffect } from 'react';
import { Button, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalIsOpenMtfPriceDetails } from '../../store/redux/actions/moneyTransferBoxAction';

const styles = makeStyles((theme) => ({
  button: {
    height: '100%',
    color: '#fff',
    fontSize: '1.5em',
    borderRadius: '0px 10px 10px 0px',
    backgroundColor: '#21a1c5',
    backgroundImage: 'linear-gradient(to right, #19cac3, #1569af)',
    animationDuration: '3s', 
    animationFillMode: 'both',
    animation: 'bounce', 
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRadius: 10,
      display: 'block'

    }
  },
  "@-webkit-keyframes bounce": {
    "0%, 20%, 50%, 80%, 100%": { 
      WebkitTransform: "translateY(0)" 
    },
    "40%": { 
      WebkitTransform: "translateY(-30px)" 
    },
    "60%": { 
      WebkitTransform: "translateY(-15px)" 
    }
  },
  "@keyframes bounce": {
    "0%, 20%, 50%, 80%, 100%": { transform: "translateY(0)" },
    "40%": { transform: "translateY(-30px)" },
    "60%": { transform: "translateY(-15px)" }
  },
  container: {
    height: '100%',
    paddingLeft: 37,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      padding: theme.spacing(2)
    }
  }
}));

function ExpandMtfPriceDetailsButton() {
  const classes = styles();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.app);
  const { isOpenMtfPriceDetails } = useSelector((state) => state.moneyTransfer);

  const handleClick = () => {
    dispatch(setGlobalIsOpenMtfPriceDetails(true));
  };

  useEffect(() => {}, []);

  return (
    <Fade in={!isOpenMtfPriceDetails}>
      <div className={classes.container}>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        className={classes.button}
        aria-label="expand-price-details-button"
        disabled={!!error || loading}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 svg-icon-style-white">
          <path d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
        </svg>
      </Button>
</div>
    </Fade>
  );
}

export default ExpandMtfPriceDetailsButton;
