import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle , Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { errorHandler, getErrorTitle } from '../../utils/errorMapper';
import TechSupportLink from './TechSupportLink';
import ReloadPageLink from './ReloadPageLink';

const useStyles = makeStyles((theme) => ({
  errorAlert: {
    filter: 'drop-shadow(3px 3px 3px rgb(0 0 0 / 0.1))',
    position: 'sticky',
    zIndex: 10,
    background: '#df5454',
    borderRadius: 4,
    color: '#fff',
    fontSize: theme.typography.pxToRem(22),
    '& .MuiAlert-icon': {
      color: '#fff',
      fontSize: 36,
      margin: 10
    },
    '& .MuiAlert-message': {
      margin: '0 0'
    }
  },
  errorText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff',
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18),
      padding: 0,
      width: 'auto'
    }
  },
  alertLink: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      padding: 0,
      width: 'auto'
    }
  }
}));
function GlobalErrorAlert({ title, error, style }) {
  const classes = useStyles();
  return (
    <Box component="div">
      <Alert className={classes.errorAlert} severity={error?.type || 'error'} style={{ ...style, background: error?.type === 'warning' ? '#ed6c02' : '#df5454' }} >
        {title ? <AlertTitle>{error && getErrorTitle(error)}</AlertTitle> : null}
        <Typography className={classes.errorText}>
          {errorHandler(error)}{' '}
        </Typography>
        <Typography gutterBottom className={classes.alertLink}>
        {error.type === 'error' ? (
          <>
            <ReloadPageLink />{' '}
            <TechSupportLink />
            </>
        ) : null}
        </Typography>
      </Alert>
    </Box>
  );
}

GlobalErrorAlert.defaultProps = {
  title: null,
  error: PropTypes.object,
  style: null
};

GlobalErrorAlert.propTypes = {
  title: PropTypes.string,
  error: PropTypes.object,
  style: PropTypes.object
};

export default GlobalErrorAlert;
