/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import posts from './posts';
import FeatureImg from '../../../../assets/img/OnlineMoneyTransfer.svg';
import ExpandablePanel from '../../../common/ExpandablePanel';

const whatsNewInVivowireStyle = makeStyles((theme) => ({
  whatsNewInVivowireContainer: {
    maxWidth: '1222px',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      paddingTop: '40px',
      height: '100%',
      padding: '20px'
    }
  },
  authorText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '400',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662',
    paddingBottom: '20px',
    paddingTop: '0px'
  },
  mainText: {
    fontFamily: 'Rubik',
    fontSize: '34px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#21a2c6',
    paddingBottom: '50px',
    paddingTop: '20px'
  },
  subText: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582',
    paddingBottom: '20px'
  },
  postType: {
    fontFamily: 'Inter',
    fontSize: '28px',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662',
    paddingBottom: '20px',
    paddingTop: '20px'
  },
  link: {
    textDecoration: 'none',
    color: '#21a2c6'
  },
  featuredImg: {
    backgroundImage: `url(${FeatureImg})`,
    height: '400px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  },
  featuredImgContainer: {
    padding: '30px',
    maxWidth: '100%',
    marginBottom: '1rem',
    border: '4px solid #fefefe',
    borderRadius: 0,
    boxShadow: '0 0 0 1px rgb(10 10 10 / 20%)',
    lineHeight: 0
  },
  featuredContainer: {
    display: 'flex',
    gridTemplateColumns: 'auto 1fr',
    gap: '10px'
  },
  expandedTextTitleStyle: {
    fontFamily: 'Inter',
    fontSize: '22px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582',
    paddingBottom: '20px',
    cursor: 'pointer',
    textAlign: 'left'
  },
  expandedText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582'
  }
}));

function WhatsNewInVivowire() {
  const classes = whatsNewInVivowireStyle();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation(['whats-new-page']);
  const handleClick = () => {
    setOpen(!open);
  };
  const featuredPosts = posts.filter((post) => post.pinned);

  return (
    <Grid container direction="row" spacing={0} className={classNames(classes.whatsNewInVivowireContainer)}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="h3" component="h3" align="left" className={classNames(classes.postType)}>
          <div className={classes.featuredContainer}>
            <NewReleasesIcon color="primary" fontSize="large" />
            {t('featuredNews')}
          </div>
        </Typography>

        {featuredPosts.map((fpost) => (
          <div key={fpost.title}>
            <Grid item xs={12} md={12} lg={12} className={classes.featuredImgContainer}>
              <div className={classes.featuredImg} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} key={fpost}>
              <Typography variant="h3" component="h3" align="left" className={classNames(classes.mainText)}>
                {fpost.title}
              </Typography>
              <Typography variant="h3" component="h3" align="left" className={classNames(classes.authorText)}>
                {t('by')}{' '}
                <a href="/about" className={classes.link}>
                  {fpost.author}
                </a>{' '}
                <span>{fpost.date}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} key={fpost.title}>
              <ExpandablePanel
                className={classNames(classes.helpSubTitle, 'lg-mg-bottom-custom', 'app-theme-text-color')}
                placement={{ component: 'News', item: `News ${fpost.date}` }}
                expandedTextTitleStyle={classes.expandedTextTitleStyle}
                expandedTextStyle={classes.expandedText}
                textAlign="left"
                customClass={`news-item-number-${fpost.date}`}
                title={fpost.spoiler}
                titleNoWrap
                handleRatingChange={null}
                content={fpost.content}
              />
            </Grid>
          </div>
        ))}
      </Grid>
      <Grid item xs={12} md={12} lg={12} onClick={handleClick}>
        <Typography variant="h3" component="h3" align="left" className={classNames(classes.postType)}>
          {t('recentNews')}
        </Typography>
        <Divider />
        {posts
          .filter((item) => !item.pinned)
          .map((post, index) => (
            <div key={post.title + index}>
              <Grid item xs={12} md={12} lg={12} key={post}>
                <Typography variant="h3" component="h3" align="left" className={classNames(classes.mainText)}>
                  {post.title}
                </Typography>
                <Typography variant="h3" component="h3" align="left" className={classNames(classes.authorText)}>
                  {t('by')}{' '}
                  <a href="/about" className={classes.link}>
                    {post.author}
                  </a>{' '}
                  <span>{post.date}</span>
                </Typography>
                <ExpandablePanel
                  className={classNames(classes.helpSubTitle, 'lg-mg-bottom-custom', 'app-theme-text-color')}
                  placement={{ component: 'News', item: `News ${post.date}` }}
                  expandedTextTitleStyle={classes.expandedTextTitleStyle}
                  textAlign="left"
                  customClass={`news-item-number-${post.date}`}
                  title={post.spoiler}
                  titleNoWrap
                  handleRatingChange={null}
                  content={post.content}
                />
              </Grid>
            </div>
          ))}
      </Grid>
    </Grid>
  );
}

WhatsNewInVivowire.propTypes = {};

export default WhatsNewInVivowire;
