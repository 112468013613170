import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';
import Lottie from 'react-lottie';
import Button from '../../common/Button';
import animationData from '../../../assets/lotties/check.json';

const useStyles = makeStyles((theme) => ({
  welcomeContainer: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  dialogPaper: {
    maxWidth: 600
  },
  title: {
    fontFamily: 'Rubik',
    fontSize: '34px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '--1.42px',
    color: '#2f4662'
  },
  subTitle: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  continueButton: {
    width: 220,
    height: 62,
    marginTop: 20,
    marginBottom: 10,
    padding: '25 121',
    objectFit: 'contain',
    opacity: 0.9,
    borderRadius: theme.borderRadiusButton,
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover,&:focus': {
      background: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  buttonText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    paddingRight: 4,
    textTransform: 'none'
  },
  cardActionRoot: {
    textAlign: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')] : {
      display: 'block'
    }
  },
  cardMediaRoot: {
    gridColumn: 1,
    gridRow: 1,
    objectFit: 'contain'
  },
  cardMediaContainer: {
    display: 'grid',
    paddingTop: theme.spacing(3),
    background: '#f3f5fd'
  },
  mailContainer: {},
  mailIconRoot: {
    color: '#37aacb',
    textAlign: 'center',
    width: '40px'
  },
  mailLink: {
    display: 'inline-grid',
    gridTemplateColumns: 'auto auto',
    textDecoration: 'none !important'
  },
  mailLinkText: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: 'normal',
    color: '#21a2c6',
    textDecoration: 'none',
    margin: 10
  }
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function SuccessDialog({ handleClose, open, title/* , image */, button, subTitle }) {
  const classes = useStyles();
  const { t } = useTranslation('dashboard-common');

  return (
    <Dialog
      transitionDuration={500}
      onClose={handleClose}
      open={open}
      scroll="body"
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.welcomeContainer}>
        <Grid item xs={12} md={12} lg={12} className={classes.placeholderImgContainer}>
          <Card>
            <div className={classes.cardMediaContainer}>
              <Lottie options={defaultOptions} height={200} width={200} />
{/*               <CardMedia
                classes={{ root: classes.cardMediaRoot }}
                component="img"
                width="500"
                height="300"
                image={image}
                alt={`Celebration ${image}`}
              /> */}
            </div>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className={classNames('app-theme-text-color', classes.title)}
              >
                {t(title)}
              </Typography>
              <Typography variant="h5" paragraph className={classes.subTitle}>
                {subTitle}
              </Typography>
            </CardContent>
            <CardActions classes={{ root: classes.cardActionRoot }}>
              {button &&
                button.map((b) => (
                  <Button key={b.text} variant={b.variant} color={b.color} onClick={b.handleButtonClick}>
                    <span className={classes.buttonText}>{t(b.text)}</span>
                  </Button>
                ))}
            </CardActions>
          </Card>
        </Grid>
      </div>
    </Dialog>
  );
}

SuccessDialog.defaultProps = {
  button: undefined
};

SuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
/*   image: PropTypes.string.isRequired, */
  button: PropTypes.element
};

export default SuccessDialog;
