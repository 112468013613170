import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { uniqueId } from 'lodash';
import SvgImage from '../../common/DynamicSvgImageRender';

const styles = makeStyles((theme) => ({
    paymentCardContainer: {
        justifyContent: 'center',
        display: 'flex',
        gap: 5,
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    }
}));

function CreditCardPaymentsDisplay({ list, width, height }) {
    const classes = styles();
    return (
        <div className={classes.paymentCardContainer}>
            {list.map((card) => <SvgImage
                key={uniqueId()}
                width={width}
                height={height}
                fileName={card}
                pathInAssets="svg"
                alt={card}
                className={`payment-${card}`}
                noAvatar
            />)}
        </div>
    )
}

CreditCardPaymentsDisplay.defaultProps = {
    list: [],
    width: 50,
    height: 50
};

CreditCardPaymentsDisplay.propTypes = {
    list: PropTypes.array,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}


export default CreditCardPaymentsDisplay;