import React from 'react';
import { useTranslation } from 'react-i18next';
import camelCase from 'lodash/camelCase';
import uniqueId from 'lodash/uniqueId';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, Typography, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SimpleLink from '../../common/SimpleLink';

const sectionPolicyContentTemplateStyle = makeStyles((theme) => ({
  sectionPolicyContentTemplateContainer: {
    margin: 'auto',
    marginBottom: '100px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '40px',
      height: '100%',
      padding: theme.spacing(2)
    }
  },
  mainText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662',
    paddingTop: '40px',
    paddingBottom: '40px'
  },
  subText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  mainTextNested: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582',
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  subTextNested: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582',
    marginBottom: '20px'
  },
  link: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    marginBottom: '20px',
    textDecoration: 'none',
    color: '#21a2c6',
    cursor: 'pointer'
  }
}));

function SectionPolicyContentTemplate(props) {
  const classes = sectionPolicyContentTemplateStyle();
  const { policy, translation } = props;
  const { t } = useTranslation([translation]);
  const subText = (text, id) =>
    text?.map((txt, index) => (
      <div key={uniqueId()}>
        {txt.textSubTitle && (
          <Typography
            variant="h3"
            component="h3"
            align="left"
            className={classNames(classes.mainTextNested)}
            key={txt.textSubTitle}
          >
            {t(camelCase(txt.textSubTitle))}
          </Typography>
        )}
        <Typography
          variant="h3"
          component="h3"
          align="left"
          key={txt.subTextNested}
          style={{ textIndent: txt.indent ? '1em' : 0, fontWeight: txt.bold ? 600 : 'inherit' }}
          className={classNames(classes.subTextNested)}
        >
          {t(`subText${id}${index}`)}
        </Typography>
      </div>
    ));
  return (
    <Container className={classNames(classes.sectionPolicyContentTemplateContainer)}>
      {policy?.map((p) => (
        <Grid container key={uniqueId()}>
          <Grid item xs={12} md={12} lg={12} key={uniqueId()}>
            <Typography style={{ textIndent: p.indent ? '1em' : 0 }} variant="h3" component="h3" align="left" key={p.title} className={classNames(classes.mainText)}>
              {t(`${camelCase(p.title)}`)}
            </Typography>
            {Array.isArray(p.text) && p.text.length > 0 ? subText(p.text, p.id) : null}
          </Grid>
          {p.readMoreUrl && p.readMore && (
            <Grid item>
              <SimpleLink linkTo={p.readMoreUrl} linkText={t(`${camelCase(p.readMore)}`)} style={classes.link} />
            </Grid>
          )}
        </Grid>
      ))}
    </Container>
  );
}

SectionPolicyContentTemplate.defaultProps = {
  policy: null
};
SectionPolicyContentTemplate.propTypes = {
  policy: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  translation: PropTypes.string.isRequired
};

export default SectionPolicyContentTemplate;
