import makeStyles from '@mui/styles/makeStyles';

export const styles = makeStyles((theme) => ({
  card: {
    maxWidth: 420,
    marginTop: 50
  },
  formFieldContainer: {
    display: 'Flex',
    justifyContent: 'center'
  },
  textfield: {
    borderRadius: theme.borderRadiusInput,
    backgroundColor: '#eef3f9',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    } /* ,     '&.Mui-focused': {
      border: 'solid 1px #21a1c5'
    } */
  },
  inputLabel: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#4a6582',
    zIndex: 10,
    padding: '25px'
  },
  helperText: {
    fontSize: '0.9rem',
    fontFamily: 'Inter',
    color: '#f44336'
  }
}));
