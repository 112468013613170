import { Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import addRecipientDialogStyle from '../dashboard/recipient/AddRecipientDialogStyle';
import Button from './Button';

function FormNavigation({ isLastStep, lastStepButtonLabel, onBackClick, onContinueClick, error, disabled, formik }) {
  const useStyles = addRecipientDialogStyle;
  const { t } = useTranslation(['dashboard-common']);
  const classes = useStyles();

  return (
    <Grid item container direction="column" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Button onClick={onContinueClick} className={classes.addRecipientButton} fullWidth type="submit" variant="contained" color="primary" disabled={!!error || disabled || formik.isSubmitting}>
          <span className={classes.addRecipientText}>{isLastStep ? lastStepButtonLabel : t('continue')}</span>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onBackClick} type="button" color="transparent">
          <span className={classes.cancelRecipientText}>{t('back')}</span>
        </Button>
      </Grid>
    </Grid>
  );
}

export default FormNavigation;

FormNavigation.defaultProps = {
  error: undefined,
  disabled: false,
  onContinueClick: undefined,
  formik: {}
};

FormNavigation.propTypes = {
  lastStepButtonLabel: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onContinueClick: PropTypes.func,
  isLastStep: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  formik: PropTypes.object
};
