import React from 'react';
// import { makeStyles } from '@mui/material/styles';

// const useStyles = makeStyles(() => ({}));

export default function BankIdSvg() {
  // const classes = useStyles();
  return (
    <span>
      <svg width="33px" height="32px" viewBox="0 0 317 300" version="1.1">
        <title>bankid_vector_rgb</title>
        <desc>BankId Logo</desc>
        <defs />
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="bankid_vector_rgb" fillRule="nonzero">
            <path
              d="M99.9,156.6 L113.1,73.4 C107.9,73.4 99,73.4 99,73.4 C92.4,73.4 83.9,69.7 81.4,62.9 C80.6,60.6 78.7,52.7 89.6,45 C93.5,42.3 96,39.3 96.5,37 C97,34.6 96.4,32.5 94.7,30.9 C92.3,28.6 87.6,27.3 81.6,27.3 C71.5,27.3 64.4,33.1 63.7,37.3 C63.2,40.4 65.6,42.9 67.7,44.5 C74,49.2 75.5,56 71.6,62.4 C67.6,69 58.9,73.3 49.6,73.4 C49.6,73.4 40.4,73.4 35.2,73.4 C34,81.5 14.4,205.7 12.9,215.5 L91,215.5 C91.7,211.1 95.3,187.6 100.2,156.6 L99.9,156.6 Z"
              id="Shape"
              fill="#469cbe"
            />
            <path
              d="M204.5,0.6 L125,0.6 L114.4,67.9 L127.9,67.9 C135.3,67.9 142.3,64.5 145.3,59.6 C146.3,58 146.7,56.6 146.7,55.3 C146.7,52.5 144.8,50.4 142.9,49 C137.7,45.1 136.6,41 136.6,38.1 C136.6,37.5 136.6,37 136.7,36.5 C137.8,29.4 147.4,21.7 160.1,21.7 C167.7,21.7 173.5,23.5 177,26.8 C180.1,29.7 181.3,33.8 180.4,38.1 C179.3,43.2 174.2,47.4 171.3,49.5 C163.6,54.9 164.6,59.6 165.1,61 C166.7,65.2 172.8,67.9 177.5,67.9 L198,67.9 C198,67.9 198,67.9 198,68 C226,68.2 241,81.1 236.3,111.1 C231.9,139 210.5,151 185,151.2 L174.9,215.6 L189.8,215.6 C252.7,215.6 304.1,175.2 314.2,111.4 C326.7,32.2 276.5,0.6 204.5,0.6 Z"
              id="Shape"
              fill="#FFF"
            />
            <path
              d="M204.5,0.6 L125,0.6 L114.4,67.9 L127.9,67.9 C135.3,67.9 142.3,64.5 145.3,59.6 C146.3,58 146.7,56.6 146.7,55.3 C146.7,52.5 144.8,50.4 142.9,49 C137.7,45.1 136.6,41 136.6,38.1 C136.6,37.5 136.6,37 136.7,36.5 C137.8,29.4 147.4,21.7 160.1,21.7 C167.7,21.7 173.5,23.5 177,26.8 C180.1,29.7 181.3,33.8 180.4,38.1 C179.3,43.2 174.2,47.4 171.3,49.5 C163.6,54.9 164.6,59.6 165.1,61 C166.7,65.2 172.8,67.9 177.5,67.9 L198,67.9 C198,67.9 198,67.9 198,68 C226,68.2 241,81.1 236.3,111.1 C231.9,139 210.5,151 185,151.2 L174.9,215.6 L189.8,215.6 C252.7,215.6 304.1,175.2 314.2,111.4 C326.7,32.2 276.5,0.6 204.5,0.6 Z"
              id="Shape"
              fill="#215971"
            />
            <g id="Group" transform="translate(0.000000, 244.000000)" fill="#215971">
              <path
                d="M8.7,0.2 L40.6,0.2 C54.2,0.2 57.5,7.1 56.5,13.4 C55.7,18.5 52.2,22.3 46.2,24.8 C53.8,27.7 56.8,32.2 55.7,39.3 C54.3,48.2 46.6,54.8 36.5,54.8 L0.2,54.8 L8.7,0.2 Z M29.8,22.8 C36,22.8 38.9,19.5 39.5,15.6 C40.1,11.4 38.2,8.5 32,8.5 L26.5,8.5 L24.3,22.8 L29.8,22.8 L29.8,22.8 Z M26.4,46.4 C32.8,46.4 36.5,43.8 37.4,38.5 C38.1,33.9 35.5,31.2 29.3,31.2 L23,31.2 L20.6,46.5 L26.4,46.5 L26.4,46.4 Z"
                id="Shape"
              />
              <path
                d="M100.4,55.2 C92.1,55.8 88.1,54.9 86.1,51.3 C81.7,54 76.8,55.4 71.6,55.4 C62.2,55.4 58.9,50.5 59.8,45.1 C60.2,42.5 61.7,40 64.1,37.9 C69.3,33.4 82.1,32.8 87.1,29.4 C87.5,25.6 86,24.2 81.3,24.2 C75.8,24.2 71.2,26 63.3,31.4 L65.2,19 C72,14.1 78.6,11.8 86.2,11.8 C95.9,11.8 104.5,15.8 102.9,26.4 L101,38.4 C100.3,42.6 100.5,43.9 105.2,44 L100.4,55.2 Z M86,36.4 C81.6,39.2 73.4,38.7 72.5,44.5 C72.1,47.2 73.8,49.2 76.5,49.2 C79.1,49.2 82.3,48.1 84.9,46.3 C84.7,45.3 84.8,44.3 85.1,42.4 L86,36.4 Z"
                id="Shape"
              />
              <path
                d="M115.9,12.5 L132.5,12.5 L131.6,18 C136.9,13.5 140.9,11.8 146.1,11.8 C155.4,11.8 159.7,17.5 158.2,26.8 L153.9,54.7 L137.3,54.7 L140.9,31.6 C141.6,27.4 140.3,25.4 137.1,25.4 C134.5,25.4 132.1,26.8 129.8,29.9 L126,54.6 L109.4,54.6 L115.9,12.5 Z"
                id="Shape"
              />
              <polygon
                id="Shape"
                points="171.1 0.2 187.7 0.2 183.5 27 199.4 12.5 219.9 12.5 199.5 30.5 215.9 54.7 195 54.7 182.4 35.2 182.2 35.2 179.2 54.7 162.6 54.7"
              />
            </g>
            <g id="Group" transform="translate(221.000000, 244.000000)" fill="#469cbe">
              <polygon id="Shape" points="8.9 0.2 28 0.2 19.6 54.7 0.5 54.7" />
              <path
                d="M37.3,0.2 L64.6,0.2 C85.7,0.2 91.8,15.5 89.8,28.2 C87.9,40.6 78.1,54.7 59.6,54.7 L28.8,54.7 L37.3,0.2 Z M55,41.7 C64.3,41.7 69.4,37.1 70.9,27.4 C72,20.2 69.8,13.1 59.5,13.1 L54.4,13.1 L50,41.7 L55,41.7 L55,41.7 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
}
