/* eslint-disable no-console */
import makeStyles from '@mui/styles/makeStyles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import background from '../../assets/img/dashboard_background.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => 
  // const matches = useMediaQuery('(max-width: 2300px) and (min-width: 600px)');  
   ({
    container: {
      width: '100%',
      margin: 0
    },
    dashboardRoot: {
      display: 'flex',
      overflow: 'inherit',
      [theme.breakpoints.down('md')]: {
        overflow: 'auto'
      }
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        height: '100vh'
      }
    },
    drawerMenuText: {
      fontFamily: 'Rubik',
      color: theme.palette.globalAppTheme.globalAppTextColorSecondary,
      textDecoration: 'none',
      fontSize: theme.typography.pxToRem(18)
    },
    topMenuText: {
      fontFamily: 'Rubik',
      color: '#2f4662',
      textDecoration: 'none',
      padding: 3,
      fontSize: theme.typography.pxToRem(18)
    },
    appBar: {
      paddingTop: theme.spacing(1),
      background: `linear-gradient(90deg, rgba(255,255,255,1) 34.1%, rgb(248 250 252) 0%)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      },
      [theme.breakpoints.down('lg')]: {
        paddingTop: theme.spacing(5),
        background: '#fff'
      },
      zIndex: theme.zIndex.drawer -1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarAlter: {
      paddingTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(5)
      },
      backgroundColor: '#ffffff',
      [theme.breakpoints.up('dashNavBackground')]: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed'
      },
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarTitle: {
      marginLeft: theme.spacing(1.6),
      fontFamily: 'Rubik',
      color: theme.palette.globalAppTheme.globalAppTextColor,
      fontSize: theme.typography.pxToRem(34),
      fontWeight: 'bold',
      marginTop: 23,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        fontSize: theme.typography.pxToRem(22)
      }
    },
    navLink: {
      color: 'inherit',
      position: 'relative',
      fontWeight: '400',
      fontSize: '20px',
      textTransform: 'none',
      borderRadius: theme.borderRadius,
      lineHeight: '20px',
      textDecoration: 'none',
      display: 'inline-flex',
      '&:hover,&:focus': {
        color: 'inherit',
        background: 'rgba(200, 200, 200, 0.2)'
      },
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 30px)',
        marginLeft: '15px',
        marginBottom: '8px',
        marginTop: '8px',
        textAlign: 'left',
        color: '#2f4662 !important',
        '& > span:first-child': {
          justifyContent: 'flex-start'
        }
      }
    },
    appBarButtonsContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    appBarButtons: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        textIndent: '-9999px'
      },
      color: 'inherit',
      position: 'relative',
      fontWeight: '400',
      fontSize: '20px',
      textTransform: 'none',
      borderRadius: theme.borderRadius,
      lineHeight: '20px',
      textDecoration: 'none',
      display: 'inline-flex',
      '&:hover,&:focus': {
        color: 'inherit',
        background: 'rgba(200, 200, 200, 0.2)'
      },
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 30px)',
        textAlign: 'left',
        color: '#2f4662 !important',
        '& > span:first-child': {
          justifyContent: 'flex-start'
        }
      },
      marginTop: 23
    },
    icons: {
      width: '20px',
      height: '20px',
      marginRight: '3px'
    },
    languageIcon: {
      width: '30px !important',
      height: '30px !important'
    },
    sendMoneyTitle: {
      fontSize: 18,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: -0.4,
      color: theme.palette.globalAppTheme.globalAppTextColor,
      margin: '0px 0px 40px 0px'
    },
    mtfBox: {
      marginTop: 23
    },
    transactionStatsTitle: {
      fontSize: 18,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: -0.4,
      color: theme.palette.globalAppTheme.globalAppTextColor
    },
    statsTitle: {
      fontSize: 18,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: -0.4,
      color: theme.palette.globalAppTheme.globalAppTextColor
    },
    transactionInfoSection: {
      marginTop: 21
    },
    transactionInfoSectionTitle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 2.67,
      letterSpacing: 'normal',
      color: '#4a6582',
      [theme.breakpoints.down('md')]: {
        display: 'block'
      }
    },
    transactionInfoSectionAmount: {
      fontFamily: 'Inter',
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: -0.4,
      color: '#2f4662',
      [theme.breakpoints.down('md')]: {
        fontSize: 'inherit'
      }
    },
    topHorizontalDivider: {
      width: 'auto',
      height: 2,
      opacity: 0.2,
      backgroundColor: '#8b91a0'
    },
    verticalDivider: {
      width: 1,
      height: 570,
      opacity: 0.2,
      backgroundColor: '#8b91a0',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    divider: {
      width: 374,
      height: 1,
      margin: '32px 0 22px 61px',
      opacity: 0.2,
      backgroundColor: '#8b91a0'
    },
    recipientsTitleContainer: {
      marginTop: 30,
      [theme.breakpoints.down('md')]: {
        display: 'flex'
      }
    },
    recipientsTitle: {
      fontFamily: 'Inter',
      fontSize: 18,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: -0.4,
      color: '#2f4662'
    },
    recipientsListName: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: -0.62,
      color: '#2f4662'
    },
    recipientsListSecondary: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      color: '#4a6582'
    },
    avatarNameContainer: {
      display: 'grid',
      gridTemplateColumns: '60px auto auto',
      paddingTop: 20,
      paddingBottom: 20,
      [theme.breakpoints.down('md')]: {
        rowGap: '117px'
      }
    },
    recipientsListAvatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor
    },
    recentTransactionListSecondary: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#4a6582',
      textTransform: 'none'
    },
    menuButton: {
      marginRight: theme.spacing(2),
/*       [theme.breakpoints.up('sm')]: {
        display: 'none'
      }, */
      color: '#2f4662'
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      width: '100%',
      margin: '0 auto',
      flexGrow: 1,
      marginRight: '-170px',
      padding: theme.spacing(3),
      backgroundColor: '#ffffff',
      background: 'linear-gradient(90deg, rgba(255,255,255,1) 31%, rgb(248 250 252) 0%)',
         [theme.breakpoints.down('md')]: {
        background: '#fff',
        padding: theme.spacing(1),
        width: '100%',
        margin: '0 auto'
      },
      [theme.breakpoints.down('lg')]: {
        background: '#fff',
        padding: theme.spacing(1),
        width: '100%',
        margin: '0 auto'
      }
      /* backgroundImage: 'none', */
      /* [theme.breakpoints.up('dashNavBackground')]: {
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed'
    } */
    },
    drawerContainer: {
      height: '100vh'
    },
    drawerTop: {
      maxWidth: '100vh'
    },
    brand: {
      marginTop: 34
    },
    formControl: {
      /* margin: theme.spacing(1), */
      minWidth: 120,
      padding: 0,
      margin: 0
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    gridListRecipient: {
      width: '100%',
      maxWidth: 'fit-content',
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#2f4662',
        borderRadius: 50
      },
      [theme.breakpoints.down('md')]: {
        height: '100%',
        // minHeight: theme.spacing(47),
        width: '100%',
        maxWidth: 360
      }
    },
    recipientContainer: {
      width: '100%',
      height: 'auto !important'
    },
    gridListRecentTransaction: {
      width: '100%',
      minWidth: 'fit-content',
      height: 'auto',
      maxHeight: 500,
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#2f4662',
        borderRadius: 50
      }
    },
    select: {
      padding: 0
    },
    avatarCircleRadius: {
      borderRadius: theme.borderRadius
    },
    buttonForMoreInformation: {
      color: 'rgb(102, 60, 0)',
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 2,
      letterSpacing: 'normal',
      marginLeft: '-8px',
      textTransform: 'initial'
    }
  })
);

export default useStyles;