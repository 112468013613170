import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: '#21a2c6',
    cursor: 'pointer'
  }
}));

function SimpleLink({ linkText, linkTo, style }) {
  const classes = useStyles();

  return (
    <a className={style || classes.link} to={linkTo} 
      target="_blank" 
      href={linkTo}
      rel="noreferrer">{linkText}</a>
  );
}

SimpleLink.defaultProps = {
  linkText: '',
  linkTo: '',
  style: {}
};

SimpleLink.propTypes = {
  linkText: PropTypes.string,
  linkTo: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default SimpleLink;
