/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy } from 'react';
import CookiePolicySection from './pages/cookie-policy/CookiePolicy';
import CompanyAndTeam from './pages/company-and-team/CompanyAndTeam';
import WhatsNewInVivowireSection from './pages/whats-new-in-vivowire/WhatsNewInVivowireSection';
import PrivacyPolicySection from './pages/privacy-policy/PrivacyPolicySection';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditionsSection';
import HelpCenter from './pages/help-center/HelpCenter';
import ErrorBoundary from '../common/ErrorBoundary';
import PERMALINKS from '../../constants/permalinks';

const AboutSection = lazy(() => import('./sections/about/AboutSection'));
const FeatureSection = lazy(() => import('./sections/feature/FeatureSection'));
const DestinationSection = lazy(() => import('./sections/destination/DestinationSection'));
/* const PlattformSection = lazy(() => import('./sections/plattform/PlattformSection')); */
const GetInTouchSection = lazy(() => import('./pages/contact-page-section/GetInTouchSection'));
const HelpSection = lazy(() => import('./sections/help/HelpSection'));

const landingPageContent = (rest) => (
  <>
    <AboutSection />
    <ErrorBoundary>
      <FeatureSection />
    </ErrorBoundary>
    <ErrorBoundary>
      <DestinationSection />
    </ErrorBoundary>
{/*     <ErrorBoundary>
      <PlattformSection />
    </ErrorBoundary> */}
    <ErrorBoundary>
      <HelpSection {...rest} />
    </ErrorBoundary>
  </>
);

const alternatePageContent = (rest) => (
  <>
{/*     <ErrorBoundary>
      <PlattformSection />
    </ErrorBoundary> */}
    <ErrorBoundary>
      <HelpSection {...rest} />
    </ErrorBoundary>
  </>
);

const content = (pathname, rest) => {
  switch (pathname) {
    case PERMALINKS.HOME:
    case PERMALINKS.GET_STARTED:
      return landingPageContent(rest);
    case PERMALINKS.CONTACT_US:
      return (
        <>
          <GetInTouchSection />
          {alternatePageContent(rest)}
        </>
      );
    case PERMALINKS.COOKIE_POLICY:
      return (
        <CookiePolicySection />
      );
    case PERMALINKS.COMPANY_AND_TEAM:
      return (
        <>
          <CompanyAndTeam />
          {alternatePageContent(rest)}
        </>
      );
    case PERMALINKS.WHATS_NEW_IN_VIVOWIRE:
      return (
        <>
          <WhatsNewInVivowireSection />
          {alternatePageContent(rest)}
        </>
      );
    case PERMALINKS.PRIVACY_POLICY:
      return (
        <PrivacyPolicySection />
      );
    case PERMALINKS.TERMS_AND_CONDITIONS:
      return (
        <TermsAndConditions />
      );
    case PERMALINKS.HELP_CENTER:
      return <HelpCenter />;
    default:
      return landingPageContent(rest);
  }
};

export default content;
