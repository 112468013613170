import React from 'react';
import SectionTemplate from '../../sections/SectionTemplate';
import AgreementAmico1 from '../../../../assets/img/AgreementAmico1-2x.png';
import SectionPolicyContentTemplate from '../../sections/SectionPolicyContentTemplate';
import policy from './policy';

function CookiePolicy() {
  return (
    <SectionTemplate
      sectionId="cookie-policy-section"
      sectionImg={AgreementAmico1}
      sectionImgTitle="Agreement"
      sectionTitle="Cookie Policy"
      sectionSubTitle="sectionSubTitle"
      pathName="cookie-policy"
      translation="cookie-policy"
    >
      <SectionPolicyContentTemplate translation="cookie-policy" policy={policy} />
    </SectionTemplate>
  );
}

CookiePolicy.propTypes = {};

export default CookiePolicy;
