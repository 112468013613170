import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert , Typography, Avatar, Fade, Hidden } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import _pick from 'lodash/pick';
import API from '../../../utils/api';
import { setGlobalUser } from '../../../store/redux/actions/userAction';
import UpdateForm from './UpdateForm';
import { errorHandler } from '../../../utils/errorMapper';
import ReloadPageLink from '../../common/ReloadPageLink';
import Button from '../../common/Button';
import DashboardBreadcrumbs from '../../breadcrumbs/DashboardBreadcrumbs';
import { userCountry } from '../../../constants/dashboardContants';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  container: {
    width: '100%',
    flexGrow: 1,
    padding: theme.spacing(5),
    backgroundColor: '#fff'
  },
  titleContainer: {},
  title: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    },
    fontFamily: 'Rubik',
    color: theme.palette.globalAppTheme.globalAppTextColor,
    fontSize: 34,
    fontWeight: 'bold'
  },
  avatarNameContainer: {
    display: 'grid',
    gridTemplateColumns: '60px 1fr auto',
    gap: 10,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  userName: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    padding: '15px 0px 0px 50px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    }
  },
  dateOfBirth: {
    padding: '2px 0px 0px 50px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#4a6582',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    }
  },
  avatar: {
    height: '89px',
    width: '89px',
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal'
  },
  innerContainer: {
    width: 'max-content',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  editButton: {
    color: theme.palette.globalAppTheme.globalAppButtonColor,
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.62,
    textTransform: 'none',
    '&:hover,&:focus': {
      color: theme.palette.globalAppTheme.globalAppButtonColor,
      background: 'transparent'
    }
  },
  editIcon: {
    [theme.breakpoints.down('md')]: {
      height: '35px !important',
      width: '35px !important'
    }
  },
  editText: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  breadcrumbs: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

function Profile() {
  const classes = useStyles();
  const { user } = useSelector((state) => state.userInfo);
  const { currentUserIsRegistred } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const { t } = useTranslation(['dashboard-common']);
  const [editMode, setEditMode] = useState(false);
  const [saveError, setSaveError] = useState(undefined);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [initialValues, setInitialValues] = useState({
    phoneNumber: user?.phoneNumber,
    email: user?.email,
    street: user?.address?.street,
    zipCode: user?.address?.zipCode,
    city: user?.address?.city,
    country: userCountry.sweden,
    emailConsent: user?.emailConsent
  });

  const fulltName = user ? `${user?.firstName} ${user?.lastName}` : '';

  function isObject(object) {
    return object != null && typeof object === 'object';
  }
  function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        // eslint-disable-next-line no-mixed-operators
        (areObjects && !deepEqual(val1, val2)) ||
        // eslint-disable-next-line no-mixed-operators
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  }

  const updateUser = (values) => {
    if (!deepEqual(values, initialValues)) {
      const updateValues = _pick(values, [
        'phoneNumber',
        'email'
      ]);
      API.put('/user', updateValues)
        .then((response) => {
          if (response.statusText === 'OK') {
            setEditMode(false);
            setIsSaveSuccess(true);
            dispatch(setGlobalUser(response.data));
            setTimeout(() => {
              setIsSaveSuccess(false);
            }, 5000);
          }
        })
        .catch((error) => {
          setSaveError(error);
          setEditMode(false);
        });
    }
    setEditMode(false);
  };

  const handleEdit = () => {
    if (user) {
      setEditMode(!editMode);
      setInitialValues(Object.assign(initialValues, { ...initialValues, phoneNumber: user?.phoneNumber,
        email: user?.email }))
      setSaveError(undefined);
    } else {
      const error = new Error(t(''), { code: 1, message: '' });
      setSaveError(error);
    }
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setSaveError(undefined);
  };

  const alert = (fadeIn, severity, children) => (
    <Fade in={fadeIn}>
      <Alert severity={severity}>{children}</Alert>
    </Fade>
  );

  const errorReload = () => (
    <span>
      {saveError && errorHandler(saveError)}
      <ReloadPageLink />
    </span>
  );

  const profileID = `${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}${user?.dateOfBirth.replace(/-/g, '')}`;

  return (
    <div className={classes.container}>
      <Hidden only={['lg', 'xl']} implementation="js">
        <Grid item xs={12} md={12} lg={12} className={classes.breadcrumbs}>
          <DashboardBreadcrumbs pathname="Profile" />
        </Grid>
      </Hidden>
      <div className={classes.innerContainer}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} noWrap>
            {t('profile')}
          </Typography>
        </div>
        {/* <div className={classes.toolbar} /> */}
        <div className={classes.avatarNameContainer}>
          <Avatar className={classes.avatar} alt={fulltName || ''}>
            {user?.firstName.charAt(0)}
            {user?.lastName.charAt(0)}
          </Avatar>
          <div>
            <Typography className={classes.userName} noWrap>
              {fulltName || ''}
            </Typography>
            <Typography className={classes.dateOfBirth} noWrap>
              {`Profile ID: ${profileID}`}
            </Typography>
          </div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Button
            disabled={!currentUserIsRegistred}
            variant="text"
            size="sm"
            className={classes.editButton}
            startIcon={<EditIcon className={classes.editIcon} title={t('editDetails')} />}
            style={{
              padding: 0,
              backgroundColor: 'transparent'
            }}
            onClick={handleEdit}
          >
            <Typography className={classes.editText} title={t('editDetails')}>
              {t('editDetails')}
            </Typography>
          </Button>
        </div>
        {editMode && alert(editMode, 'info', `${t('editing')}...`)}
        {saveError && alert(!!saveError, 'error', errorReload())}
        {isSaveSuccess && alert(isSaveSuccess, 'success', t('changesSaved'))}
        {/** Personal information */}
        <UpdateForm
          initialValues={initialValues}
          updateUser={updateUser}
          editMode={editMode}
          handleCancelEdit={handleCancelEdit}
        />
      </div>
    </div>
  );
}

Profile.defaultProps = {
  user: undefined
};

Profile.propTypes = {
  user: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    dateOfBirth: PropTypes.string,
    email: PropTypes.string,
    emailConsent: PropTypes.bool,
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    recipient: PropTypes.arrayOf(PropTypes.object),
    street: PropTypes.string,
    transferHabit: PropTypes.string,
    userFlag: PropTypes.shape({
      isBlocked: PropTypes.bool,
      isOnWatchList: PropTypes.bool,
      isPep: PropTypes.bool,
      isRegistered: PropTypes.bool,
      isVerifiedPep: PropTypes.bool
    }),
    zipCode: PropTypes.string
  })
};

export default Profile;
