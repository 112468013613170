import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { setGlobalLoginModalVisible, setGlobalIsGetStartedLogin } from '../../store/redux/actions/loginModalAction';
import CustomButton from '../common/Button';

const styles = makeStyles((theme) => ({
    loginButtonContainer: {
        [theme.breakpoints.down('md')]: {
          width: '100%',
          margin: 0
        },
        [theme.breakpoints.up('md')]: {
          width: '100%'
        },
        [theme.breakpoints.down('lg')]: {
          width: '100%',
          margin: 0
        }
      },
      loginButton: {
        width: '100%',
        minWidth: 205,
        height: '78px',
        textTransform: 'none',
        borderRadius: theme.borderRadiusButton,
        backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
        backgroundImage: 'linear-gradient(to right, #19cac3, #1569af)',
        '&:hover,&:focus': {
          color: 'inherit',
          background: '#21a2c6'
        },
        [theme.breakpoints.down('md')]: {
          margin: 0
        },
        [theme.breakpoints.down('lg')]: {
          width: '100%'
        }
      },
      loginButtonGridItem: {
        [theme.breakpoints.down('md')]: {
          textAlign: 'center',
          marginTop: '-108px'
        },
        [theme.breakpoints.down('lg')]: {
          maxWidth: '100%',
          padding: '30px'
        }
      },
      loginButtonArrowForward: {
        marginLeft: theme.spacing(2),
        width: '16px',
        height: '16px',
        objectFit: 'contain',
        color: '#ffffff'
      },
      loginButtonText: {
        fontFamily: 'Inter',
        fontSize: theme.typography.pxToRem(20),
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.7px',
        color: '#ffffff'
      }
}));

function GetStartedButton({ marginR, disabled }) {
    const { t } = useTranslation(['landing-page-common'])
    const dispatch = useDispatch();
    const classes = styles();
    const { isInvalidSenderAmount } = useSelector((state) => state.moneyTransfer);
    const { error: appError, maintenanceMessage } = useSelector((state) => state.app);

    const setSignInForm = () => {
        dispatch(setGlobalIsGetStartedLogin(true));
        dispatch(setGlobalLoginModalVisible(true));
      };

      return (
        <div className={classes.loginButtonContainer}>
        <CustomButton
          id="login-modal-button"
          className={classes.loginButton}
          style={{ marginRight: `${marginR}px` }}
          disabled={disabled || isInvalidSenderAmount || appError || maintenanceMessage}
          color="transparent"
          size="lg"
          onClick={setSignInForm}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography className={classes.loginButtonText}>{t('getStartedButtonLabel')}</Typography>
          <ArrowForward className={classes.loginButtonArrowForward} />
        </CustomButton>
      </div>
      )
}

GetStartedButton.defaultProps = {
    marginR: '',
    disabled: undefined
};
  
GetStartedButton.propTypes = {
    marginR: PropTypes.string,
    disabled: PropTypes.bool
};

export default GetStartedButton;