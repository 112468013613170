/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import TextfieldWrapperAutocomplete from './TextfieldWrapperAutocomplete';

const useStyles = makeStyles((theme) => ({
  option: {
    padding: '5px 6px',
    height: 'auto',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    marginLeft: theme.spacing(0),
    flex: 1,
    color: theme.palette.globalAppTheme.globalAppTextColor
  },
  popupIndicator: {
    right: 22
  },
  clearIndicator: {
    right: 16
  }
}));

function CountrySelectAutocompleteField({ name, options, label, id, fullWidth }) {
  const classes = useStyles();
  const { t } = useTranslation(['common']);
  const { setFieldValue } = useFormikContext();

  const handleChange = (event, value) => {
    if (value != null) {
      setFieldValue(name, value.countryName);
    }
    return '';
  };

  return (
    <Autocomplete
      classes={{
        option: classes.option,
        popupIndicator: classes.popupIndicator,
        clearIndicator: classes.clearIndicator
      }}
      id={id}
      name={name}
      options={options || []}
      clearText={t('clear')}
      autoComplete
      autoSelect
      fullWidth={fullWidth}
      getOptionLabel={(option) => t(camelCase(option.countryName))}
      noOptionsText={t('noOptions')}
      onChange={handleChange}
      renderInput={(params) =>  <TextfieldWrapperAutocomplete stye={{ margin: 80 }} label={label} name={name} {...params} />}
    />
  );
}

CountrySelectAutocompleteField.defaultProps = {
  fullWidth: false
};

CountrySelectAutocompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  options: PropTypes.oneOfType([PropTypes.shape(PropTypes.object), PropTypes.array]).isRequired,
  fullWidth: PropTypes.bool
};

export default CountrySelectAutocompleteField;
