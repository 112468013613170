// eslint-disable-next-line import/prefer-default-export
export const ACTION_TYPES = {
  /** SESSION */
  SET_CURRENT_PAGE_IS_LANDING_PAGE: 'SET_CURRENT_PAGE_IS_LANDING_PAGE',
  SET_CURRENT_PAGE_IS_DASHBOARD: 'SET_CURRENT_PAGE_IS_DASHBOARD',
  SET_CURRENT_PAGE_IS_CONTACT_PAGE: 'SET_CURRENT_PAGE_IS_CONTACT_PAGE',
  /** MAIN APP */
  SET_RECIPIENT_COUNTRIES: 'SET_RECIPIENT_COUNTRIES',
  SET_SENDER_COUNTRIES: 'SET_SENDER_COUNTRIES',
  SET_CONVERTED_AMOUNT: 'SET_CONVERTED_AMOUNT',
  SET_CONVERTED_AMOUNT_ERROR: 'SET_CONVERTED_AMOUNT_ERROR',
  SET_SENDER_COUNTRY_OPTION_VALUE: 'SET_SENDER_COUNTRY_OPTION_VALUE',
  SET_RECIPIENT_COUNTRY_OPTION_VALUE: 'SET_RECIPIENT_COUNTRY_OPTION_VALUE',
  SET_SELECTED_SENDER_COUNTRY_NAME: 'SET_SELECTED_SENDER_COUNTRY_NAME',
  SET_SELECTED_RECIPIENT_COUNTRY_NAME: 'SET_SELECTED_RECIPIENT_COUNTRY_NAME',
  SET_SELECTED_DELIVERY_METHOD: 'SET_SELECTED_DELIVERY_METHOD',
  SET_MAX_TRANSACTION_AMOUNT_SELECTED_DELIVERY_METHOD: 'SET_MAX_TRANSACTION_AMOUNT_SELECTED_DELIVERY_METHOD',
  SET_DELIVERY_METHODS: 'SET_DELIVERY_METHODS',
  SET_SETTING: 'SET_SETTING',
  SET_AMOUNT: 'SET_AMOUNT',
  SET_IS_INVALID_SENDER_AMOUNT: 'SET_IS_INVALID_SENDER_AMOUNT',
  SET_IS_INVALID_PAYER_TRANSACTION_AMOUNT: 'SET_IS_INVALID_PAYER_TRANSACTION_AMOUNT',
  SET_IS_OPEN_MTF_PRICE_DETAILS: 'SET_IS_OPEN_MTF_PRICE_DETAILS',
  SET_AMOUNT_TO_RECEIVE: 'SET_AMOUNT_TO_RECEIVE',
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  SET_LOGGED_IN: 'SET_LOGGED_IN',
  SET_LOGIN_FAILED: 'SET_LOGIN_FAILED',
  SET_APP_ERROR: 'SET_APP_ERROR',
  SET_APP_LOADING: 'SET_APP_LOADING',
  SET_APP_LANGUAGE: 'SET_APP_LANGUAGE',
  SET_IS_GET_STARTED_LOGIN: 'SET_IS_GET_STARTED_LOGIN',
  SET_LOGIN_MODAL_VISIBLE: 'SET_LOGIN_MODAL_VISIBLE',
  SET_COOCKIE_CONCENT_VISIBLE: 'SET_COOCKIE_CONCENT_VISIBLE',
  SET_FAQ_ITEMS: 'SET_FAQ_ITEMS',
  SET_MAINTENANCE_MESSAGE: 'SET_MAINTENANCE_MESSAGE',
  SET_CONVERSION_MODE: 'SET_CONVERSION_MODE',
  SET_IS_CONVERTING: 'SET_IS_CONVERTING',
  /** DASHBOARD */
  SET_PAYMENT_BODY: 'SET_PAYMENT_BODY',
  SET_TRANSACTION_OBJECT: 'SET_TRANSACTION_OBJECT',
  SET_TRANSACTION_OBJECT_ORDER_ID: 'SET_TRANSACTION_OBJECT_ORDER_ID',
  SET_TRANSACTION_OBJECT_SENDING_SERVICE: 'SET_TRANSACTION_OBJECT_SENDING_SERVICE',
  SET_TRANSACTION_OBJECT_RECEIVING_SERVICE: 'SET_TRANSACTION_OBJECT_RECIEVING_SERVICE',
  SET_TRANSACTION_OBJECT_SENDING_CURRENCY: 'SET_TRANSACTION_OBJECT_SENDING_CURRENCY',
  SET_TRANSACTION_OBJECT_PAYMENT_SERVICE: 'SET_TRANSACTION_OBJECT_PAYMENT_SERVICE',
  SET_TRANSACTION_OBJECT_PAYMENT_SERVICE_ORDER_ID: 'SET_TRANSACTION_OBJECT_PAYMENT_SERVICE_ORDER_ID',
  SET_TRANSACTION_OBJECT_RECEIVING_CURRENCY: 'SET_TRANSACTION_OBJECT_RECIEVE_CURRENCY',
  SET_TRANSACTION_OBJECT_MONEY_TO_SEND: 'SET_TRANSACTION_OBJECT_MONEY_TO_SEND',
  SET_TRANSACTION_OBJECT_MONEY_TO_RECEIVE: 'SET_TRANSACTION_OBJECT_MONEY_TO_RECEIVE',
  SET_TRANSACTION_OBJECT_FEE: 'SET_TRANSACTION_OBJECT_FEE',
  SET_TRANSACTION_OBJECT_EXCHANGE_FEE: 'SET_TRANSACTION_OBJECT_EXCHANGE_FEE',
  SET_TRANSACTION_OBJECT_EXCHANGE_RATE: 'SET_TRANSACTION_OBJECT_EXCHANGE_RATE',
  SET_TRANSACTION_OBJECT_TOTAL: 'SET_TRANSACTION_OBJECT_TOTAL',
  SET_TRANSACTION_OBJECT_STATUS: 'SET_TRANSACTION_OBJECT_STATUS',
  SET_TRANSACTION_OBJECT_USER_ID: 'SET_TRANSACTION_OBJECT_USER_ID',
  SET_TRANSACTION_OBJECT_RECEIPIENT_ID: 'SET_TRANSACTION_OBJECT_RECEIPIENT_ID',
  SET_TRANSACTION_OBJECT_REASON_FOR_SENDING: 'SET_TRANSACTION_OBJECT_REASON_FOR_SENDING',
  SET_TRANSACTION_OBJECT_IS_SUSPECT: 'SET_TRANSACTION_OBJECT_IS_SUSPECT',
  SET_TRANSACTION_OBJECT_CREATED_AT: 'SET_TRANSACTION_OBJECT_CREATED_AT',
  SET_TRANSACTION_OBJECT_UPDATED_AT: 'SET_TRANSACTION_OBJECT_UPDATED_AT',
  SET_DASHBOARD_ERROR: 'SET_DASHBOARD_ERROR',
  SET_SELECTED_RECIPIENT: 'SET_SELECTED_RECIPIENT',
  SET_SELECTED_RECIPIENT_PAYERS: 'SET_SELECTED_RECIPIENT_PAYERS',
  SET_USER_RECIPIENTS: 'SET_USER_RECIPIENTS',
  SET_USER_TRANSACTIONS: 'SET_USER_TRANSACTIONS',
  SET_IS_REGISTRED: 'SET_IS_REGISTRED',
  SET_IS_EMAIL_CONFIRMED: 'SET_IS_EMAIL_CONFIRMED',
  SET_IS_VERIFIED_PEP: 'SET_IS_VERIFIED_PEP',
  SET_IS_PEP: 'SET_IS_PEP',
  SET_SEND_MONEY_DIALOG_IS_OPEN: 'SET_SEND_MONEY_DIALOG_IS_OPEN',
  SET_USER_TRANSACTIONS_AMOUNT_MONTH_LIMIT_REACHED: 'SET_USER_TRANSACTIONS_AMOUNT_MONTH_LIMIT_REACHED',
  SET_USER_TOTAL_TRANSACTION_AMOUNT_CURRENT_MONTH: 'SET_USER_TOTAL_TRANSACTION_AMOUNT_CURRENT_MONTH',
  SET_TRANSACTION_PAYMENT_STATUS: 'SET_TRANSACTION_PAYMENT_STATUS',
  /** USER */
  SET_USER: 'SET_USER',
  SET_TRANSACTION: 'SET_TRANSACTION',
  SET_TRANSACTION_STATS: 'SET_TRANSACTION_STATS',

  /** RECIPIENT */
  SET_RECIPIENTS: 'SET_RECIPIENTS',

  /** AUTH */
  SET_LOGOUT: 'SET_LOGOUT'
};
