import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import { Button, DialogActions, DialogContent } from '@mui/material';
import ContactForm from '../landing-page/pages/contact-page-section/ContactForm';
import CommonDialogTitle from '../common/DialogTitle';
import ContactUsDialogBackground from '../../assets/img/ContactUsDialogBackground.svg';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundImage: `url(${ContactUsDialogBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    '&.MuiDialog-paperWidthSm': {
      maxWidth: 800,
      [theme.breakpoints.down('md')]: {
        maxWidth: 'fit-content'
      }
    }
  },
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(7)
    }
  },
  abortButtonText: {
    opacity: 0.9,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#919ba8',
    textTransform: 'none'
  }
}));

function ContactUsDialog({ handleClose, open }) {
  const classes = useStyles();
  const { t } = useTranslation('dashboard-common');

  return (
    <Dialog
      transitionDuration={500}
      onClose={handleClose}
      open={open}
      scroll="body"
      classes={{ paper: classes.dialogPaper }}
    >
      <CommonDialogTitle
        title={t('needHelp')}
        subTitle={t('contactUs')}
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 svg-icon-style">
            <path d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
          </svg>
        }
        onClose={handleClose}
      />
      <DialogContent className={classes.dialogContent}>
        <ContactForm />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleClose}>
            <span className={classes.abortButtonText}>{t('cancel')}</span>
          </Button>
      </DialogActions>
    </Dialog>
  );
}

ContactUsDialog.defaultProps = {};

ContactUsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ContactUsDialog;
