/* eslint-disable no-extra-parens */
/* eslint-disable no-console */
import camelCase from 'lodash/camelCase';
import React from 'react';
import { Translation, useTranslation } from 'react-i18next';
import ERROR_CODES from '../constants/internalAppErrorCodes';

const getErrorCode = (error) => {
  if (error?.code && typeof error?.code === 'number') {
    return error.code;
  }
  if (error?.status && typeof error?.status === 'number') {
    return error.status;
  }
  if (error && error?.response?.status) {
    return error?.response?.status;
  }
  return 1000;
};

const errorMessage = (error) => {
  const { i18n } = useTranslation(['error-common']);
  const errorKey = camelCase(error?.message);
  if(process.env.NODE_ENV === 'development') console.log(errorKey);

  if (getErrorCode(error) >= 500 && !i18n.exists(errorKey)) {
    if (error?.message) {
      return error?.message;
    }
    return 'defaultErrorMessage';
  }

  if (getErrorCode(error) <= 499 && !i18n.exists(errorKey)) {
    if (error?.message) {
      return error?.message;
    }
    return 'defaultWarningMessage';
  }

  return errorKey;
};

export const getErrorTitle = (error) => ERROR_CODES.filter((err) => err.code === getErrorCode(error))[0]?.title;

export const errorHandler = (error) =>
  error && typeof error?.message === 'object' ? (
    error?.message
  ) : (
    <Translation ns={['error-common']}>{(t) => t(errorMessage(error))}</Translation>
  );
