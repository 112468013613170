/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import API from '../../../utils/api';
import AddRecipientDialog from './AddRecipientDialog';
import { setGlobalSelectedRecipient, setGlobalUserRecipients } from '../../../store/redux/actions/dashboardAction';
import Util from '../../../utils/Util';
import { payoutAccountType } from '../payoutAccountType';
import { useHandleRecipientChange } from '../send-money-dialog/handleRecipientChange';
import useUserRecipients from '../../../hooks/useUserRecipients';

const styles = makeStyles((theme) => ({
  addIconRoot: {
    width: 80,
    height: 80,
    fontSize: 24
  },
  recipientsAddNew: {
    color: theme.palette.globalAppTheme.globalAppButtonColor,
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.62,
    textTransform: 'none'
  }
}));

function AddRecipient({ text, short }) {
  const classes = styles();
  const { t } = useTranslation(['dashboard-common']);
  const dispatch = useDispatch();
  const { handleRecipientChange } = useHandleRecipientChange();
  const { fetchUserRecipients } = useUserRecipients(); 
  const { userRecipients, currentUserIsRegistred, currentUserEmailIsConfirmed, sendMoneyDialogIsOpen } = useSelector(
    (state) => state.dashboard
  );
  const { error: appError } = useSelector((state) => state.app);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [stepNumber, setStepNumber] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setError(null);
    setOpen(true);
  };

  const handleClose = () => {
    setError(null);
    setStepNumber(0);
    setOpen(false);
  };

  const dispatchSendMoneyToRecipientEvent = async (_recipient) => {
    if(!sendMoneyDialogIsOpen) {
      const addRecipientEvent = new CustomEvent('send-money-to-recipient', {
        detail: { recipient: _recipient, country: _recipient?.country },
        bubbles: true
      });
      dispatch(setGlobalSelectedRecipient(_recipient));
      const countryNameFromISO = Util.getCountryNameByISO3Code(_recipient?.country);
      setTimeout(() => {
        handleRecipientChange({..._recipient, country: countryNameFromISO });
        window.dispatchEvent(addRecipientEvent);
      }, 500);
    }
  }

  const addRecipient = async (recipient) => {
    setLoading(true);
    const _recipient = _pick(recipient, [
      'firstName',
      'lastName',
      'street',
      'city',
      'country',
      'citizenship',
      'relationship'
    ]);
    const payer = _get(recipient, 'payer', {});
    const recipientPayout = payoutAccountType(payer.type, recipient.recipientPayoutAccount, '');
    const country = Util.getISO3Code(_recipient.country);
    const citizenship = Util.getISO3Code(_recipient.citizenship);
    API.post('/recipient', {
      recipient: { ..._recipient, country, citizenship },
      recipientPayer: {
        payerId: payer.id,
        firstName: _recipient.firstName,
        lastName: _recipient.lastName,
        ...recipientPayout.accountFormat
      }
    })
      .then((response) => {
        const { data } = response;
        userRecipients.push(data);
        dispatch(setGlobalUserRecipients(userRecipients));
        payoutAccountType(payer.type, '');
        fetchUserRecipients();
        dispatchSendMoneyToRecipientEvent(data);
        handleClose();
        setLoading(false);
      })
      .catch((_error) => {
        setLoading(false);
        setError(_error);
      });
  };

  const welcomeDialogOpen = (e) => {
    setTimeout(() => {
      if (userRecipients && e?.detail?.userIsRegistred && userRecipients?.length === 0) {
        setOpen(true);
      }
    }, 500);
  }
  React.useEffect(() => {
    window.addEventListener('welcome-dialog-close', welcomeDialogOpen);
    return () => window.removeEventListener('welcome-dialog-close', welcomeDialogOpen);
  }, [currentUserIsRegistred, currentUserEmailIsConfirmed])

  return short ? (
    <Grid item sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
      <Button
        disabled={!currentUserIsRegistred || !!appError}
        variant="text"
        size="large"
        className={classes.recipientsAddNew}
        startIcon={<AddIcon classes={{ root: classes.addIconRoot }} fontSize="large" size="large" />}
        style={{
          padding: 0,
          backgroundColor: 'transparent'
        }}
        onClick={handleClickOpen}
        title={t('addNewRecipient')}
      />
      <AddRecipientDialog
        addRecipient={addRecipient}
        open={open}
        onClose={handleClose}
        error={error}
        stepNumber={stepNumber}
        setStepNumber={setStepNumber}
        setError={setError}
      />
    </Grid>
  ) : (
    <>
      <Grid item sm={12} md={12} lg={12}>
        <Button
          disabled={!currentUserIsRegistred || !!appError}
          variant="text"
          size="large"
          className={classes.recipientsAddNew}
          startIcon={<PersonAddIcon fontSize="large" size="large" />}
          style={{
            padding: 0,
            backgroundColor: 'transparent'
          }}
          onClick={handleClickOpen}
        >
          {text}
        </Button>
      </Grid>
      <AddRecipientDialog
        setLoading={setLoading}
        loading={loading}
        addRecipient={addRecipient}
        open={open}
        onClose={handleClose}
        error={error}
        stepNumber={stepNumber}
        setStepNumber={setStepNumber}
        setError={setError}
      />
    </>
  );
}

AddRecipient.defaultProps = {
  short: false
};

AddRecipient.propTypes = {
  text: PropTypes.string.isRequired,
  short: PropTypes.bool
};
export default AddRecipient;
