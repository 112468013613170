import WariLogo from '../../assets/img/payer-logo/wari-logo.jpg';
import DahabshiilLogo from '../../assets/img/payer-logo/dahabshiil-logo.jpg'
import JubaExpressLogo from '../../assets/img/payer-logo/juba-express.jpg';
import EquityGroupLogo from '../../assets/img/payer-logo/equity-bank-tanzania-limited-logo.jpg';
import TigoPesaLogo from '../../assets/img/payer-logo/tigo-pesa-logo.jpg';
import MPesaLogo from '../../assets/img/payer-logo/m-pesa-logo.jpg';
import AirtelLogo from '../../assets/img/payer-logo/airtel-money-logo.jpg';
import MTNLogo from '../../assets/img/payer-logo/mtn-logo.jpg';
import EquitelMoneyLogo from '../../assets/img/payer-logo/equitel-money.jpg'
import AmoleLogo from '../../assets/img/payer-logo/amole-logo.jpg';
import HelloCashLogo from '../../assets/img/payer-logo/hello-cash-logo.jpg';
import HormuudLogo from '../../assets/img/payer-logo/hormuud-logo.jpg';
import PremeierWalletLogo from '../../assets/img/payer-logo/premier-wallet.png';
import EBesaMobileWalletLogo from '../../assets/img/payer-logo/eBesa-logo.png';
import IBSBankLogo from '../../assets/img/payer-logo/IBS-bank-logo.png';
import EDahabLogo from '../../assets/img/payer-logo/eDahab.png';
import ZaadLogo from '../../assets/img/payer-logo/zaad-logo.png';
import GolisSahalLogo from '../../assets/img/payer-logo/golis-sahal.png';
import BakaalLogo from '../../assets/img/payer-logo/bakaal-logo.png'
import NoImg from '../../assets/img/payer-logo/wallet-icon.jpg';

export const payerLogo = (payer) => {
  switch (payer) {
    case 'Wari':
      return WariLogo;
    case 'Dahabshiil':
      return DahabshiilLogo;
    case 'JubaExpress':
      return JubaExpressLogo;
    case 'Equity Bank Tanzania Limited':
      return EquityGroupLogo
    case 'Tigo Pesa': 
      return TigoPesaLogo
    case 'M-Pesa':
      return MPesaLogo
    case 'Airtel Money':
      return AirtelLogo
    case 'MTN Mobile Money':
      return MTNLogo
    case 'Equitel Money':
      return EquitelMoneyLogo
    case 'Amole':
      return AmoleLogo
    case 'HelloCash':
      return HelloCashLogo
    case 'Hormuud-EVC':
    case 'EVC':
      return HormuudLogo
    case 'Premier Wallet':
      return PremeierWalletLogo
    case 'EBesa Mobile Wallet':
      return EBesaMobileWalletLogo
    case 'EBesa Cash Pickup':
      return EBesaMobileWalletLogo
    case 'IBS Bank':
      return IBSBankLogo
    case 'EDahab':
      return EDahabLogo
    case 'Zaad':
      return ZaadLogo
    case 'Sahal':
      return GolisSahalLogo
    case 'Bakaal':
      return BakaalLogo
    default:
      return NoImg;
  }
};
