import termsAppendixA from "./terms-appendix-a";


const terms = [
  {
    id: 1,
    title: '',
    text: [
      {
        textSubTitle: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ],
    readMore: '',
    readMoreUrl: ''
  },
  {
    id: 2,
    title: 'General',
    text: [
      {
        textSubTitle: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 3,
    title: 'Notices & language',
    text: [
      {
        textSubTitle: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 4,
    title: 'Prerequisites for the use of the Service',
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 5,
    title: 'Customer due diligence',
    text: [
      {
        textSubTitle: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 6,
    title: 'Carrying out a Transfer',
    text: [
      {
        textSubTitle: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 7,
    title: 'Availability',
    text: [
      {
        textSubTitle: ''
      }
    ]
  },
  {
    id: 8,
    title: 'Vivowires responsibility for the execution of a Transfer',
    text: [
      {
        textSubTitle: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 9,
    title: 'Users responsibility',
    text: [
      {
        textSubTitle: '',
        indent: true,
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubTitle: '',
        indent: true,
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 10,
    title: 'Fees and exchange rates',
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },      {
        textSubText: ''
      }
    ]
  },
  {
    id: 11,
    title: 'Limitation to Vivowires liability',
    text: [
      {
        textSubTitle: '',
        indent: true,
        bold: true
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubTitle: '',
        indent: true,
        bold: true
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 12,
    title: 'Term',
    text: [
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 13,
    title: 'Additions and changes',
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 14,
    title: 'Termination',
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 15,
    title: 'Intellectual property',
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 16,
    title: 'Assignment',
    text: [
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 17,
    title: 'Processing of personal data',
    text: [
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 18,
    title: 'Complaints',
    text: [
      {
        textSubText: ''
      },
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 19,
    title: 'Governing law and jurisdiction',
    text: [
      {
        textSubText: ''
      }
    ]
  },
  {
    id: 20,
    title: '',
    text: [
      {
        textSubText: '',
        indent: true,
        bold: true
      }
    ]
  },
  ...termsAppendixA
]

export default terms;
