import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Fade, Grid , Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _camelCase from 'lodash/camelCase';

import { useDispatch, useSelector } from 'react-redux';
import NumberFormatter from '../../utils/NumberFormatter';
import { setGlobalIsInvalidPayerTransactionAmount } from '../../store/redux/actions/moneyTransferBoxAction';

const useStyles = makeStyles(() => ({
  amountLimitInfoMessage: {
    margin: '8px 0'
  }
}));

function PayerTransactionAmountLimitMessage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('landing-page-common');
  const { selectedDeliveryMethod, isInvalidSenderAmount, amountToReceive, deliveryMethods, convertedAmountError } = useSelector(
    (state) => state.moneyTransfer
  );

  const [isValidPayerTransactionAmount, setIsValidPayerTransactionAmount] = useState();

  useEffect(() => {
    if (
      selectedDeliveryMethod?.maxTransaction &&
      selectedDeliveryMethod?.minTransaction &&
      ((amountToReceive && amountToReceive > selectedDeliveryMethod?.maxTransaction) ||
        amountToReceive < selectedDeliveryMethod?.minTransaction)
    ) {
      dispatch(setGlobalIsInvalidPayerTransactionAmount(true));
      setIsValidPayerTransactionAmount(true);
    } else {
      dispatch(setGlobalIsInvalidPayerTransactionAmount(false));
      setIsValidPayerTransactionAmount(false);
    }
  }, [isValidPayerTransactionAmount, amountToReceive]);

  return (!convertedAmountError && isValidPayerTransactionAmount && !isInvalidSenderAmount) || deliveryMethods.length === 0 ? (
    <Grid item xs={12} className="payer-limit-message">
      <Fade in={isValidPayerTransactionAmount || deliveryMethods.length === 0} timeout={{ enter: 800, exit: 800 }}>
        <Alert severity="info" className={classes.amountLimitInfoMessage}>
          {deliveryMethods.length === 0 ?
            t('noDeliveryMethodsForSelectedCurrency') :
            t('serviceTransactionLimitAmount', {
                maxServiceAmount: `${
                  NumberFormatter.svAmountFormatNoFloat(selectedDeliveryMethod?.maxTransaction) || '0'
                } ${selectedDeliveryMethod?.currency}`,
                minServiceAmount: `${
                  NumberFormatter.svAmountFormatNoFloat(selectedDeliveryMethod?.minTransaction) || '0'
                } ${selectedDeliveryMethod?.currency}`,
                selectedDeliveryMethod: t(_camelCase(selectedDeliveryMethod?.type))
              })}
        </Alert>
      </Fade>
    </Grid>
  ) : null;
}

export default PayerTransactionAmountLimitMessage;
