import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  ListItem,
  ListItemText,
  Grid,
  Avatar,
  ListItemAvatar,
  ListItemSecondaryAction,
  Fade,
  List
} from '@mui/material';
import Alert from '@mui/material/Alert';
import useStyles from '../dashboardStyles';
import AddRecipient from './AddRecipient';
import RecipientOption from './RecipientOption';
import { errorHandler } from '../../../utils/errorMapper';
import Loading from '../../common/Loading';
import Util from '../../../utils/Util';

function Recipients({ loading, error }) {
  const classes = useStyles();
  const { userRecipients } = useSelector((state) => state.dashboard);
  const { t } = useTranslation(['dashboard-common']);
  const [recipientOptionError, setRecipientOptionError] = useState(null);

  const handleRecipientOptionError = (_error) => {
    setRecipientOptionError(_error);
  };

  const handleRecipientClickEvent = (e, id) => {
    const recipientClickEvent = new CustomEvent('recipient-click', { bubbles: true, detail: id });
    e.target.dispatchEvent(recipientClickEvent);
  }

  const recipientList = () => {
    if (userRecipients && userRecipients.length > 0) {
      return userRecipients.map((recipient) => (
        <div key={recipient.id} className={classes.recipientContainer}>
          <ListItem style={{ padding: 0, cursor: 'pointer', gap: 5 }} >
            <div
            style={{ display: 'flex', width: 400 }} 
            onClick={(e) => handleRecipientClickEvent(e, recipient.id)}
            onKeyDown={(e) => handleRecipientClickEvent(e, recipient.id)}
            role="button"
            tabIndex={0}
          >
            <ListItemAvatar>
              <Avatar className={classes.recipientsListAvatar}>
                {recipient?.firstName?.charAt(0)}
                {recipient?.lastName?.charAt(0)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              style={{ marginLeft: 7 }}
              classes={{
                primary: classes.recipientsListName,
                secondary: classes.recipientsListSecondary
              }}
              primary={`${recipient?.firstName} ${recipient?.lastName}`}
              secondary={<span>{`${Util.getCountryNameByISO3Code(recipient?.country) || ''}`}</span>}
            />
            </div>
            <ListItemSecondaryAction>
              <RecipientOption
                recipient={{ ...recipient, country: Util.getCountryNameByISO3Code(recipient?.country) }}
                recipientId={recipient.id}
                handleError={handleRecipientOptionError}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="middle" className={classes.divider} />
        </div>
      ));
    }
    return null;
  };

  React.useEffect(() => {
    recipientList();
  }, [userRecipients]);

  return (
    <Grid item xs={12} sm={12} md={12} lg={4} className="lg-p-custom">
      <Grid item xs={10}>
        <Divider className={classes.topHorizontalDivider} />
      </Grid>
      <Grid container item style={{ marginTop: 44 }}>
        <Fade in={recipientOptionError} timeout={1000}>
          <Grid item xs={11}>
            {!!recipientOptionError && 
              <Alert severity={recipientOptionError?.type || 'error'}>{errorHandler(recipientOptionError)}</Alert>
            }
          </Grid>
        </Fade>
        <Grid className={classes.recipientsTitleContainer} container direction="column">
          <Grid item sm={12} md={6} lg={6} className={classes.recipientsTitle}>
            {t('yourRecipients')}
          </Grid>
          <AddRecipient text={t('addNewRecipient')} />
        </Grid>
        {loading ? 
          <Loading /> :
         (
          <Grid container item direction="column" style={{ marginTop: 30 }}>
            <Grid item sm={6} md={12} lg={12}>
              <List className={classes.gridListRecipient} cols={1}>
                {!!error && <Alert severity="error">{errorHandler(error)}</Alert>}
                {recipientList()}
              </List>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

Recipients.defaultProps = {
  loading: undefined,
  error: undefined
};

Recipients.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default Recipients;
