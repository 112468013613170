import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { Grid, IconButton, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CommonDialogTitle from '../../common/DialogTitle';
import TransactionReceiptPDF from './TransactionReceiptPdf';
import Loading from '../../common/Loading';
import RecentTransactionsStatus from './RecentTransactionsStatus';
import TRANSACTION_STATUS from '../../../constants/transactionStatus';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1000,
    width: '100%',
    maxWidth: 500,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2)
    }
  },
  boxContainer: {
    display: 'flex',
    margin: 'auto',
    padding: theme.spacing(1),
    maxWidth: '400px',
    width: '100%'
  },
  title: {
    textAlign: 'left',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(50),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  keyGridItem: {
    textAlign: 'left'
  },
  key: {
    color: '#2f4662',
    fontSize: theme.typography.pxToRem(19),
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-1.42px'
  },
  valueGridItem: {
    textAlign: 'right'
  },
  value: {
    color: '#2f4662',
    fontSize: theme.typography.pxToRem(18),
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-1.42px'
  },
  divider: {
    width: '100%',
    background: '#21a1c5',
    height: 1
  },
  link: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: 'normal',
    color: '#21a2c6',
    textDecoration: 'none',
    margin: 10,
    display: 'flex',
    justifyContent: 'center',
    gap: 5
  }
}));

function RecentTransactionDetails({ open, transaction }) {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [error, value, copy] = useCopyToClipboard();
  const { t } = useTranslation(['dashboard-common']);

  const pdfPrintButton = () => <Grid item xs={12}>
    <PDFDownloadLink style={{ textDecoration: 'none' }} document={<TransactionReceiptPDF transaction={transaction} />} fileName={`vivowire-transaction-receipt-${transaction.date}.pdf`}>
      {({/*  blob, url, */ loading/* , error */ }) =>
        loading ? <Loading /> :
          <span className={classes.link}>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 svg-icon-style">
              <path d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75" />
            </svg>
            {`${t('downloadReceipt')} (PDF)`}
          </span>
      }
    </PDFDownloadLink>
  </Grid>

  if (transaction) {
    return (
      <Drawer
        anchor="right"
        open={open}
        PaperProps={{
          classes: {
            root: classes.root
          }
        }}
      >
        <Grid container row spacing={1} justifyContent="center" style={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            <CommonDialogTitle
              title={t('transactionDetails')}
              subTitle={
                <Trans t={t}>
                  <span style={{ fontWeight: 400 }}>{t('sent')}</span> {transaction.sent}{' '}
                  <span style={{ fontWeight: 400 }}>{t('to')}</span> {transaction.name}
                  <RecentTransactionsStatus status={transaction.status} />
                </Trans>
              }
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 svg-icon-style">
                  <path d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              }
            /* onClose={() => console.log('close')} */
            />
          </Grid>
          <Grid container row spacing={2} justifyContent="center" className={classes.boxContainer}>
            <Grid item xs={6} className={classes.keyGridItem}>
              <Typography className={classes.key}>{t('transactionId')}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.valueGridItem}>
            <IconButton
              title={t('copyToClipboard')}
              aria-label="close"
              style={{ padding: 0 }}
              className={classes.closeButton}
              onClick={() => copy(transaction?.orderId)}
              size="large">
              <Typography className={classes.value}>{transaction?.orderId}</Typography>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ height: 20, width: 20 }} className="w-6 h-6 svg-icon-style">
                  <path d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" />
                </svg>
              </IconButton>
            </Grid>
            <Divider variant="middle" classes={{ root: classes.divider }} />
            <Grid item xs={6} className={classes.keyGridItem}>
              <Typography className={classes.key}>{t('youSent')}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.valueGridItem}>
              <Typography className={classes.value}>{transaction?.sent}</Typography>
            </Grid>
            <Divider variant="middle" className={classes.divider} />
            <Grid item xs={6} className={classes.keyGridItem}>
              <Typography className={classes.key}>{t('theyGot')}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.valueGridItem}>
              <Typography className={classes.value}>{transaction?.received}</Typography>
            </Grid>
            <Divider variant="middle" className={classes.divider} />
            <Grid item xs={6} className={classes.keyGridItem}>
              <Typography className={classes.key}>{t('exchangeRate')}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.valueGridItem}>
              <Typography className={classes.value}>{transaction?.exchangeRate}</Typography>
            </Grid>
            <Divider variant="middle" className={classes.divider} />
            <Grid item xs={6} className={classes.keyGridItem}>
              <Typography className={classes.key}>{t('ourFee')}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.valueGridItem}>
              <Typography className={classes.value}>{transaction?.fee}</Typography>
            </Grid>
            <Divider variant="middle" className={classes.divider} />
            <Grid item xs={6} className={classes.keyGridItem}>
              <Typography className={classes.key}>{t('totalPaid')}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.valueGridItem}>
              <Typography className={classes.value}>{transaction?.total}</Typography>
            </Grid>
          </Grid>
          {transaction?.status?.replace(/_/g, ' ') === TRANSACTION_STATUS.completed ? pdfPrintButton() : null}
        </Grid>
      </Drawer>
    );
  }
  return null;
}

RecentTransactionDetails.defaultProps = {
  transaction: undefined,
  open: false
};

RecentTransactionDetails.propTypes = {
  transaction: PropTypes.object,
  open: PropTypes.bool
};

export default RecentTransactionDetails;
