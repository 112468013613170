import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Title from './Title';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center'
  },
  message: {
    fontSize: '0.9em',
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    color: '#686868'
  },
  spinner: {
    color: theme.palette.globalAppTheme.globalAppColor
  }
}));

export default function Loading(props) {
  const classes = useStyles();
  const { message, title, subtitle, size } = props;
  return (
    <Box className={classes.container}>
      {title ? <Title subtitle={subtitle || null}>{title}</Title> : null}
      <CircularProgress size={size} color="inherit" className={classes.spinner} thickness={3} />
      <Box className={classes.message}>{message}</Box>
    </Box>
  );
}

Loading.defaultProps = {
  message: '',
  title: '',
  subtitle: '',
  size: 30
};

Loading.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  size: PropTypes.number
};
