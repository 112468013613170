import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, StyleSheet, Image /* PDFDownloadLink */, Font } from '@react-pdf/renderer';
import { useTranslation, Trans, Translation } from 'react-i18next';
import { camelCase } from 'lodash';
import Inter from '../../../assets/fonts/inter/inter-v2-latin-regular.ttf';
import Rubik from '../../../assets/fonts/rubik/rubik-v10-latin-regular.ttf';
import Logo from '../../../assets/img/vivowire-logo-outlined-no-text-color.png';

Font.register({ family: 'Inter', src: Inter });
Font.register({ family: 'Rubik', src: Rubik });

const styles = StyleSheet.create({
  topBar: { height: 10, width: 800, position: 'absolute', backgroundColor: '#21a2c6' },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'Rubik',
    color: '#2f4662',
    fontWeight: 600
  },
  date: {
    fontFamily: 'Rubik',
    fontSize: 16,
    textAlign: 'center',
    color: '#2f4662',
    padding: 8
  },
  status: {
    fontFamily: 'Rubik',
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 20,
    color: '#4a6582',
    padding: 8
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Inter'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Inter'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 235,
    height: 40,
    width: 55
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: '#999999'
  },
  mainText: {
    textAlign: 'center',
    padding: 20,
    fontSize: 12,
    marginBottom: 20,
    color: '#999999'
  },
  footer: {
    fontSize: 8,
    marginBottom: 10,
    textAlign: 'center',
    color: '#999999'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10
  },
  left: {
    marginLeft: 10,
    color: '#4a6582',
    flexGrow: 3,
    flexShrink: 0,
    flexBasis: 200
  },
  right: {
    color: '#2f4662',
    flexShrink: 0,
    flexGrow: 1,
    fontWeight: 'bold'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#999999'
  },
  divider: { height: 1, backgroundColor: '#21a2c6', width: 400, marginLeft: 20, marginRight: 100 }
});

function TransactionReceiptPDF({ transaction }) {
  const { t } = useTranslation(['dashboard-common']);
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.topBar} fixed />
        <Image style={styles.image} src={Logo} />
        <View style={styles.body}>
          <Text style={styles.header} fixed>
            {t('transactionReceipt')}
          </Text>
          <Text style={styles.title}>
            {' '}
            <Trans t={t}>{`${t('sent')} ${transaction.sent} ${t('to')} ${transaction.name}`}</Trans>
          </Text>
          <View style={{ height: 400 }}>
            <Text style={styles.date}>{transaction.fullDate}</Text>
            <Text style={styles.status}>{`--- ${t(camelCase(transaction.status))} ---`}</Text>
            <Text style={styles.mainText}>{t('weHaveSentTheTransactionReceipt')}</Text>
            <View style={styles.row}>
              <Text style={[styles.subtitle, styles.left]}>{`${t('transactionId')}#`}</Text>
              <Text style={[styles.subtitle, styles.right]}>{transaction.orderId}</Text>
            </View>
            <Text style={styles.divider} />
            <View style={styles.row}>
              <Text style={[styles.subtitle, styles.left]}>{t('youSent')}</Text>
              <Text style={[styles.subtitle, styles.right]}>{transaction.sent}</Text>
            </View>
            <Text style={styles.divider} />
            <View style={styles.row}>
              <Text style={[styles.subtitle, styles.left]}>{t('theyGot')}</Text>
              <Text style={[styles.subtitle, styles.right]}>{transaction.received}</Text>
            </View>
            <Text style={styles.divider} />
            <View style={styles.row}>
              <Text style={[styles.subtitle, styles.left]}>{t('exchangeRate')}</Text>
              <Text style={[styles.subtitle, styles.right]}>{transaction?.exchangeRate}</Text>
            </View>
            <Text style={styles.divider} />
            <View style={styles.row}>
              <Text style={[styles.subtitle, styles.left]}>{t('ourFee')}</Text>
              <Text style={[styles.subtitle, styles.right]}>{transaction.fee}</Text>
            </View>
            <Text style={styles.divider} />
            <View style={styles.row}>
              <Text style={[styles.subtitle, styles.left]}>{t('totalPaid')}</Text>
              <Text style={[styles.subtitle, styles.right]}>{transaction.total}</Text>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>
          <Text>
            <Translation ns={['landing-page-common']}>
              {(_t) => _t('copyright', { thisYear: new Date().getFullYear() })}
            </Translation>
          </Text>{'. '}
          <Text>
            <Translation ns={['landing-page-common']}>{(_t) => _t('companyAuthorization')}</Translation>
          </Text>
        </Text>
        <Text style={styles.mainText}> Vivowire AB</Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
}

TransactionReceiptPDF.defaultProps = {
  transaction: undefined
};
TransactionReceiptPDF.propTypes = {
  transaction: PropTypes.object
};

export default TransactionReceiptPDF;
