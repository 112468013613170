import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  ListItemText,
  IconButton,
  Dialog,
  DialogContent,
  Menu,
  MenuItem,
  ListItemIcon,
  Fade,
  Typography
, Alert } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from 'react-redux';
import CommonDialogTitle from '../../common/DialogTitle';
import API from '../../../utils/api';
import RecipientUpdateForm from './RecipientUpdateForm';
import { errorHandler } from '../../../utils/errorMapper';
import { setGlobalSelectedRecipient, setGlobalUserRecipients } from '../../../store/redux/actions/dashboardAction';
import ConfirmDialog from './ConfirmDialog';
import { useHandleRecipientChange } from '../send-money-dialog/handleRecipientChange';

const styles = makeStyles((theme) => ({
  dialogPaper: {
    '&.MuiDialog-paperWidthSm': {
      maxWidth: '720px',
      [theme.breakpoints.down('md')]: {
        maxWidth: 'min-content'
      }
    }
  },
  listItemIconRoot: {
    minWidth: 33
  },
  closeDialogButtonText: {
    opacity: 0.9,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#919ba8',
    textTransform: 'none'
  },
  menuPaper: {
    top: 'auto'
  },
  listItemText: {
    whiteSpace: 'break-spaces'
  }
}));

function EditRecipient({ recipient, handleError }) {
  const classes = styles();
  const { t } = useTranslation(['dashboard-common']);
  const dispatch = useDispatch();
  const { currentUserIsRegistred, currentUserEmailIsConfirmed, currentUserIsPep, currentUserIsVerifiedPep } = useSelector((state) => state.dashboard);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [error, setError] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { handleRecipientChange } = useHandleRecipientChange();

  const handleMenuItemClick = (event) => {
    setError(null);
    handleError(null);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClose = () => {
    setError(null);
    setOpenDialog(false);
    setAnchorEl(null);
  };

  const getUserRecipients = () => {
    API.get('/recipients')
      .then((res) => {
        dispatch(setGlobalUserRecipients(res.data));
      })
      .catch((recipientError) => {
        setError(recipientError);
      });
  };

  const deleteRecipient = async () => {
    setTimeout(() => {
      setOpenConfirmationDialog(true);
    }, 100);
  };

  const handleContinue = async () => {
    setError(undefined);
    handleError(null);
    const { id } = recipient;

    try {
      await API.delete(`/recipient/${id}`);
      handleMenuItemClose();
      getUserRecipients();
      setOpenConfirmationDialog(false);
    } catch (_error) {
      setError(_error);
      setAnchorEl(null);
      handleError(_error);
      getUserRecipients();
      setOpenConfirmationDialog(false);
    }

  };

  const handleCancel = () => {
    setOpenConfirmationDialog(false);
    handleMenuItemClose();
  }

  const handleDialogClose = () => {
    setError(null);
    handleError(null);
    setOpenDialog(false);
  };

  const handleDialogOpen = () => {
    setError(null)
    handleMenuItemClose();
    setOpenDialog(true);
  };

  const handleSend = (e) => {
    e.preventDefault();
    const listItemClickEvent = new CustomEvent('send-money-to-recipient', {
      detail: { recipient, country: recipient?.country },
      bubbles: true
    });
    dispatch(setGlobalSelectedRecipient(recipient))
    handleRecipientChange(recipient);
    e.target.dispatchEvent(listItemClickEvent);
    handleMenuItemClose();
  }

  const handleRecipientClickEvent = (e) => {
    if(e.detail === recipient?.id) {
      handleMenuItemClick(e);
    }
  }

  useEffect(() => {
      window.addEventListener('recipient-click', handleRecipientClickEvent);

    return () => {
      window.removeEventListener('recipient-click', handleRecipientClickEvent);
    } 
  }, [anchorEl]);

  return <>
    <Grid item xs={12}>
      <ConfirmDialog
        open={openConfirmationDialog}
        message={`${recipient.firstName} ${recipient.lastName}`}
        item={t('youAreAboutToDeleteARecipient')}
        handleContinue={handleContinue}
        handleCancel={handleCancel}
      />
      <IconButton
        title={t('recipientOptions')}
        edge="end"
        aria-label="option"
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleMenuItemClick}
        size="large">
        <MoreHorizIcon color='primary' />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleMenuItemClose}
        TransitionComponent={Fade}
        classes={{
          paper: classes.menuPaper
        }}
      >
        <MenuItem
          onClick={handleSend}
          disabled={(currentUserIsRegistred && !currentUserEmailIsConfirmed) || (currentUserIsPep && !currentUserIsVerifiedPep)}
        >
          <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography className={classes.listItemText}>{t('sendMoneyToRecipient', { name: `${recipient.firstName} ${recipient.lastName}` })}</Typography>} />
        </MenuItem>
        <MenuItem className='edit-recipient' onClick={handleDialogOpen}>
          <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography className={classes.listItemText}>{t('editRecipient')}</Typography>} />
        </MenuItem>
        <MenuItem className='delete-recipient' onClick={deleteRecipient}>
          <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography className={classes.listItemText}>{t('deleteRecipient')}</Typography>} />
        </MenuItem>
      </Menu>
    </Grid>
    <Dialog transitionDuration={500} onClose={handleDialogClose} open={openDialog} classes={{ paper: classes.dialogPaper }} disableScrollLock>
      <CommonDialogTitle
        title={t('editRecipient')}
        subTitle={t('editRecipientDetails')}
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style">
            <path d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
          </svg>
        }
        onClose={handleDialogClose}
      />
      <DialogContent dividers>
        {!!error && <Alert severity="error">{errorHandler(error)}</Alert>}
        <RecipientUpdateForm
          recipient={recipient}
          handleMenuItemClose={handleMenuItemClose}
          editMode={openDialog}
          handleCancelEdit={() => setOpenDialog(false)}
        />
      </DialogContent>
    </Dialog>
  </>;
}

EditRecipient.propTypes = {
  recipient: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    nationality: PropTypes.string,
    networkId: PropTypes.string,
    phoneNumber: PropTypes.string,
    street: PropTypes.string
  }).isRequired,
  handleError: PropTypes.func.isRequired
};
export default EditRecipient;
