import makeStyles from '@mui/styles/makeStyles';
import {
  primaryColor,
  infoColor,
  transition,
  boxShadow,
  drawerWidth
} from '../../../../assets/jss/materialKitReact';

const headerStyle = makeStyles((theme) => ({
  appBar: {
    display: 'block',
    border: '0',
    padding: '0.625rem 0',
    marginBottom: '20px',
    color: '#555',
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
    transition: 'all 150ms ease 0s',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    position: 'relative',
    zIndex: 'unset',
    '@media print': {
      display: 'none'
    }
  },
  absolute: {
    position: 'absolute',
    zIndex: '1100'
  },
  fixed: {
    position: 'fixed',
    zIndex: '10'
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',

    minHeight: '50px',
    flex: '1',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap'
  },
  /*   flex: {
      flex: 1,
    }, */
  title: {
    fontFamily: 'Inter',
    lineHeight: '30px',
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 600,
    borderRadius: theme.borderRadius,
    textTransform: 'none',
    color: 'inherit',
    padding: '8px 16px',
    letterSpacing: 'unset',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'transparent'
    }
  },
  leftLinksContainer: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '8em'
    }
  },
  brandAlt: {
    cursor: 'pointer',
    filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2))'
  },
  brand: {
    cursor: 'pointer',
    filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2))'
  },
  appResponsive: {
    margin: '20px 10px'
  },
  primary: {
    backgroundColor: primaryColor,
    color: '#FFFFFF',
    boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)'
  },
  info: {
    backgroundColor: infoColor,
    color: '#FFFFFF',
    boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)'
  },
  transparent: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    paddingTop: '25px',
    color: '#FFFFFF'
  },
  dark: {
    color: '#FFFFFF',
    backgroundColor: '#212121 !important',
    boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)'
  },
  white: {
    border: '0',
    padding: '0.625rem 0',
    marginBottom: '20px',
    color: '#555',
    backgroundColor: '#fff !important',
    boxShadow: '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)'
  },
  drawerPaper: {
    border: 'none',
    bottom: '0',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    width: drawerWidth,
    ...boxShadow,
    position: 'fixed',
    display: 'block',
    top: '0',
    height: '100vh',
    right: '0',
    left: 'auto',
    visibility: 'visible',
    overflowY: 'visible',
    borderTop: 'none',
    textAlign: 'left',
    paddingRight: '0px',
    paddingLeft: '0',
    ...transition
  }
}));

export default headerStyle;
