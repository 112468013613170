/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import _camelCase from 'lodash/camelCase';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { Fade, Grid , AlertTitle, Button } from '@mui/material';
import { Field } from 'formik';
import _isEmpty from 'lodash/isEmpty';

import { errorHandler } from '../../../utils/errorMapper';
import CustomButton from '../../common/Button';
import sendMoneyDialogStyles from './sendMoneyDialogStyle';
import API from '../../../utils/api';
import CommonDialogTitle from '../../common/DialogTitle';
import MultiStepForm, { FormStep } from '../recipient/MultiStepForm';
import ConfirmationDialog from './ConfirmationDialog';
import Jump from '../../../assets/svg/Jump-v2.svg';
import ContinueOrCancelDialog from '../common/ContinueOrCancelDialog';
import { FORM_TRANSLATIONS } from '../formTranslations';
import FormStepTransactionDetail from './FormStepTransactionDetails';
import FormStepSelectOrAddRecipient from './FormStepSelectOrAddRecipient';
import {
  setGlobalPaymentBody,
  setGlobalSelectedRecipient,
  setGlobalSelectedRecipientPayers,
  setGlobalSendMoneyDialogIsOpen,
  setGlobalUserTransactionAmountMonthLimitReached
} from '../../../store/redux/actions/dashboardAction';
import { setGlobalIsGetStartedLogin } from '../../../store/redux/actions/loginModalAction';
import { recipientPayoutAccountValidationSchema } from '../recipient-payout-account/recipientPayoutAccountValidationSchema';
import PayerTransactionAmountLimitMessage from '../../mtf/PayerTransactionAmountLimitMessage';
import { useScript } from '../../../hooks/useScript';
import PaymentModal from './PaymentModal';
import AppError from '../../../utils/appError';
import { externalUrl } from '../../../constants/dashboardContants';
import Loading from '../../common/Loading';
import DiffrentReceiverCountryMessage from './DiffrentReceiverCountryMessage';
import { moneyTransferBoxInitialValues } from '../../../store/constants';
import Util from '../../../utils/Util';
import { payoutAccountType } from '../payoutAccountType';
import { useHandleRecipientChange } from './handleRecipientChange';
import FormStepPaymentMethods from './FormStepPaymentMethods';
import BankTransferPayment from './BankTransferPayment';
import DebitCreditCardPaymentIFrame from './DebitCreditCardPaymentIFrame';
import { PAYMENT_SERVICE } from './constants';
import SelectedPayerUnavailable from './SelectedPayerUnavalaible';
import { accountValidate } from '../recipient-payout-account/accountValidate';
import useTransactions from '../../../hooks/useTransactions';
import useUserRecipients from '../../../hooks/useUserRecipients';

const useStyles = sendMoneyDialogStyles;

function SendMoneyDialog() {
  const [stepNumber, setStepNumber] = useState(0);
  const { Brite } = useScript(externalUrl.BRITE_CLIENT_URL, 'Brite');
  const dispatch = useDispatch();
  const classes = useStyles();
  const { fetchTransactions } = useTransactions();
  const { t } = useTranslation(['dashboard-common']);
  const {
    userRecipients: recipients,
    selectedRecipient: selectedRecipientFromStore,
    currentUserIsRegistred,
    currentUserEmailIsConfirmed,
    currentUserIsPep,
    currentUserIsVerifiedPep,
    selectedRecipientPayers,
    userTransactionAmountMonthLimitReached,
    userTotalTransactionAmountCurrentMonth,
    transactionPaymentStatus
  } = useSelector((state) => state.dashboard);

  const INITIAL_STATE_VALUES = {
    id: '',
    recipientPayoutAccount: '',
    reasonForSending: '',
    country: '',
    paymentOption: undefined
  };

  const { isGetStartedLogin } = useSelector((state) => state.loginModal);
  const user = useSelector((state) => state.userInfo.user);
  const { error: appError, setting } = useSelector((state) => state.app);
  const {
    amount,
    isInvalidSenderAmount,
    selectedDeliveryMethod,
    isInvalidPayerTransactionAmount,
    selectedRecipientCountryName,
    convertedAmountError
  } = useSelector((state) => state.moneyTransfer);
  const { reasonForSending: _reasonForSending } = useSelector((state) => state.dashboard.paymentBody);
  const [open, setOpen] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState(INITIAL_STATE_VALUES);
  const [selectedRecipientCountry, setSelectedRecipientCountry] = useState(selectedRecipientFromStore?.country || '');
  const [reasonForSending, setReasonForSending] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [cancleModalOpen, setCancleModalOpen] = useState(false);
  const [service, setService] = useState(selectedDeliveryMethod?.type);
  const [error, setError] = useState(null);
  const [formikErrors, setFormikErrors] = useState(null);
  const [createdPayerId, setCreatedPayerId] = useState();
  const [externalPaymentOpen, setExternalPaymentOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();
  const [loading, setLoading] = useState(false);
  // const briteIFrameContainerRef = useRef(null);
  const { handleRecipientChange } = useHandleRecipientChange();
  const [unlimitClientUrl, setUnlimitClientUrl] = useState('');
  const [transactionId, setTransactionId] = useState(null);
  const { fetchUserRecipients } = useUserRecipients();
  const { verifyPayoutAccount, getBenificieryMessage, getBenificieryLoading, getBenificieryError, setGetBenificieryError } = accountValidate(selectedRecipient, selectedRecipient?.payer);

  const currentRecipientPayer =
    selectedRecipientPayers.length > 0 ?
      selectedRecipientPayers.find((payer) => payer.payerId === selectedDeliveryMethod?.id) :
      {};

  const isDiffrentRecipientCountry =
    (selectedRecipientFromStore?.country &&
      selectedRecipientCountryName &&
      Util.getISO3Code(selectedRecipientFromStore?.country) !== selectedRecipientCountryName) ||
    false;

  const handleOnCloseConfirmationDialog = (reason) => {
    if (reason && reason === 'backdropClick') return;
    setConfirmationModalOpen(!confirmationModalOpen);
  };

  const handleClickOpen = () => {
    setError();
    setGetBenificieryError()
    setOpen(true);
    dispatch(setGlobalSendMoneyDialogIsOpen(true));
  };

  const handleCloseExternalPayment = (e, reason) => {
    if (reason && reason === 'backdropClick') return;
    setLoading(false);
    setExternalPaymentOpen(false);
    fetchTransactions();
  };

  const handleClose = (e, reason) => {
    if (reason && reason === 'backdropClick') return;
    setCancleModalOpen(!cancleModalOpen);
    setStepNumber(0);
    setSelectedRecipient({});
    setCancleModalOpen(false);
    setError();
    setGetBenificieryError()
    setOpen(false);
    dispatch(setGlobalSendMoneyDialogIsOpen(false));
    dispatch(setGlobalUserTransactionAmountMonthLimitReached(false));
  };

  const handleContinue = () => {
    setSelectedRecipient(...selectedRecipient);
    setReasonForSending(_reasonForSending);
    setStepNumber(1);
    setCancleModalOpen(false);
    dispatch(setGlobalSelectedRecipient(selectedRecipient));
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleClickBack = () => {
    setGetBenificieryError();
  }

  const sendMoneyWhenGetStartedLogin = () => {
    if (isGetStartedLogin) {
      if (currentUserIsRegistred && currentUserEmailIsConfirmed && currentUserIsPep && currentUserIsVerifiedPep) {
        setTimeout(() => {
          setOpen(true);
          dispatch(setGlobalSendMoneyDialogIsOpen(true));
          dispatch(setGlobalIsGetStartedLogin(false));
        }, 500);
      }
    }
  };

  const handleDeliveryMethodChange = () => {
    setSelectedRecipient({
      ...selectedRecipient,
      recipientPayoutAccount: ''
    });
  };

  const handlePayerChange = () => {
    setSelectedRecipient({
      ...selectedRecipient,
      recipientPayoutAccount: ''
    });
    return false;
  };

  const getRecipientPayers = (recipientId) => {
    API.get(`/recipient/${recipientId}`)
      .then((res) => {
        const { data } = res;
        dispatch(setGlobalSelectedRecipientPayers(data.recipientPayer));
        dispatch(setGlobalSelectedRecipient(data));
      })
      .catch((_error) => setError(_error));
  };

  const handleClickContinue = async () => {
    if (stepNumber === 0) {
      verifyPayoutAccount();
    }

    if (stepNumber === 1 && selectedRecipientFromStore?.recipientPayoutAccount) {
      const { type, id } = selectedDeliveryMethod;
      const recipientPayoutAccountTrimmed = selectedRecipient?.recipientPayoutAccount.replace(' ', '').trim();
      const selectedPayoutAccount = payoutAccountType(type, recipientPayoutAccountTrimmed, '');
      const existingPayer = selectedRecipientPayers.find((payer) => payer?.payerId === id);

      if (existingPayer) {
        setCreatedPayerId(existingPayer.id);
      }
      if (_isEmpty(existingPayer) && recipientPayoutAccountTrimmed && _isEmpty(formikErrors.recipientPayoutAccount)) {
        API.post('/recipient-payer', {
          recipientId: selectedRecipient?.id,
          firstName: selectedRecipient.firstName,
          lastName: selectedRecipient.lastName,
          payerId: id,
          ...selectedPayoutAccount.accountFormat
        })
          .then((res) => {
            const { data } = res;
            dispatch(setGlobalSelectedRecipient({ ...selectedRecipientFromStore, ...data }));
            setCreatedPayerId(data.id);
            fetchUserRecipients();
            getRecipientPayers(selectedRecipient?.id);
          })
          .catch((_error) => setError(_error));
      }
    }
  };

  const initiateBritePayment = (token) => {
    try {
      const client = new Brite(token);
      setExternalPaymentOpen(true);
      client.start(
        {
          selector: '#brite-payment-client',
          locale: 'en'
        },
        (briteState) => {
          try {
            if (briteState === Brite.STATE_COMPLETED) {
              handleClose();
              handleCloseExternalPayment();
              dispatch(setGlobalPaymentBody({}));
              setConfirmationModalOpen(true);
              fetchTransactions();
              setLoading(false);
            } else if (briteState === Brite.STATE_FAILED) {
              setLoading(false);
              const briteMappedError = {
                name: 'BriteError',
                type: 'error',
                origin: `Brite payment state (${briteState})`,
                message: 'Payment failed try again later or contact our support',
                code: 1000
              };
              throw new AppError(briteMappedError);
            }
          } catch (briteMappedError) {
            setError(briteMappedError);
          }
        },
        () => {
          setExternalPaymentOpen(false);
          client.stop();
          setLoading(false);
        }
      );
      // briteIFrameContainerRef.current.focus();
    } catch (_error) {
      throw new Error(_error);
    }
  };

  const initiateUnlimitPayment = (source) => {
    setUnlimitClientUrl(source);
    setExternalPaymentOpen(true);
  }

  const createTransaction = (_paymentMethod) => {
    setLoading(true);
    setService(selectedRecipient.service);

    const transactionBody = {
      recipientId: selectedRecipient.id,
      recipientPayerId: createdPayerId,
      amount,
      reasonForSending: selectedRecipient.reasonForSending,
      calculationMode: moneyTransferBoxInitialValues.SOURCE_AMOUNT
    };

    dispatch(setGlobalPaymentBody(transactionBody));

    API.post('transaction', transactionBody)
      .then((res) => {
        if (res.data.id) {
          API.get(`/transaction/${res.data.id}/pay?paymentService=${_paymentMethod}`)
            .then((_res) => {
              if (_paymentMethod === PAYMENT_SERVICE.brite) initiateBritePayment(_res.data?.token);
              if (_paymentMethod === PAYMENT_SERVICE.unlimit) initiateUnlimitPayment(_res.data?.url);
              setTransactionId(res.data.id);
            })
            .catch((_error) => {
              setLoading(false);
              setError(_error);
            });
        }
      })
      .catch((_error) => {
        setLoading(false);
        setError(_error);
      });
  };

  const handleSubmit = async (values) => {
    setPaymentMethod(values.paymentOption);
    createTransaction(values.paymentOption);
  };

  const paymentMethodOption = (selectedPaymentMethod) => {
    switch (selectedPaymentMethod) {
      case PAYMENT_SERVICE.brite:
        return <BankTransferPayment />;
      case PAYMENT_SERVICE.unlimit:
        return <DebitCreditCardPaymentIFrame source={unlimitClientUrl} />;
      default:
        return null;
    }
  };

  const handleSendMoneyToRecipientEvent = (e) => {
    setSelectedRecipient({ ...e.detail?.recipient });
    dispatch(setGlobalSelectedRecipient({ ...e.detail?.recipient }));
    getRecipientPayers(e.detail?.recipient?.id);
    setOpen(true);
    dispatch(setGlobalSendMoneyDialogIsOpen(true));
  };

  useEffect(() => {
    const monthlyLimit = setting?.amountMonthLimit
    const userTransactionLimit = monthlyLimit - userTotalTransactionAmountCurrentMonth - parseInt(amount, 10);

    if (userTransactionLimit === 0 || Util.isNegative(userTransactionLimit) && open) {
      dispatch(setGlobalUserTransactionAmountMonthLimitReached(true));
    } else {
      dispatch(setGlobalUserTransactionAmountMonthLimitReached(false));
    }

    const timeout = setTimeout(() => {
      sendMoneyWhenGetStartedLogin();
    }, 10);
    window.addEventListener('send-money-to-recipient', handleSendMoneyToRecipientEvent);
    window.addEventListener('delivery-method-change', handleDeliveryMethodChange);
    window.addEventListener('payer-change', handlePayerChange);

    if (selectedRecipientFromStore?.country) {
      setSelectedRecipientCountry(selectedRecipientFromStore?.country);
    }

    const iframContent = document.querySelector('iframe');
    if (iframContent) {
      iframContent.style.width = '100%'
    }

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('send-money-to-recipient', handleSendMoneyToRecipientEvent);
      window.removeEventListener('delivery-method-change', handleDeliveryMethodChange);
      window.removeEventListener('payer-change', handlePayerChange);
    };
  }, [
    user,
    selectedRecipientFromStore?.country,
    selectedRecipientPayers,
    isInvalidPayerTransactionAmount,
    isInvalidSenderAmount,
    paymentMethod,
    transactionId,
    transactionPaymentStatus
  ]);

  return (
    <>
      <CustomButton
        disabled={
          error ||
          appError ||
          (currentUserIsRegistred && !currentUserEmailIsConfirmed) ||
          (currentUserIsPep && !currentUserIsVerifiedPep)
        }
        id="send-money-button"
        className={classes.button}
        rel="noopener noreferrer"
        onClick={handleClickOpen}
        size="lg"
      >
        <Typography className={classes.buttonText}>{t('sendNow')}</Typography>
        <ArrowForwardIcon className={classes.arrow} />
      </CustomButton>
      {confirmationModalOpen && (
        <ConfirmationDialog
          open={confirmationModalOpen}
          handleClose={handleOnCloseConfirmationDialog}
          image={Jump}
          title={t('transactionSuccessful')}
          subTitle={
            <>
              <span>
                {t('transferToRecipientComplete', {
                  service: `${t(_camelCase(service)) || t('account'.toLocaleLowerCase())}`,
                  userEmail: user.email
                })}
              </span>
              <p className={classes.thankYou}>{t('thankYouForUsingVW')} 🙏</p>
            </>
          }
          button={[
            {
              text: 'backToDashboard',
              variant: 'outlined',
              color: 'secondary',
              handleButtonClick: () => {
                dispatch(setGlobalSelectedRecipient({}));
                setConfirmationModalOpen(false);
              }
            },
            {
              text: 'sendAnother',
              variant: 'contained',
              color: 'primary',
              handleButtonClick: () => {
                setSelectedRecipient(selectedRecipientFromStore);
                handleRecipientChange(selectedRecipientFromStore);
                setStepNumber(0);
                setConfirmationModalOpen(false);
                setOpen(true);
                dispatch(setGlobalSendMoneyDialogIsOpen(true));
              }
            }
          ]}
        />
      )}
      {cancleModalOpen && (
        <ContinueOrCancelDialog
          open={cancleModalOpen}
          handleContinue={handleContinue}
          handleCancel={handleCancel}
          title={t('cancel')}
          subTitle={t('cancelTransaction')}
          content={t('cancelTransactionMessage')}
          continueButtonText={t('continueTransaction')}
          cancelButtonText={t('cancelTransaction')}
          note={t('pleaseNote')}
        />
      )}
      <PaymentModal
        handleClose={handleCloseExternalPayment}
        open={externalPaymentOpen}
        maxWidth={paymentMethod === PAYMENT_SERVICE.brite ? 'xs' : 'md'}
        transactionId={transactionId}
        paymentService={paymentMethod}
      >
        {paymentMethodOption(paymentMethod)}
      </PaymentModal>
      <Dialog disableScrollLock classes={{ paper: classes.dialogPaper }} open={open} onClose={handleClose} aria-labelledby="main-payment-dialog">
        <MultiStepForm
          onContinueClick={handleClickContinue}
          onBackClick={handleClickBack}
          onClose={handleClose}
          lastStepName={t('confirm')}
          initialValues={selectedRecipient}
          onSubmit={handleSubmit}
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          setError={setError}
          disableStepper
          disabled={
            isInvalidSenderAmount ||
            !!error ||
            !!convertedAmountError ||
            isInvalidPayerTransactionAmount ||
            isDiffrentRecipientCountry ||
            loading ||
            userTransactionAmountMonthLimitReached ||
            !selectedDeliveryMethod.isActive ||
            getBenificieryError
          }
          snapshotState={({ values, errors }) => {
            setFormikErrors(errors);
            useEffect(() => {
              setReasonForSending(values.reasonForSending);

              if (currentRecipientPayer?.phoneNumber || currentRecipientPayer?.bankAccountNumber) {
                values.recipientPayoutAccount = '';
              }

              const updateValues = Object.assign(values, { ...values, country: selectedRecipient.country });

              if (updateValues.id && selectedRecipientFromStore) {
                const recipient = recipients.find(({ id }) => id === values.id);
                const updatedRecipient = Object.assign(selectedRecipientFromStore, {
                  ...selectedRecipientFromStore,
                  ...recipient,
                  reasonForSending: updateValues.reasonForSending,
                  payer: currentRecipientPayer,
                  country: Util.getCountryNameByISO3Code(recipient?.country),
                  recipientPayoutAccount:
                    currentRecipientPayer?.phoneNumber ||
                    currentRecipientPayer?.bankAccountNumber ||
                    values.recipientPayoutAccount
                });

                dispatch(setGlobalSelectedRecipient(updatedRecipient));
                setSelectedRecipient(updatedRecipient);
              }
            }, [values, selectedRecipientCountry, selectedDeliveryMethod, selectedRecipientPayers]);
          }}
        >
          <FormStep
            stepName={t('selectARecipientOrAddOne')}
            validationSchema={yup.object({
              id: yup.string().required(FORM_TRANSLATIONS.formRecipientMustbeSelected),
              reasonForSending: yup.string().required(FORM_TRANSLATIONS.formReasonForSendingMustbeSelected),
              ...recipientPayoutAccountValidationSchema(selectedDeliveryMethod?.type, t, selectedRecipient?.recipientPayoutAccount)
            })}
            heading={
              <>
                <CommonDialogTitle
                  title={t('sendMoney')}
                  subTitle={t('selectOrAddRecipient')}
                  icon={
                    <svg className="h-6 w-6 svg-icon-style" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                    </svg>
                  }
                  onClose={handleClose}
                />
                {userTransactionAmountMonthLimitReached && (
                  <Grid xs={12}>
                    <Fade timeout={3000} in={userTransactionAmountMonthLimitReached}>
                      <Alert severity="info">
                        <AlertTitle>
                          <strong>{t('youHaveReachedTheMaximumTransactionLimitForThisMonth')}</strong>
                        </AlertTitle>
                        {t('seeAccountStatusForInformation')}
                      </Alert>
                    </Fade>
                  </Grid>
                )}
                {convertedAmountError && (
                  <Grid xs={12} item>
                    <Fade timeout={3000} in={!!convertedAmountError}>
                      <Alert severity={convertedAmountError?.type || "error"}>
                        {errorHandler(convertedAmountError)}
                      </Alert>
                    </Fade>
                  </Grid>
                )}
                <DiffrentReceiverCountryMessage isDiffrentRecipientCountry={isDiffrentRecipientCountry} />
                <SelectedPayerUnavailable />
                <PayerTransactionAmountLimitMessage />
              </>
            }
          >
            <Field type="hidden" className="form-control" name="country" value={selectedRecipientCountry} />
            <FormStepSelectOrAddRecipient
              reasonForSending={reasonForSending}
              selectedRecipient={selectedRecipient}
              getBenificieryMessage={getBenificieryMessage}
              getBenificieryError={getBenificieryError}
            />
          </FormStep>
          <FormStep
            stepName="Transaction Details"
            heading={
              <CommonDialogTitle
                title={t('summary')}
                subTitle={t('transactionDetails')}
                icon={
                  <svg className="h-6 w-6 svg-icon-style" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                  </svg>
                }
                onClose={handleClose}
              />
            }
          >
            <Grid xs={12} item style={{ paddingBottom: 8, width: '100%' }}>{getBenificieryLoading ? <Loading size={30} /> : getBenificieryMessage}</Grid>
            {!!getBenificieryError && <Alert severity={getBenificieryError?.type || 'error'} action={
              getBenificieryError?.isDismissable ? 
              <Button onClick={() => setGetBenificieryError()} color="inherit" size="small">
                {t('continue')}
              </Button> : null
            }>{errorHandler(getBenificieryError)}</Alert>}
            {loading ? <Loading /> : <FormStepTransactionDetail stepName={t('transactionDetails')} />}
          </FormStep>
          <FormStep
            stepName="Payment Mehods"
            validationSchema={yup.object().shape({
              paymentOption: yup.string().required()
            })}
            heading={
              <CommonDialogTitle
                title={t('paymentMehods')}
                subTitle={t('selectAPaymentMethod')}
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 svg-icon-style">
                    <path d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                  </svg>
                }
                onClose={handleClose}
              />
            }
          >
            {!!error && <Alert onClose={() => setError(null)} severity={error?.type || 'error'}>{errorHandler(error)}</Alert>}
            <div>{formikErrors?.recipientPayoutAccount}</div>
            {loading ? <Loading /> : <FormStepPaymentMethods stepName={t('paymentMethods')} />}
          </FormStep>
        </MultiStepForm>
      </Dialog>
    </>
  );
}

export default SendMoneyDialog;
