// import LandingPageBG from '../../../../assets/img/landing-bg.png';
import LandingPageBG from '../../../../assets/img/hero.jpg';
import Util from '../../../../utils/Util';

const heroStyles = (theme) => ({
  wrapper: {
    background: `url(${LandingPageBG}) fixed`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingBottom: theme.spacing(2),
    backgroundAttachment: Util.isTouchDevice() ? 'scroll' : 'fixed',
    backgroundPosition: 'center center'
  },
  container: {
    maxWidth: '1440px',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(9)
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(6)
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3)
    }
  },
  mainText: {
    filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.1))',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#ffffff',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(20),
      padding: theme.spacing(2),
      width: 'auto',
      margin: 'auto'
    }
  },
  mainTextSub: {
    filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))',
    margin: 'auto',
    width: '100%',
    maxWidth: '70%',
    textAlign: 'center',
    color: '#FFF',
    fontWeight: 600,
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(68),
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      fontSize: theme.typography.pxToRem(34),
      width: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  }
});

export default heroStyles;
