import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Grid, Typography, CardMedia, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WhatsNewInVivowire from './WhatsNewInVivowire';
import LoginToSendModal from '../../../sign-in-modal/LoginModal';
import AgreementAmico1 from '../../../../assets/img/Outbound.svg';
import CustomBreadcrumbs from '../../../breadcrumbs/CustomBreadcrumbs';

const useStyles = makeStyles((theme) => ({
  whatsNewInVivowireSectionContainer: {
    background: '#fff',
    maxWidth: '1873px',
    margin: 'auto',
    marginTop: '330px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '0px',
      height: '100%',
      padding: '20px'
    }
  },
  whatsNewInVivowireHeadContainer: {
    background: 'rgb(33, 161, 197, 0.1)',
    height: '225px',
    maxWidth: '1222px',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      paddingTop: '40px',
      height: '100%',
      padding: '20px'
    }
  },
  mainText: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#4a6582',
    textAlign: 'left',
    marginBottom: '10px'
  },
  mainTextSub: {
    fontFamily: 'Rubik',
    fontSize: '36px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-1.42px',
    color: '#2f4662',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      paddingTop: '20px'
    }
  },
  cardMedia: {
    width: '207px',
    height: '207px',
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%'
    }
  },
  mediaContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      paddingTop: '125px'
    }
  },
  gridContainer: {
    padding: '95px',
    paddingTop: '0px'
  },
  textGrid: {
    padding: '60px',
    [theme.breakpoints.down('md')]: {
      padding: '0px'
    }
  },
  breadcrumbs: {
    position: 'absolute',
    top: '-30px',
    paddingLeft: '313px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px'
    }
  }
}));

function WhatsNewInVivowireSection() {
  const classes = useStyles();
  const { t } = useTranslation(['whats-new-page']);
  const { error } = useSelector((state) => state.app);

  return (
    <section id="what-new-in-vivowire-section">
      <Container
        maxWidth="md"
        className={classNames(classes.whatsNewInVivowireSectionContainer, 'lg-mg-bottom')}
      >
        <Grid item xs={12} md={12} lg={12} className={classes.breadcrumbs}>
          <CustomBreadcrumbs pathname="What's New In Vivowire" />
        </Grid>
        <Grid
          container
          direction="row"
          className={classNames(classes.whatsNewInVivowireHeadContainer, 'lg-mg-bottom-custom')}
        >
          <Grid item xs={12} md={9} lg={9} className={classNames(classes.textGrid, 'lg-mg-bottom-custom')}>
            <Typography variant="h5" component="h5" align="left" className={classNames(classes.mainTextSub)}>
              {t('whatsNewInVivoWire')}
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              align="left"
              className={classNames(classes.mainText, 'lg-mg-bottom-custom')}
            >
              {t('whatsNewInVivoWireSubTitle')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={3} className={classes.mediaContainer}>
            <CardMedia className={classes.cardMedia} component="img" image={AgreementAmico1} title="Agreement" />
          </Grid>
        </Grid>
        <WhatsNewInVivowire />
        <div style={{ display: 'none' }}>
          <LoginToSendModal appError={error} sendMoneyButtonText="" />
        </div>
      </Container>
    </section>
  );
}

WhatsNewInVivowireSection.propTypes = {};

export default WhatsNewInVivowireSection;
