/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, IconButton, Typography, Collapse, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  depositContext: {
    flex: 1
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  expandButtonText: {
    float: 'left',
    paddingLeft: '18px',
    marginTop: '14px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.62px',
    color: '#2f4662'
  },
  expandedText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582',
    margin: '16px 16px 32px'
  },
  expandedLinkText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: 'normal',
    color: '#21a2c6',
    textDecoration: 'none'
  },
  titleGridItem: {
    cursor: 'pointer'
  }
}));

export default function ExpandablePanel(props) {
  const {
    title,
    subTitle,
    readMore,
    readMoreUrl,
    textAlign,
    customClass,
    expandedTextTitleStyle,
    expandedTextStyle,
    titleNoWrap,
    children
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const readMoreLinkRef = useRef(null);
  const expandButtonRef = useRef(null);

  const handleExpandClick = (e) => {
    const expandEvent = new Event('help-center-item-expanded', { bubbles: true });
    e.target.dispatchEvent(expandEvent);
    setExpanded(!expanded);
  };

  const collapseRatedItem = () => {
    setTimeout(() => {
      setExpanded(false);
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener('item-rated', collapseRatedItem);
    return () => {
      window.removeEventListener('item-rated', collapseRatedItem);
    };
  }, [children]);

  return (
    <Grid container spacing={2} className={customClass}>
      {title &&
      <Grid item xs={10} onClick={handleExpandClick} className={classes.titleGridItem}>
        <Typography
          paragraph
          noWrap={expanded ? false : titleNoWrap}
          className={expandedTextTitleStyle || classes.expandButtonText}
          onClick={handleExpandClick}
        >
          {title}
        </Typography>
      </Grid>}
      <Grid item>
        <IconButton
          ref={expandButtonRef}
          className={`${clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })} ${classes.expandButton}`}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Read more"
          title=""
          edge="start"
          color="primary"
          size="large">
          <ExpandMoreIcon />
        </IconButton>
      </Grid>
        <Collapse in={expanded}>
        <Grid item xs={12} lg={12}>
          {subTitle && 
          <div className={expandedTextStyle || classes.expandedText} style={{ textAlign: textAlign && 'justify' }}>
            {subTitle}
          </div>}
            {readMoreUrl ? (
              <Typography className={classes.expandedText} style={{ textAlign: textAlign && 'justify' }}>
              <Link
                ref={readMoreLinkRef}
                color="inherit"
                className={classes.expandedLinkText}
                href={readMoreUrl}
                target="_blank"
                rel="noreferrer"
              >
                {readMore}
              </Link>
              </Typography>
            ) : null}
             {children}
            </Grid>
        </Collapse>
      
    </Grid>
  );
}

ExpandablePanel.defaultProps = {
  title: '',
  subTitle: '',
  readMore: '',
  textAlign: '',
  readMoreUrl: '',
  customClass: '',
  expandedTextTitleStyle: undefined,
  expandedTextStyle: undefined,
  titleNoWrap: false,
  children: []
};

ExpandablePanel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  readMore: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textAlign: PropTypes.string,
  readMoreUrl: PropTypes.string,
  customClass: PropTypes.string,
  expandedTextTitleStyle: PropTypes.string,
  expandedTextStyle: PropTypes.string,
  titleNoWrap: PropTypes.bool,
  children: PropTypes.node
};
