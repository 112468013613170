import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import _get from 'lodash/get';
import { CssBaseline } from '@mui/material';
import API from './utils/api';
import LandingPage from './components/landing-page/LandingPage';
import Dashboard from './components/dashboard/Dashboard';
import NotFoundPage from './components/landing-page/pages/404/NotFoundPage';
import PrivateRoute from './context/PrivateRoute';

import {
  setGlobalSetting,
  setGlobalSenderCountries,
  setGlobalRecipientCountries,
  setGlobalAppError,
  setGlobalAppLoading,
  setGlobalMaintenanceMessage
} from './store/redux/actions/rootAction';

import {
  setGlobalSenderCountryOptionValue,
  setGlobalRecipientCountryOptionValue
} from './store/redux/actions/moneyTransferBoxAction';
import PERMALINKS from './constants/permalinks';
import { moneyTransferBoxInitialValues } from './store/constants';
import { getPayersForRecipientCountry } from './components/mtf/getPayers';

const generateClassName = createGenerateClassName({
  productionPrefix: 'vivowire'
});

const fetchData = (url, params = {}) => API.get(url, params);
function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const {
    selectedSenderCountryName,
    selectedRecipientCountryName
  } = useSelector((state) => state.moneyTransfer);
  const [convertedAmount] = useState(useSelector((state) => state.moneyTransfer.convertedAmount));
  const { mapIsoCodeAndGetPayers } = getPayersForRecipientCountry();
  
  const updateCountryData = (countries, defaultCountry) =>
    countries.map((_country) =>
      _country.id === defaultCountry.id || _country.countryName === defaultCountry.countryName ?
        Object.assign(_country, defaultCountry) :
        _country
    );

  const setSenderCountry = (countries, initialCountry) => {
    const currentCountry = countries.find((co) => co.isoCode3 === initialCountry);
    if (currentCountry.length === 0) dispatch(setGlobalSenderCountryOptionValue(currentCountry));
  };

  const setRecipientCountry = (countries, initialCountry) => {
    const currentCountry = countries.find((co) => co.isoCode3 === initialCountry);
    if (currentCountry.length === 0) dispatch(setGlobalRecipientCountryOptionValue(currentCountry));
  };

  const handleFetchError = (_error) => useCallback(() => {
    dispatch(setGlobalAppError(_error));
    setError(_error);
  }, [error]);

  const initialFetch = () => {
    setIsLoading(true);
    dispatch(setGlobalAppError(null));
    dispatch(setGlobalAppLoading(true));
    axios
      .all([
        fetchData('/setting'),
        fetchData('/sender-countries'),
        fetchData('/recipient-countries'),
        fetchData(`/recipient-country/${moneyTransferBoxInitialValues.SELECTED_RECIPIENT_COUNTRY_NAME}`),
        fetchData(`/sender-country/${moneyTransferBoxInitialValues.SELECTED_SENDER_COUNTRY_NAME}`)
      ])
      .then(
        axios.spread(
          (
            settingRes,
            senderCountriesRes,
            recipientCountriesRes,
            initialRecipientCountryRes,
            initialSenderCountryRes
          ) => {
            const defaultSelectedSenderCountry = _get(initialSenderCountryRes, 'data', {});
            const defaultSelectedRecipientCountry = _get(initialRecipientCountryRes, 'data', {});

            const appSetting = _get(settingRes, 'data', []);
            const senderCountries = _get(senderCountriesRes, 'data', []);
            const recipientCountries = _get(recipientCountriesRes, 'data', []);

            updateCountryData(senderCountries, defaultSelectedSenderCountry);
            updateCountryData(recipientCountries, defaultSelectedRecipientCountry);

            setSenderCountry(senderCountries, selectedSenderCountryName);
            setRecipientCountry(recipientCountries, selectedRecipientCountryName);
            
            dispatch(setGlobalSetting(appSetting));
            dispatch(setGlobalSenderCountries(senderCountries));
            dispatch(setGlobalRecipientCountries(recipientCountries));
            dispatch(setGlobalMaintenanceMessage(appSetting?.maintenanceMessage));

            dispatch(setGlobalSenderCountryOptionValue(defaultSelectedSenderCountry));
            dispatch(setGlobalRecipientCountryOptionValue(defaultSelectedRecipientCountry));

            mapIsoCodeAndGetPayers(defaultSelectedRecipientCountry?.isoCode3, defaultSelectedRecipientCountry?.countryName);

            setIsLoading(false);
            dispatch(setGlobalAppLoading(false));
          }
        )
      )
      .catch((error_) => handleFetchError(error_));
  };

  const getCsrf = () => {
    API.get('/csrf')
      .then(() => {
        initialFetch();
      })
      .catch((error_) => handleFetchError(error_));
  };

  useEffect(() => {
    getCsrf();
  }, [convertedAmount]);

  return (
    <StylesProvider generateClassName={generateClassName}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path={PERMALINKS.HOME} exact element={<LandingPage error={error} isLoading={isLoading} />} />
          <Route path={PERMALINKS.CONTACT_US} element={<LandingPage />} />
          <Route path={PERMALINKS.WHATS_NEW_IN_VIVOWIRE} element={<LandingPage />} />
          <Route path={PERMALINKS.COMPANY_AND_TEAM} element={<LandingPage />} />
          <Route path={PERMALINKS.COOKIE_POLICY} element={<LandingPage />} />
          <Route path={PERMALINKS.PRIVACY_POLICY} element={<LandingPage />} />
          <Route path={PERMALINKS.TERMS_AND_CONDITIONS} element={<LandingPage />} />
          <Route path={PERMALINKS.HELP_CENTER} element={<LandingPage />} />
          <Route path={PERMALINKS.GET_STARTED} element={<LandingPage />} />
          {/* Private Routes */}
          <Route element={<PrivateRoute />}>
            <Route path="dashboard/*" element={<Dashboard />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </StylesProvider>
  );
}

export default App;
