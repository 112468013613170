import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import languages from '../../constants/languages';
import { setGlobalAppLanguage } from '../../store/redux/actions/rootAction';

function LanguageSelectMenu(props) {
  const { anchorEl, handleClose, setAnchorElement, setLanguage } = props;
  const { i18n } = useTranslation(['landing-page-common']);
  const dispatch = useDispatch();

  const handleMenuItemClick = (event, language) => {
    setLanguage(language);
    i18n.changeLanguage(language.code);
    dispatch(setGlobalAppLanguage(language));
    setAnchorElement(null);
    const languageSelectClickEvent = new CustomEvent('language-select', { bubbles: true });
    event.target.dispatchEvent(languageSelectClickEvent);
  };

  return (
    <Menu
      id="language-select-menu"
      disableScrollLock
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {languages &&
        languages.filter((language) => language.active).map((language) => (
          <MenuItem
            aria-label='right-header-menu-list-item'
            onClick={(event) => handleMenuItemClick(event, language)}
            value={language.language}
            key={language.language}
            className={language.language}
          >
            <div className={language.code}>{language.language}</div>
          </MenuItem>
        ))}
    </Menu>
  );
}

LanguageSelectMenu.defaultProps = {
  anchorEl: null
};

LanguageSelectMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  handleClose: PropTypes.func.isRequired,
  setAnchorElement: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired
};

export default LanguageSelectMenu;
