import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, useMediaQuery } from '@mui/material';
import LanguageSelectMenu from '../../../common/LanguageSelectMenu';
import SignInModal from '../../../sign-in-modal/LoginToDashboardButton';
import Button from '../../../common/Button';

import styles from './headerLinksStyle';

export default function RightHeaderLinks(props) {
  const classes = styles();
  const matches = useMediaQuery('(max-width: 1280px) and (min-width: 960px)'); 
  const { i18n } = useTranslation(['landing-page-common']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(useSelector((state) => state.app.appLanguage));
  const { color, hideLogin } = props;

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLoginButtonClick = (e) => {
    const listItemClickEvent = new CustomEvent('header-login-clicked', { bubbles: true });
    e.target.dispatchEvent(listItemClickEvent);
  }

  useEffect(() => {
    const currentLanguage = i18n.language;
    if (currentLanguage !== selectedLanguage.code) i18n.changeLanguage(selectedLanguage.code);
  }, [selectedLanguage]);

  return (
    <Box className={classes.rightLinksContainer}>
      <List className={classes.rightHeaderList}>
        <ListItem className={classes.listItem} id="header-tab-1">
          <Button
            aria-label="right-header-menu-list"
            aria-haspopup="true"
            color="transparent"
            onClick={handleLanguageClick}
            className={classes.navLink}
            style={{ color: color || 'inherit' }}
          >
            <LanguageIcon className={classes.languageIcon} />
            {matches ? selectedLanguage.language.slice(0, 2) : selectedLanguage.language}
            <KeyboardArrowDownIcon />
          </Button>
          <LanguageSelectMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            setAnchorElement={setAnchorEl}
            setLanguage={setSelectedLanguage}
          />
        </ListItem>
        <ListItem className={classes.listItem} id="header-tab-2" onClick={handleLoginButtonClick}>
          {hideLogin ? null : <SignInModal className={classes.navLink} />}
        </ListItem>
      </List>
    </Box>
  );
}

RightHeaderLinks.defaultProps = {
  color: 'white',
  hideLogin: false
};

RightHeaderLinks.propTypes = {
  color: PropTypes.string,
  hideLogin: PropTypes.bool
};
