import React from 'react';
import { useField, useFormikContext } from 'formik';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const styles = makeStyles((theme) => ({
  card: {
    maxWidth: 420,
    marginTop: 50
  },
  formFieldContainer: {
    display: 'Flex',
    justifyContent: 'center'
  },
  textfield: {
    borderRadius: theme.borderRadiusInput,
    backgroundColor: '#eef3f9',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    padding: '12px 15px 20px',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  inputLabel: {
    width: 'max-content',
    height: '32px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#4a6582',
    zIndex: 10,
    padding: '10px 25px 10px'
  },
  helperText: {
    fontSize: '0.9rem',
    fontFamily: 'Inter',
    color: '#f44336'
  }
}));

function PhoneField({ name, country, value, ...otherprops }) {
  const { recipientCountries, senderCountries } = useSelector((state) => state.app);
  const { recipientCountryOptionValue } = useSelector((state) => state.moneyTransfer)
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const classes = styles();
  const selectedCountry = [...recipientCountries, ...senderCountries].find((_country) => _country.countryName === country) || recipientCountryOptionValue;

  const fieldValue = () => {
    if(value.includes(selectedCountry?.phoneCode)) {
      return value;
    } 
    if(value === "") {
      return value.replace (/^/, selectedCountry?.phoneCode);
    }
    return value;
  }


  React.useEffect(() => {
   setFieldValue(name, '');

  }, [country])

  const configTextfield = {
    ...field,
    ...otherprops,
    variant: 'filled',
    fullWidth: true,
    value: fieldValue(),
    InputProps: {
      ...otherprops.InputProps,
      disableUnderline: true,
      className: classes.textfield,
      readOnly: false
    },
    InputLabelProps: {
      ...otherprops.InputLabelProps,
      className: classes.inputLabel,
      shrink: true
    }
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TextField style={{ width: '100%' }} {...configTextfield} />;
}

export default PhoneField;

PhoneField.defaultProps = {
  otherprops: undefined,
  value: '',
  country: ''
};

PhoneField.propTypes = {
  name: PropTypes.string.isRequired,
  country: PropTypes.string,
  value: PropTypes.string,
  otherprops: PropTypes.shape(PropTypes.object)
};
