import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Fade, Grid , Alert } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';



const useStyles = makeStyles(() => ({
  selectedPayerUnavailableInfoMessage: {
    margin: '8px 0'
  }
}));

function SelectedPayerUnavailable() {
  const classes = useStyles();
  const { t } = useTranslation(['landing-page-common']);

  const { selectedDeliveryMethod } = useSelector((state) => state.moneyTransfer);

  useEffect(() => {}, [selectedDeliveryMethod]);

  return !selectedDeliveryMethod.isActive ? (
    <Grid xs={12} item>
      <Fade in={!selectedDeliveryMethod.isActive} timeout={{ enter: 800, exit: 800 }} style={{ transitionDelay: !selectedDeliveryMethod ? '1000ms' : '500ms' }} unmountOnExit>
        <Alert severity="info" className={classes.selectedPayerUnavailableInfoMessage}>
          {t('serviceNotAvailable', { type: t('payer') })}
        </Alert>
      </Fade>
    </Grid>
  ) : null;
}

export default SelectedPayerUnavailable;
