import * as Yup from 'yup';
import { parse, isDate } from 'date-fns';
import { FORM_TRANSLATIONS } from '../formTranslations';

const today = new Date();

/* function formatDate(date) {
  return new Date(date).toLocaleDateString();
} */

/* function addHyphen(dateString) {
  let hyphenedString;
  if (typeof originalValue === 'string' && !/-/.test(dateString)) {
    hyphenedString = `${dateString.substr(0, 4)}-${dateString.substr(4, 2)}-${dateString.substr(6, 2)}`;
  }
  return hyphenedString;
} */

function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'yyyy-MM-dd', new Date());

  return parsedDate;
}

const RegistrationFormSchema = Yup.object().shape({
  /*   country: Yup.string().required(FORM_TRANSLATIONS.formCountryIsRequired), */
  /*   country: Yup.string().nullable().notRequired(),
  firstName: Yup.string()
    .matches(/[A-Za-zÇÖÜçöüĞğİıŞş]+/g, {
      message: FORM_TRANSLATIONS.formEnterValidFirstName,
      excludeEmptyString: false
    })
    .min(2, FORM_TRANSLATIONS.formNameMustBeXOrMore)
    .required(FORM_TRANSLATIONS.formFirsNameIsRequired),
  lastName: Yup.string()
    .matches(/[A-Za-zÇÖÜçöüĞğİıŞş]+/g, {
      message: FORM_TRANSLATIONS.formEnterValidLastName,
      excludeEmptyString: false
    })
    .min(2, FORM_TRANSLATIONS.formNameMustBeXOrMore)
    .required(FORM_TRANSLATIONS.formLastNameIsRequired), */
  email: Yup.string().email(FORM_TRANSLATIONS.formEnterValidEmail).required(FORM_TRANSLATIONS.formEmailIsRequired),
  /*   street: Yup.string()
    .min(2, FORM_TRANSLATIONS.formStreetMustBeXOrMore)
    .required(FORM_TRANSLATIONS.formStreetNameIsRequired),
  zipCode: Yup.string()
    .min(2, FORM_TRANSLATIONS.formZipCodeMustBeXOrMore)
    .required(FORM_TRANSLATIONS.formZipCodeIsRequired),
  city: Yup.string().min(2, FORM_TRANSLATIONS.formCityMustBeXOrMore).required(FORM_TRANSLATIONS.formCityIsRequired), */
  phoneNumber: Yup.string()
    .min(10, FORM_TRANSLATIONS.formEnterValidMobileNumber)
    .max(17, FORM_TRANSLATIONS.formEnterValidMobileNumber)
    /*     .nullable()
    .matches(/^\+(?:\d ?){6,14}\d$|^\(\d{2,4}\)(?: ?\d){5,13}\d$|^(?:\d ?){6,14}\d$/gm, { message: FORM_TRANSLATIONS.formEnterValidMobileNumber }) */
    .required(FORM_TRANSLATIONS.formMobileNumberIsRequired),
  receiverCountries: Yup.array()
    .min(1, FORM_TRANSLATIONS.formIntendedRecipientCountries)
    .required(FORM_TRANSLATIONS.formIntendedRecipientCountries)
    .nullable(),
  sendToMultipleRecipients: Yup.string().required(FORM_TRANSLATIONS.formAnswerYesOrNo).nullable(),
  originsOfFunds: Yup.array()
  .of(Yup.string())
  .required(FORM_TRANSLATIONS.selectOneOption)
  .min(1, FORM_TRANSLATIONS.selectOneOption),
  isPep: Yup.string().when('userIsPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formAnswerYesOrNo)
  }),
  userIsPep: Yup.string().required(FORM_TRANSLATIONS.formAnswerYesOrNo).nullable(),
  /*   isPep: Yup.string().when('userIsPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formAnswerYesOrNo)
  }), */
  checkboxGroupPepRolls: Yup.array().when('isPep', {
    is: 'yes',
    then: Yup.array()
      .of(Yup.string())
      .required(FORM_TRANSLATIONS.formAtleastOneRoleIsRequired)
      .min(1, FORM_TRANSLATIONS.formAtleastOneRoleIsRequired)
  }),
  checkboxGroupPepRollsPepCountry: Yup.string().when('isPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formCountryIsRequired)
  }),
  checkboxGroupPepRollsPepOrganisation: Yup.string().when('isPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formOragnizationIsRequired)
  }),
  checkboxGroupPepRollsPepTitle: Yup.string().when('isPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formTitleIsRequired)
  }),
  /*   checkboxGroupPepRollsPepFullName: Yup.string()
    .when('isPep', {
      is: 'yes',
      then: Yup.string().required(FORM_TRANSLATIONS.formFullNameIsRequired)
    }),
  checkboxGroupPepRollsPepDateOfBirth: Yup.date()
    .when('isPep', {
      is: 'yes',
      then: Yup.date().transform(parseDateString)
        .required(FORM_TRANSLATIONS.formDateIsRequired)
        .max(today, FORM_TRANSLATIONS.dateNotGreaterThanToday)
        .nullable()
    }).nullable(), */
  isFamilymemberOfPep: Yup.string().when('userIsPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formAnswerYesOrNo).nullable()
  }),
  isAssociateOfPep: Yup.string().when('userIsPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formAnswerYesOrNo).nullable()
  }),
  checkboxGroupPepFamilyRelation: Yup.array().when('isFamilymemberOfPep', {
    is: 'yes',
    then: Yup.array()
      .of(Yup.string())
      .required(FORM_TRANSLATIONS.formAtleastOneRelationshipIsRequired)
      .min(1, FORM_TRANSLATIONS.formAtleastOneRelationshipIsRequired)
  }),
  checkboxGroupPepFamilyRelationPepCountry: Yup.string().when('isFamilymemberOfPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formCountryIsRequired)
  }),
  checkboxGroupPepFamilyRelationPepFullName: Yup.string().when('isFamilymemberOfPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formFullNameIsRequired)
  }),
  checkboxGroupPepFamilyRelationPepOrganisation: Yup.string().when('isFamilymemberOfPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formOragnizationIsRequired)
  }),
  checkboxGroupPepFamilyRelationPepTitle: Yup.string().when('isFamilymemberOfPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formTitleIsRequired)
  }),
  checkboxGroupPepFamilyRelationPepDateOfBirth: Yup.date()
    .when('isFamilymemberOfPep', {
      is: 'yes',
      then: Yup.date()
        .transform(parseDateString)
        .required(FORM_TRANSLATIONS.formDateIsRequired)
        .max(today, FORM_TRANSLATIONS.dateNotGreaterThanToday)
        .nullable()
    })
    .nullable(),
  checkboxGroupPepAssociateRelation: Yup.array().when('isAssociateOfPep', {
    is: 'yes',
    then: Yup.array()
      .of(Yup.string())
      .required(FORM_TRANSLATIONS.formAtleastOneRelationshipIsRequired)
      .min(1, FORM_TRANSLATIONS.formAtleastOneRelationshipIsRequired)
  }),
  checkboxGroupPepAssociateRelationPepCountry: Yup.string().when('isAssociateOfPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formCountryIsRequired)
  }),
  checkboxGroupPepAssociateRelationPepFullName: Yup.string().when('isAssociateOfPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formFullNameIsRequired)
  }),
  checkboxGroupPepAssociateRelationPepOrganisation: Yup.string().when('isAssociateOfPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formOragnizationIsRequired)
  }),
  checkboxGroupPepAssociateRelationPepTitle: Yup.string().when('isAssociateOfPep', {
    is: 'yes',
    then: Yup.string().required(FORM_TRANSLATIONS.formTitleIsRequired)
  }),
  checkboxGroupPepAssociateRelationPepDateOfBirth: Yup.date()
    .when('isAssociateOfPep', {
      is: 'yes',
      then: Yup.date()
        .transform(parseDateString)
        .required(FORM_TRANSLATIONS.formDateIsRequired)
        .max(today, FORM_TRANSLATIONS.dateNotGreaterThanToday)
        .nullable()
    })
    .nullable(),
  transferHabit: Yup.string().required(FORM_TRANSLATIONS.formTransferHabitRequired).nullable(),
  frequencyOfTransaction: Yup.string().required(FORM_TRANSLATIONS.formFrequencyOfTransactionRequired).nullable(),
  checkboxGroupPepOriginsOfFunds: Yup.array().when(['isPep', 'isFamilymemberOfPep', 'isAssociateOfPep'], {
    is: (isPep, isFamilymemberOfPep, isAssociateOfPep) =>
      isPep === 'yes' || isFamilymemberOfPep === 'yes' || isAssociateOfPep === 'yes',
    then: Yup.array()
      .of(Yup.string())
      .required(FORM_TRANSLATIONS.selectOneOption)
      .min(1, FORM_TRANSLATIONS.selectOneOption)
  }),
  recipientRelationship: Yup.array()
    .of(Yup.string())
    .required(FORM_TRANSLATIONS.selectOneOption)
    .min(1, FORM_TRANSLATIONS.selectOneOption),
  emailConsent: Yup.boolean().nullable()
});

export default RegistrationFormSchema;
