import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { InputAdornment } from '@mui/material';
import NumberFormatter from '../../utils/NumberFormatter';
import convertAmount from "./convertAmount";
import { setGlobalIsOpenMtfPriceDetails } from '../../store/redux/actions/moneyTransferBoxAction';
import CountryCurrencySelectMenu from '../country-currency-select/CountryCurrencySelectMenu';
import { moneyTransferBoxInitialValues } from '../../store/constants';
import Loading from '../common/Loading';
// import { InputAdornment } from '@mui/material';
// import Loading from '../common/Loading';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      background: '#F8FAFC',
      padding: theme.spacing(2)
    }
  },
  'MuiFormHelperText-root': {
    marginTop: '50px'
  },
  recipientAmountInput: {
    width: '100%',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    flex: 1,
    color: theme.palette.globalAppTheme.globalAppTextColor,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18)
    }
  },
  recipientAmountInputLabel: {
    width: 'max-content',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#4a6582',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  title: {
    marginRight: '2px',
    marginLeft: '2px'
  },
  errorText: {
    background: 'none',
    color: '#989898',
    padding: '3px',
    borderRadius: '0px',
    width: 'max-content',
    position: 'absolute'
  },
  helperText: {
    fontSize: '0.70rem',
    fontFamily: 'Inter'
  },
  gridItem: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  }
}));

function RecipientAmountInput() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['landing-page-common']);
  const { error: appError, setting } = useSelector((state) => state.app);
  const { selectedDeliveryMethod, deliveryMethods, isInvalidPayerTransactionAmount, amountToReceive, isConverting } = useSelector((state) => state.moneyTransfer);
  const [amount, setAmount] = useState('');
  const { getConvertedRate } = convertAmount();
  const [isFocusedInput, setIsFocusedInput] = useState(false);

  const getRate = (value) => {
    if (value && parseInt(value, 10) >= setting.minimumAmount) {
      getConvertedRate(
        value,
        selectedDeliveryMethod && selectedDeliveryMethod?.id,
        moneyTransferBoxInitialValues.DESTINATION_AMOUNT
      )
    }
  }

  const handleChangeAmount = (e) => {
    const { value } = e.target;
    setAmount(value);
    if (deliveryMethods.length > 0 && !isInvalidPayerTransactionAmount) dispatch(setGlobalIsOpenMtfPriceDetails(true))    
    getRate(value);
  };

  const handleInputClick = (e) => {
    e.preventDefault();
    setIsFocusedInput(true);
    setAmount((prevState) => {
      if(prevState !== amountToReceive) {
        return amountToReceive
      }
      return amount
    });
  }

  useEffect(() => {
    setAmount(amountToReceive)
  }, []);

  return (
      <Grid container justifyContent="space-evenly" className={classes.gridContainer}>
        <Grid item xs={7} sm={7} md={7} lg={7} className={classes.gridItem}>
        <div role="presentation" onClick={(e) => handleInputClick(e)} onBlur={() => setIsFocusedInput(false)}>
          <TextField
            variant="standard"
            autoComplete="off"
            id="recipient-amount-input"
            key="recipientAmountInput"
            onChange={handleChangeAmount}
            autoFocus
            type={isFocusedInput? "number" : "string"}
            InputProps={{
              disableUnderline: true,
              className: classes.recipientAmountInput,
              endAdornment: isConverting ? (
                <InputAdornment position='start'>
                  <Loading size={20} style={{ padding: 0 }} />
                </InputAdornment>
              ) : null 
            }}
            InputLabelProps={{
              className: classes.recipientAmountInputLabel,
              shrink: true
            }}
            value={(isFocusedInput ? amount : NumberFormatter.svAmountFormat(amountToReceive)) || ''}
            name="recipientAmount"
            label={t('mtfBoxRecipientLabel')}
            required
            aria-label="recipient-amount-input"
            placement="top-start"
            disabled={!!appError}
          />
          </div>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <CountryCurrencySelectMenu type="recipient" />
        </Grid>
      </Grid>
  );
}

RecipientAmountInput.defaultProps = {};

RecipientAmountInput.propTypes = {};

export default RecipientAmountInput;
