/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Translation } from 'react-i18next';
// import PropTypes from 'prop-types';
import { Grid, Container, Paper, Typography, Button, Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import helpCenterStyles from './helpCenterStyles';
import API from '../../../../utils/api';
import { setGlobalFaqItems } from '../../../../store/redux/actions/rootAction';
import ExpandablePanel from '../../../common/ExpandablePanel';
import CustomerSupport from '../../../../assets/img/CustomerSupport.png';
import SectionTemplate from '../../sections/SectionTemplate';
/* import SearchBar from './SearchBar'; */
import HelpType from './HelpType';
import HelpTypeIcon from './HelpTypeIcon';
import Ratings from '../../../common/Ratings';
import NoFAQ from '../../../../assets/svg/Match.svg';
import Loading from '../../../common/Loading';
import { ComponentError } from '../../../common/ErrorDisplay';
import NoResults from '../../../common/NoResults';
import PERMALINKS from '../../../../constants/permalinks';
import ArticleTemplate from './ArticleTemplate';
import { articles } from './articles';

const useStyles = makeStyles((theme) => helpCenterStyles(theme));
function HelpCenter() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['help-center-page']);
  const [selectedType, setSelectedType] = useState('all');
  const [faqItems, setFaqItems] = useState(useSelector((state) => state.app.faqItems) || []);
  const [searchString, setSearchString] = useState('');
/*   const [defaultSearchValue, setDefaultSearchValue] = useState(''); */
  const [ratingApiError, setRatingApiError] = useState(undefined);
  const [getFagsError, setGetFagsError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [helpItemExpanded, setHelpItemExpanded] = useState(false);

  const handleHelpItemExpandEvent = ()=> setHelpItemExpanded(!helpItemExpanded)

  const handleTypeSelect = (event, selected) => {
    setSearchString('');
    /* setDefaultSearchValue(''); */
    setSelectedType(selected);
  };

  const mapRating = (ratingValue) => {
    switch (ratingValue) {
      case '1':
        return 'one';
      case '2':
        return 'two';
      case '3':
        return 'three';
      case '4':
        return 'four';
      case '5':
        return 'five';
      default:
        return '0';
    }
  };

  const getFaqs = () => {
    API.get('/faqs')
      .then((res) => {
        setFaqItems(res.data)
        dispatch(setGlobalFaqItems(res.data))
        
      })
      .catch((error) => setGetFagsError(error));
  };

  const handleRatingChange = async (e, ratedItem) => {
    const mappedRatingValue = mapRating(e.target.value);
    ratedItem.ratings[mappedRatingValue]++;
    setLoading(true);
    API.put(`/rating/${ratedItem.id}`, { rating: mappedRatingValue })
      .then((res) => {
        faqItems.map((element, index) => {
          if (faqItems[index].id === ratedItem.id) {
            element.rated = !!res;
          }
          return faqItems;
        });
        dispatch(setGlobalFaqItems(faqItems));
        setLoading(false);
        const event = new Event('item-rated');
        window.dispatchEvent(event);
      })
      .catch((error) => {
        setLoading(false);
        setRatingApiError(error);
      });
  };

  /** TODO HITTA EN BRA LÖSNING FÖR SÖKNING */
  const filteredFaqs =
    faqItems &&
    faqItems.filter(
      (item) =>
        item.question.toLowerCase().includes(searchString.toLocaleLowerCase()) ||
        item.answer.toLowerCase().includes(searchString)
    );

/*   const handleSearchChange = (e) => {
    setSearchString(e.target.value);
  }; */

  useEffect(() => {
    window.addEventListener('help-center-item-expanded', handleHelpItemExpandEvent);

    if (faqItems.length === 0 && !getFagsError) getFaqs();
    return () => {
      window.removeEventListener('help-center-item-expanded', handleHelpItemExpandEvent);
    };
  }, [faqItems]);

  return (
    <SectionTemplate
      sectionId="help-center-page"
      sectionImg={CustomerSupport}
      sectionImgTitle="Customer Support"
      sectionTitle="howCanWeHelpYou"
      sectionSubTitle="sectionSubTitle"
      pathName="help-center"
      translation="help-center-page"
    >
      <Container id="help-center-conbtainer" className={classNames(classes.container)}>
        {getFagsError ? 
          <ComponentError /> :
         (
          <Grid container className={classNames(classes.gridContainer, 'lg-p-bottom-custom')}>
            {/* <SearchBar handleChange={handleSearchChange} defaultValue={defaultSearchValue} /> */}
            <HelpType handleTypeSelect={handleTypeSelect} selectedType={selectedType} />
            {filteredFaqs && filteredFaqs.length === 0 && 
              <NoResults title={t('noMatchesFoundFor', { searchString })} imgSrc={NoFAQ} style={{ paddingTop: 160 }} />
            }
            <div id="faqs-items-container">
              {filteredFaqs &&
                filteredFaqs
                  .filter((item) => {
                    if (selectedType === 'all') {
                      return filteredFaqs;
                    }
                    return item.type.toLowerCase() === selectedType;
                  })
                  .map((question, index) => {
                    const questionId = index + 1;
                    return (
                      <div className="lg-p-top-custom" key={question?.question} id={`faqs-item-${index}`}>
                        <Paper
                          elevation={1}
                          className={classNames(classes.paper)}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.expandeblePanelContainer}>
                            <ExpandablePanel
                              className={classNames(
                                classes.helpSubTitle,
                                'lg-mg-bottom-custom',
                                'app-theme-text-color'
                              )}
                              placement={{ component: 'HelpCenter', item: `HelpCenter Question ${questionId}` }}
                              title={
                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.titleIconContainer}>
                                <HelpTypeIcon type={question?.type} />
                                <Typography className={classNames(classes.helpTitle, 'app-theme-text-color')}>
                                  {t(camelCase(question?.question))}
                                </Typography>
                                <Typography className={classes.expandedTextTitleStyle}>{t(camelCase(question.answer))}</Typography>
                              </Grid>
                              }
                              expandedTextTitleStyle={classes.expandedTextTitleStyle}
                              expandedTextStyle={classes.expandedTextStyle}
                              textAlign="left"
                              customClass={`help-item-number-${questionId}`}
                              titleNoWrap
                              handleRatingChange={handleRatingChange}
                            >
                            <Box m={8} alignItems="center" gap={4} id="expandable-help-center-articles" className={classes.expandableHelpCenterArticles}>
                              {articles.map((article) => {
                                if(article?.id === question?.id) {
                                  return <ArticleTemplate content={article} key={article} />;
                                }
                                return null;
                              })}
                              </Box>
                              <Box mt={6}>
                              {loading ? 
                                <Loading /> :
                               (
                                <>
                                  {ratingApiError && <Alert severity="error">{t('submitRatingError')}</Alert>}
                                  <Ratings
                                    handleChange={handleRatingChange}
                                    rated={question?.rated}
                                    defaultValue={0}
                                    item={question}
                                  />
                                </>
                              )}
                              </Box>
                            </ExpandablePanel>
                          </Grid>
                        </Paper>
                      </div>
                    );
                  })}
            </div>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          alignContent="center"
          justifyContent="center"
          className={classNames(classes.contactButtonContainer, 'lg-p-top')}
        >
          <Grid item sm={12} md={6} lg={6}>
            <Typography variant="h5" component="h5" align="left" className={classNames(classes.needHelpText)}>
              {t('cantFindWhatYoureLookingFor')}
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} lg={6}>
            <Button
              variant="outlined"
              className={classes.extraLargeButton}
              classes={{ label: classes.extraLargeButtonLabel }}
              onClick={null}
            >
              <Translation ns={['landing-page-common']}>
                {(tr) => (
                  <a href={PERMALINKS.CONTACT_US} className={classes.contactSupportLink}>
                    {tr('contactSupport')}
                  </a>
                )}
              </Translation>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </SectionTemplate>
  );
}

HelpCenter.propTypes = {};

export default HelpCenter;
