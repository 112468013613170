/* eslint-disable import/no-unused-modules */
export const SOMALIA_SOMALILAND_ISO_CODE_3 = {
    SOMALILAND: 'SQM',
    SOMALIA: 'SOM'
};

export const SOMALIA_SOMALILAND_ISO_CODE_2 = {
    SOMALILAND: 'SQ',
    SOMALIA: 'SO'
};

export const SOMALIA_SOMALILAND = {
    SOMALILAND: 'Somaliland',
    SOMALIA: 'Somalia'
};

export const COUNTRY_TYPES = {
    SENDER: 'sender',
    RECIPIENT: 'recipient'
};

export const SOMALILAND_PAYERS = ['Telesom Zaad', 'Premier Wallet', 'JubaExpress']

/**
Somalia 
Mobil Wallets – Premier Wallet
Mobil Wallets - Golis Sahal
Mobil Wallets - Hormuud EVC plus
Cash pick up - Jubba express

Somaliland
Mobil Wallets – Telesom Zaad
Mobil Wallets – Premier Wallet
Cash pick up -JubbaExpress  */