import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer  } from 'recharts';
import { MONTHS } from '../../../constants/dashboardContants';

const styles = makeStyles(() => ({
  barChart: {
    padding: 0
  }
}));

function StatsBarChart({ periods }) {
  const { t } = useTranslation(['dashboard-common']);
  const classes = styles();
  const currentYear = new Date().getFullYear();
  return (
    <ResponsiveContainer width="94%" height={300}>
    <BarChart data={periods} barCategoryGap="20%" className={classes.barChart}>
      <CartesianGrid strokeDasharray="5" vertical={false} />
      <XAxis
        axisLine={false}
        dataKey={(value) => {
          if (value.year !== currentYear) {
            return `
            ${t(MONTHS[value.month])} '${value.year.toString().slice(2, 4)}
            `;
          }
          return t(MONTHS[value.month]);
        }}
        tickLine={false}
        tick={{ fontSize: 12, fontFamily: 'Inter', fontWeight: 600 }}
        textAnchor="end"
        sclaeToFit="true"
        verticalAnchor="start"
        interval={0}
        angle="-20"
        /* stroke="#21a1c5" */
      />
      <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 12, fontFamily: 'Inter', fontWeight: 600 }} />
      <Tooltip />
      <Bar dataKey={(value) => value.amount} barSize={30}>
        {periods && periods.map((value) => <Cell key={value} fill="#21a1c5" />)}
      </Bar>
    </BarChart>
    </ResponsiveContainer>
  );
}

StatsBarChart.defaultProps = {
  periods: {}
};

StatsBarChart.propTypes = {
  periods: PropTypes.oneOfType([PropTypes.shape({
    3: PropTypes.arrayOf(PropTypes.object),
    6: PropTypes.arrayOf(PropTypes.object),
    12: PropTypes.arrayOf(PropTypes.object),
    map: PropTypes.func
  }), 
  PropTypes.array
])
};

export default StatsBarChart;
