/* eslint-disable import/prefer-default-export */
const conf = {
  development: {
    auth: {
      jwt: {
        read: {
          publicKey:
            process.env.USER_AUTH_PUBLIC_KEY?.replace(/\\n/gm, '\n') ||
            '-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvzAaemVG6x4ZORIwo8s9\nVYsZYMLoPI1wS1uPpSYPIvRJ8cGLPotch4ozdI4kBWPKs9/6LkFiNtF4ewb7bODT\naINH2egXEQXHOglohKuQ1sSDq30wqrFm5+rFpPxCKTesox9FuXrQmZEJpWIsZq9Z\nLsNVR1LSgQZUnxXnRp2S+VBpVy20QpH8z0pnGoe4mttx+7ZD9NgpTxvVrEL5LK3W\nNHX9JNNptBYZwXfVmUd1O8ViP/9nlBYwTQbqVcJUDy3EqJewJLL9/PR1Y67WcE9r\nn6Lszmg7Gk7hky/W5Q8obmT1vLS3k86UjNDnGtgFbiPnSFjbeP/07ffDPctLhwfh\nlQIDAQAB\n-----END PUBLIC KEY-----\n',
          issuer: process.env.USER_AUTH_WRITE_ISSUER || 'vivowire@user-service',
          audience: process.env.USER_AUTH_WRITE_AUDIENCE || 'vivowire@api-gateway',
          algorithms: process.env.USER_AUTH_WRITE_ALGORITHM || 'RS256'
        }
      },
      cookie: {
        path: process.env.COOKIE_PATH || '/',
        domain: process.env.COOKIE_DOMAIN || 'localhost'
      }
    },
    api: {
      apiGateway: process.env.API_GATEWAY_URL || ''
    }
  },
  test: {
    auth: {
      jwt: {
        read: {
          publicKey:
            process.env.USER_AUTH_PUBLIC_KEY?.replace(/\\n/gm, '\n') ||
            '-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyWuxmIjTIJLbVJlDa/sa\n0xTJX5OGUrjBM1KExxJJfYieGFj1UKp2tuzzY51YfSdHzcV5eWoNvZY0OyKiKAXn\n2xbJsuwM5Zb9UgYI1tPfwV79dZf6y74IotLqTKI1gOba0eN7l8U0B+JEFRe94UXJ\nsji8FINMOLXWwtgv4I18Fzg90VUQG9aJZ3fwfmCz4l4sGPInWnl81Vt5Cy+j9uh8\naIuvOqNzPTBE6z8IViLY+SHH9+bOjx3xQpbCgpWE5bjjFqJ3kPrbBQ3TJMaZpDkE\njBtSevmtlRFmZAm6str+PkFx4hmE/diHsd2sRSfx8zo+x0bIeQLZzGhehgCzrREo\n3QIDAQAB\n-----END PUBLIC KEY-----\n',
          issuer: process.env.USER_AUTH_WRITE_ISSUER || 'vivowire@api-gateway',
          audience: process.env.USER_AUTH_WRITE_AUDIENCE || 'vivowire@client',
          algorithms: process.env.USER_AUTH_WRITE_ALGORITHM || 'RS256'
        }
      },
      cookie: {
        path: process.env.COOKIE_PATH || '/',
        domain: process.env.COOKIE_DOMAIN || 'localhost'
      }
    },
    api: {
      apiGateway: process.env.API_GATEWAY_URL || ''
    }
  },
  production: {
    auth: {
      jwt: {
        read: {
          publicKey: process.env.USER_AUTH_PUBLIC_KEY?.replace(/\\n/gm, '\n'),
          issuer: process.env.USER_AUTH_WRITE_ISSUER,
          audience: process.env.USER_AUTH_WRITE_AUDIENCE,
          algorithms: process.env.USER_AUTH_WRITE_ALGORITHM
        }
      },
      cookie: {
        path: process.env.COOKIE_PATH,
        domain: process.env.COOKIE_DOMAIN
      }
    },
    api: {
      apiGateway: process.env.API_GATEWAY_URL
    }
  }
};

const env = process.env.NODE_ENV;
const config = conf[env];

export default config;
