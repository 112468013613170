import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  support: {
    cursor: 'pointer'
  }
}));

function TechSupportLink() {
  const classes = useStyles();
  const { t } = useTranslation(['common']);
  const goTo = () => window.location.replace('contact-us')
  return (
    <span className={classes.support} role="button" onClick={goTo} onKeyDown={goTo} tabIndex={0}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" style={{ height: 30, width: 30, margin: '0px 8px -8px 0px' }}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
      </svg>

      {t('techSupport')}
    </span>
  );
}

export default TechSupportLink;
