/* eslint-disable no-console */
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';
import API from '../utils/api';
import config from '../config/config';

const isAuthenticated = () => {
  const token = localStorage.getItem('authentication');
  
  if (token) {
    try {
      jwt.verify(token, config.auth.jwt.read.publicKey, {
        audience: config.auth.jwt.read.audience,
        issuer: config.auth.jwt.read.issuer,
        algorithms: config.auth.jwt.read.algorithms
      });
      return true;
    } catch (error) {
      if (error.message === 'jwt expired') {
        return API.get('/refresh')
          .then(() => {
            const refreshedAuth = Cookies.get('authentication');
            localStorage.setItem('authentication', refreshedAuth);

            return true;
          })
          .catch(() => {
            localStorage.clear();
            return false;
          });
      }
      localStorage.clear();
      Cookies.remove('authentication', { path: config.auth.cookie.path, domain: config.auth.cookie.domain });
      Cookies.remove('refresh', { path: config.auth.cookie.path, domain: config.auth.cookie.domain });

      return false;
    }
  }

  return false;
};

export function logout() {
  localStorage.clear();
  Cookies.remove('authentication', { path: config.auth.cookie.path, domain: config.auth.cookie.domain });
  Cookies.remove('refresh', { path: config.auth.cookie.path, domain: config.auth.cookie.domain });
  window.location.replace('/');
}

export default isAuthenticated;
