import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Collapse, IconButton , Alert } from '@mui/material';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { camelCase, isEmpty } from 'lodash';

import GetStartedButton from '../sign-in-modal/GetStartedButton';
import { deliveryMethodsIcons } from './deliveryMethodsIcons';
import HelpPopOver from '../common/HelpPopOver';
import MtfDetailsBg from '../../assets/img/MtfDetailsBg.svg';
import PaperPlane from '../../assets/svg/PaperPlane.svg';
import { setGlobalIsOpenMtfPriceDetails } from '../../store/redux/actions/moneyTransferBoxAction';
import Loading from '../common/Loading';
import PayerSelect from './PayerSelect';
import PayerTransactionAmountLimitMessage from './PayerTransactionAmountLimitMessage';
import NumberFormatter from '../../utils/NumberFormatter';
import { handleDeviantPayoutSpeed } from './handleDeviantPayoutSpeed';
import { errorHandler } from '../../utils/errorMapper';

const useStyles = makeStyles((theme) => ({
  bgImg: {
    background: `url(${MtfDetailsBg}) no-repeat`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom'
  },
  paperPlaneImg: {
    background: `url(${PaperPlane}) no-repeat`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom'
  },
  card: {
    minWidth: 275,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: '0 5px 30px 0 rgb(145 155 168 / 30%)',
    background: 'transparent'
  },
  cardContent: {
    textAlign: 'left',
    width: '100%',
    maxWidth: 800,
    margin: 'auto',
    marginTop: '-40px',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  cardActions: {
    textAlign: 'center',
    borderTop: '1px solid rgba(0, 0, 0, 0.13)'
  },
  boxContainer: {
    display: 'grid',
    gridTemplateColumns: `${theme.spacing(5)} 400px 1fr`,
    margin: 'auto',
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto',
      gap: 20
    }
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  spaceBottom: {
    marginBottom: 15
  },
  exchangeRateText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: 'normal',
    color: '#4a6582',
    [theme.breakpoints.down('md')]: {
      fontWeight: '800'
    }
  },
  exchangeRate: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: 'normal',
    color: theme.palette.globalAppTheme.globalAppTextColor
  },
  totalToPayBox: {
    /* background: 'aliceblue', */
    borderTop: '2px solid #00acc1',
    [theme.breakpoints.down('md')]: {
      background: 'none'
    }
  },
  totalText: {
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 500,
    fontStyle: 'normal',
    color: '#4a6582',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(26)
    }
  },
  totalSum: {
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 500,
    fontStyle: 'normal',
    letterSpacing: -0.86,
    color: theme.palette.globalAppTheme.globalAppTextColor,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(26)
    }
  },
  recipientGetsBox: {
    display: 'contents'
  },
  recipientGetText: {
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(26),
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#4a6582',
    opacity: 0.8,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(24)
    }
  },
  recipientGets: {
    display: 'inline-flex',
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(26),
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#4a6582',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(24)
    }
  },
  feeAndSum: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3)
    }
  },
  divider: {
    width: '100%'
  },
  svg: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

function MtfPriceDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('landing-page-common');
  const { error }  = useSelector((state) => state.app);
  const {
    convertedAmount,
    isOpenMtfPriceDetails,
    selectedDeliveryMethod,
    deliveryMethods,
    convertedAmountError,
    isInvalidSenderAmount,
    isConverting
  } = useSelector((state) => state.moneyTransfer);

  const { fee, exchangeRate } = useSelector((state) => state.moneyTransfer.convertedAmount);

  const collapse = () => {
    dispatch(setGlobalIsOpenMtfPriceDetails(false));
  };

  useEffect(() => {}, [isOpenMtfPriceDetails]);

  return (
    <Collapse
      in={isOpenMtfPriceDetails && !isInvalidSenderAmount && !error}
      timeout="auto"
      unmountOnExit
      style={{ width: '100%', maxWidth: '1000px', margin: 'auto' }}
    >
      <Box className={classes.wrapper}>
        <Card className={classNames(classes.card)} variant="outlined">
          <div className={classes.infoMessageAndCollapeButtonContainer}>
            <PayerTransactionAmountLimitMessage />
            {isEmpty(convertedAmount) || convertedAmountError ? <Alert severity={convertedAmountError?.type || 'error'}>{errorHandler(convertedAmountError)}</Alert> : null}
            <IconButton
              onClick={collapse}
              aria-label="collapse-price-details-button"
              size="large">
              <svg xmlns="http://www.w3.org/2000/svg" className={classNames('w-6 h-6', 'svg-icon-style')}>
                <path d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
              </svg>
            </IconButton>
          </div>
          {isInvalidSenderAmount ? (
            <Loading
              title={t('pleaseEnterAValidAmount')}
              size={60}
            />
          ) : (
            <CardContent className={classNames(classes.cardContent, classes.bgImg)}>
              <Box className={classNames(classes.boxContainer, 'exchange-rate-box')}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={classNames('w-6 h-6', 'svg-icon-style', classes.svg)}
                >
                  <path d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
                </svg>
                <Typography className={classes.exchangeRateText}>{t('exchangeRate')}</Typography>
                <Typography className={classes.exchangeRate}>
                  {exchangeRate || '-'}
                  {exchangeRate && <HelpPopOver content={<p>{t('exchangeRateInfo')}</p>} title={t('exchangeRate')} iconWidth=".8" />}
                </Typography>
              </Box>
              <Box className={classNames(classes.boxContainer, 'percentage-fee-box')}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={classNames('w-6 h-6', 'svg-icon-style', classes.svg)}
                >
                  <path d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                </svg>
                <Typography className={classes.exchangeRateText}>
                  {t('fees')}
                </Typography>
                <Typography className={classes.exchangeRate}>{fee || '-'}</Typography>
              </Box>
              <Box className={classNames(classes.boxContainer, 'delivery-method-box')}>
                <span className={classNames('w-6 h-6', 'svg-icon-style', classes.svg)}>
                  {deliveryMethodsIcons(selectedDeliveryMethod?.type)}
                </span>
                <Typography className={classes.exchangeRateText}>{t('deliveryMethod')}</Typography>
                <Typography className={classes.exchangeRate}>{t(camelCase(selectedDeliveryMethod?.type))}</Typography>
              </Box>
              {deliveryMethods && deliveryMethods.length === 0 ? null : (
                <Box className={classNames(classes.boxContainer, 'delivery-method-partner-box')}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={classNames('w-6 h-6', 'svg-icon-style', classes.svg)}
                  >
                    <path d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                  </svg>
                  <Typography className={classes.exchangeRateText}>{t('payer')}</Typography>
                  <PayerSelect selectedDeliveryMethod={selectedDeliveryMethod} logo={false} label={false} altStyle />
                </Box>
              )}
              <Box className={classNames(classes.boxContainer, 'delivery-speed')}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={classNames('w-6 h-6', 'svg-icon-style', classes.svg)}
                >
                  <path d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <Typography className={classes.exchangeRateText}>{t('deliverySpeed')}</Typography>
                <Typography className={classes.exchangeRate}>
                  {t(camelCase(handleDeviantPayoutSpeed(selectedDeliveryMethod)?.speed || '-'))}
                  {' '} <span title={t('delaysForPayer')}> {handleDeviantPayoutSpeed(selectedDeliveryMethod)?.icon}</span>
                </Typography>
              </Box>
              <Box className={classNames(classes.boxContainer, classes.totalToPayBox)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={classNames('w-6 h-6', 'svg-icon-style', classes.svg)}
                >
                  <path d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
                </svg>
                <Typography className={classes.totalText}>{t('totalToPay')}</Typography>
                <Typography className={classes.totalSum}>{NumberFormatter.numberAmountWithTwoPlacedFloat(convertedAmount?.totalToPay) || '-'}</Typography>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="currentColor"
                  className={classNames('w-6 h-6', 'svg-icon-style', classes.svg)}
                >
                  <path d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                </svg>
                <Box className={classes.recipientGetsBox}>
                  <Typography className={classes.recipientGetText}>{t('recipientGets')}</Typography>
                  <Typography className={classes.recipientGets}>{convertedAmount?.convertedAmount || '-'}{isConverting && <Loading size={20} style={{ padding: 0 }} />}</Typography>
                </Box>
              </Box>
            </CardContent>
          )}
          <CardActions className={classes.cardActions}>
            <GetStartedButton /* disabled={isInvalidPayerTransactionAmount} */ />
          </CardActions>
        </Card>
      </Box>
    </Collapse>
  );
}

export default MtfPriceDetails;
