/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
  const { tick } = props;
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} size={85} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" style={{ color: '#21a2c6', fontSize: '1em' }}>
          {isNaN(tick.seconds) ? '00:00' : `00:${tick.seconds < 10 ? `0${tick.seconds}` : tick.seconds}`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  tick: PropTypes.object.isRequired
};

export default function SessionTimeProgress({ countDown }) {
  const minutes = parseInt(countDown.split(':')[0], 10);
  const seconds = parseInt(countDown.split(':')[1], 10);
  const [progress, setProgress] = React.useState(1.66);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress >= 100 ? 1.66 : prevProgress + 1.66);
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, [seconds]);

  const tick = {
    minutes: minutes.toString(),
    seconds: seconds.toString()
  }

  return <CircularProgressWithLabel value={progress} tick={tick} />;
}

SessionTimeProgress.propTypes = {
  countDown: PropTypes.string.isRequired
};
