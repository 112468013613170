/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles((theme) => ({
  reactionContainer: {
    padding: '12px',
    textAlign: 'center',
    color: '#777',
    borderRadius: theme.borderRadius
  },
  checkIconRoot: {
    color: 'green',
    fontSize: '2.5rem',
    [theme.breakpoints.down('md')]: {
      color: 'green'
    }
  },
  reactionIconRoot: {
    fontSize: '2.5rem'
  }
}));

const customIconsStyle = {
  fontSize: '2.5rem'
};

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon style={customIconsStyle} />,
    label: 'Very Dissatisfied'
  },
  2: {
    icon: <SentimentDissatisfiedIcon style={customIconsStyle} />,
    label: 'Dissatisfied'
  },
  3: {
    icon: <SentimentSatisfiedIcon style={customIconsStyle} />,
    label: 'Neutral'
  },
  4: {
    icon: <SentimentSatisfiedAltIcon style={customIconsStyle} />,
    label: 'Satisfied'
  },
  5: {
    icon: <SentimentVerySatisfiedIcon style={customIconsStyle} />,
    label: 'Very Satisfied'
  }
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired
};

export default function Ratings(props) {
  const classes = useStyles();
  const { defaultValue, handleChange, item } = props;
  const { t } = useTranslation(['landing-page-common']);
  const [rated] = useState(item?.rated);

  useEffect(() => {}, [rated]);

  return (
    <Box component="fieldset" mb={3} borderColor="transparent" className={classes.reactionContainer}>
      {rated ? (
        <>
          <Typography component="legend">{t('ratingTitleSuccess')}</Typography>
          <CheckCircleIcon classes={{ root: classes.checkIconRoot }} />
        </>
      ) : (
        <>
          <Typography component="legend">{t('ratingTitle')}</Typography>
          <Rating
            className={classes.reactionIconRoot}
            name="reaction-icons"
            size="large"
            defaultValue={defaultValue}
            getLabelText={(value) => customIcons[value].label}
            IconContainerComponent={IconContainer}
            onChange={(e) => handleChange(e, item)}
          />
        </>
      )}
    </Box>
  );
}

Ratings.defaultProps = {
  item: {},
  defaultValue: 0
};

Ratings.propTypes = {
  item: PropTypes.shape({
    rated: PropTypes.bool
  }),
  handleChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.number
};
