import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import API from '../utils/api'; 
import { setTransactionStats } from '../store/redux/actions/userAction'; 

const useTransactionsStats = () => {
  const dispatch = useDispatch();
  const [transactionsStats, setTransactionsStats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTransactionsStats = async () => {
    try {
      setIsLoading(true);

      const response = await API.get('/transaction-dashboard');
      const transactionStatsData = response.data;

      // Update Redux store
      dispatch(setTransactionStats(transactionStatsData));

      setTransactionsStats(transactionStatsData);
      setIsLoading(false);
    } catch (_error) {
      setError(_error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactionsStats();  // Fetch dashboard transactions when the component mounts
  }, []);  // Empty dependency array to mimic componentDidMount

  return { transactionsStats, isLoading, error, fetchTransactionsStats };
};

export default useTransactionsStats;
