import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import CommonDialogTitle from '../../common/DialogTitle';

import Button from '../../common/Button';

const useStyles = makeStyles((theme) => ({
  continueRegistrationButton: {
    width: 220,
    height: 62,
    marginTop: 20,
    marginBottom: 10,
    padding: '25 121',
    objectFit: 'contain',
    opacity: 0.9,
    borderRadius: theme.borderRadiusButton,
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover,&:focus': {
      background: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  continueRegistrationButtonText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    paddingRight: 4,
    textTransform: 'none'
  },
  cancleRegistrationButtonText: {
    opacity: 0.9,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#919ba8',
    textTransform: 'none'
  }
}));

function ContinueOrCancelDialog(props) {
  const classes = useStyles();
  const { open, handleContinue, handleCancel, title, subTitle, content, continueButtonText, cancelButtonText, note } =
    props;

  useEffect(() => {}, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="cancle-sign-up-dialog-title"
        aria-describedby="cancle-sign-up-dialog-description"
        transitionDuration={500}
      >
        <CommonDialogTitle
          id="cancle-sign-up-dialog-title"
          title={title}
          subTitle={subTitle}
          icon={
            <svg className="h-6 w-6 svg-icon-style" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          }
        />
        <DialogContent>
          <DialogContentText id="cancle-sign-up-dialog-description">
            <strong>{note || ''}</strong> {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.gridContainer}
          >
            <Grid item xs={12}>
              <Button
                className={classes.saveUserButton}
                fullWidth
                onClick={handleContinue}
                type="button"
                variant="contained"
                color="primary"
              >
                <span className={classes.continueRegistrationButtonText}>{continueButtonText}</span>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={handleCancel} type="button" color="transparent">
                <span className={classes.cancleRegistrationButtonText}>{cancelButtonText}</span>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ContinueOrCancelDialog.defaultProps = {
  open: false,
  note: ''
};

ContinueOrCancelDialog.propTypes = {
  open: PropTypes.bool,
  handleContinue: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  continueButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  note: PropTypes.string
};

export default ContinueOrCancelDialog;
