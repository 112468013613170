import React from 'react';

export const mapFormikErrorFields = (formikError, t, FORM_TRANSLATIONS) => {
  const mapedErrorFields = [];
  Object.keys(formikError).map((field) => {
    switch (field) {
      case 'street':
        mapedErrorFields.push(FORM_TRANSLATIONS.formStreet);
        break;
      case 'zipCode':
        mapedErrorFields.push(FORM_TRANSLATIONS.formZipCode);
        break;
      case 'city':
        mapedErrorFields.push(FORM_TRANSLATIONS.formCity);
        break;
      case 'country':
        mapedErrorFields.push(FORM_TRANSLATIONS.formCountry);
        break;
      case 'email':
        mapedErrorFields.push(FORM_TRANSLATIONS.formEmail);
        break;
      case 'phoneNumber':
        mapedErrorFields.push(FORM_TRANSLATIONS.formPhoneNumber);
        break;
      case 'isPep':
        mapedErrorFields.push(t('areYouPep'));
        break;
      case 'checkboxGroupPepRollsPepFullName':
        mapedErrorFields.push([
          FORM_TRANSLATIONS.formPepFullNameMustBeXOrMore,
          ' ',
          FORM_TRANSLATIONS.formFullNameIsRequired
        ]);
        break;
      case 'checkboxGroupPepRollsPepDateOfBirth':
        mapedErrorFields.push([t('areYouPep'), ' ', FORM_TRANSLATIONS.formDateIsRequired]);
        break;
      case 'checkboxGroupPepRollsPepTitle':
        mapedErrorFields.push([t('areYouPep'), ' ', FORM_TRANSLATIONS.formTitleIsRequired]);
        break;
      case 'checkboxGroupPepRollsPepOrganisation':
        mapedErrorFields.push([t('areYouPep'), ' ', FORM_TRANSLATIONS.formOragnizationIsRequired]);
        break;
      case 'checkboxGroupPepRollsPepCountry':
        mapedErrorFields.push([t('areYouPep'), ' ', FORM_TRANSLATIONS.formCountryIsRequired]);
        break;
      case 'checkboxGroupPepRolls':
        mapedErrorFields.push([t('yourPepRoll'), ' ', FORM_TRANSLATIONS.selectOneOption]);
        break;
      case 'isFamilymemberOfPep':
        mapedErrorFields.push(t('areYouFamilymemberOfPep'));
        break;
      case 'checkboxGroupPepFamilyRelationPepFullName':
        mapedErrorFields.push([
          FORM_TRANSLATIONS.formPepFullNameMustBeXOrMore,
          ' ',
          FORM_TRANSLATIONS.formFullNameIsRequired
        ]);
        break;
      case 'checkboxGroupPepFamilyRelationPepDateOfBirth':
        mapedErrorFields.push([t('areYouFamilymemberOfPep'), ' ', FORM_TRANSLATIONS.formDateIsRequired]);
        break;
      case 'checkboxGroupPepFamilyRelationPepTitle':
        mapedErrorFields.push([t('areYouFamilymemberOfPep'), ' ', FORM_TRANSLATIONS.formTitleIsRequired]);
        break;
      case 'checkboxGroupPepFamilyRelationPepOrganisation':
        mapedErrorFields.push([t('areYouFamilymemberOfPep'), ' ', FORM_TRANSLATIONS.formOragnizationIsRequired]);
        break;
      case 'checkboxGroupPepFamilyRelationCountry':
        mapedErrorFields.push([t('areYouFamilymemberOfPep'), ' ', FORM_TRANSLATIONS.formCountryIsRequired]);
        break;
      case 'checkboxGroupPepFamilyRelation':
        mapedErrorFields.push([t('informationAboutPEP'), ' ', FORM_TRANSLATIONS.selectOneOption]);
        break;
      case 'isAssociateOfPep':
        mapedErrorFields.push(t('areYouAssociateOfPep'));
        break;
      case 'checkboxGroupPepAssociateRelationPepFullName':
        mapedErrorFields.push([
          FORM_TRANSLATIONS.formPepFullNameMustBeXOrMore,
          ' ',
          FORM_TRANSLATIONS.formFullNameIsRequired
        ]);
        break;
      case 'checkboxGroupPepAssociateRelationPepDateOfBirth':
        mapedErrorFields.push([t('areYouAssociateOfPep'), ' ', FORM_TRANSLATIONS.formDateIsRequired]);
        break;
      case 'checkboxGroupPepAssociateRelationPepTitle':
        mapedErrorFields.push([t('areYouAssociateOfPep'), ' ', FORM_TRANSLATIONS.formTitleIsRequired]);
        break;
      case 'checkboxGroupPepAssociateRelationPepOrganisation':
        mapedErrorFields.push([t('areYouAssociateOfPep'), ' ', FORM_TRANSLATIONS.formOragnizationIsRequired]);
        break;
      case 'checkboxGroupPepAssociateRelationPepCountry':
        mapedErrorFields.push([t('areYouAssociateOfPep'), ' ', FORM_TRANSLATIONS.formCountryIsRequired]);
        break;
      case 'checkboxGroupPepAssociateRelation':
        mapedErrorFields.push([t('informationAboutPEP'), ' ', FORM_TRANSLATIONS.selectOneOption]);
        break;
      case 'transferHabit':
        mapedErrorFields.push(FORM_TRANSLATIONS.formTransferHabitTitle);
        break;
      case 'checkboxGroupPepOriginsOfFunds':
        mapedErrorFields.push(FORM_TRANSLATIONS.originOfTransferFunds);
        break;
      case 'userIsPep':
        mapedErrorFields.push(t('areYouPepOrRCA'));
        break;
      case 'receiverCountries':
        mapedErrorFields.push(t('formIntendedRecipientCountries'));
        break;
      case 'frequencyOfTransaction':
        mapedErrorFields.push(t('frequencyOfTransaction'));
        break;
      case 'recipientRelationship':
        mapedErrorFields.push(t('formWhoWillYouSendMoneyTo'));
        break;
      case 'sendToMultipleRecipients':
        mapedErrorFields.push(t('planToSednMoneyToMultipleRecipient'));
        break;
      case 'originsOfFunds':
        mapedErrorFields.push(t('originOfTransferFunds'));
        break;
      case 'paymentOption':
        mapedErrorFields.push(t('paymentOptionIsRequired'));
        break;
      default:
        return mapedErrorFields;
    }
    return mapedErrorFields;
  });
  return mapedErrorFields.map((error, index) => <div key={`${error}-${index + 1}`}>- {error}</div>);
};
