import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import loginModalReducer from './reducers/loginModalReducer';
import moneyTransferBoxReducer from './reducers/moneyTransferBoxReducer';
import rootReducer from './reducers/rootReducer';
import dashboardReducer from './reducers/dashboardReducer';
import userReducer from './reducers/userReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userInfo', 'moneyTransfer', 'app', 'dashboard']
};

/** Setup redux chrome devtool TODO turn of in prod */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/** Setup redux store */
const store = createStore(
  persistReducer(
    persistConfig,
    combineReducers({
      userInfo: userReducer,
      loginModal: loginModalReducer,
      moneyTransfer: moneyTransferBoxReducer,
      app: rootReducer,
      dashboard: dashboardReducer
    })
  ),
  composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { store, persistor };
