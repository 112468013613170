import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import HelpPopOver from '../../common/HelpPopOver';
import Iframe from '../../common/Iframe';

const styles = makeStyles(() => ({}));

function DebitCreditCardPaymentIFrame({ source }) {
  const classes = styles();
  const { t } = useTranslation(['dashboard-common']);

  return (
    <div
      id="unlimit-card-payment-client"
      style={{ width: '100%', height: '75vh', textAlign: 'center', justifyContent: 'center', fontFamily: 'Inter' }}
    >
      <div className={classes.unlimitHelpPopover}>
        <HelpPopOver
          content={
            <>
              <p>{t('')}</p>
              {/* <img width="40" height="40" src={BriteLanguageImage} alt="Unlimit Language Change" /> */}
            </>
          }
          title={t('unlimitPayment')}
          fullWidth
        />
      </div>
      {/* {window.open(source,'NewWindow','resizable=yes')} */}
      <Iframe source={source} title='Unlimint' width='100%' />
    </div>
  );
}

DebitCreditCardPaymentIFrame.defaultProps = {
  source: undefined
};

DebitCreditCardPaymentIFrame.propTypes = {
  source: PropTypes.string
}

export default DebitCreditCardPaymentIFrame;
