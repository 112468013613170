import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import TextField from '@mui/material/TextField';
import { styles } from './fieldStyles';

function FormikFieldInput(props) {
  const { name, label, type, required = false, disabled, onChange, readOnly, shrink } = props;

  const classes = styles();

  return (
    <div className={classes.formFieldContainer}>
      <Field
        variant="filled"
        InputProps={{
          disableUnderline: true,
          className: classes.textfield,
          readOnly
        }}
        InputLabelProps={{ className: classes.inputLabel, shrink }}
        required={required}
        autoComplete="off"
        as={TextField}
        label={label}
        name={name}
        fullWidth
        type={type}
        helperText={
          <span className={classes.helperText}>
            <ErrorMessage name={name} />
          </span>
        }
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
}

FormikFieldInput.defaultProps = {
  disabled: false,
  label: undefined,
  type: 'text',
  required: false,
  onChange: undefined,
  InputProps: undefined,
  InputLabelProps: undefined,
  readOnly: false,
  shrink: undefined
};

FormikFieldInput.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  required: PropTypes.bool,
  InputProps: PropTypes.shape({
    className: PropTypes.string
  }),
  InputLabelProps: PropTypes.shape({
    className: PropTypes.string
  }),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  shrink: PropTypes.bool
};

export default FormikFieldInput;
