import makeStyles from '@mui/styles/makeStyles';

const sendMoneyDialogStyles = makeStyles((theme) => ({
  dialogPaper: {
    '&.MuiDialog-paperWidthSm': {
      maxWidth: 1000,
      [theme.breakpoints.down('md')]: {
        maxWidth: 'fit-content'
      }
    }
  },
  iconButton: {},
  button: {
    width: '100%',
    minWidth: 165,
    height: '78px',
    textTransform: 'none',
    margin: 10,
    borderRadius: theme.borderRadiusButton,
    backgroundImage: 'linear-gradient(to right, #19cac3, #1569af)',
    '&:hover,&:focus': {
      color: 'inherit',
      background: '#21a2c6'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      margin: 0
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      maxWidth: '100%',
      marginLeft: 54
    }
  },
  buttonText: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.7px',
    color: '#ffffff'
  },
  arrow: {
    marginLeft: '20px',
    width: '16px',
    height: '16px',
    objectFit: 'contain',
    color: '#ffffff'
  },
  cancelDialog: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#919ba8',
    textTransform: 'none'
  },
  sendMoneyDialogTitle: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#4a6582',
    textShadow: '0 0 0 #4a6582'
  },
  sendMoneyDialogDescription: {
    fontFamily: 'Rubik',
    fontSize: 34,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: -1.42,
    color: '#2f4662'
  },
  gridItem: {
    padding: 16,
    paddingLeft: '31px'
  },
  inputBackground: {
    borderRadius: theme.borderRadius,
    backgroundColor: '#f8fafc',
    padding: theme.spacing(2)
  },
  dottedLine: {
    transform: 'rotate(-180deg)',
    opacity: 0.2,
    borderBottom: '2px dashed'
  },
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.globalAppTheme.globalAppTextColor,
      opacity: 0.8
    }
  },
  termsAndCondition: {
    width: 285,
    height: 40,
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#4a6582'
  },
  cardMediaRoot: {
    objectFit: 'contain'
  },
  cardMediaContainer: {
    display: 'grid',
    gridTemplateColumns: '180px 1fr',
    paddingTop: theme.spacing(2)
  },
  thankYou: {
    fontFamily: 'Rubik',
    fontSize: '20px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '--1.42px',
    color: '#2f4662',
    opacity: 0.9
  },
  toolbar: { ...theme.mixins.toolbar, padding: 1 },
  recipientPayoutAccountContainer: { 
    textAlign: 'center', 
    display: 'flex', 
    width: 'auto', 
    padding: '20px 0 48px 0' 
  }
  
}));

export default sendMoneyDialogStyles;
