import React from 'react';
import PropTypes from 'prop-types';

export default function HelpTypeIcon(props) {
  const { type } = props;
  const iconCustomStyle = { height: 50, width: 50 }

  if (type === 'GENERAL') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style" viewBox="0 0 24 24" style={iconCustomStyle}>
        <path
          strokeWidth={1}
          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );
  }
  if (type === 'ACCOUNT') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style" viewBox="0 0 24 24" style={iconCustomStyle}>
        <path
          strokeWidth={1}
          d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
        />
      </svg>
    );
  }
  if (type === 'TRANSACTION') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style" viewBox="0 0 24 24" style={iconCustomStyle}>
        <path
          strokeWidth={1}
          d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
        />
      </svg>
    );
  }
  if (type === 'PAYMENT') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style" viewBox="0 0 24 24" style={iconCustomStyle}>
        <path
          strokeWidth={1}
          d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
        />
      </svg>
    );
  }
}

HelpTypeIcon.defaultProps = {
  type: 'general'
};

HelpTypeIcon.propTypes = {
  type: PropTypes.string
};
