
const LANGUAGES = [
  {language: 'Abkhazian', code: 'ab', active: false },
  {language: 'Afar', code: 'aa', active: false },
  {language: 'Afrikaans', code: 'af', active: false },
  {language: 'Akan', code: 'ak', active: false },
  {language: 'Albanian', code: 'sq', active: false },
  {language: 'Amharic', code: 'am', active: false },
  {language: 'عربى', code: 'ar', active: false },
  {language: 'Aragonese', code: 'an', active: false },
  {language: 'Armenian', code: 'hy', active: false },
  {language: 'Assamese', code: 'as', active: false },
  {language: 'Avaric', code: 'av', active: false },
  {language: 'Avestan', code: 'ae', active: false },
  {language: 'Aymara', code: 'ay', active: false },
  {language: 'Azerbaijani', code: 'az', active: false },
  {language: 'Bambara', code: 'bm', active: false },
  {language: 'Bashkir', code: 'ba', active: false },
  {language: 'Basque', code: 'eu', active: false },
  {language: 'Belarusian', code: 'be', active: false },
  {language: 'Bengali (Bangla)', code: 'bn', active: false },
  {language: 'Bihari', code: 'bh', active: false },
  {language: 'Bislama', code: 'bi', active: false },
  {language: 'Bosnian', code: 'bs', active: false },
  {language: 'Breton', code: 'br', active: false },
  {language: 'Bulgarian', code: 'bg', active: false },
  {language: 'Burmese', code: 'my', active: false },
  {language: 'Catalan', code: 'ca', active: false },
  {language: 'Chamorro', code: 'ch', active: false },
  {language: 'Chechen', code: 'ce', active: false },
  {language: 'Chichewa, Chewa, Nyanja', code: 'ny', active: false },
  {language: 'Chinese', code: 'zh', active: false },
  {language: 'Chinese (Simplified)', code: 'zh-Hans', active: false },
  {language: 'Chinese (Traditional)', code: 'zh-Hant', active: false },
  {language: 'Chuvash', code: 'cv', active: false },
  {language: 'Cornish', code: 'kw', active: false },
  {language: 'Corsican', code: 'co', active: false },
  {language: 'Cree', code: 'cr', active: false },
  {language: 'Croatian', code: 'hr', active: false },
  {language: 'Czech', code: 'cs', active: false },
  {language: 'Danish', code: 'da', active: false },
  {language: 'Divehi, Dhivehi, Maldivian', code: 'dv', active: false },
  {language: 'Dutch', code: 'nl', active: false },
  {language: 'Dzongkha', code: 'dz', active: false },
  {language: 'English', code: 'en', active: true },
  {language: 'Esperanto', code: 'eo', active: false },
  {language: 'Estonian', code: 'et', active: false },
  {language: 'Ewe', code: 'ee', active: false },
  {language: 'Faroese', code: 'fo', active: false },
  {language: 'Fijian', code: 'fj', active: false },
  {language: 'Finnish', code: 'fi', active: false },
  {language: 'French', code: 'fr', active: false },
  {language: 'Fula, Fulah, Pulaar, Pular', code: 'ff', active: false },
  {language: 'Galician', code: 'gl', active: false },
  {language: 'Gaelic (Scottish)', code: 'gd', active: false },
  {language: 'Gaelic (Manx)', code: 'gv', active: false },
  {language: 'Georgian', code: 'ka', active: false },
  {language: 'German', code: 'de', active: false },
  {language: 'Greek', code: 'el', active: false },
  {language: 'Greenlandic', code: 'kl', active: false },
  {language: 'Guarani', code: 'gn', active: false },
  {language: 'Gujarati', code: 'gu', active: false },
  {language: 'Haitian Creole', code: 'ht', active: false },
  {language: 'Hausa', code: 'ha', active: false },
  {language: 'Hebrew', code: 'he', active: false },
  {language: 'Herero', code: 'hz', active: false },
  {language: 'Hindi', code: 'hi', active: false },
  {language: 'Hiri Motu', code: 'ho', active: false },
  {language: 'Hungarian', code: 'hu', active: false },
  {language: 'Icelandic', code: 'is', active: false },
  {language: 'Ido', code: 'io', active: false },
  {language: 'Igbo', code: 'ig', active: false },
  {language: 'Indonesian', code: 'id, in', active: false },
  {language: 'Interlingua', code: 'ia', active: false },
  {language: 'Interlingue', code: 'ie', active: false },
  {language: 'Inuktitut', code: 'iu', active: false },
  {language: 'Inupiak', code: 'ik', active: false },
  {language: 'Irish', code: 'ga', active: false },
  {language: 'Italian', code: 'it', active: false },
  {language: 'Japanese', code: 'ja', active: false },
  {language: 'Javanese', code: 'jv', active: false },
  {language: 'Kalaallisut, Greenlandic', code: 'kl', active: false },
  {language: 'Kannada', code: 'kn', active: false },
  {language: 'Kanuri', code: 'kr', active: false },
  {language: 'Kashmiri', code: 'ks', active: false },
  {language: 'Kazakh', code: 'kk', active: false },
  {language: 'Khmer', code: 'km', active: false },
  {language: 'Kikuyu', code: 'ki', active: false },
  {language: 'Kinyarwanda (Rwanda)', code: 'rw', active: false },
  {language: 'Kirundi', code: 'rn', active: false },
  {language: 'Kyrgyz', code: 'ky', active: false },
  {language: 'Komi', code: 'kv', active: false },
  {language: 'Kongo', code: 'kg', active: false },
  {language: 'Korean', code: 'ko', active: false },
  {language: 'Kurdish', code: 'ku', active: false },
  {language: 'Kwanyama', code: 'kj', active: false },
  {language: 'Lao', code: 'lo', active: false },
  {language: 'Latin', code: 'la', active: false },
  {language: 'Latvian (Lettish)', code: 'lv', active: false },
  {language: 'Limburgish ( Limburger)', code: 'li', active: false },
  {language: 'Lingala', code: 'ln', active: false },
  {language: 'Lithuanian', code: 'lt', active: false },
  {language: 'Luga-Katanga', code: 'lu', active: false },
  {language: 'Luganda, Ganda', code: 'lg', active: false },
  {language: 'Luxembourgish', code: 'lb', active: false },
  {language: 'Manx', code: 'gv', active: false },
  {language: 'Macedonian', code: 'mk', active: false },
  {language: 'Malagasy', code: 'mg', active: false },
  {language: 'Malay', code: 'ms', active: false },
  {language: 'Malayalam', code: 'ml', active: false },
  {language: 'Maltese', code: 'mt', active: false },
  {language: 'Maori', code: 'mi', active: false },
  {language: 'Marathi', code: 'mr', active: false },
  {language: 'Marshallese', code: 'mh', active: false },
  {language: 'Moldavian', code: 'mo', active: false },
  {language: 'Mongolian', code: 'mn', active: false },
  {language: 'Nauru', code: 'na', active: false },
  {language: 'Navajo', code: 'nv', active: false },
  {language: 'Ndonga', code: 'ng', active: false },
  {language: 'Northern Ndebele', code: 'nd', active: false },
  {language: 'Nepali', code: 'ne', active: false },
  {language: 'Norwegian', code: 'no', active: false },
  {language: 'Norwegian bokmål', code: 'nb', active: false },
  {language: 'Norwegian nynorsk', code: 'nn', active: false },
  {language: 'Nuosu', code: 'ii', active: false },
  {language: 'Occitan', code: 'oc', active: false },
  {language: 'Ojibwe', code: 'oj', active: false },
  {language: 'Old Church Slavonic, Old Bulgarian', code: 'cu', active: false },
  {language: 'Oriya', code: 'or', active: false },
  {language: 'Oromo (Afaan Oromo)', code: 'om', active: false },
  {language: 'Ossetian', code: 'os', active: false },
  {language: 'Pāli', code: 'pi', active: false },
  {language: 'Pashto, Pushto', code: 'ps', active: false },
  {language: 'Persian (Farsi)', code: 'fa', active: false },
  {language: 'Polish', code: 'pl', active: false },
  {language: 'Portuguese', code: 'pt', active: false },
  {language: 'Punjabi (Eastern)', code: 'pa', active: false },
  {language: 'Quechua', code: 'qu', active: false },
  {language: 'Romansh', code: 'rm', active: false },
  {language: 'Romanian', code: 'ro', active: false },
  {language: 'Russian', code: 'ru', active: false },
  {language: 'Sami', code: 'se', active: false },
  {language: 'Samoan', code: 'sm', active: false },
  {language: 'Sango', code: 'sg', active: false },
  {language: 'Sanskrit', code: 'sa', active: false },
  {language: 'Serbian', code: 'sr', active: false },
  {language: 'Serbo-Croatian', code: 'sh', active: false },
  {language: 'Sesotho', code: 'st', active: false },
  {language: 'Setswana', code: 'tn', active: false },
  {language: 'Shona', code: 'sn', active: false },
  {language: 'Sichuan Yi', code: 'ii', active: false },
  {language: 'Sindhi', code: 'sd', active: false },
  {language: 'Sinhalese', code: 'si', active: false },
  {language: 'Siswati', code: 'ss', active: false },
  {language: 'Slovak', code: 'sk', active: false },
  {language: 'Slovenian', code: 'sl', active: false },
  {language: 'Af-Soomaali', code: 'so', active: false },
  {language: 'Southern Ndebele', code: 'nr', active: false },
  {language: 'Spanish', code: 'es', active: false },
  {language: 'Sundanese', code: 'su', active: false },
  {language: 'Swahili (Kiswahili)', code: 'sw', active: false },
  {language: 'Swati', code: 'ss', active: false },
  {language: 'Svenska', code: 'sv', active: true },
  {language: 'Tagalog', code: 'tl', active: false },
  {language: 'Tahitian', code: 'ty', active: false },
  {language: 'Tajik', code: 'tg', active: false },
  {language: 'Tamil', code: 'ta', active: false },
  {language: 'Tatar', code: 'tt', active: false },
  {language: 'Telugu', code: 'te', active: false },
  {language: 'Thai', code: 'th', active: false },
  {language: 'Tibetan', code: 'bo', active: false },
  {language: 'Tigrinya', code: 'ti', active: false },
  {language: 'Tonga', code: 'to', active: false },
  {language: 'Tsonga', code: 'ts', active: false },
  {language: 'Turkish', code: 'tr', active: false },
  {language: 'Turkmen', code: 'tk', active: false },
  {language: 'Twi', code: 'tw', active: false },
  {language: 'Uyghur', code: 'ug', active: false },
  {language: 'Ukrainian', code: 'uk', active: false },
  {language: 'Urdu', code: 'ur', active: false },
  {language: 'Uzbek', code: 'uz', active: false },
  {language: 'Venda', code: 've', active: false },
  {language: 'Vietnamese', code: 'vi', active: false },
  {language: 'Volapük', code: 'vo', active: false },
  {language: 'Wallon', code: 'wa', active: false },
  {language: 'Welsh', code: 'cy', active: false },
  {language: 'Wolof', code: 'wo', active: false },
  {language: 'Western Frisian', code: 'fy', active: false },
  {language: 'Xhosa', code: 'xh', active: false },
  {language: 'Yiddish', code: 'yi, ji', active: false },
  {language: 'Yoruba', code: 'yo', active: false },
  {language: 'Zhuang, Chuang', code: 'za', active: false },
  {language: 'Zulu', code: 'zu', active: false }
];

export default LANGUAGES;
