import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@mui/material';
import Button from '../common/Button';
import BankIdSvgWhite from '../common/BankIdSvgWhite';
import BankIdSvgDarkBlue from '../common/BankIdSvgDarkBlue';

import { setGlobalLoginModalVisible } from '../../store/redux/actions/loginModalAction';

const useStyles = makeStyles((theme) => ({
  loginButton: {
    borderRadius: theme.borderRadiusButton,
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover': {
      color: '#fff',
      background: '#106982'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  loginButtonText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
    paddingRight: '4px',
    textTransform: 'none'
  }
}));

export default function SessionModal(properties) {
  const classes = useStyles(properties);
  const matches = useMediaQuery('(max-width: 1280px) and (min-width: 960px)');
  const { t } = useTranslation(['landing-page-common']);
  const dispatch = useDispatch();
  const buttonReference = useRef();
  const [scrolled, setScrolled] = useState(false);
  const { error: appError, maintenanceMessage } = useSelector((state) => state.app);

  const handleLoginButtonClick = () => {
    dispatch(setGlobalLoginModalVisible(true));
  };

  const handleScroll = () => {
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > 50 && buttonReference && buttonReference.current !== null) {
      setScrolled(windowsScrollTop > 50);
      buttonReference.current.style.backgroundColor = '#fff';
      buttonReference.current.style.color = '#2f4662';
    } else if (buttonReference && buttonReference.current !== null) {
      setScrolled(windowsScrollTop > 50);
      buttonReference.current.style.backgroundColor = '#21a1c5';
      buttonReference.current.style.color = '#fff';
    }
  }

  const handleClickEvent = () => {
    dispatch(setGlobalLoginModalVisible(true));
  }

  useEffect(() => {
    window.addEventListener('header-login-clicked', handleClickEvent);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('header-login-clicked', handleClickEvent);
      window.removeEventListener('scroll', handleScroll);
    }
  });

  return (
    <Button
      id="login-to-dashboard-button"
      disabled={appError || maintenanceMessage}
      ref={buttonReference}
      className={classes.loginButton}
      onClick={handleLoginButtonClick}
      target="_blank"
      rel="noopener noreferrer"
    >
      {scrolled ? <BankIdSvgDarkBlue /> : <BankIdSvgWhite />}&nbsp;
      <span className={classes.loginButtonText}>{matches ? '' : t('headerLogin')}</span>
    </Button>
  );
}
