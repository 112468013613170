/* eslint-disable import/no-unused-modules */
import React from 'react';
import classNames from 'classnames';
import {
  Select,
  InputLabel,
  FormControl,
  Box,
  OutlinedInput as MuiOutlinedInput,
  Chip
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import InputFeedback from './InputFeedback';

const styles = makeStyles((theme) => ({
  card: {
    maxWidth: 420,
    marginTop: 50
  },
  formFieldContainer: {
    display: 'Flex',
    justifyContent: 'center'
  },
  select: {
    marginTop: 10,
    '& .MuiSelect-icon': {
      position: 'relative'
    },
    borderRadius: theme.borderRadiusInput,
    backgroundColor: '#eef3f9',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    padding: '20px 15px 10px',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    } /* ,
         '&.Mui-focused': {
      border: 'solid 1px #21a1c5'
    } */
  },
  inputLabel: {
    width: 'max-content',
    height: '32px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#4a6582',
    zIndex: 10,
    padding: '35px 25px'
  },
  helperText: {
    fontSize: '0.9rem',
    fontFamily: 'Inter',
    color: '#f44336'
  },
  countryMultiSelectLabel: {
    padding: 18,
    fontSize: '18px',
    color: '#fff',
    backgroundColor: '#37aacb'
  }
}));

const OutlinedInput = withStyles(() => ({
  notchedOutline: {
    borderColor: 'white !important'
  }
}))(MuiOutlinedInput);

function MultiCountrySelect({ name, fullWidth, shrink, readOnly, children, ...otherprops }) {
  const classes = styles();
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (event) => {
    const { value } = event.target;
    setFieldValue(name, value);
  };

  const configTextfield = {
    ...field,
    ...otherprops,
    fullWidth,
    onChange: handleChange,
    variant: 'outlined',
    value: otherprops.value
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helpertext = meta.error;
  }

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel className={classes.inputLabel}>{otherprops.label}</InputLabel>
        <Select
          className={classNames(classes.select)}
          {...configTextfield}
          shrink={shrink}
          input={<OutlinedInput label={otherprops.label} />}
          defaultValue=""
          renderValue={(selected) => (
            <Box style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
              {selected.map((country) =>
                <Chip className={classes.countryMultiSelectLabel} key={uniqueId()} label={country} />
              )}
            </Box>
          )}
        >
          {children}
        </Select>
        <InputFeedback error={configTextfield.helpertext} />
      </FormControl>
    </Box>
  );
}

export default MultiCountrySelect;

MultiCountrySelect.defaultProps = {
  fullWidth: false,
  otherprops: '',
  shrink: undefined,
  readOnly: false,
  children: []
};

MultiCountrySelect.propTypes = {
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  otherprops: PropTypes.string,
  shrink: PropTypes.bool,
  readOnly: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.object)
};
