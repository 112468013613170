import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import camelCase from 'lodash/camelCase';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Box, Typography } from '@mui/material';
import { kebabCase } from 'lodash';
import headerRoutes from '../../routes/navbarRoutes';
import alternateHeaderRoutes from '../../routes/alternatePageNavbarRoutes';

import Button from '../../../common/Button';

import styles from './headerLinksStyle';
import PERMALINKS from '../../../../constants/permalinks';

export default function HeaderLinks(props) {
  const classes = styles();
  const { t } = useTranslation(['landing-page-common']);
  const { color } = props;

  const handleListItemClick = (e, id) => {
    const listElement = document.getElementById(id);
    listElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    const listItemClickEvent = new CustomEvent('header-link-clicked', { detail: id, bubbles: true });
    e.target.dispatchEvent(listItemClickEvent);
  };

  const pageRoutes = () => {
    switch (window.location.pathname) {
      case PERMALINKS.HOME:
        return headerRoutes;
      case PERMALINKS.CONTACT_US:
      case PERMALINKS.COMPANY_AND_TEAM:
      case PERMALINKS.WHATS_NEW_IN_VIVOWIRE:
        return alternateHeaderRoutes;
      case PERMALINKS.COOKIE_POLICY:
      case PERMALINKS.PRIVACY_POLICY:
      case PERMALINKS.TERMS_AND_CONDITIONS:
      case PERMALINKS.HELP_CENTER:
        return [];
      default:
        return [];
    }
  };

  return (
    <Box className={classes.leftLinksContainer}>
      <List className={classes.leftHeaderList} id="header-link-list">
        {pageRoutes().map((item, index) => (
          <ListItem
            onClick={(e) => handleListItemClick(e, item.id)}
            className={classes.listItem}
            style={{ color: color || 'inherit' }}
            key={item.navbarName}
            index={index}
            id={`header-tab-${kebabCase(item.navbarName)}`}
          >
            <Button
              onClick={(event) => handleListItemClick(event, item.id)}
              color="transparent"
              target="_blank"
              className={classes.navLink}
            >
              <Typography className={classes.listItem} >{t(`${camelCase(item.navbarName)}`)}</Typography>
            </Button>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
HeaderLinks.defaultProps = {
  color: ''
};

HeaderLinks.propTypes = {
  color: PropTypes.string
};
