import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  reload: {
    cursor: 'pointer',
    fontWeight: 500
  }
}));

function ReloadPageLink() {
  const classes = useStyles();
  const { t } = useTranslation(['landing-page-common']);
  const reload = () => window.location.reload();
  return (
      <span role="button" className={classes.reload} onClick={reload} onKeyDown={reload} tabIndex={0}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" style={{ height: 30, width: 30, margin: '0px 8px -8px 0px' }}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
        {t('reloadThePage')}
      </span>
  );
}

export default ReloadPageLink;
