/* eslint-disable no-shadow */
import React from 'react';
import { TextField, MenuItem, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import ListItemIcon from '@mui/material/ListItemIcon';
import makeStyles from '@mui/styles/makeStyles';
import SvgImage from './DynamicSvgImageRender';
import Loading from './Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSelect-outlined': {
      display: 'flex'
    }
  },
  selectMenu: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'break-spaces'
    }
  },
  card: {
    maxWidth: 420,
    marginTop: 50
  },
  formFieldContainer: {
    display: 'Flex',
    justifyContent: 'center'
  },
  textfield: {
    borderRadius: theme.borderRadiusInput,
    backgroundColor: '#eef3f9',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
    '&.Mui-focused': {
      border: 'solid 1px #21a1c5'
    }
  },
  noBorder: {
    border: 'none'
  },
  inputLabel: {
    width: 'auto',
    height: '32px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#4a6582',
    zIndex: 10,
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  name: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.86px',
    color: '#2f4662',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  }
}));

function SelectWrapper({ name, options, handleSelectChange, fullWidth, loading, ...passthroughprops }) {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    const { value } = event.target;
    setFieldValue(name, value);
    if (handleSelectChange) handleSelectChange(value);
  };

  const configSelect = {
    ...field,
    ...passthroughprops,
    select: true,
    variant: 'outlined',
    fullWidth: !!fullWidth,
    onChange: handleChange,
    value: passthroughprops.value || '',
    InputProps: {
      className: classes.textfield,
      classes: { notchedOutline: classes.noBorder }
    },
    InputLabelProps: {
      className: classes.inputLabel
    }
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextField classes={{ root: classes.root }} {...configSelect} >
      {loading ? <Loading size={20} /> :
      options.map(({ name, override, icon }) => (
        <MenuItem key={`${name}-${override?.name}`} value={name || ''}>
          {icon ? (
            <ListItemIcon sx={{ margin: '0 8px 0 8px' }}>
              <SvgImage
                width="30"
                height="30"
                fileName={override.item?.isoCode2.toLowerCase()}
                pathInAssets='flags/4x3'
                alt={override.item?.isoCode2.toLowerCase()}
                className={override.item.name}
              />
            </ListItemIcon>
          ) : null}
          <Typography className={classes.name}>{override ? override.name : name}</Typography>
        </MenuItem>
      ))}
    </TextField>
  );
}

SelectWrapper.defaultProps = {
  loading: false,
  passthroughprops: undefined,
  handleSelectChange: undefined
};

SelectWrapper.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.shape(PropTypes.object), PropTypes.array]).isRequired,
  fullWidth: PropTypes.bool.isRequired,
  passthroughprops: PropTypes.shape(PropTypes.object),
  handleSelectChange: PropTypes.func
};

export default SelectWrapper;
