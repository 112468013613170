import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import InputFeedback from './InputFeedback';

const style = makeStyles(() => ({
  children: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center'
  }
}));
function CheckboxGroup(props) {
  const localClasses = style();
  const { value, error, touched, label, className, children, onBlur, onChange, id } = props;
  const classes = classNames(
    'input-field',
    {
      'is-success': value || (!error && touched), // handle prefilled or user-filled
      'is-error': !!error && touched
    },
    className,
    localClasses
  );

  const handleChange = (event) => {
    const target = event.currentTarget;
    const valueArray = value ? [...value] : [];

    if (target.checked) {
      valueArray.push(target.id);
    } else {
      valueArray.splice(valueArray.indexOf(target.id), 1);
    }

    onChange(id, valueArray);
  };

  const handleBlur = () => {
    // take care of touched
    onBlur(id, true);
  };

  return (
    <div>
      <span>{label}</span>
      <div className={classes}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            field: {
              value: value?.includes(child.props.id),
              onChange: handleChange,
              onBlur: handleBlur
            }
          })
        )}
        {touched && <InputFeedback error={error} />}
      </div>
    </div>
  );
}

CheckboxGroup.defaultProps = {
  value: null,
  error: null,
  touched: null,
  label: null,
  className: null,
  children: null,
  onBlur: null,
  onChange: null,
  id: null
};

CheckboxGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  touched: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.shape(PropTypes.object)]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(PropTypes.object)]),
  children: PropTypes.node,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.string
};

InputFeedback.defaultProps = {
  error: null
};

InputFeedback.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node])
};

export default CheckboxGroup;
