import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Title from './Title';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    padding: '2em'
  },
  message: {
    fontSize: '0.9em',
    fontFamily: 'Inter',
    fontStyle: 'italic',
    color: '#686868'
  },
  spinner: {
    color: theme.palette.globalAppTheme.globalAppColor
  }
}));

export default function Loading({ message, title, subtitle, size, style, children }) {
  const classes = useStyles();

  return (
    <Box className={classes.container} style={style}>
      {title ? <Title subtitle={subtitle || ''}>{title}</Title> : null}
      <CircularProgress size={size} thickness={3} color="inherit" className={classes.spinner} />
      <Box className={classes.message}>{message}</Box>
      {children}
    </Box>
  );
}

Loading.defaultProps = {
  message: '',
  title: '',
  subtitle: '',
  size: 40,
  style: null,
  children: undefined
};

Loading.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node])
};
