import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { get } from 'lodash';
import Util from '../../../utils/Util';
import {
  setGlobalConvertedAmount,
  setGlobalDeliveryMethods,
  setGlobalMaxTransactionAmountForSelectedDeliveryMethod,
  setGlobalRecipientCountryOptionValue,
  setGlobalSelectedDeliveryMethod,
  setGlobalSelectedRecipientCountryName
} from '../../../store/redux/actions/moneyTransferBoxAction';
import { setGlobalRecipientCountries } from '../../../store/redux/actions/rootAction';
import API from '../../../utils/api';
import convertAmount from '../../mtf/convertAmount';
import { SOMALIA_SOMALILAND } from '../../country-currency-select/constants';
import { getPayersForRecipientCountry } from '../../mtf/getPayers';

export function useHandleRecipientChange() {
  const dispatch = useDispatch();
  const { mapIsoCodeAndGetPayers } = getPayersForRecipientCountry(); 
  const { amount, convertedAmount } = useSelector((state) => state.moneyTransfer);
  const { recipientCountries } = useSelector((state) => state.app);
  const [error, setError] = useState();
  const { getConvertedRate } = convertAmount();

  const setAndDispatchPayers = (payers, recipient) => {
    const selectedRecipientPayer = payers.find((_method) => _method.id === recipient.recipientPayer[0]?.payerId);
    if (payers.length > 0 && selectedRecipientPayer?.id) {
      getConvertedRate(amount, selectedRecipientPayer?.id);

      dispatch(setGlobalDeliveryMethods(payers));
      dispatch(setGlobalSelectedDeliveryMethod(selectedRecipientPayer));

      dispatch(
        setGlobalMaxTransactionAmountForSelectedDeliveryMethod({
          maxAmount: selectedRecipientPayer?.maxTransaction,
          minAmount: selectedRecipientPayer?.minTransaction,
          currency: selectedRecipientPayer?.currency,
          name: selectedRecipientPayer.name
        })
      );
    } else {
      dispatch(setGlobalDeliveryMethods([]));
      dispatch(setGlobalSelectedDeliveryMethod({ type: 'missingDeliveryMethod', empty: true }));
      dispatch(setGlobalMaxTransactionAmountForSelectedDeliveryMethod(null));
      dispatch(
        setGlobalConvertedAmount(
          Object.assign(convertedAmount, { ...convertedAmount, rate: '', totalToPay: '', convertedAmount: '' })
        )
      );
    }
  };

  const getPayers = (recipient) => {
    setError();
    const countryISO3 = Util.getISO3Code(recipient?.country);
    API.get(`/recipient-country/${countryISO3}`)
      .then((res) => {
        recipientCountries.map((_country) => {
          if (_country.countryName === res.data.countryName) {
            const payers = get(res.data, 'payers', []);
            setAndDispatchPayers(payers, recipient);
            return payers;
          }
          return _country;
        });
        dispatch(setGlobalRecipientCountries(recipientCountries));
      })
      .catch((_error) => setError(_error));
  };

  const handleRecipientChange = (recipient) => {
    const currentSelected = recipientCountries.find((co) => co.countryName === recipient?.country);
    const countryISO3 = Util.getISO3Code(recipient?.country);

    if(recipient?.country === SOMALIA_SOMALILAND.SOMALILAND) {
      mapIsoCodeAndGetPayers(countryISO3, recipient?.country);
      dispatch(setGlobalRecipientCountryOptionValue(currentSelected));
      dispatch(setGlobalSelectedRecipientCountryName(countryISO3));
    } else {
      dispatch(setGlobalRecipientCountryOptionValue(currentSelected));
      dispatch(setGlobalSelectedRecipientCountryName(countryISO3));
  
      getPayers(recipient);
    } 
  };

  return {
    handleRecipientChange,
    error
  };
}