import makeStyles from '@mui/styles/makeStyles';

const addRecipientDialogStyle = makeStyles((theme) => ({
  dialogPaper: {
    '&.MuiDialog-paperWidthSm': {
      maxWidth: '720px',
      [theme.breakpoints.down('md')]: {
        maxWidth: 'min-content'
      }
    }
  },
  gridContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3)
    }
  },
  gridItem: {
    width: '100%'
  },
  recipientSummaryContainer: {
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7)
    }
  },
  title: {
    opacity: 0.9,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.78,
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  description: {
    opacity: 0.9,
    fontFamily: 'Rubik',
    fontSize: 30,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -1.42,
    color: '#2f4662'
  },
  subDescription: {
    opacity: 0.9,
    fontFamily: 'Inter',
    fontSize: 17,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.78,
    letterSpacing: 'normal',
    color: '#2f4662'
  },
  addRecipientButton: {
    width: 220,
    height: 62,
    marginTop: 20,
    marginBottom: 10,
    padding: '25 121',
    objectFit: 'contain',
    opacity: 0.9,
    borderRadius: theme.borderRadiusButton,
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover,&:focus': {
      background: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  addRecipientText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    paddingRight: 4,
    textTransform: 'none'
  },
  cancelRecipientText: {
    opacity: 0.9,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#919ba8',
    textTransform: 'capitalize'
  },
  recipientDetailsHeading: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662',
    padding: '34px 0px 16px 0px',
    textAlign: 'left'
  },
  recipientDetailsSub1: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.8,
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  recipientDetailsSub2: {
    textAlign: 'right',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  dottedLine: {
    transform: 'rotate(-180deg)',
    opacity: 0.2,
    borderBottom: '2px'
  },
  streetHelpPopover: {
    position: 'relative',
    bottom: '-35px',
    right: '20px',
    zIndex: 10,
    height: 0,
    textAlign: 'end'
  },
  spaceTop: {
    marginBottom: 15
  },
  spaceBottom: {
    marginBottom: 15
  },
  infoTitle: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662',
    padding: '34px 0px 16px 0px'
  },
  addPayoutAccountButton: {
    float: 'right',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    textTransform: 'initial',
    color: '#21a1c5',
    cursor: 'pointer',
    textAlign: 'left',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal'
  }
}));

export default addRecipientDialogStyle;
