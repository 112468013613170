import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, IconButton , Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import API from '../../../utils/api';
import { errorHandler } from '../../../utils/errorMapper';
import ConfirmDialog from '../recipient/ConfirmDialog';
import HelpPopOver from '../../common/HelpPopOver';

const useStyles = makeStyles((theme) => ({
  infoTitle: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662',
    padding: '34px 0px 0px 0px'
  },
  recipientPayerListAccount: {
    color: '#2f4662',
    fontSize: '1.375rem',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: 'normal',
    fontStretch: 'normal',
    borderRadius: '10px',
    letterSpacing: '-0.86px',
    textAlign: 'end'
  },
  recipientPayerListName: {
    color: '#2f4662',
    fontSize: '1.375rem',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: 'normal',
    fontStretch: 'normal',
    borderRadius: '10px',
    letterSpacing: '-0.86px'
  },
  recipientPayerListSecondary: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  gridItem: {
    padding: 16
  },
  closeButton: {
    padding: theme.spacing(2)
  }
}));

function RecipientPayoutAccountList({ recipient /* , editMode, handleChange */ }) {
  const classes = useStyles();
  const { t } = useTranslation(['dashboard-common']);
  const [payers, setPayers] = useState([]);
  const [recipientPayers, setRecipientPayers] = useState([]);
  const [getPayersError, setGetPayersError] = React.useState();
  const [getRecipienPayersError, setGetRecipienPayersError] = React.useState();
  const [deleteAccountError, setDeleteAccountError] = useState();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedPayer, setSelectedPayer] = useState();
  /* const [loading, setLoading] = useState(false); */

  const getRecipientPayers = () => {
    API.get(`/recipient/${recipient.id}`)
      .then((res) => {
        const { data } = res;
        setRecipientPayers(data.recipientPayer);
      })
      .catch((_error) => setGetRecipienPayersError(_error));
  };

  const getAllPayers = () => {
    API.get('/payers')
      .then((res) => {
        const { data } = res;
        setPayers(data);
      })
      .catch((_error) => setGetPayersError(_error));
  };

  const handleDeleteAccount = (recipientPayer, payerName) => {
    setDeleteAccountError(undefined);
    setSelectedPayer({ ...recipientPayer, payerName });
    setTimeout(() => {
      setOpenConfirmationDialog(true);
    }, 100);
  };

  const toggleConfirmDialog = (e) => {
    if (e.target.innerHTML === 'Fortsätt') {
      API.delete(`/recipient-payer/${selectedPayer?.id}`)
        .then(() => {
          getRecipientPayers();
          setOpenConfirmationDialog(false);
        })
        .catch((_error) => {
          if (_isEmpty(JSON.stringify(_error)) || _isEmpty(_error)) {
            setOpenConfirmationDialog(false);
            getRecipientPayers();
          } else {
            setDeleteAccountError(_error);
          }

          setOpenConfirmationDialog(false);
        });
    } else if (e.target.innerHTML === 'Avbryt') {
      setOpenConfirmationDialog(false);
    }
  };

  useEffect(() => {
    getRecipientPayers();
    getAllPayers();
    setGetPayersError(undefined);
    setDeleteAccountError(undefined);
    setGetRecipienPayersError(undefined);
  }, [selectedPayer]);

  if (recipientPayers && payers) {
    return (
      <Grid container item direction="row" className={classes.gridContainer} spacing={2}>
        <ConfirmDialog
          open={openConfirmationDialog}
          item={t('youAreAboutToBeDeleteAPayoutAccount')}
          message={`${t('payoutAccount')}: ${selectedPayer ?
            `${selectedPayer.payerName}; ${selectedPayer?.phoneNumber || selectedPayer?.bankAccountNumber}` :
            null
            }`}
          handleChoice={toggleConfirmDialog}
        />

        <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
          <Typography className={classes.infoTitle} noWrap>
            {t('payoutAccounts')}
            <HelpPopOver content={<p>{t('payoutAccountsHelpInfo')}</p>} title={t('payoutAccounts')} />
          </Typography>
        </Grid>
        <Grid item direction="row" className={classes.gridContainer}>
          {!!deleteAccountError && <Alert severity={deleteAccountError?.type || 'error'}>{errorHandler(deleteAccountError)}</Alert>}
          {!!getPayersError && <Alert severity={getPayersError?.type || 'error'}>{errorHandler(getPayersError)}</Alert>}
          {!!getRecipienPayersError && <Alert severity={getRecipienPayersError?.type || 'error'}>{errorHandler(getRecipienPayersError)}</Alert>}
        </Grid>
        <Grid container direction="row" className={classes.gridContainer} spacing={2}>
          <Grid item xs={12} md={12} lg={12} className={classes.gridItem}>
            <List className={classes.root} aria-label="recipient-account-list">
              {recipientPayers.map((p) =>
                payers
                  .filter((item) => item.id === p.payerId)
                  .map((i) => (
                    <span key={p.payerId}>
                      <ListItem key={p.payerId}>
                        {/*               <ListItemAvatar>
                            <Avatar>
                            <ImageIcon />
                            </Avatar>
                                </ListItemAvatar> */}
                        <ListItemText
                          key={`${p.payerId}${i.name}`}
                          classes={{
                            primary: classes.recipientPayerListName,
                            secondary: classes.recipientPayerListSecondary
                          }}
                          primary={i.name}
                          secondary={i.type}
                        />
                        <ListItemText
                          key={p.payerId}
                          classes={{
                            primary: classes.recipientPayerListAccount
                          }}
                          primary={p?.phoneNumber || p?.bankAccountNumber}
                        />
                        <IconButton
                          aria-label="close"
                          className={classes.closeButton}
                          onClick={() => handleDeleteAccount(p, i.name)}
                          size="large">
                          <svg xmlns="http://www.w3.org/2000/svg" className={classNames('w-6 h-6', 'svg-icon-style')}>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </IconButton>
                      </ListItem>
                      <Divider variant="middle" className={classes.divider} />
                    </span>
                  ))
              )}
            </List>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return null;
}

RecipientPayoutAccountList.defaultProps = {
  recipient: {}
};

RecipientPayoutAccountList.propTypes = {
  recipient: PropTypes.object
}

export default RecipientPayoutAccountList;
