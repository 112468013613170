import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box } from '@mui/material';
import WarningOutlineIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  helpPopupContainer: {
    display: 'inline-block'
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(3)
  },
  iconButton: {
    color: '#37aacb',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    },
    '& .MuiIconButton-label': {
      display: 'block',
      margin: '2px 0 0 5px'
    }
  },
  title: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: '#2f4662'
  },
  contentText: {
    width: '500px',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '400',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  paragraph: {
    color: '#919ba8',
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'left',
    fontFamily: 'Inter',
    fontWeight: 300,
    lineHeight: 1.5,
    fontStretch: 'normal',
    letterSpacing: 'normal',
    display: 'inline-block',
    cursor: 'pointer',
    fontStyle: 'italic'
  }
}));

function HelpPopOver(props) {
  const classes = useStyles();
  const { content, title, iconWidth, iconHeight, type, paragraph } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box className={classes.helpPopupContainer} component='span'>
      {paragraph ? <Typography onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} variant="h6" gutterBottom className={classes.paragraph}>
        {paragraph}
      </Typography> : null}
      <IconButton
        aria-label="help"
        className={classes.iconButton}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        size="large">
        {type && type === 'info' ? <WarningOutlineIcon style={{ width: `${iconWidth}em`, height: `${iconHeight}em` }} /> : <HelpOutlineIcon style={{ width: `${iconWidth}em` }} /> }
      </IconButton>
      <Popover
        disableScrollLock
        id="mouse-over-popover"
        className={classes.popover}
        keepMounted
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography className={classes.title}>{title}</Typography>
        <div className={classes.contentText}>{content}</div>
      </Popover>
    </Box>
  );
}

HelpPopOver.defaultProps = {
  content: '',
  title: '',
  iconWidth: null,
  iconHeight: null,
  type: undefined,
  paragraph: undefined
};

HelpPopOver.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.shape(PropTypes.object)]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.shape(PropTypes.object)]),
  iconWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(PropTypes.number)]),
  iconHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(PropTypes.number)]),
  type: PropTypes.string,
  paragraph:  PropTypes.string
};

export default HelpPopOver;