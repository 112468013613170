import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
/* import { Translation,  useTranslation } from 'react-i18next'; */
import { Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
/* import { isEqual, pick } from 'lodash'; */
import API from '../../../utils/api';
import { payoutAccountType } from '../payoutAccountType';
import { errorHandler } from '../../../utils/errorMapper';
/* import AppError from '../../../utils/appError'; */

export const accountValidate = (selectedRecipient) => {
/*   const { t } = useTranslation(['dashboard-common']); */
  const { selectedDeliveryMethod } = useSelector((state) => state.moneyTransfer);
  const [getBenificieryLoading, setGetBenificieryLoading] = useState(false);
  const [showValidateMessage, setShowValidateMessage] = useState(false);
  const [getBenificieryError, setGetBenificieryError] = useState();
  const [beneficiary, setBeneficiary] = useState();

  const verifyPayoutAccount = async () => {
    let result;
    const selectedPayoutAccount = payoutAccountType(
      selectedDeliveryMethod?.type,
      selectedRecipient.recipientPayoutAccount,
      ''
    );
/* 
    const objValuesToLowerCase = (obj) => Object.fromEntries(
      Object.entries(obj).map(([k, v]) => [k, v.toLowerCase()])
    ); */

    const payerId =
      selectedDeliveryMethod?.transferService?.toLowerCase() === 'thunes' ?
        `${selectedDeliveryMethod.idInTransferService}` :
        undefined;
    const service = selectedDeliveryMethod?.transferService?.toLowerCase();

    if (selectedRecipient.recipientPayoutAccount && selectedRecipient.recipientPayoutAccount !== '') {
      setShowValidateMessage(false);
      setBeneficiary(undefined);
      setGetBenificieryLoading(true);
      result = await API.post('/recipient-info', {
        service,
        type: selectedPayoutAccount?.type,
        payerId,
        number: selectedPayoutAccount.number
      })
        .then((response) => {
          const { data } = response;

          /* const slectedRecipientName = pick(selectedRecipient, ['firstName', 'lastName']);
          const currentBenificiaryName = pick(data, ['firstName', 'lastName']); */
/* 
          if (!isEqual(objValuesToLowerCase(slectedRecipientName), objValuesToLowerCase(currentBenificiaryName))) {
            throw new AppError({
              code: 409,
              type: 'warning',
              isDismissable: true,
              message: (
                <Translation ns={['error-common']}>
                  {(_t) =>
                    _t('recipientBenificiaryNameMissmatchMessage', {
                      recipientName: `${slectedRecipientName?.firstName} ${slectedRecipientName?.lastName}`,
                      beneficiaryName: `${currentBenificiaryName?.firstName} ${currentBenificiaryName?.lastName}`
                    })
                  }
                </Translation>
              ),
              name: 'Benificiary name missmatch'
            });
          } */
          setBeneficiary(data);
          setGetBenificieryLoading(false);
          setShowValidateMessage(true);
          return data;
        })
        .catch((_error) => {
          setGetBenificieryLoading(false);
          if(_error?.code >= 500) {
            setGetBenificieryError(_error);
            setBeneficiary(undefined);
            setShowValidateMessage(true);
          }

          return _error;
        });
    }
    return result;
  };

  const validateRecipient = () => {
    if (beneficiary) {
      return (
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <CheckCircleIcon color="primary" fontSize="large" />
        </div>
      );
    }
/*     if (!beneficiary && getBenificieryError?.response?.status >= 400 && getBenificieryError?.response?.status <= 403) {
      return (
        <Alert style={{ marginTop: 10, marginBottom: 10 }} severity="warning">
          {t('unableToRetrieveRecipientInformation')}
        </Alert>
      );
    } */
    if (!beneficiary && getBenificieryError?.response?.status >= 500) {
      return (
        <Alert style={{ marginTop: 10, marginBottom: 10 }} severity="error">
          {errorHandler(getBenificieryError)}
        </Alert>
      );
    }
    return null;
  };

  useEffect(() => {
    setShowValidateMessage(false);
  }, [selectedRecipient, selectedRecipient.recipientPayoutAccount]);

  return {
    verifyPayoutAccount,
    getBenificieryLoading,
    getBenificieryError,
    setGetBenificieryError,
    getBenificieryMessage: showValidateMessage && <div>{validateRecipient()}</div>
  };
};
