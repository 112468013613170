/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import { Typography, useMediaQuery , AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _camelCase from 'lodash/camelCase';
import * as yup from 'yup';

import addRecipientDialogStyle from './AddRecipientDialogStyle';
import API from '../../../utils/api';
import { recipientDetailsValidation } from './addRecipientValidation';
import CommonDialogTitle from '../../common/DialogTitle';
import MultiStepForm, { FormStep } from './MultiStepForm';
import Textfield from '../../common/TextfieldWrapper';
import { FORM_TRANSLATIONS } from '../formTranslations';
import { errorHandler } from '../../../utils/errorMapper';
import HelpPopOver from '../../common/HelpPopOver';
import ErrorBoundary from '../../common/ErrorBoundary';
import { worldCountries } from '../../../constants/worldCountries';
import CountrySelectAutocompleteField from '../../common/CountrySelectAutocompleteField';
import Selectfield from '../../common/SelectField';
import RecipientPayoutAccount from '../recipient-payout-account/RecipientPayoutAccount';
import { relationshipList } from '../../../constants/dashboardContants';
import Loading from '../../common/Loading';
import Util from '../../../utils/Util';
import { recipientPayoutAccountValidationSchema } from '../recipient-payout-account/recipientPayoutAccountValidationSchema';
import { payoutAccountType } from '../payoutAccountType';
import { getPayersForRecipientCountry } from '../../mtf/getPayers';
import { SOMALIA_SOMALILAND } from '../../country-currency-select/constants';
import { setGlobalDeliveryMethods } from '../../../store/redux/actions/moneyTransferBoxAction';

const useStyles = addRecipientDialogStyle;

export default function AddRecipientDialog(props) {
  const classes = useStyles();
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const { t } = useTranslation(['dashboard-common']);
  const { mapIsoCodeAndGetPayers, payers } = getPayersForRecipientCountry();
  const { onClose, open, addRecipient, error, stepNumber, setStepNumber, setError } = props;
  const [selectedRecipientPayer, setSelectedRecipientPayer] = useState();
  const { selectedRecipientCountryName, recipientCountries, deliveryMethods } = useSelector(
    (state) => state.moneyTransfer
  );
  const [selectedRecipientCountryPayers, setSelectedRecipientCountryPayers] = useState([]);
  const [services, setServices] = useState([...new Set(deliveryMethods.map((item) => item.type))]);
  const [selectedService, setSelectedService] = useState('Mobile Wallet');
  const [recipient, setRecipient] = useState({});
  const [beneficiary, setBeneficiary] = useState();
  const [recipientPayerError, setRecipientPayerError] = useState(undefined);
  const [recipientPayerLoading, setRecipientPayerLoading] = useState(false);
  const [recipientInfoLoading, setRecipientInfoLoading] = useState(false);
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [isSomaliland, setIsSomaliland] = useState(false);

  const INITIAL_STATE_VALUES = {
    country: Util.getCountryNameByISO3Code(selectedRecipientCountryName),
    service: '',
    payer: selectedRecipientPayer?.name,
    recipientPayoutAccount: '',
    citizenship: '',
    firstName: '',
    lastName: '',
    street: '',
    city: '',
    relationship: ''
  };

  const handlePayerSelect = (selectedPayer) => {
    setBeneficiary(undefined);
    setRecipientInfoLoading(true);
    const selectedPayoutAccount = payoutAccountType(selectedPayer?.type, recipient?.recipientPayoutAccount, '');

    const payerId = selectedPayer?.transferService?.toLowerCase() === 'thunes' ? `${selectedPayer.idInTransferService}` : undefined;

    if (recipient?.recipientPayoutAccount !== '') {

      API.post('/recipient-info', {
        service: selectedPayer?.transferService?.toLowerCase(),
        type: selectedPayoutAccount?.type,
        payerId,
        number: selectedPayoutAccount.number
      })
        .then((response) => {
          const { data } = response;
          if (data) {
            setBeneficiary(data);
            setRecipient(
              Object.assign(recipient, {
                ...recipient,
                ...data
              })
            );
          }
          setRecipientInfoLoading(false);
        })
        .catch((_error) => {
          setRecipientInfoLoading(false);
          setBeneficiary(undefined);
          setRecipientPayerError(_error);
        });
    }
  };

  const getPayersForRecipient = async (country) => {
    const recipientCountry = recipientCountries.find((_country) => _country.countryName === country);

    setRecipientPayerLoading(true);
    if (country && country === SOMALIA_SOMALILAND.SOMALILAND) {
      await mapIsoCodeAndGetPayers(recipientCountry?.isoCode3, country);
      setTimeout(() => {
        setSelectedRecipientCountryPayers(payers);
        setServices([...new Set(payers.map((item) => item.type))]);
        setRecipientPayerLoading(false);
        setIsSomaliland(true);
      }, 500);
    } else {
      API.get(`/recipient-country/${recipientCountry.isoCode3}`)
        .then((response) => {
          setSelectedRecipientCountryPayers(response.data.payers);
          setServices([...new Set(response.data.payers.map((item) => item.type))]);
          dispatch(setGlobalDeliveryMethods(response?.data?.payers));
          setRecipientPayerLoading(false);
          setIsSomaliland(false);
        })
        .catch((_error) => {
          setRecipient(INITIAL_STATE_VALUES);
          setBeneficiary();
          setError(_error)
          setRecipientPayerLoading(false);
        });
    }
  };

  const handleClose = () => {
    if (!error) {
      onClose();
      setRecipient({ ...INITIAL_STATE_VALUES });
      setBeneficiary(undefined);
      setRecipientPayerError(undefined);
      setServices([]);
    }
  };

  const handleServiceChange = async (service) => {
    setRecipientPayerError(undefined);

    if (service) {
      setSelectedService(service);
      const selectedCurrentService = selectedRecipientCountryPayers.find((item) => item?.type === service);
      setSelectedService(selectedCurrentService);
      setBeneficiary(undefined);
      setRecipient({ ...recipient, service, payer: undefined });
    }
  };

  const handlePayerChange = async (payer) => {
    if (payer) {
      setBeneficiary(undefined);
      const selectedPayer = selectedRecipientCountryPayers.find(
        (item) => item.name === payer && item?.type === selectedService?.type
      );
      setSelectedRecipientPayer(selectedPayer);
      setRecipient({ ...recipient, payer: selectedPayer?.name });
    }
  }

  const handleCountryChange = (country) => {
    if (country) {
      setRecipient({ country, recipientPayoutAccount: '', payer: '' });
      setBeneficiary();
      getPayersForRecipient(country);
    }
  }

  const handleClickContinue = () => {
    if (stepNumber === 0 && _isEmpty(beneficiary)) {
      handlePayerSelect(selectedRecipientPayer);
      setRecipientPayerError(undefined);
    }
    setIsBackClicked(false);
  };

  const handleClickBack = () => {
    setRecipientPayerError();
    setBeneficiary();
    setServices([...new Set(deliveryMethods.map((item) => item.type))]);
    setIsBackClicked(true);
    getPayersForRecipient(recipient?.country);
  };

  useEffect(() => {
    const countryNameByIso = Util.getCountryNameByISO3Code(selectedRecipientCountryName);
    if (recipient?.country === countryNameByIso) getPayersForRecipient(countryNameByIso);

    setServices([...new Set(deliveryMethods.map((item) => item.type))]);
    setSelectedRecipientCountryPayers(deliveryMethods);

  }, [recipient?.country, open, isSomaliland]);

  return (
    <Dialog
      transitionDuration={500}
      maxWidth={matchesSm ? "md" : "sm"}
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.dialogPaper }}
      disableScrollLock
    >
      <ErrorBoundary>
        <MultiStepForm
          onBackClick={handleClickBack}
          onContinueClick={handleClickContinue}
          lastStepName={t('addRecipient')}
          onClose={handleClose}
          initialValues={INITIAL_STATE_VALUES}
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          setError={setError}
          error={error}
          disabled={error || recipientPayerError?.code >= 500 || recipientPayerError?.code === 404}
          onSubmit={(values) => {
            addRecipient({ ...values, payer: selectedRecipientPayer });
            handleClose();
          }}
          snapshotState={({ values }) => {
            useEffect(() => {
              values.firstName = beneficiary?.firstName || values.firstName;
              values.lastName = beneficiary?.lastName || values.lastName;
              values.street = beneficiary?.address || values.street;
              values.city = beneficiary?.city || values.city;

              if (isBackClicked) {
                values.firstName = '';
                values.lastName = '';
                values.street = '';
                values.city = '';
              }
              setRecipient({ ...values, payer: recipient?.payer, service: recipient?.service });
            }, [values, selectedService, beneficiary]);
          }}
        >
          <FormStep
            stepName={t('paymentInformation')}
            validationSchema={yup.object().shape({
              country: yup.string().required(FORM_TRANSLATIONS.formCountryIsRequired),
              service: yup.string().required(FORM_TRANSLATIONS.formServiceMustbeSelected),
              payer: yup.string().required(FORM_TRANSLATIONS.formPayerIsRequired),
              ...recipientPayoutAccountValidationSchema(selectedService?.type, t, undefined)
            })}
            heading={
              <CommonDialogTitle
                title={t('addRecipient')}
                subTitle={t('enterRecipientPaymentDetails')}
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style">
                    <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                  </svg>
                }
                onClose={handleClose}
              />
            }
          >
            {!!error && <Alert severity={error?.type || 'error'}>{errorHandler(error)}</Alert>}
            <Grid
              className={classes.gridContainer}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {/*           <Typography className={classes.infoTitle} noWrap>
                  {t('paymentInformation')}
                </Typography> */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Selectfield
                  handleSelectChange={handleCountryChange}
                  fullWidth
                  name="country"
                  value={recipient?.country}
                  InputProps={{
                    className: classes.innerLabelRecipient
                  }}
                  options={recipientCountries.map((item) => ({
                    id: item.countryName,
                    name: item.countryName,
                    icon: true,
                    override: {
                      item,
                      name: item.countryName
                    }
                  }))}
                  label={FORM_TRANSLATIONS.formReceivingCountry}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} style={{ width: '100%' }}>
                <Selectfield
                  loading={recipientPayerLoading}
                  handleSelectChange={handleServiceChange}
                  fullWidth
                  name="service"
                  value={recipient?.service || ''}
                  InputProps={{
                    className: classes.innerLabelRecipient
                  }}
                  options={services.map((item) => ({
                    id: item,
                    name: item,
                    override: {
                      item,
                      name: t(_camelCase(item))
                    }
                  }))}
                  label={t('selectService')}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={recipient?.service && recipient?.country ? null : 'disabled-element'}
              >
                <Selectfield
                  handleSelectChange={handlePayerChange}
                  fullWidth
                  name="payer"
                  value={selectedRecipientPayer?.name || ''}
                  InputProps={{
                    className: classes.innerLabelRecipient
                  }}
                  options={selectedRecipientCountryPayers
                    .filter((item) => item?.type === selectedService?.type)
                    .map((item) => ({
                      id: item,
                      name: item.name,
                      override: {
                        item,
                        name: item.name
                      }
                    }))}
                  label={t('selectPayer')}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                className={recipient?.service && recipient?.country ? null : 'disabled-element'}
              >
                <RecipientPayoutAccount
                  selectedRecipient={recipient}
                  type={selectedService?.type}
                  recipientPayer={selectedRecipientPayer}
                  displayValidationInfo={false}
                />
              </Grid>
            </Grid>
          </FormStep>
          <FormStep
            stepName={t('recipientDetails')}
            validationSchema={recipientDetailsValidation}
            heading={
              <CommonDialogTitle
                title={t('addRecipient')}
                subTitle={t('enterRecipientsDetails')}
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style">
                    <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                  </svg>
                }
                onClose={handleClose}
              />
            }
          >
            {error ? <Alert severity={error?.type || 'error'}>{errorHandler(error)}</Alert> : null}
            {recipientPayerError ? <Alert severity={recipientPayerError?.type || 'error'}>{errorHandler(recipientPayerError)}</Alert> : null}
             {recipientPayerError && (recipientPayerError?.code >= 400 && recipientPayerError?.code <= 403) ? (
              <Alert severity="warning">
                <AlertTitle>{`${t('payoutAccountNumber')}: ${recipient?.recipientPayoutAccount}`}</AlertTitle>
                {t('unableToRetrieveRecipientInformationAlt')}
                <br />
              </Alert>
            ) : null}
            {recipientPayerError && (recipientPayerError?.code === 404) ? (
              <Alert severity="error">
                {t('theAccountNumberDoesNotExist', { payoutAccount: recipient?.recipientPayoutAccount, payer: selectedRecipientPayer?.name })}
                <br />
              </Alert>
            ) : null}  
            {recipientInfoLoading ?
              <Loading /> :
              (
                <Grid
                  className={classes.gridContainer}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  {/*         <Typography className={classes.infoTitle} noWrap>
                  {t('personalInformation')}
                </Typography> */}
                  <Grid item lg={6} md={6} sm={12}>
                    <Textfield
                      shrink={beneficiary?.firstName !== null}
                      name="firstName"
                      label={FORM_TRANSLATIONS.formFirstName}
                      value={recipient?.firstName}
                      readOnly={!!beneficiary?.firstName}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12}>
                    <Textfield
                      shrink={beneficiary?.lastName !== null}
                      name="lastName"
                      label={FORM_TRANSLATIONS.formLastName}
                      value={recipient?.lastName}
                      readOnly={!!beneficiary?.lastName}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12}>
                    <div className={classes.streetHelpPopover}>
                      <HelpPopOver content={<p>{t('missingStreetAddress')}</p>} title={t('streetAddress')} fullWidth />
                    </div>
                    <Textfield
                      shrink={!!recipient?.street || beneficiary?.address !== null}
                      name="street"
                      label={FORM_TRANSLATIONS.formStreet}
                      value={recipient?.street}
                      readOnly={!!beneficiary?.adress}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12}>
                    <Textfield
                      shrink={!!recipient?.city || beneficiary?.city !== null}
                      name="city"
                      label={FORM_TRANSLATIONS.formCity}
                      value={recipient?.city}
                      readOnly={!!beneficiary?.city}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} style={{ width: '100%' }}>
                    <CountrySelectAutocompleteField
                      fullWidth
                      id="citizenship"
                      name="citizenship"
                      options={worldCountries}
                      label={FORM_TRANSLATIONS.formNationality}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} style={{ width: '100%' }}>
                    <Selectfield
                      fullWidth
                      name="relationship"
                      value={recipient?.relationship}
                      InputProps={{
                        className: classes.innerLabelRecipient
                      }}
                      options={relationshipList.map((relationship) => ({
                        id: relationship.id,
                        name: relationship.relationship,
                        override: {
                          relationship,
                          name: t(_camelCase(relationship.value))
                        }
                      }))}
                      label={t('relationship')}
                    />
                  </Grid>
                  <Grid item lg={5} md={5} sm={12}>
                    {/* placeholder */}
                  </Grid>
                </Grid>
              )}
          </FormStep>
          <FormStep
            stepName={t('summary')}
            heading={<CommonDialogTitle title={t('summary')} subTitle={t('recipientDetails')} onClose={handleClose} />}
          >
            {!!error && <Alert severity={error?.type || 'error'}>{errorHandler(error)}</Alert>}
            <Grid
              className={classes.gridContainer}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ paddingLeft: 50, paddingRight: 50 }}
            >
              <Grid item xs={12}>
                <Typography fontWeight="fontWeightLight" align="center" className={classes.recipientDetailsHeading}>
                  {t('payer')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub1}>{t('service')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub2}>{selectedService?.type}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub1}>{t('payer')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub2}>{selectedRecipientPayer?.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub1}>{t('payoutAccount')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub2}>{recipient?.recipientPayoutAccount}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight="fontWeightLight" align="center" className={classes.recipientDetailsHeading}>
                  {t('recipient')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub1}>{t('name')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub2}>
                  {recipient?.firstName} {recipient?.lastName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub1}>{`${t('formStreet')}\\${t(
                  'formLocality'
                )}`}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub2}>{recipient?.street}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub1}>{t('formCity')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub2}>{recipient?.city}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub1}>{t('formCountry')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub2}>{recipient?.country}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub1}>{t('formNationality')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub2}>
                  <Translation ns={['common']}>{(_t) => _t(_camelCase(recipient?.citizenship))}</Translation>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub1}>{t('formRelationship')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.recipientDetailsSub2}>
                  {t(_camelCase(recipient?.relationship))}
                </Typography>
              </Grid>
            </Grid>
          </FormStep>
        </MultiStepForm>
      </ErrorBoundary>
    </Dialog>
  );
}

AddRecipientDialog.defaultProps = {
  error: null,
  setError: undefined
};

AddRecipientDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  addRecipient: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  stepNumber: PropTypes.number.isRequired,
  setStepNumber: PropTypes.func.isRequired,
  setError: PropTypes.func
};