export const worldCountries = [
  { countryName: 'Afghanistan', isoCode2: 'AF', isoCode3: 'AFG', nationality: 'Afghan', phoneCode: '93' },
  { countryName: 'Alland Islands', isoCode2: 'AX', isoCode3: 'ALA', nationality: 'Åland Island', phoneCode: '358' },
  { countryName: 'Albania', isoCode2: 'AL', isoCode3: 'ALB', nationality: 'Albanian', phoneCode: '355' },
  { countryName: 'Algeria', isoCode2: 'DZ', isoCode3: 'DZA', nationality: 'Algerian', phoneCode: '213' },
  {
    countryName: 'American Samoa',
    isoCode2: 'AS',
    isoCode3: 'ASM',
    nationality: 'American Samoan',
    phoneCode: '1-684'
  },
  { countryName: 'Andorra', isoCode2: 'AD', isoCode3: 'AND', nationality: 'Andorran', phoneCode: '376' },
  { countryName: 'Angola', isoCode2: 'AO', isoCode3: 'AGO', nationality: 'Angolan', phoneCode: '244' },
  { countryName: 'Anguilla', isoCode2: 'AI', isoCode3: 'AIA', nationality: 'Anguillan', phoneCode: '1-264' },
  { countryName: 'Antarctica', isoCode2: 'AQ', isoCode3: 'ATA', nationality: 'Antarctic', phoneCode: '672' },
  {
    countryName: 'Antigua and Barbuda',
    isoCode2: 'AG',
    isoCode3: 'ATG',
    nationality: 'Antiguan or Barbudan',
    phoneCode: '1-268'
  },
  { countryName: 'Argentina', isoCode2: 'AR', isoCode3: 'ARG', nationality: 'Argentine', phoneCode: '54' },
  { countryName: 'Armenia', isoCode2: 'AM', isoCode3: 'ARM', nationality: 'Armenian', phoneCode: '374' },
  { countryName: 'Aruba', isoCode2: 'AW', isoCode3: 'ABW', nationality: 'Aruban', phoneCode: '297' },
  { countryName: 'Australia', isoCode2: 'AU', isoCode3: 'AUS', nationality: 'Australian', phoneCode: '61' },
  { countryName: 'Austria', isoCode2: 'AT', isoCode3: 'AUT', nationality: 'Austrian', phoneCode: '43' },
  { countryName: 'Azerbaijan', isoCode2: 'AZ', isoCode3: 'AZE', nationality: 'Azerbaijani, Azeri', phoneCode: '994' },
  { countryName: 'Bahamas', isoCode2: 'BS', isoCode3: 'BHS', nationality: 'Bahamian', phoneCode: '1-242' },
  { countryName: 'Bahrain', isoCode2: 'BH', isoCode3: 'BHR', nationality: 'Bahraini', phoneCode: '973' },
  { countryName: 'Bangladesh', isoCode2: 'BD', isoCode3: 'BGD', nationality: 'Bangladeshi', phoneCode: '880' },
  { countryName: 'Barbados', isoCode2: 'BB', isoCode3: 'BRB', nationality: 'Barbadian', phoneCode: '1-246' },
  { countryName: 'Belarus', isoCode2: 'BY', isoCode3: 'BLR', nationality: 'Belarusian', phoneCode: '375' },
  { countryName: 'Belgium', isoCode2: 'BE', isoCode3: 'BEL', nationality: 'Belgian', phoneCode: '32' },
  { countryName: 'Belize', isoCode2: 'BZ', isoCode3: 'BLZ', nationality: 'Belizean', phoneCode: '501' },
  { countryName: 'Benin', isoCode2: 'BJ', isoCode3: 'BEN', nationality: 'Beninese, Beninois', phoneCode: '229' },
  { countryName: 'Bermuda', isoCode2: 'BM', isoCode3: 'BMU', nationality: 'Bermudian, Bermudan', phoneCode: '1-441' },
  { countryName: 'Bhutan', isoCode2: 'BT', isoCode3: 'BTN', nationality: 'Bhutanese', phoneCode: '975' },
  { countryName: 'Bolivia', isoCode2: 'BO', isoCode3: 'BOL', nationality: 'Bolivian', phoneCode: '591' },
  {
    countryName: 'Bosnia and Herzegovina',
    isoCode2: 'BA',
    isoCode3: 'BIH',
    nationality: 'Bosnian or Herzegovinian',
    phoneCode: '387'
  },
  { countryName: 'Botswana', isoCode2: 'BW', isoCode3: 'BWA', nationality: 'Motswana, Botswanan', phoneCode: '267' },
  { countryName: 'Bouvet Island', isoCode2: 'BV', isoCode3: 'BVT', nationality: 'Bouvet Island', phoneCode: '47' },
  { countryName: 'Brazil', isoCode2: 'BR', isoCode3: 'BRA', nationality: 'Brazilian', phoneCode: '55' },
  {
    countryName: 'British Indian Ocean Territory',
    isoCode2: 'IO',
    isoCode3: 'IOT',
    nationality: 'BIOT',
    phoneCode: '246'
  },
  { countryName: 'Brunei Darussalam', isoCode2: 'BN', isoCode3: 'BRN', nationality: 'Bruneian', phoneCode: '673' },
  { countryName: 'Bulgaria', isoCode2: 'BG', isoCode3: 'BGR', nationality: 'Bulgarian', phoneCode: '359' },
  { countryName: 'Burkina Faso', isoCode2: 'BF', isoCode3: 'BFA', nationality: 'Burkinabé', phoneCode: '226' },
  { countryName: 'Burundi', isoCode2: 'BI', isoCode3: 'BDI', nationality: 'Burundian', phoneCode: '257' },
  { countryName: 'Cape Verde', isoCode2: 'CV', isoCode3: 'CPV', nationality: 'Cabo Verdean', phoneCode: '238' },
  { countryName: 'Cambodia', isoCode2: 'KH', isoCode3: 'KHM', nationality: 'Cambodian', phoneCode: '855' },
  { countryName: 'Cameroon', isoCode2: 'CM', isoCode3: 'CMR', nationality: 'Cameroonian', phoneCode: '237' },
  { countryName: 'Canada', isoCode2: 'CA', isoCode3: 'CAN', nationality: 'Canadian', phoneCode: '1' },
  { countryName: 'Cayman Islands', isoCode2: 'KY', isoCode3: 'CYM', nationality: 'Caymanian', phoneCode: '1-345' },
  {
    countryName: 'Central African Republic',
    isoCode2: 'CF',
    isoCode3: 'CAF',
    nationality: 'Central African',
    phoneCode: '236'
  },
  { countryName: 'Chad', isoCode2: 'TD', isoCode3: 'TCD', nationality: 'Chadian', phoneCode: '235' },
  { countryName: 'Chile', isoCode2: 'CL', isoCode3: 'CHL', nationality: 'Chilean', phoneCode: '56' },
  { countryName: 'China', isoCode2: 'CN', isoCode3: 'CHN', nationality: 'Chinese', phoneCode: '86' },
  {
    countryName: 'Christmas Island',
    isoCode2: 'CX',
    isoCode3: 'CXR',
    nationality: 'Christmas Island',
    phoneCode: '61'
  },
  {
    countryName: 'Cocos (Keeling) Islands',
    isoCode2: 'CC',
    isoCode3: 'CCK',
    nationality: 'Cocos Island',
    phoneCode: '61'
  },
  { countryName: 'Colombia', isoCode2: 'CO', isoCode3: 'COL', nationality: 'Colombian', phoneCode: '57' },
  { countryName: 'Comoros', isoCode2: 'KM', isoCode3: 'COM', nationality: 'Comoran, Comorian', phoneCode: '269' },
  {
    countryName: 'Congo, Republic of the',
    isoCode2: 'CG',
    isoCode3: 'COG',
    nationality: 'Congolese',
    phoneCode: '242'
  },
  {
    countryName: 'Congo, Democratic Republic of the',
    isoCode2: 'CD',
    isoCode3: 'COD',
    nationality: 'Congolese',
    phoneCode: '243'
  },
  { countryName: 'Cook Islands', isoCode2: 'CK', isoCode3: 'COK', nationality: 'Cook Island', phoneCode: '682' },
  { countryName: 'Costa Rica', isoCode2: 'CR', isoCode3: 'CRI', nationality: 'Costa Rican', phoneCode: '506' },
  { countryName: "Cote d'Ivoire", isoCode2: 'CI', isoCode3: 'CIV', nationality: 'Ivorian', phoneCode: '225' },
  { countryName: 'Croatia', isoCode2: 'HR', isoCode3: 'HRV', nationality: 'Croatian', phoneCode: '385' },
  { countryName: 'Cuba', isoCode2: 'CU', isoCode3: 'CUB', nationality: 'Cuban', phoneCode: '53' },
  { countryName: 'Curacao', isoCode2: 'CW', isoCode3: 'CUW', nationality: 'Curaçaoan', phoneCode: '599' },
  { countryName: 'Cyprus', isoCode2: 'CY', isoCode3: 'CYP', nationality: 'Cypriot', phoneCode: '357' },
  { countryName: 'Czech Republic', isoCode2: 'CZ', isoCode3: 'CZE', nationality: 'Czech', phoneCode: '420' },
  { countryName: 'Denmark', isoCode2: 'DK', isoCode3: 'DNK', nationality: 'Danish', phoneCode: '45' },
  { countryName: 'Djibouti', isoCode2: 'DJ', isoCode3: 'DJI', nationality: 'Djiboutian', phoneCode: '253' },
  { countryName: 'Dominica', isoCode2: 'DM', isoCode3: 'DMA', nationality: 'Dominican', phoneCode: '1-767' },
  { countryName: 'Dominican Republic', isoCode2: 'DO', isoCode3: 'DOM', nationality: 'Dominican', phoneCode: '1-809' },
  { countryName: 'Ecuador', isoCode2: 'EC', isoCode3: 'ECU', nationality: 'Ecuadorian', phoneCode: '593' },
  { countryName: 'Egypt', isoCode2: 'EG', isoCode3: 'EGY', nationality: 'Egyptian', phoneCode: '20' },
  { countryName: 'El Salvador', isoCode2: 'SV', isoCode3: 'SLV', nationality: 'Salvadoran', phoneCode: '503' },
  {
    countryName: 'Equatorial Guinea',
    isoCode2: 'GQ',
    isoCode3: 'GNQ',
    nationality: 'Equatorial Guinean, Equatoguinean',
    phoneCode: '240'
  },
  { countryName: 'Eritrea', isoCode2: 'ER', isoCode3: 'ERI', nationality: 'Eritrean', phoneCode: '291' },
  { countryName: 'Estonia', isoCode2: 'EE', isoCode3: 'EST', nationality: 'Estonian', phoneCode: '372' },
  { countryName: 'Ethiopia', isoCode2: 'ET', isoCode3: 'ETH', nationality: 'Ethiopian', phoneCode: '251' },
  {
    countryName: 'Falkland Islands (Malvinas)',
    isoCode2: 'FK',
    isoCode3: 'FLK',
    nationality: 'Falkland Island',
    phoneCode: '500'
  },
  { countryName: 'Faroe Islands', isoCode2: 'FO', isoCode3: 'FRO', nationality: 'Faroese', phoneCode: '298' },
  { countryName: 'Fiji', isoCode2: 'FJ', isoCode3: 'FJI', nationality: 'Fijian', phoneCode: '679' },
  { countryName: 'Finland', isoCode2: 'FI', isoCode3: 'FIN', nationality: 'Finnish', phoneCode: '358' },
  { countryName: 'France', isoCode2: 'FR', isoCode3: 'FRA', nationality: 'French', phoneCode: '33' },
  { countryName: 'French Guiana', isoCode2: 'GF', isoCode3: 'GUF', nationality: 'French Guianese', phoneCode: '594' },
  {
    countryName: 'French Polynesia',
    isoCode2: 'PF',
    isoCode3: 'PYF',
    nationality: 'French Polynesian',
    phoneCode: '689'
  },
  {
    countryName: 'French Southern Territories',
    isoCode2: 'TF',
    isoCode3: 'ATF',
    nationality: 'French Southern Territories',
    phoneCode: '262'
  },
  { countryName: 'Gabon', isoCode2: 'GA', isoCode3: 'GAB', nationality: 'Gabonese', phoneCode: '241' },
  { countryName: 'Gambia', isoCode2: 'GM', isoCode3: 'GMB', nationality: 'Gambian', phoneCode: '220' },
  { countryName: 'Georgia', isoCode2: 'GE', isoCode3: 'GEO', nationality: 'Georgian', phoneCode: '995' },
  { countryName: 'Germany', isoCode2: 'DE', isoCode3: 'DEU', nationality: 'German', phoneCode: '49' },
  { countryName: 'Ghana', isoCode2: 'GH', isoCode3: 'GHA', nationality: 'Ghanaian', phoneCode: '233' },
  { countryName: 'Gibraltar', isoCode2: 'GI', isoCode3: 'GIB', nationality: 'Gibraltar', phoneCode: '350' },
  { countryName: 'Greece', isoCode2: 'GR', isoCode3: 'GRC', nationality: 'Greek, Hellenic', phoneCode: '30' },
  { countryName: 'Greenland', isoCode2: 'GL', isoCode3: 'GRL', nationality: 'Greenlandic', phoneCode: '299' },
  { countryName: 'Grenada', isoCode2: 'GD', isoCode3: 'GRD', nationality: 'Grenadian', phoneCode: '1-473' },
  { countryName: 'Guadeloupe', isoCode2: 'GP', isoCode3: 'GLP', nationality: 'Guadeloupe', phoneCode: '590' },
  { countryName: 'Guam', isoCode2: 'GU', isoCode3: 'GUM', nationality: 'Guamanian, Guambat', phoneCode: '1-671' },
  { countryName: 'Guatemala', isoCode2: 'GT', isoCode3: 'GTM', nationality: 'Guatemalan', phoneCode: '502' },
  { countryName: 'Guernsey', isoCode2: 'GG', isoCode3: 'GGY', nationality: 'Channel Island', phoneCode: '44' },
  { countryName: 'Guinea', isoCode2: 'GN', isoCode3: 'GIN', nationality: 'Guinean', phoneCode: '224' },
  { countryName: 'Guinea-Bissau', isoCode2: 'GW', isoCode3: 'GNB', nationality: 'Bissau-Guinean', phoneCode: '245' },
  { countryName: 'Guyana', isoCode2: 'GY', isoCode3: 'GUY', nationality: 'Guyanese', phoneCode: '592' },
  { countryName: 'Haiti', isoCode2: 'HT', isoCode3: 'HTI', nationality: 'Haitian', phoneCode: '509' },
  {
    countryName: 'Heard Island and McDonald Islands',
    isoCode2: 'HM',
    isoCode3: 'HMD',
    nationality: 'Heard Island or McDonald Islands',
    phoneCode: '672'
  },
  {
    countryName: 'Holy See (Vatican City State)',
    isoCode2: 'VA',
    isoCode3: 'VAT',
    nationality: 'Vatican',
    phoneCode: '379'
  },
  { countryName: 'Honduras', isoCode2: 'HN', isoCode3: 'HND', nationality: 'Honduran', phoneCode: '504' },
  {
    countryName: 'Hong Kong',
    isoCode2: 'HK',
    isoCode3: 'HKG',
    nationality: 'Hong Kong, Hong Kongese',
    phoneCode: '852'
  },
  { countryName: 'Hungary', isoCode2: 'HU', isoCode3: 'HUN', nationality: 'Hungarian, Magyar', phoneCode: '36' },
  { countryName: 'Iceland', isoCode2: 'IS', isoCode3: 'ISL', nationality: 'Icelandic', phoneCode: '354' },
  { countryName: 'India', isoCode2: 'IN', isoCode3: 'IND', nationality: 'Indian', phoneCode: '91' },
  { countryName: 'Indonesia', isoCode2: 'ID', isoCode3: 'IDN', nationality: 'Indonesian', phoneCode: '62' },
  {
    countryName: 'Iran, Islamic Republic of',
    isoCode2: 'IR',
    isoCode3: 'IRN',
    nationality: 'Iranian, Persian',
    phoneCode: '98'
  },
  { countryName: 'Iraq', isoCode2: 'IQ', isoCode3: 'IRQ', nationality: 'Iraqi', phoneCode: '964' },
  { countryName: 'Ireland', isoCode2: 'IE', isoCode3: 'IRL', nationality: 'Irish', phoneCode: '353' },
  { countryName: 'Isle of Man', isoCode2: 'IM', isoCode3: 'IMN', nationality: 'Manx', phoneCode: '44' },
  { countryName: 'Israel', isoCode2: 'IL', isoCode3: 'ISR', nationality: 'Israeli', phoneCode: '972' },
  { countryName: 'Italy', isoCode2: 'IT', isoCode3: 'ITA', nationality: 'Italian', phoneCode: '39' },
  { countryName: 'Jamaica', isoCode2: 'JM', isoCode3: 'JAM', nationality: 'Jamaican', phoneCode: '1-876' },
  { countryName: 'Japan', isoCode2: 'JP', isoCode3: 'JPN', nationality: 'Japanese', phoneCode: '81' },
  { countryName: 'Jersey', isoCode2: 'JE', isoCode3: 'JEY', nationality: 'Channel Island', phoneCode: '44' },
  { countryName: 'Jordan', isoCode2: 'JO', isoCode3: 'JOR', nationality: 'Jordanian', phoneCode: '962' },
  { countryName: 'Kazakhstan', isoCode2: 'KZ', isoCode3: 'KAZ', nationality: 'Kazakhstani, Kazakh', phoneCode: '7' },
  { countryName: 'Kenya', isoCode2: 'KE', isoCode3: 'KEN', nationality: 'Kenyan', phoneCode: '254' },
  { countryName: 'Kiribati', isoCode2: 'KI', isoCode3: 'KIR', nationality: 'I-Kiribati', phoneCode: '686' },
  {
    countryName: "Korea, Democratic People's Republic of",
    isoCode2: 'KP',
    isoCode3: 'PRK',
    nationality: 'North Korean',
    phoneCode: '850'
  },
  { countryName: 'Korea, Republic of', isoCode2: 'KR', isoCode3: 'KOR', nationality: 'South Korean', phoneCode: '82' },
  { countryName: 'Kuwait', isoCode2: 'KW', isoCode3: 'KWT', nationality: 'Kuwaiti', phoneCode: '965' },
  {
    countryName: 'Kyrgyzstan',
    isoCode2: 'KG',
    isoCode3: 'KGZ',
    nationality: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
    phoneCode: '996'
  },
  {
    countryName: "Lao People's Democratic Republic",
    isoCode2: 'LA',
    isoCode3: 'LAO',
    nationality: 'Lao, Laotian',
    phoneCode: '856'
  },
  { countryName: 'Latvia', isoCode2: 'LV', isoCode3: 'LVA', nationality: 'Latvian', phoneCode: '371' },
  { countryName: 'Lebanon', isoCode2: 'LB', isoCode3: 'LBN', nationality: 'Lebanese', phoneCode: '961' },
  { countryName: 'Lesotho', isoCode2: 'LS', isoCode3: 'LSO', nationality: 'Basotho', phoneCode: '266' },
  { countryName: 'Liberia', isoCode2: 'LR', isoCode3: 'LBR', nationality: 'Liberian', phoneCode: '231' },
  { countryName: 'Libya', isoCode2: 'LY', isoCode3: 'LBY', nationality: 'Libyan', phoneCode: '218' },
  { countryName: 'Liechtenstein', isoCode2: 'LI', isoCode3: 'LIE', nationality: 'Liechtenstein', phoneCode: '423' },
  { countryName: 'Lithuania', isoCode2: 'LT', isoCode3: 'LTU', nationality: 'Lithuanian', phoneCode: '370' },
  {
    countryName: 'Luxembourg',
    isoCode2: 'LU',
    isoCode3: 'LUX',
    nationality: 'Luxembourg, Luxembourgish',
    phoneCode: '352'
  },
  { countryName: 'Macao', isoCode2: 'MO', isoCode3: 'MAC', nationality: 'Macanese, Chinese', phoneCode: '853' },
  {
    countryName: 'Macedonia, the Former Yugoslav Republic of',
    isoCode2: 'MK',
    isoCode3: 'MKD',
    nationality: 'Macedonian',
    phoneCode: '389'
  },
  { countryName: 'Madagascar', isoCode2: 'MG', isoCode3: 'MDG', nationality: 'Malagasy', phoneCode: '261' },
  { countryName: 'Malawi', isoCode2: 'MW', isoCode3: 'MWI', nationality: 'Malawian', phoneCode: '265' },
  { countryName: 'Malaysia', isoCode2: 'MY', isoCode3: 'MYS', nationality: 'Malaysian', phoneCode: '60' },
  { countryName: 'Maldives', isoCode2: 'MV', isoCode3: 'MDV', nationality: 'Maldivian', phoneCode: '960' },
  { countryName: 'Mali', isoCode2: 'ML', isoCode3: 'MLI', nationality: 'Malian, Malinese', phoneCode: '223' },
  { countryName: 'Malta', isoCode2: 'MT', isoCode3: 'MLT', nationality: 'Maltese', phoneCode: '356' },
  { countryName: 'Marshall Islands', isoCode2: 'MH', isoCode3: 'MHL', nationality: 'Marshallese', phoneCode: '692' },
  {
    countryName: 'Martinique',
    isoCode2: 'MQ',
    isoCode3: 'MTQ',
    nationality: 'Martiniquais, Martinican',
    phoneCode: '596'
  },
  { countryName: 'Mauritania', isoCode2: 'MR', isoCode3: 'MRT', nationality: 'Mauritanian', phoneCode: '222' },
  { countryName: 'Mauritius', isoCode2: 'MU', isoCode3: 'MUS', nationality: 'Mauritian', phoneCode: '230' },
  { countryName: 'Mayotte', isoCode2: 'YT', isoCode3: 'MYT', nationality: 'Mahoran', phoneCode: '262' },
  { countryName: 'Mexico', isoCode2: 'MX', isoCode3: 'MEX', nationality: 'Mexican', phoneCode: '52' },
  {
    countryName: 'Micronesia, Federated States of',
    isoCode2: 'FM',
    isoCode3: 'FSM',
    nationality: 'Micronesian',
    phoneCode: '691'
  },
  { countryName: 'Moldova, Republic of', isoCode2: 'MD', isoCode3: 'MDA', nationality: 'Moldovan', phoneCode: '373' },
  { countryName: 'Monaco', isoCode2: 'MC', isoCode3: 'MCO', nationality: 'Monégasque, Monacan', phoneCode: '377' },
  { countryName: 'Mongolia', isoCode2: 'MN', isoCode3: 'MNG', nationality: 'Mongolian', phoneCode: '976' },
  { countryName: 'Montenegro', isoCode2: 'ME', isoCode3: 'MNE', nationality: 'Montenegrin', phoneCode: '382' },
  { countryName: 'Montserrat', isoCode2: 'MS', isoCode3: 'MSR', nationality: 'Montserratian', phoneCode: '1-664' },
  { countryName: 'Morocco', isoCode2: 'MA', isoCode3: 'MAR', nationality: 'Moroccan', phoneCode: '212' },
  { countryName: 'Mozambique', isoCode2: 'MZ', isoCode3: 'MOZ', nationality: 'Mozambican', phoneCode: '258' },
  { countryName: 'Myanmar', isoCode2: 'MM', isoCode3: 'MMR', nationality: 'Burmese', phoneCode: '95' },
  { countryName: 'Namibia', isoCode2: 'NA', isoCode3: 'NAM', nationality: 'Namibian', phoneCode: '264' },
  { countryName: 'Nauru', isoCode2: 'NR', isoCode3: 'NRU', nationality: 'Nauruan', phoneCode: '674' },
  { countryName: 'Nepal', isoCode2: 'NP', isoCode3: 'NPL', nationality: 'Nepali, Nepalese', phoneCode: '977' },
  { countryName: 'Netherlands', isoCode2: 'NL', isoCode3: 'NLD', nationality: 'Dutch, Netherlandic', phoneCode: '31' },
  { countryName: 'New Caledonia', isoCode2: 'NC', isoCode3: 'NCL', nationality: 'New Caledonian', phoneCode: '687' },
  { countryName: 'New Zealand', isoCode2: 'NZ', isoCode3: 'NZL', nationality: 'New Zealand, NZ', phoneCode: '64' },
  { countryName: 'Nicaragua', isoCode2: 'NI', isoCode3: 'NIC', nationality: 'Nicaraguan', phoneCode: '505' },
  { countryName: 'Niger', isoCode2: 'NE', isoCode3: 'NER', nationality: 'Nigerien', phoneCode: '227' },
  { countryName: 'Nigeria', isoCode2: 'NG', isoCode3: 'NGA', nationality: 'Nigerian', phoneCode: '234' },
  { countryName: 'Niue', isoCode2: 'NU', isoCode3: 'NIU', nationality: 'Niuean', phoneCode: '683' },
  { countryName: 'Norfolk Island', isoCode2: 'NF', isoCode3: 'NFK', nationality: 'Norfolk Island', phoneCode: '672' },
  {
    countryName: 'Northern Mariana Islands',
    isoCode2: 'MP',
    isoCode3: 'MNP',
    nationality: 'Northern Marianan',
    phoneCode: '1-670'
  },
  { countryName: 'Norway', isoCode2: 'NO', isoCode3: 'NOR', nationality: 'Norwegian', phoneCode: '47' },
  { countryName: 'Oman', isoCode2: 'OM', isoCode3: 'OMN', nationality: 'Omani', phoneCode: '968' },
  { countryName: 'Pakistan', isoCode2: 'PK', isoCode3: 'PAK', nationality: 'Pakistani', phoneCode: '92' },
  { countryName: 'Palau', isoCode2: 'PW', isoCode3: 'PLW', nationality: 'Palauan', phoneCode: '680' },
  { countryName: 'Palestine, State of', isoCode2: 'PS', isoCode3: 'PSE', nationality: 'Palestinian', phoneCode: '970' },
  { countryName: 'Panama', isoCode2: 'PA', isoCode3: 'PAN', nationality: 'Panamanian', phoneCode: '507' },
  {
    countryName: 'Papua New Guinea',
    isoCode2: 'PG',
    isoCode3: 'PNG',
    nationality: 'Papua New Guinean, Papuan',
    phoneCode: '675'
  },
  { countryName: 'Paraguay', isoCode2: 'PY', isoCode3: 'PRY', nationality: 'Paraguayan', phoneCode: '595' },
  { countryName: 'Peru', isoCode2: 'PE', isoCode3: 'PER', nationality: 'Peruvian', phoneCode: '51' },
  { countryName: 'Philippines', isoCode2: 'PH', isoCode3: 'PHL', nationality: 'Philippine, Filipino', phoneCode: '63' },
  { countryName: 'Pitcairn', isoCode2: 'PN', isoCode3: 'PCN', nationality: 'Pitcairn Island', phoneCode: '870' },
  { countryName: 'Poland', isoCode2: 'PL', isoCode3: 'POL', nationality: 'Polish', phoneCode: '48' },
  { countryName: 'Portugal', isoCode2: 'PT', isoCode3: 'PRT', nationality: 'Portuguese', phoneCode: '351' },
  { countryName: 'Puerto Rico', isoCode2: 'PR', isoCode3: 'PRI', nationality: 'Puerto Rican', phoneCode: '1' },
  { countryName: 'Qatar', isoCode2: 'QA', isoCode3: 'QAT', nationality: 'Qatari', phoneCode: '974' },
  { countryName: 'Reunion', isoCode2: 'RE', isoCode3: 'REU', nationality: 'Réunionese, Réunionnais', phoneCode: '262' },
  { countryName: 'Romania', isoCode2: 'RO', isoCode3: 'ROU', nationality: 'Romanian', phoneCode: '40' },
  { countryName: 'Russian Federation', isoCode2: 'RU', isoCode3: 'RUS', nationality: 'Russian', phoneCode: '7' },
  { countryName: 'Rwanda', isoCode2: 'RW', isoCode3: 'RWA', nationality: 'Rwandan', phoneCode: '250' },
  { countryName: 'Saint Barthelemy', isoCode2: 'BL', isoCode3: 'BLM', nationality: 'Barthélemois', phoneCode: '590' },
  { countryName: 'Saint Helena', isoCode2: 'SH', isoCode3: 'SHN', nationality: 'Saint Helenian', phoneCode: '290' },
  {
    countryName: 'Saint Kitts and Nevis',
    isoCode2: 'KN',
    isoCode3: 'KNA',
    nationality: 'Kittitian or Nevisian',
    phoneCode: '1-869'
  },
  { countryName: 'Saint Lucia', isoCode2: 'LC', isoCode3: 'LCA', nationality: 'Saint Lucian', phoneCode: '1-758' },
  {
    countryName: 'Saint Martin (French part)',
    isoCode2: 'MF',
    isoCode3: 'MAF',
    nationality: 'Saint-Martinoise',
    phoneCode: '590'
  },
  {
    countryName: 'Saint Pierre and Miquelon',
    isoCode2: 'PM',
    isoCode3: 'SPM',
    nationality: 'Saint-Pierrais or Miquelonnais',
    phoneCode: '508'
  },
  {
    countryName: 'Saint Vincent and the Grenadines',
    isoCode2: 'VC',
    isoCode3: 'VCT',
    nationality: 'Saint Vincentian, Vincentian',
    phoneCode: '1-784'
  },
  { countryName: 'Samoa', isoCode2: 'WS', isoCode3: 'WSM', nationality: 'Samoan', phoneCode: '685' },
  { countryName: 'San Marino', isoCode2: 'SM', isoCode3: 'SMR', nationality: 'Sammarinese', phoneCode: '378' },
  {
    countryName: 'Sao Tome and Principe',
    isoCode2: 'ST',
    isoCode3: 'STP',
    nationality: 'São Toméan',
    phoneCode: '239'
  },
  {
    countryName: 'Saudi Arabia',
    isoCode2: 'SA',
    isoCode3: 'SAU',
    nationality: 'Saudi, Saudi Arabian',
    phoneCode: '966'
  },
  { countryName: 'Senegal', isoCode2: 'SN', isoCode3: 'SEN', nationality: 'Senegalese', phoneCode: '221' },
  { countryName: 'Serbia', isoCode2: 'RS', isoCode3: 'SRB', nationality: 'Serbian', phoneCode: '381' },
  { countryName: 'Seychelles', isoCode2: 'SC', isoCode3: 'SYC', nationality: 'Seychellois', phoneCode: '248' },
  { countryName: 'Sierra Leone', isoCode2: 'SL', isoCode3: 'SLE', nationality: 'Sierra Leonean', phoneCode: '232' },
  { countryName: 'Singapore', isoCode2: 'SG', isoCode3: 'SGP', nationality: 'Singaporean', phoneCode: '65' },
  {
    countryName: 'Sint Maarten (Dutch part)',
    isoCode2: 'SX',
    isoCode3: 'SXM',
    nationality: 'Sint Maarten',
    phoneCode: '1-721'
  },
  { countryName: 'Slovakia', isoCode2: 'SK', isoCode3: 'SVK', nationality: 'Slovak', phoneCode: '421' },
  { countryName: 'Slovenia', isoCode2: 'SI', isoCode3: 'SVN', nationality: 'Slovenian, Slovene', phoneCode: '386' },
  { countryName: 'Solomon Islands', isoCode2: 'SB', isoCode3: 'SLB', nationality: 'Solomon Island', phoneCode: '677' },
  { countryName: 'Somalia', isoCode2: 'SO', isoCode3: 'SOM', nationality: 'Somali, Somalian', phoneCode: '252' },
  { countryName: 'Somaliland', isoCode2: 'SQ', isoCode3: 'SQM', nationality: 'Somali, Somalian', phoneCode: '252' },
  { countryName: 'South Africa', isoCode2: 'ZA', isoCode3: 'ZAF', nationality: 'South African', phoneCode: '27' },
  {
    countryName: 'South Georgia and the South Sandwich Islands',
    isoCode2: 'GS',
    isoCode3: 'SGS',
    nationality: 'South Georgia or South Sandwich Islands',
    phoneCode: '500'
  },
  { countryName: 'South Sudan', isoCode2: 'SS', isoCode3: 'SSD', nationality: 'South Sudanese', phoneCode: '211' },
  { countryName: 'Spain', isoCode2: 'ES', isoCode3: 'ESP', nationality: 'Spanish', phoneCode: '34' },
  { countryName: 'Sri Lanka', isoCode2: 'LK', isoCode3: 'LKA', nationality: 'Sri Lankan', phoneCode: '94' },
  { countryName: 'Sudan', isoCode2: 'SD', isoCode3: 'SDN', nationality: 'Sudanese', phoneCode: '249' },
  { countryName: 'Suriname', isoCode2: 'SR', isoCode3: 'SUR', nationality: 'Surinamese', phoneCode: '597' },
  { countryName: 'Svalbard and Jan Mayen', isoCode2: 'SJ', isoCode3: 'SJM', nationality: 'Svalbard', phoneCode: '47' },
  { countryName: 'Swaziland', isoCode2: 'SZ', isoCode3: 'SWZ', nationality: 'Swazi', phoneCode: '268' },
  { countryName: 'Sweden', isoCode2: 'SE', isoCode3: 'SWE', nationality: 'Swedish', phoneCode: '46' },
  { countryName: 'Switzerland', isoCode2: 'CH', isoCode3: 'CHE', nationality: 'Swiss', phoneCode: '41' },
  { countryName: 'Syrian Arab Republic', isoCode2: 'SY', isoCode3: 'SYR', nationality: 'Syrian', phoneCode: '963' },
  {
    countryName: 'Taiwan, Province of China',
    isoCode2: 'TW',
    isoCode3: 'TWN',
    nationality: 'Chinese, Taiwanese',
    phoneCode: '886'
  },
  { countryName: 'Tajikistan', isoCode2: 'TJ', isoCode3: 'TJK', nationality: 'Tajikistani', phoneCode: '992' },
  {
    countryName: 'Tanzania',
    isoCode2: 'TZ',
    isoCode3: 'TZA',
    nationality: 'Tanzanian',
    phoneCode: '255'
  },
  { countryName: 'Thailand', isoCode2: 'TH', isoCode3: 'THA', nationality: 'Thai', phoneCode: '66' },
  { countryName: 'Timor-Leste', isoCode2: 'TL', isoCode3: 'TLS', nationality: 'Timorese', phoneCode: '670' },
  { countryName: 'Togo', isoCode2: 'TG', isoCode3: 'TGO', nationality: 'Togolese', phoneCode: '228' },
  { countryName: 'Tokelau', isoCode2: 'TK', isoCode3: 'TKL', nationality: 'Tokelauan', phoneCode: '690' },
  { countryName: 'Tonga', isoCode2: 'TO', isoCode3: 'TON', nationality: 'Tongan', phoneCode: '676' },
  {
    countryName: 'Trinidad and Tobago',
    isoCode2: 'TT',
    isoCode3: 'TTO',
    nationality: 'Trinidadian or Tobagonian',
    phoneCode: '1-868'
  },
  { countryName: 'Tunisia', isoCode2: 'TN', isoCode3: 'TUN', nationality: 'Tunisian', phoneCode: '216' },
  { countryName: 'Turkey', isoCode2: 'TR', isoCode3: 'TUR', nationality: 'Turkish', phoneCode: '90' },
  { countryName: 'Turkmenistan', isoCode2: 'TM', isoCode3: 'TKM', nationality: 'Turkmen', phoneCode: '993' },
  {
    countryName: 'Turks and Caicos Islands',
    isoCode2: 'TC',
    isoCode3: 'TCA',
    nationality: 'Turks and Caicos Island',
    phoneCode: '1-649'
  },
  { countryName: 'Tuvalu', isoCode2: 'TV', isoCode3: 'TUV', nationality: 'Tuvaluan', phoneCode: '688' },
  { countryName: 'Uganda', isoCode2: 'UG', isoCode3: 'UGA', nationality: 'Ugandan', phoneCode: '256' },
  { countryName: 'Ukraine', isoCode2: 'UA', isoCode3: 'UKR', nationality: 'Ukrainian', phoneCode: '380' },
  {
    countryName: 'United Arab Emirates',
    isoCode2: 'AE',
    isoCode3: 'ARE',
    nationality: 'Emirati, Emirian, Emiri',
    phoneCode: '971'
  },
  { countryName: 'United Kingdom', isoCode2: 'GB', isoCode3: 'GBR', nationality: 'British, UK', phoneCode: '44' },
  { countryName: 'United States', isoCode2: 'US', isoCode3: 'USA', nationality: 'American', phoneCode: '1' },
  { countryName: 'Uruguay', isoCode2: 'UY', isoCode3: 'URY', nationality: 'Uruguayan', phoneCode: '598' },
  { countryName: 'Uzbekistan', isoCode2: 'UZ', isoCode3: 'UZB', nationality: 'Uzbekistani, Uzbek', phoneCode: '998' },
  { countryName: 'Vanuatu', isoCode2: 'VU', isoCode3: 'VUT', nationality: 'Ni-Vanuatu, Vanuatuan', phoneCode: '678' },
  { countryName: 'Venezuela', isoCode2: 'VE', isoCode3: 'VEN', nationality: 'Venezuelan', phoneCode: '58' },
  { countryName: 'Vietnam', isoCode2: 'VN', isoCode3: 'VNM', nationality: 'Vietnamese', phoneCode: '84' },
  {
    countryName: 'British Virgin Islands',
    isoCode2: 'VG',
    isoCode3: 'VGB',
    nationality: 'British Virgin Island',
    phoneCode: '1-284'
  },
  {
    countryName: 'US Virgin Islands',
    isoCode2: 'VI',
    isoCode3: 'VIR',
    nationality: 'U.S. Virgin Island',
    phoneCode: '1-340'
  },
  {
    countryName: 'Wallis and Futuna',
    isoCode2: 'WF',
    isoCode3: 'WLF',
    nationality: 'Wallis and Futuna, Wallisian or Futunan',
    phoneCode: '681'
  },
  {
    countryName: 'Western Sahara',
    isoCode2: 'EH',
    isoCode3: 'ESH',
    nationality: 'Sahrawi, Sahrawian, Sahraouian',
    phoneCode: '212'
  },
  { countryName: 'Yemen', isoCode2: 'YE', isoCode3: 'YEM', nationality: 'Yemeni', phoneCode: '967' },
  { countryName: 'Zambia', isoCode2: 'ZM', isoCode3: 'ZMB', nationality: 'Zambian', phoneCode: '260' },
  { countryName: 'Zimbabwe', isoCode2: 'ZW', isoCode3: 'ZWE', nationality: 'Zimbabwean', phoneCode: '263' }
];
