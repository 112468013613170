import { ACTION_TYPES } from '../ReduxActionTypes';

const initialState = {
  paymentBody: {
    receivingService: null,
    receivingCurrency: null,
    sendingCurrency: null,
    moneyToSend: null,
    recipientFirstName: null,
    recipientLastName: null,
    moneyToReceive: null,
    total: null,
    reasonForSending: null,
    fee: null,
    recipientId: null
  },
  selectedRecipient: {},
  selectedRecipientPayers: [],
  error: undefined,
  userRecipients: [],
  userTransactions: [],
  currentUserIsRegistred: undefined,
  currentUserIsVerifiedPep: undefined,
  currentUserEmailIsConfirmed: undefined,
  currentUserIsPep: undefined,
  userTransactionAmountMonthLimitReached: undefined,
  userTotalTransactionAmountCurrentMonth: undefined,
  transactionPaymentStatus: undefined,
  sendMoneyDialogIsOpen: undefined
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_TRANSACTION_OBJECT:
      return { ...state, null: action.payload };
    case ACTION_TYPES.SET_PAYMENT_BODY:
      return { ...state, paymentBody: action.payload };
    case ACTION_TYPES.SET_SELECTED_RECIPIENT:
      return { ...state, selectedRecipient: action.payload };
    case ACTION_TYPES.SET_SELECTED_RECIPIENT_PAYERS:
        return { ...state, selectedRecipientPayers: action.payload };
    case ACTION_TYPES.SET_USER_RECIPIENTS:
      return { ...state, userRecipients: action.payload };
    case ACTION_TYPES.SET_USER_TRANSACTIONS:
        return { ...state, userTransactions: action.payload };
    case ACTION_TYPES.SET_DASHBOARD_ERROR:
      return { ...state, error: action.payload };
    case ACTION_TYPES.SET_IS_REGISTRED:
      return { ...state, currentUserIsRegistred: action.payload };
    case ACTION_TYPES.SET_IS_EMAIL_CONFIRMED:
      return { ...state, currentUserEmailIsConfirmed: action.payload };
    case ACTION_TYPES.SET_IS_VERIFIED_PEP:
      return { ...state, currentUserIsVerifiedPep: action.payload };
    case ACTION_TYPES.SET_IS_PEP:
      return { ...state, currentUserIsPep: action.payload };
    case ACTION_TYPES.SET_USER_TRANSACTIONS_AMOUNT_MONTH_LIMIT_REACHED:
      return { ...state, userTransactionAmountMonthLimitReached: action.payload };
    case ACTION_TYPES.SET_USER_TOTAL_TRANSACTION_AMOUNT_CURRENT_MONTH:
      return { ...state, userTotalTransactionAmountCurrentMonth: action.payload };
    case ACTION_TYPES.SET_TRANSACTION_PAYMENT_STATUS:
      return { ...state, transactionPaymentStatus: action.payload };
    case ACTION_TYPES.SET_SEND_MONEY_DIALOG_IS_OPEN:
      return { ...state, sendMoneyDialogIsOpen: action.payload };
    default:
      return state;
  }
};

export default DashboardReducer;
