import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme) => ({
  noResultsImgContainer: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(5)
  },
  noResultsImg: {
    height: '100px'
  },
  noResultsText: {
    height: '28px',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#4a6582',
    textAlign: 'center',
    marginBottom: '10px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '32px'
    },
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      float: 'none'
    }
  }
}));

function NoResults({ imgSrc, title, style }) {
  const classes = styles();
  const { t } = useTranslation(['common']);
  return (
    <div
      className={classNames(classes.noResultsImgContainer)}
      style={{ paddingTop: style?.paddingTop, paddingBottom: style?.paddingBottom }}
    >
      {imgSrc ? (
        <img
          height={100}
          width={100}
          className={classes.noResultsImg}
          src={imgSrc}
          alt={t('noResultsFound')}
          style={{ height: style?.imgHeight }}
        />
      ) : null}
      <Typography variant="h5" component="h5" align="left" className={classes.noResultsText}>
        {title}
      </Typography>
    </div>
  );
}

NoResults.defaultProps = {
  imgSrc: undefined,
  title: undefined,
  style: undefined
};

NoResults.propTypes = {
  imgSrc: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
};

export default NoResults;
