import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import _groupBy from 'lodash/groupBy';
import { Typography, Grid, Avatar, MenuItem, FormControl, Select , Alert } from '@mui/material';
import _isEmpty from 'lodash/isEmpty';

import NumberFormatter from '../../../utils/NumberFormatter';
import StatsBarChart from './StatsBarChart';
import Loading from '../../common/Loading';
import { errorHandler } from '../../../utils/errorMapper';

const useStyles = makeStyles((theme) => ({
  statsTitle: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
    color: theme.palette.globalAppTheme.globalAppTextColor,
    padding: theme.spacing(1.5)
  },
  select: {
    padding: 10,
    color: theme.palette.globalAppTheme.globalAppTextColor,
    opacity: '0.8',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    minWidth: 167
  },
  statsMoneySent: {
    width: 'auto',
    height: 75,
    gap: 10,
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.globalAppTheme.globalAppColor,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: 'inherit'
    },
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  },
  statsMoneyAmount: {
    textAlign: 'right',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.4,
    color: '#fff'
  },
  sentTitle: {
    textAlign: 'left',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.4,
    color: '#fff'
  },
  statsMoneyText: {
    margin: '30px 19px 30px 0',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  statsMoneyIconHolder: {
    width: 38,
    height: 38,
    borderRadius: theme.borderRadius,
    backgroundColor: '#ffffff'
  },
  avatarCircleRadius: {
    borderRadius: theme.borderRadius,
    backgroundColor: '#ffffff',
    padding: '7px 0px 1px 7px',
    width: '40px',
    height: '40 px'
  },
  disabled: {
    color: '#bebebf'
  },
  selectContainer: {
    paddingBottom: 16,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: 'inherit'
    }
  }
}));

const selectPeriods = [
  { displayValue: 'last3Months', value: 3 },
  { displayValue: 'last6Months', value: 6 },
  { displayValue: 'last12Months', value: 12 }
];

function StatsContainer({ loading, error }) {
  const classes = useStyles();
  const { t } = useTranslation(['dashboard-common']);
  const [selectedPeriod, setSelectedPeriod] = useState(3);
  const periods = useSelector((state) => state.userInfo?.transactionStats?.periods);

  const handleChange = (e) => {
    setSelectedPeriod(e.target.value);
  };

  const getSelectedDisplayValue = (option) => {
    if (option === null || selectPeriods.length === 0) {
      return <span>{t('selectAPeriod')}</span>;
    }
    const selectOption = selectPeriods.find((i) => i.value === option);
    return <span>{t(selectOption && selectOption.displayValue)}</span>;
  };

  const totalSentForPeriod = () =>
    periods &&
    periods[selectedPeriod] &&
    periods[selectedPeriod].reduce((accumulator, currentValue) => accumulator + Number.parseFloat(currentValue.amount), 0);

  const disableSelect = _isEmpty(periods) || _isEmpty(selectPeriods, 'timestamp');

  const groupSelectedPeriod = periods && periods[selectedPeriod] && _groupBy(periods[selectedPeriod], 'month');

  const groupedPeriod =
    groupSelectedPeriod &&
    Object.values(groupSelectedPeriod).map((item) => {
      if (item.length > 0) {
        return {
          month: item[0].month,
          amount: item.reduce((accumulator, currentValue) => accumulator + Number.parseFloat(currentValue.amount), 0),
          timestamp: item[0].timestamp,
          year: item[0].year
        };
      }
      return [...groupSelectedPeriod];
    });

  return (
    <Grid item sm={12} md={12} lg={3} className="lg-p-custom">
      <Grid container className={classes.selectContainer}>
        <Grid item sm={12} md={6} lg={6}>
          <Typography className={classes.statsTitle}>{t('stats')}</Typography>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <FormControl>
            <Select
              disabled={disableSelect}
              variant="outlined"
              classes={{ select: classnames(classes.select, disableSelect && classes.disabled) }}
              labelId="stats-select-label"
              id="stats-select"
              value={selectedPeriod}
              defaultValue={selectedPeriod || ''}
              renderValue={getSelectedDisplayValue}
              onChange={handleChange}
            >
              {selectPeriods.map((p) => (
                <MenuItem key={p.value} value={p.value}>
                  {t(p.displayValue)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {_isEmpty(error) ? null : (
          <Grid item sm={12} md={12} lg={12}>
            <Alert severity={error?.type || 'error'}>{errorHandler(error)}</Alert>
          </Grid>
        )}
      </Grid>
      <Grid container className={classes.statsMoneySent} alignItems="center">
        <Grid item sm={2} md={2} lg={2} style={{ paddingLeft: 20 }}>
          <Avatar classes={{ circular: classes.avatarCircleRadius }}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 svg-icon-style">
              <path d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          </Avatar>
        </Grid>
        <Grid item sm={4} md={4} lg={4} className={classes.statsSentTitle}>
          <Typography className={classes.sentTitle}>{t('sent')}</Typography>
        </Grid>
        <Grid item sm={5} md={5} lg={5} className={classes.statsMoneyAmount}>
          {_isEmpty(periods) ? '0 kr' : `${NumberFormatter.svAmountFormat(totalSentForPeriod())} kr`}
        </Grid>
      </Grid>
      {_isEmpty(periods) ? null : (
        <>
          <Grid item sm={12} md={12} lg={12}>
            <Typography className={classes.statsMoneyText}>
              {t('statsFromLast', { period: selectedPeriod, joinArrays: ' ' })}
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} lg={12}>
            {loading ? <Loading /> : <StatsBarChart selectedPeriod={selectedPeriod} periods={groupedPeriod} />}
          </Grid>
        </>
      )}
    </Grid>
  );
}

StatsContainer.defaultProps = {
  loading: false,
  error: undefined
};

StatsContainer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default StatsContainer;
