import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import makeStyles from '@mui/styles/makeStyles';
import Email from '../../common/Email';
import Celebration from '../../../assets/svg/Celebration.svg';
import Confetti from '../../../assets/img/confetti.gif';
import Button from '../../common/Button';
import support from '../../../constants/support';

const useStyles = makeStyles((theme) => ({
  welcomeContainer: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  cardContent: {
    padding: theme.spacing(3)
  },
  title: {
    fontFamily: 'Rubik',
    fontSize: '34px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '--1.42px',
    color: '#2f4662'
  },
  subTitle: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.78',
    letterSpacing: 'normal',
    color: '#4a6582'
  },
  continueButton: {
    width: 220,
    height: 62,
    marginTop: 20,
    marginBottom: 10,
    padding: '25 121',
    objectFit: 'contain',
    opacity: 0.9,
    borderRadius: theme.borderRadiusButton,
    backgroundColor: theme.palette.globalAppTheme.globalAppButtonColor,
    '&:hover,&:focus': {
      background: theme.palette.globalAppTheme.globalAppButtonColor
    }
  },
  continueButtonText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    paddingRight: 4,
    textTransform: 'none'
  },
  cardActionRoot: {
    textAlign: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  cardMediaRoot: {
    gridColumn: 1,
    gridRow: 1,
    objectFit: 'contain'
  },
  cardMediaContainer: {
    display: 'grid',
    paddingTop: theme.spacing(3),
    background: '#f3f5fd'
  }
}));

function Welcome({ handleClose, userName, open }) {
  const classes = useStyles();
  const { t } = useTranslation('dashboard-common');

  return (
    <Dialog transitionDuration={500} onClose={handleClose} open={open} scroll="body" classes={{ paper: classes.dialogPaper }}>
      <div className={classes.welcomeContainer}>
        <Grid item xs={12} md={12} lg={12} className={classes.placeholderImgContainer}>
          <Card>
            <div className={classes.cardMediaContainer}>
              <CardMedia
                classes={{ root: classes.cardMediaRoot }}
                component="img"
                width="500"
                height="150"
                image={Confetti}
                alt="Celebration"
              />
              <CardMedia
                classes={{ root: classes.cardMediaRoot }}
                component="img"
                width="500"
                height="300"
                image={Celebration}
                alt="Celebration"
              />
            </div>
            <CardContent className={classes.cardContent}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className={classNames('app-theme-text-color', classes.title)}
              >
                {t('welcomeToVivoWire')}
              </Typography>
              <Typography variant="h5" paragraph className={classes.subTitle}>
                {t('gladToJoinUs', { name: `${userName}👋` })}
              </Typography>
              <Email email={support.VIVOWIRE_CONTACT_EMAIL} />
            </CardContent>
            <CardActions classes={{ root: classes.cardActionRoot }}>
              <Button className={classes.continueButton} variant="contained" color="primary" onClick={handleClose}>
                <span className={classes.continueButtonText}>{t('letsGo')}</span>
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </div>
    </Dialog>
  );
}

Welcome.defaultProps = {};

Welcome.propTypes = {
  userName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default Welcome;
