export const articles = [
  {
    id: '71ff6c12-8dc0-4e30-a5ec-0b969d0014d8',
    title: 'Why Vivowire',
    subTitle: 'manyGoodReasonsToUseVivowire',
    titleImg: '',
    text: [
      'whyVivowirePargraphOne'/* ,
      'whyVivowirePargraphTwo',
      'whyVivowirePargraphThree',
      'whyVivowirePargraphFour',
      'whyVivowirePargraphFive',
      'whyVivowireSummeryPargraphOne' */
    ],
    readMore: '',
    readMoreUrl: ''
  },
  {
    id: '71ff6c12-8dc0-4e30-a5ec-0b969d0014d7',
    title: 'How do I login in',
    subTitle: 'howToLogin',
    titleImg: '',
    text: [
      'howToLoginStepOne',
      'howToLoginStepTwo',
      'howToLoginStepThree',
      'howToLoginStepFour',
      'howToLoginAlreadyRegistered',
      'howToLoginNewCustomers'
    ],
    readMore: '',
    readMoreUrl: ''
  },
  {
    id: '71ff6c19-6dc0-4e30-a8ec-0b969d0014d9',
    title: 'How do I register',
    subTitle: 'toGetStartedDoTheFollowing',
    titleImg: '',
    text: [
      'howToLoginStepOne',
      'howToLoginStepTwo',
      'howToLoginStepThree',
      'howToLoginStepFour',
      'nowTheIdentificationIsComplete',
      'howToRegisterStepOne',
      'howToRegisterStepTwo',
      'howToRegisterStepThree',
      'howToRegisterStepFour',
      'howToRegisterStepFive',
      'howToRegisterStepSix'
    ],
    readMore: '',
    readMoreUrl: ''
  },
  {
    id: '71ff6c19-6dc0-4e30-a8ec-0b969d001410',
    title: 'What is Brite',
    subTitle: '',
    titleImg: '',
    text: [
      'briteIsASwedishPaymentProvider',
      'inShortBriteIsAFastReliableAndSecure',
      'howDoIUseBrite',
      'itsVerySimpleNoRegistrationNoSoftware',
      'youCanReceiveAPayoutWithBrite'
    ],
    readMore: 'wouldYouLikeToKnowMore',
    readMoreUrl: 'https://docs.britepayments.com/support/'
  },
  {
    id: '71ff6c19-6dc0-4e30-a8ec-0b969d0014x9',
    title: 'How do I send money with Vivowire',
    subTitle: '',
    titleImg: '',
    text: [
      'howDoISendMoneyWithVivowireStepOne',
      'howDoISendMoneyWithVivowireStepTwo',
      'howDoISendMoneyWithVivowireStepThree',
      'howDoISendMoneyWithVivowireStepFour',
      'howDoISendMoneyWithVivowireStepFive',
      'howDoISendMoneyWithVivowireStepSix'
    ],
    readMore: '',
    readMoreUrl: 'https://docs.britepayments.com//'
  }
];