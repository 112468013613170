import makeStyles from '@mui/styles/makeStyles';

const mtfBoxPadding = 16;

const mtfBoxStyles = makeStyles((theme) => ({
  mtfBox: {
    background: '#FFF',
    margin: 'auto',
    borderRadius: theme.borderRadiusInput,
    justifyContent: 'space-around',
    textAlign: 'right',
    filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))',
    [theme.breakpoints.down('md')]: {
      width: '85%',
      height: 'auto'
    }
  },
  divider: {
    height: 'auto',
    marginLeft: theme.spacing(4)
  },
  gridContainer: {
    margin: 0
  },
  gridItem: {
    padding: mtfBoxPadding
  },
  gridItemRecipientInput: {
    padding: mtfBoxPadding,
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  },
  gridItemSelectSender: {
    padding: mtfBoxPadding,
    marginRight: 75,
    marginLeft: 30,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: '-110px',
      margin: '0'
    }
  },
  gridItemSelectRecipient: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 20,
    padding: mtfBoxPadding,
    marginLeft: '60px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      position: 'relative',
      top: '-110px',
      margin: '0'
    }
  },
  gridItemServiceSelect: {
    padding: mtfBoxPadding,
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  },

  gridItemSumInput: {
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`
    }
  },
  gridItemButton: {
    [theme.breakpoints.down('md')]: {
      padding: 20
    }
  },
  gridItemInput: {
    padding: mtfBoxPadding
  },
  exchangeRateContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)} 0 0 0`
    }
  }
}));

export default mtfBoxStyles;
